import React, { useEffect } from "react";
import { useRouter } from "next/navigation";
import { OneTapLoginConstants } from "../../../constants/keys/googleOneTapLogin";
import { checkCartAndLoginCookie } from "../../../utils/fus";
import { SOCIAL_LOGIN_KEY } from "../../../analytics/gaConstant";
import { setSessionStorage } from "../../../utils/storage/sessionStorageUtil";

/**
 * This functional component provides the functionality of Google One Tap Login
 *
 * @returns {React.ReactElement} It returns nothing.
 */
const OneTapLogin = () => {
  const router = useRouter();

  /**
   * @function handleCredentialResponse - This is the callback function from the initialization.
   * @param {*} res - The response containing the credential from Google One Tap.
   */
  const handleCredentialResponse = (res) => {
    if (res.credential) {
      // Construct the redirect URL
      const redirectUrl = `${window.location.origin}${OneTapLoginConstants.REDIRECT_PATH}?DONE_PAGE=${encodeURIComponent(window.location.href)}&jwtToken=${encodeURIComponent(res.credential)}`;
      // Redirect using window.location.href
      window.location.href = redirectUrl;
      setSessionStorage(SOCIAL_LOGIN_KEY, true);
    }
  };

  useEffect(() => {
    if (!checkCartAndLoginCookie()) {
      // Initialize Google One Tap
      if (window.google && window.google.accounts && window.google.accounts.id) {
        window.google.accounts.id.initialize({
          client_id: OneTapLoginConstants.CLIENT_ID,
          callback: handleCredentialResponse,
          cancel_on_tap_outside: false,
        });
        window.google.accounts.id.prompt();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return <></>;
};

export default OneTapLogin;
