"use client";
const CookieUtil = {
  setCookie: (name, value, options = {}) => {
    if (!name || typeof name !== "string") {
      console.error("Cookie name must be a non-empty string.");
      return;
    }
    if (typeof value !== "string") {
      console.error("Cookie value must be a string.");
      return;
    }

    let cookieString = `${name}=${encodeURIComponent(value)}`;

    if (options.maxAge && typeof options.maxAge === "number") {
      cookieString += `; max-age=${options.maxAge}`;
    }

    if (options.path && typeof options.path === "string") {
      cookieString += `; path=${options.path}`;
    }
    if (process.env.NEXT_PUBLIC_IS_LOCAL_MACHINE !== "true") {
      const hardCodedDomain = ".fnp.com";
      cookieString += `; domain=${hardCodedDomain}`;
    }

    if (options.encode && typeof options.encode === "function") {
      cookieString = options.encode(cookieString);
    }
    if (typeof document !== "undefined") {
      document.cookie = cookieString;
    }
  },

  getCookie: (name) => {
    if (!name || typeof name !== "string") {
      console.error("Cookie name must be a non-empty string.");
      return null;
    }
    const nameEQ = name + "=";
    if (typeof document !== "undefined") {
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
      }
      return null;
    }
  },

  deleteCookie: (name, options = {}) => {
    if (!name || typeof name !== "string") {
      console.error("Cookie name must be a non-empty string.");
      return;
    }

    let cookieString = `${name}=; max-age=-1`;

    if (options.path && typeof options.path === "string") {
      cookieString += `; path=${options.path}`;
    }
    if (process.env.NEXT_PUBLIC_IS_LOCAL_MACHINE !== "true") {
      const hardCodedDomain = ".fnp.com";
      cookieString += `; domain=${hardCodedDomain}`;
    }
    if (typeof document !== "undefined") {
      document.cookie = cookieString;
    }
  },
};

export default CookieUtil;
