import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import useCommonOmnitureData from "./useCommonOmnitureData";
import useOmnitureSetup from "./useOmnitureSetup";
import { PageTypes } from "../../utils/fnpPageType";
import { gatePopupTracking } from "../../analytics/omniture/omniture-consts";
import { COUNTRIES } from "../../constants/common/common";
import { AUTO_TRIGGER_LOCK, OUTSIDE_INDIA, WITHIN_INDIA } from "../../constants/common/locationFieldConstants";
import CookieUtil from "../../utils/storage/cookieUtil";

/**
 * Home page omniture setup
 */
export default function useCommonOmnitureSetup() {
  const { rootGeoId, catalogId, checkLoginInfo } = useCommonOmnitureData();
  const { autoGatePopupConfig, pincode, selectedIntlCity } = useSelector(
    (state) => ({
      autoGatePopupConfig: state?.locationLock?.configData,
      pincode: state?.locationLock?.selectedDomesticLocation?.pincode,
      selectedIntlCity: state?.locationLock?.selectedIntLocation?.location,
    }),
    shallowEqual,
  );
  const { isAutoGateEnabledOnDesktopHome, isAutoGateEnabledOnMobileHome } = autoGatePopupConfig || {};
  const isHomePage = typeof window !== "undefined" ? window?.fnpPageType === PageTypes.HOME : false;
  const isAutoTriggerLock = CookieUtil.getCookie(AUTO_TRIGGER_LOCK);

  const gateData = {
    category: {
      country: catalogId !== COUNTRIES.INDIA ? OUTSIDE_INDIA : WITHIN_INDIA,
      countryname: catalogId,
      city: catalogId !== COUNTRIES.INDIA ? selectedIntlCity : "",
      pincode: catalogId === COUNTRIES.INDIA ? pincode : "",
    },
  };

  if ((isAutoGateEnabledOnDesktopHome || isAutoGateEnabledOnMobileHome) && !isAutoTriggerLock && isHomePage) {
    gateData.gate = { popUp: gatePopupTracking.AUTO_GATE_POPUP_VIEWED };
  }

  const omniData = useMemo(
    () => ({
      userDetails: {
        checkLoginInfo,
      },
      appConfigs: {
        rootGeoId,
        catalogId,
      },
      gateDetails: {
        gateData,
        lastVisitedPincode: pincode,
        selectedIntlCity,
        autoGatePopupConfig,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [catalogId, checkLoginInfo, rootGeoId, autoGatePopupConfig],
  );

  useOmnitureSetup(omniData, [omniData?.userDetails?.checkLoginInfo]);
}
