/**
 * Stores a value in sessionStorage.
 *
 * @function setSessionStorage
 * @param {string} key - The key under which the value will be stored.
 * @param {any} value - The value to store. If the value is an object, it will be stringified.
 */
export const setSessionStorage = (key, value) => {
  try {
    const stringValue = typeof value === "object" ? JSON.stringify(value) : value;
    sessionStorage.setItem(key, stringValue);
  } catch (error) {
    console.error("Error saving to sessionStorage", error);
  }
};

/**
 * Retrieves a value from sessionStorage and converts it to a boolean if needed.
 *
 * @function getSessionStorage
 * @param {string} key - The key of the value to retrieve.
 * @returns {any} The retrieved value. If the value is "true" or "false", it will be converted to a boolean.
 */
export const getSessionStorage = (key) => {
  try {
    const value = sessionStorage.getItem(key);
    if (value === "true") return true;
    if (value === "false") return false;
    return value ? (value.startsWith("{") || value.startsWith("[") ? JSON.parse(value) : value) : null;
  } catch (error) {
    console.error("Error reading from sessionStorage", error);
    return null;
  }
};

/**
 * Deletes a value from sessionStorage.
 *
 * @function removeSessionStorage
 * @param {string} key - The key of the value to delete.
 */
export const removeSessionStorage = (key) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error("Error removing from sessionStorage", error);
  }
};
