import PropTypes from "prop-types";
import Modal from "../../../molecules/modal";
import IconButtonComponent from "../../../atoms/iconButton";
import { mediaPopUp } from "../../../../constants/common/headerToolbarConstants";

/**
 * MediaPopup component renders a modal for media inquiries.
 * @param {Object} props - Component props
 * @param {function} props.onToggleMediaPopup - Callback function to toggle the media popup visibility.
 * @returns {JSX.Element} The rendered component.
 */
const MediaPopup = ({ onToggleMediaPopup }) => {
  return (
    <Modal
      position="fixed"
      headerChildren="Media Enquires"
      headerClasses="text-24 flex justify-center items-center"
      containerClass="rounded-8 relative"
    >
      <IconButtonComponent
        labelColor="w-full font-normal"
        iconSrc="/icons/drawer-close.svg"
        onClick={onToggleMediaPopup}
        classes="py-2 absolute right-16 top-16 cursor-pointer"
      />
      <p className="text-18">
        {mediaPopUp?.PLEASE_MAIL_TO}
        <a href={`mailto:${mediaPopUp?.PR_EMAIL}`} className="mx-4 text-blue-600">
          {mediaPopUp?.PR_EMAIL}
        </a>
        {mediaPopUp?.FOR_QUERY}
      </p>
    </Modal>
  );
};
MediaPopup.propTypes = {
  onToggleMediaPopup: PropTypes.func.isRequired,
};
export default MediaPopup;
