import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { desktopIndiaConfig } from "../../../../../constants/mockData/mockData";
import CustomImage from "../../../../atoms/customImage";
import CookieUtil from "../../../../../utils/storage/cookieUtil";
import { requestToSaveSelectedCurrency } from "../../../../../store/slices/common/currencyLists";
import { LOCAL_CURRENCY } from "../../../../../constants/common/common";

/** */
const CurrencyList = ({ setSelectedCurrency, selectedCurrency }) => {
  const dispatch = useDispatch();
  const currencies = useSelector((state) => state?.currencyList?.currencies) || [];
  const currenciesListWithIconUrl = desktopIndiaConfig?.countryList || [];

  const updatedCurrencies = currencies.map((currencyItem) => {
    const matchingCountry =
      currenciesListWithIconUrl.length > 0 &&
      currenciesListWithIconUrl.find((country) => country.countryCurrency === currencyItem.currency.id);

    // Return a new object with iconUrl added if there's a matching country
    return matchingCountry ? { ...currencyItem, iconUrl: matchingCountry.iconUrl } : currencyItem;
  });

  /** */
  const onDropdownItemClick = (item) => {
    const options = {
      path: "/",
      encode: decodeURIComponent,
    };
    CookieUtil.setCookie(LOCAL_CURRENCY, item?.currency?.id, options);
    setSelectedCurrency(item?.currency?.id);
    dispatch(requestToSaveSelectedCurrency(item));
  };
  return (
    <>
      {updatedCurrencies.map((item, idx) => (
        <div
          key={idx}
          className={`flex h-48 flex-col justify-center ${selectedCurrency === item?.currency?.id ? "bg-fnp-100" : ""}`}
          onClick={() => onDropdownItemClick(item)}
        >
          <div className="flex h-full items-center gap-8 border-b-1 border-lightGrey px-16">
            <CustomImage
              src={item?.iconUrl}
              alt="dropdown-icons"
              sizes="10vw"
              width={26}
              height={20}
              isPriority={false}
            />
            <span
              className={`text-14 font-500 ${selectedCurrency === item?.currency?.id ? "text-fnp-500" : "text-grey-500"} `}
            >
              {item.currency.id}
            </span>
          </div>
        </div>
      ))}
    </>
  );
};
CurrencyList.propTypes = {
  setSelectedCurrency: PropType.func.isRequired,
  selectedCurrency: PropType.string.isRequired,
};
export default CurrencyList;
