/**
 * @param {*} urlParams
 * @param {*} appliedParams
 * @returns
 */
export const getReplaceURL = (urlParams, appliedParams) => {
  urlParams.set("sort", encodeURIComponent(appliedParams));
  const newSearch = urlParams.toString();
  const url = `${window.location.pathname}${newSearch ? `?${newSearch}` : ""}`;
  return url;
};
