import PropTypes from "prop-types";

/**
 * Accordion Content Component with smooth transition for showing/hiding content.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be displayed inside the accordion.
 * @param {number} props.index - The index of the accordion item.
 * @param {number} props.activeIndex - The index of the currently active accordion item.
 * @param {string} [props.styles=""] - Additional styles for custom styling.
 * @returns {JSX.Element} The rendered AccordionContent component.
 */
const AccordionContent = ({ children, index, activeIndex, styles = "" }) => {
  return (
    <div
      data-testid="accordion_content"
      className={`${styles} transition-[max-height, opacity] max-h-0 overflow-hidden opacity-0 duration-500 ease-in-out ${
        index === activeIndex ? "max-h-[1000px] opacity-100" : ""
      }`}
    >
      {children}
    </div>
  );
};

AccordionContent.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  activeIndex: PropTypes.number,
  styles: PropTypes.string,
};

export default AccordionContent;
