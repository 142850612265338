import PropTypes from "prop-types";
import Typography from "../../../components/atoms/typography";
import { useEffect, useRef } from "react";
import { homePageConstants } from "../../../constants/pageConstants/homeConstants";

/**
 * SameDayTimer component displays a countdown timer for same-day delivery.
 * It shows the time left until a given cutoff timestamp and updates every second.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.fetchData - Data fetched from an API, including images and background information.
 * @param {string} props.cutoffTimestamp - The cutoff timestamp in the format "DD-MM-YYYY HH:MM:SS".
 * @returns {JSX.Element}
 */
const SameDayTimer = ({ fetchData, cutoffTimestamp, isMobile }) => {
  const timeRef = useRef(null);

  // Parse the cutoff timestamp
  const [datePart, timePart] = cutoffTimestamp.split(" ");
  const [day, month, year] = datePart.split("-");
  const dateObject = new Date(`${year}-${month}-${day}T${timePart}`);
  const timestamp = dateObject.getTime();

  useEffect(() => {
    /**
     * updateTime function calculates the time difference between the current time
     * and the cutoff time, and updates the displayed time left.
     */
    const updateTime = () => {
      const currentTime = new Date().getTime();
      const finalTimedifference = timestamp - currentTime;

      let seconds = Math.floor((finalTimedifference / 1000) % 60);
      let minutes = Math.floor((finalTimedifference / (1000 * 60)) % 60);
      let hours = Math.floor((finalTimedifference / (1000 * 60 * 60)) % 24);

      // Format the time units to ensure they always display two digits
      seconds = seconds < 10 ? "0" + seconds : seconds;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      hours = hours < 10 ? "0" + hours : hours;

      // Update the displayed time
      if (timeRef.current) {
        timeRef.current.textContent = `${hours}:${minutes}:${seconds}`;
      }
    };

    // Set an interval to update the time every second
    const intervalId = setInterval(updateTime, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [timestamp]);

  return (
    <div className="flex flex-col justify-between gap-16 px-16 sm:flex-row sm:px-40">
      <div
        className="relative flex w-full flex-1 justify-between rounded-8 py-8 pb-14 pt-12 md:p-16"
        style={{
          backgroundImage: `url(${fetchData?.backGroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="flex w-1/3 items-end justify-center">
          <img
            src={fetchData?.leftImage}
            alt="ringing bell"
            loading="lazy"
            style={{ width: isMobile ? "81px" : "100px", height: isMobile ? "81px" : "100px" }}
          />
        </div>

        <div className="flex w-2/3 flex-col items-center justify-center">
          <Typography
            variant="h1"
            Tag="h2"
            classes="flex w-full justify-center text-black-100 text-16 font-500 md:text-24"
          >
            {homePageConstants.NEED_TODAY}
          </Typography>
          <Typography
            variant="h1"
            Tag="p"
            classes="flex w-full items-center justify-center text-center text-grey-500 text-10 font-400 mb-4 md:text-14 md:mb-8"
          >
            {homePageConstants.TIME_LEFT_TODAY_DELIVERY}
          </Typography>
          <div
            ref={timeRef}
            className="flex w-full items-center justify-center text-center text-16 font-500 text-red-300 md:text-24"
          >
            --:--:--
          </div>
          <a
            href="/same-day-delivery-gifts-lp"
            className="mt-4 min-w-[154px] rounded-4 bg-fnp-600 px-16 py-6 text-center text-12 font-600 leading-none text-white-100 md:mt-8 md:min-w-[200px] md:rounded-8 md:py-12 md:text-14"
          >
            {homePageConstants.FIND_GIFTS_NOW}
          </a>
        </div>
      </div>
    </div>
  );
};

// Adding propTypes for the SameDayTimer component
SameDayTimer.propTypes = {
  fetchData: PropTypes.shape({
    backGroundImage: PropTypes.string,
    leftImage: PropTypes.string,
  }),
  cutoffTimestamp: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default SameDayTimer;
