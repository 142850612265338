import { InterceptorClient } from "../../interceptor/interceptorClient";
import {
  GET_BLOCKED_DATES_URL,
  GET_DELIVERY_DATE_AND_GEO_PRICE,
  GET_INTERNATIONAL_DELIVERY_DATES,
  GET_INTERNATIONAL_SHIPPING_AND_TIME_SLOT,
  GET_PRICE_AND_EARLIEST_SHIP_DETAILS_URL,
  GET_SHIPPING_AND_TIME_SLOT_URL,
} from "./constant";

/**
 * Method to fetch deliveryDates on client side
 *
 * @param {string} contentURL page content slug
 * @returns {string} returns html file data
 */
export async function fetchPriceAndEarliestShipDetails({ productId, pinCode, currencyUomId, catalogId, countryGeoId }) {
  try {
    const res = await InterceptorClient.getFTSInstance().get(
      `${GET_PRICE_AND_EARLIEST_SHIP_DETAILS_URL(productId, pinCode, currencyUomId, catalogId, countryGeoId)}`,
      false,
    );
    return res;
  } catch (err) {
    throw new Error(err.message);
  }
}

/**
 * Method to fetch blockedDates on client side
 *
 * @param {string} contentURL page content slug
 * @returns {string} returns html file data
 */
export async function fetchBlockedDatesDetails({ productId, pinCode }) {
  try {
    const res = await InterceptorClient.getFTSInstance().get(`${GET_BLOCKED_DATES_URL(productId, pinCode)}`, false);
    return res;
  } catch (err) {
    throw new Error(err.message);
  }
}

/**
 * Method to fetch blockedDates on client side
 *
 * @param {string} contentURL page content slug
 * @returns {string} returns html file data
 */
export async function fetchShippingAndTimeSlotDetails({ productId, pinCode, deliveryDate, countryGeoId }) {
  try {
    const res = await InterceptorClient.getFTSInstance().get(
      `${GET_SHIPPING_AND_TIME_SLOT_URL(productId, pinCode, deliveryDate, countryGeoId)}`,
      false,
    );
    return res;
  } catch (err) {
    throw new Error(err.message);
  }
}

/**
 * Method to fetch blockedDates on client side
 *
 * @param {string} contentURL page content slug
 * @returns {string} returns html file data
 */
export async function fetchGetDeliveryDateAndGeoPriceDetails({
  productId,
  pinCode,
  currencyUomId,
  deliveryDate,
  catalogId,
}) {
  try {
    const res = await InterceptorClient.getFTSInstance().get(
      `${GET_DELIVERY_DATE_AND_GEO_PRICE(productId, pinCode, currencyUomId, deliveryDate, catalogId)}`,
      false,
    );
    return res;
  } catch (err) {
    throw new Error(err.message);
  }
}
/**
 * Method to fetch international shipping method and time slot on client side
 *
 * @param {string} productId product id of product
 * @param {string} city shipping method and slot as per city
 * @returns {object} shipping method and slot
 */
export async function fetchInternationalShippingAndTimeSlot({ productId, city }) {
  try {
    const response = await InterceptorClient.getFTSInstance().get(
      `${GET_INTERNATIONAL_SHIPPING_AND_TIME_SLOT(productId, city)}`,
      false,
    );
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
}

/**
 * Method to fetch international delivery dates on client side
 *
 * @param {string} productId product id of product
 * @param {string} city shipping method and slot as per city
 * @param {string} shipmentType shipment type id
 * @param {string} timeSlotId selected time slot
 * @returns {object} shipping method and slot
 */
export async function fetchInternationalDeliveryDates({ productId, city, shipmentType, timeSlotId }) {
  try {
    const response = await InterceptorClient.getFTSInstance().get(
      `${GET_INTERNATIONAL_DELIVERY_DATES(productId, city, shipmentType, timeSlotId)}`,
      false,
    );
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
}
