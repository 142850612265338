export const AVAILABLE_PINCODES = "global/static/pincode/availablepincodes";
export const PINCODE_MAPPING = "/d/control/jc?id=PINCODE_MAPPING";

/**
 * Generates the API endpoint URL to fetch the available pincodes.
 *
 * @returns {string} The API endpoint URL.
 */
export const fetchPincodeJson = (pincode) => {
  return `/d/assets/js/pincodes/${pincode}.json`;
};
/**
 * Generates the API endpoint URL to fetch the available pincodes based on latitude and longitude.
 *
 * @returns {string} The API endpoint URL.
 */
export const fetchCityPincodeBasedOnLocation = (latitude, longitude) => {
  return `/control/getPinCodeByPostalAddress?latitude=${latitude}&longitude=${longitude}`;
};
