import { CURRENCY_ID } from "../../../constants/common/common";
import { productTypes } from "../../../constants/pageConstants/pdpConstants";
import {
  resetGetDeliveryDateAndGeoPriceAction,
  setDeliveryDetailsAction,
} from "../../../store/slices/common/shippingDateAndSlotSelection";
import {
  requestToFetchProductStockAvailable,
  requestToUpdateProductDetails,
  setDeliveryType,
  setUserSelectedDeliveryType,
} from "../../../store/slices/pdpSlice";

/**
 * This method is used to check the product is international or not
 *
 * @returns {boolean}  will return true or false
 */
export const checkIsInternationalProduct = (primaryCategoryId) => {
  return primaryCategoryId === productTypes.INTERNATIONAL;
};

/**
 * This method is used to check that product is same sku or not
 *
 * @returns  {boolean} is same sku product or not .
 */
export const isSameSkuProduct = (assocProductDetails) => !!assocProductDetails;

/**
 * This method is used to check, delivery available for selected SKU and delivery type.
 *
 * @param {*} deliveryDetails
 * @param {*} isShippingDetailsExists
 * @returns
 */
export const checkIsSKUDeliverable = (deliveryDetails, isShippingDetailsExists) => {
  const dates = deliveryDetails?.deliveryDates;
  if (dates?.length) {
    return isShippingDetailsExists && deliveryDetails.isDatesAvailable;
  }
  return false;
};

/**
 * This method is used to check product is non deliverable or not for domestic.
 *
 * @param {*} deliveryDetails
 * @param {*} isShippingDetailsExists
 * @param {*} assocProductDetails
 * @param {*} primaryCategoryId
 * @returns {boolean} true or false
 */
export const isNonDeliverable = (deliveryDetails, isShippingDetailsExists, assocProductDetails) => {
  const isDatesAvailable = deliveryDetails?.isDatesAvailable;
  const isNotDeliverable = isSameSkuProduct(assocProductDetails)
    ? !checkIsSKUDeliverable(deliveryDetails, isShippingDetailsExists)
    : !isDatesAvailable;

  return isNotDeliverable;
};

/**
 * This method is used to check product is non deliverable or not for abroad.
 * @param {*} isShippingDetailsExists
 */
export const isNonDeliverableAbroad = (isShippingDetailsExists = true) => {
  const isNotDeliverable = !isShippingDetailsExists;
  return isNotDeliverable;
};

/**
 * This method is used to get assoc product price for the same sku products
 *
 * @param {string} productType type of product.
 * @param {*} product
 * @returns  {object} it will return price.
 * @returns
 */
export const getAssocProdIdPrice = (productType, product) => {
  let assocInfo;
  const { assocProductDetails } = product;
  if (productType === product.primaryCategoryId) {
    assocInfo = {
      productId: product.productId,
      price: product.productPrice.price,
      deliveryType: product.primaryCategoryId,
    };
  } else if (assocProductDetails && assocProductDetails.substituteProduct) {
    assocInfo = {
      productId: assocProductDetails.substituteProduct.productId,
      price: assocProductDetails.substituteProduct.productPrice[assocProductDetails.substituteProduct.productId].price,
      deliveryType: assocProductDetails.substituteProduct.primaryProductCategoryId,
    };
  }
  return assocInfo;
};

/**
 * This method is used to convert price as per the currency code and factor
 *
 * @param {number}  price product price.
 * @returns {number}  calculated price.
 */
export const getPrice = (price, selectedCurrency) => {
  if (selectedCurrency?.id === CURRENCY_ID || !(selectedCurrency?.id || selectedCurrency?.currencyCode)) {
    return price;
  }
  return (price * selectedCurrency.factor).toFixed(2);
};

/**
 * It will auto-filled the date time slot, if shipping details are available.
 * @param {*} param0
 * @param {*} deliveryDetails
 * @param {*} dispatch
 */
export const onAutoFillDateTimeSlots = ({ data, selectedShippingMethod }, deliveryDetails, dispatch) => {
  if (data?.length === 1 && deliveryDetails?.isDatesAvailable) {
    const earliestShippingDetails = data?.[0]?.[selectedShippingMethod];
    const { startTime, endTime } = earliestShippingDetails.timeSlots[0];
    const formatTime = `${startTime} - ${endTime} Hrs`;
    const timeSlotDetails = { timeSlots: earliestShippingDetails?.timeSlots?.[0], formatTime };
    dispatch(
      setDeliveryDetailsAction({
        currentShippingMethod: {
          shippingMethodId: selectedShippingMethod,
          shippingMethodName: earliestShippingDetails?.shippingMethodName,
        },
        timeSlots: timeSlotDetails,
        selectedDate: deliveryDetails?.selectedDeliveryDate,
      }),
    );
  } else {
    dispatch(setDeliveryDetailsAction({}));
    dispatch(resetGetDeliveryDateAndGeoPriceAction());
  }
};

/**
 * Switch Delivery Type
 * @param {*} stockData
 * @param {*} type
 * @param {*} deliveryTypeProductId
 * @param {*} productId
 * @param {*} primaryCategoryId
 * @param {*} catalogId
 * @param {*} dispatch
 */
export const switchDeliveryType = (
  stockData,
  type,
  deliveryTypeProductId,
  productId,
  primaryCategoryId,
  catalogId,
  dispatch,
) => {
  if (!stockData?.data?.length) {
    dispatch(requestToFetchProductStockAvailable({ productId: `${productId},${deliveryTypeProductId}` }));
  }
  dispatch(setDeliveryType(type));
  if (type !== primaryCategoryId && deliveryTypeProductId !== productId) {
    dispatch(
      requestToUpdateProductDetails({
        productId: deliveryTypeProductId,
        currencyUomId: CURRENCY_ID,
        catalogId,
      }),
    );
  }
  dispatch(setUserSelectedDeliveryType(type));
};

/**
 * Return true if product is out of stock.
 * @param {*} productStockData
 * @param {*} productId
 * @returns
 */
export const getOutOfStock = (productStockData, productId) => {
  const filteredItem = productStockData?.data?.filter((item) => item.productId === productId);
  return filteredItem?.[0]?.isProductStockAvailable === "N";
};
