"use client";
import PropTypes from "prop-types";
import React from "react";

import MegaMenu from "./megaMenu";

/**
 * Component representing the desktop version of the mega menu.
 *
 * @param {object} props - The props passed to the component.
 * @param {array} props.menuData - The data representing the menu items.
 * @returns {JSX.Element|null} DesktopMenu component.
 */
const Menu = ({ data, classes }) => {
  return (
    <div
      className={`border-grey-100 z-1 hidden grid-cols-1 gap-30 border-b border-t px-40 py-12 md:flex ${classes}`}
      data-testid="desktop-menu"
    >
      <MegaMenu menuItems={data} />
    </div>
  );
};

Menu.propTypes = {
  classes: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      banners: PropTypes.array,
      categoryName: PropTypes.string,
      productCategoryId: PropTypes.string,
      parentProductCategoryId: PropTypes.string,
      childCategoryList: PropTypes.array,
    }),
  ).isRequired,
};

const DesktopMenu = React.memo(Menu);
export default DesktopMenu;
