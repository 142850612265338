import { InterceptorClient } from "../../interceptor/interceptorClient";
import { webFeatureControl } from "./constants";

const apiBaseUrl = process.env.NEXT_PUBLIC_R2_CLIENT_API_GATEWAY;

/**
 * Fetches web feature control settings.
 * @async
 * @returns {Promise<Object>} A promise that resolves to the web feature control settings.
 * @throws {Error} If the fetch request fails.
 */
export const getWebFeatureControls = async () => {
  return await InterceptorClient.getGatewayInstance(apiBaseUrl).get(`${webFeatureControl()}`);
};
