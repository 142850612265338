import PropTypes from "prop-types";
import Checkbox from "../../atoms/checkbox/index";

/**
 * Description placeholder
 * @date 13/03/2024 - 11:48:10
 *
 * @param {{ id?: string; label?: string; labelPlacement?: string; labelColor?: string; isDisabled?: boolean; }} param
 * @param {string} [param.id="checkbox"]
 * @param {string} [param.label=""]
 * @param {string} [param.labelPlacement="right"]
 * @param {string} [param.labelColor=""]
 * @param {boolean} [param.isDisabled=false]
 * @returns
 */
const CheckboxItemList = ({
  id = "checkbox",
  label = "",
  labelPlacement = "right",
  labelColor = "",
  activeColorClass = "",
  isDisabled = false,
  onChange,
  ...restParams
}) => {
  return (
    <div
      className={`flex w-full items-center justify-between ${!label ? "border-b-2" : ""}`}
      data-testid="checkbox-item"
    >
      <Checkbox
        id={id}
        label={label}
        labelPlacement={labelPlacement}
        labelColor={labelColor}
        isDisabled={isDisabled}
        onChange={onChange}
        activeColor={`font-600 text-fnp-500 ${activeColorClass ? activeColorClass : "text-14"}`}
        {...restParams}
      />
    </div>
  );
};
CheckboxItemList.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelPlacement: PropTypes.string,
  labelColor: PropTypes.string,
  activeColorClass: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckboxItemList;
