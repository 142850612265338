import { setUserPersonalization } from "../../../store/slices/personalizationSlice";
import { getLocalStorageItem, setLocalStorageItem } from "../../storage/localStorageUtil";

/**
 * This method is used to validate extension
 *
 * @param {string} allowedMediaType comma separated allowed media types.
 * @param {string} currentMediaType current media type.
 * @returns {boolean} return valid or false.
 */
export function validateExtension(allowedMediaType, currentMediaType) {
  const mediaTypeToArray = allowedMediaType.split(", ");
  return mediaTypeToArray.indexOf(currentMediaType) > -1;
}
/**
 * This method brings component to add personalized image
 *
 * @param {string} minSize minimum size of file.
 * @param {string} minUnit minimum size file unit.
 * @param {string} maxSize maximum size file unit.
 * @param {string} maxUnit maximum size file unit.
 * @param {number} fileSize current file size.
 * @returns {void} jsx for the personalized image.
 */
export const isValidFileSize = (minSize, minUnit, maxSize, maxUnit, fileSize) => {
  const MB = 1024 * 1024;
  const KB = 1024;
  const minFileSize = minSize * (minUnit === "KB" ? KB : MB);
  const maxFileSize = maxSize * (maxUnit === "MB" ? MB : KB);
  return fileSize > minFileSize && fileSize < maxFileSize;
};
/**
 * This method is used to lead number with zero
 *
 * @param {number} number number.
 * @returns {string} return modified number
 */
export const leadingZero = (number) => {
  return number <= 9 ? `0${number}` : number;
};
/**
 * This method is used to get foldername of date
 *
 * @returns {string} return date folder
 */
export const getFolderDirectory = () => {
  const currentDate = new Date();
  const date = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  return `${year}${leadingZero(month)}${leadingZero(date)}`;
};
/**
 * This method is used to generate random character with specified length
 *
 * @param {number} size length of random characters.
 * @returns {string} return random characters
 */
export const generateRandomCharacters = (size) => {
  let generatedOutput = "";
  const storedCharacters = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const totalCharacterSize = storedCharacters.length;
  for (let index = 0; index < size; index += 1) {
    generatedOutput += storedCharacters.charAt(Math.floor(Math.random() * totalCharacterSize));
  }
  return generatedOutput;
};

/**
 * This method brings component to add personalized image
 *
 * @param {string} filename name of file.
 * @returns {string} file path.
 */
export const generateFilePath = (filename) => {
  return `pz/${getFolderDirectory()}/${generateRandomCharacters(10)}-${filename}`;
};

/**
 * Returns the supported file types based on the allowed media types.
 *
 * @param {string} allowedMediaType - A comma-separated string of allowed media types (e.g., "image/jpeg,image/png").
 * @returns {string} The supported file extensions (e.g., "JPEG, PNG").
 */
export const getSupportedFileType = (allowedMediaType) => {
  const data = allowedMediaType?.split(",");
  const extensions = [];
  data?.forEach((element) => {
    const [, extension] = element.split("/");
    extensions.push(extension.toUpperCase());
  });
  return extensions.join(", ");
};

/**
 * This method is used to prepare image configs as per the input required
 *
 * @param {string} maxInputRequired max number of images to configure
 * @returns {object} returns the initial structure of the multi image upload
 */
export const imageCountConfig = (maxInputRequired) => {
  const configs = [];
  Array(parseInt(maxInputRequired, 10))
    .fill("")
    .map((_, index) => {
      configs.push({
        uploading: false,
        uploaded: false,
        sizeError: false,
        uploadError: false,
        imgUrl: null,
        id: index,
      });
      return configs;
    });
  return configs;
};

/**
 * This method is used to check uploaded image count
 *
 * @returns {number} uploaded image count.
 */
export const checkMultiUploadedImageCount = (imageConfigs) => {
  const uploaded = imageConfigs.filter((c) => c.imgUrl !== null);
  return uploaded?.length;
};

/**
 * Updates or adds a personalization item in the user personalization state.
 *
 * This function checks if a personalization with the same `fieldName` exists in the current
 * `userPersonalization` array. If it does, the existing item is updated with the new value;
 * otherwise, the new personalization is added to the array.
 *
 * @param {Object} personalizationValue - The new personalization value to add or update.
 * @param {Array} userPersonalization - The current list of user personalizations.
 * @param {Function} dispatch - The dispatch function to update the personalization state.
 */
export const handleSetUserPersonalizations = (productId, personalizationValue, userPersonalization, dispatch) => {
  const existingPersonalizationIndex = userPersonalization.findIndex(
    (personalization) => personalization.fieldName === personalizationValue?.fieldName,
  );

  if (Array.isArray(personalizationValue)) {
    setLocalStorageItem([productId], JSON.stringify(personalizationValue));
    dispatch(setUserPersonalization(personalizationValue));
  } else if (existingPersonalizationIndex >= 0) {
    const updatedPersonalization = [...userPersonalization];
    updatedPersonalization[existingPersonalizationIndex] = personalizationValue;
    dispatch(setUserPersonalization(updatedPersonalization));
    setLocalStorageItem([productId], JSON.stringify(updatedPersonalization));
  } else {
    dispatch(setUserPersonalization([...userPersonalization, personalizationValue]));
    setLocalStorageItem([productId], JSON.stringify([...userPersonalization, personalizationValue]));
  }
};

/**
 *
 * @param {*} productId
 * @param {*} dispatch
 */
export const handlePersonalizationDetailsOnRefresh = (productId) => {
  let getDetailsFromLocalStorage;
  try {
    getDetailsFromLocalStorage = JSON.parse(getLocalStorageItem(productId)) || {};
  } catch (error) {
    getDetailsFromLocalStorage = {}; // Fallback to an empty object if parsing fails
  }
  return getDetailsFromLocalStorage;
};

/**
 * Validates the personalization data against user inputs.
 * @param {object} personlizedData - The data for the personalized product.
 * @param {Array} userPersonalization - The user's personalization inputs.
 * @returns {boolean} Returns `true` if validation passes or if no validation is required, otherwise `false`.
 */
export const checkPersonalizationValidation = (personlizedData, userPersonalization) => {
  const personalizationOptions = personlizedData?.personalizedOptions;
  if (!personlizedData?.personalizedProduct || personalizationOptions?.[0]?.required === "N") {
    // No personalized product, validation not required
    return true;
  }

  // Check if lengths are the same
  if (personalizationOptions.length !== userPersonalization.length) {
    return false;
  }

  // Check each personalization option
  return personalizationOptions.every((option) => {
    // Find corresponding user personalization by fieldName
    const userOption = userPersonalization.find((user) => user.fieldName === option.fieldName);

    // Validate user personalization
    return (
      userOption &&
      Array.isArray(userOption.value) &&
      userOption.value.length > 0 &&
      userOption.value.some((value) => value !== "")
    );
  });
};
