import { createSlice } from "@reduxjs/toolkit";
import { COUNTRIES } from "../../../constants/common/common";

const initialState = {
  isMobile: false,
  catalogId: COUNTRIES.INDIA,
};

export const AppConfigSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    setIsMobile: (state, action) => {
      return {
        ...state,
        isMobile: action.payload,
      };
    },
    setCatalogId: (state, action) => {
      return {
        ...state,
        catalogId: action.payload,
      };
    },
  },
});

export default AppConfigSlice.reducer;
export const { setIsMobile, setCatalogId } = AppConfigSlice.actions;
