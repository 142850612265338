import PropTypes from "prop-types";
import React from "react";

/**
 * Description placeholder
 * @date 08/04/2024 - 15:33:41
 *
 * @param {{ children: any; }} param
 * @param {*} param.children // A react element node
 * @returns {React.Component} // Accordion component
 */
const Accordion = ({ children }) => {
  return <div className="w-full">{children}</div>;
};

Accordion.propTypes = {
  children: PropTypes.node,
};
export default Accordion;
