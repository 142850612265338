/* eslint-disable react/jsx-handler-names */
import PropTypes from "prop-types";
import CustomImage from "../customImage";

/**
 * Text field component with various customization options.
 * @param {object} props - The props for the component.
 * @param {string} props.type - The type of the input field.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {boolean} props.isRequired - Whether the input field is required or not.
 * @param {string} props.variant - The variant of the input field.
 * @param {string} props.id - The id of the input field.
 * @param {JSX.Element} props.endAdornment - The end adornment element for the input field.
 * @param {JSX.Element} props.startAdornment - The start adornment element for the input field.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.inputValue - The value of the input field.
 * @param {boolean} props.isError - Whether the input field has an error or not.
 * @param {boolean} props.isSuccess - Whether the input field is successful or not.
 * @param {string} props.message - The message to display below the input field.
 * @param {boolean} props.isAutoComplete - Whether autocomplete is enabled for the input field.
 * @param {boolean} props.hasStartDivider - Whether to display a divider at the start of the input field.
 * @param {boolean} props.hasEndDivider -Whether to display a divider at the end of the input field.
 * @param {string} props.classes - Custom class for textfield input box.
 * @param {string} props.messageClass - Custom class for error, success message.
 * @returns {JSX.Element} The TextField component.
 */
export default function TextField({
  type,
  placeholder = "",
  isRequired = false,
  variant = "primary",
  id,
  endAdornment = null,
  startAdornment = null,
  label = "",
  inputValue = "",
  isError = false,
  isSuccess = false,
  message = "",
  hasStartDivider = false,
  hasEndDivider = false,
  isAutoComplete = false,
  classes = "",
  messageClass = "",
  customRef = null,
  ...restParams
}) {
  /**
   *  Receieve input type name as param.
   *  @param {string} inputType
   *  @returns {boolean} retrun true or false.
   */
  const checkInputType = (inputType) => {
    const nonTextTypes = ["radio", "checkbox", "submit"];
    return nonTextTypes.includes(inputType) ? "text" : inputType;
  };

  const variantClasses = {
    primary: `focus:border-[#191A0B] border-grey-300 rounded-lg border text-fnp-500 text-sm block  focus:outline-none w-full p-3.5 h-44 md:h-auto`,
    primaryWithDarkBorder:
      "rounded-lg border border-grey-500 text-fnp-500 text-sm block focus:outline-none w-full p-3.5 h-44 md:h-auto",
    withComponent:
      "rounded-lg border border-grey-300 text-fnp-500 text-sm block  focus:outline-none w-full p-3.5 h-44 md:h-auto",
    "outline-none":
      "rounded-lg text-fnp-500 text-sm  placeholder-grey-500 focus:outline-none w-full p-3.5 h-44 md:h-auto",
  };

  const labelPositionClass = startAdornment ? "left-44" : "left-16";
  return (
    <div className="relative w-full">
      <div className="relative">
        {startAdornment && (
          <div
            className={`absolute inset-y-0 left-0 flex items-center pl-[12px] pr-[12px]  ${variant === "withComponent" ? "min-w-88 max-w-88" : ""} ${startAdornment?.props?.onClick ? "cursor-pointer" : "pointer-events-none"}`}
            onClick={startAdornment?.props?.onClick ?? null}
          >
            {hasStartDivider && (
              <div
                data-testid="start_divider"
                className={`absolute right-1 ${variant === "withComponent" ? "top-0" : "top-2"} ${variant === "withComponent" ? "bottom-0" : "bottom-2"} w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-400 to-transparent opacity-25 lg:block dark:opacity-100`}
              />
            )}
            {startAdornment}
          </div>
        )}
        <input
          aria-label={id}
          data-testid="input_field"
          type={checkInputType(type)}
          className={` ${classes} inter ${variantClasses[variant]} ${endAdornment ? "pr-11" : ""} ${startAdornment ? "pl-11" : ""} ${
            variant === "withComponent" ? "pl-[6rem]" : ""
          } ${isError ? "border-red" : ""} ${label ? "peer" : ""}  overflow-hidden text-ellipsis whitespace-nowrap disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-white-900`}
          placeholder={label ? "" : placeholder}
          required={isRequired}
          id={id}
          autoComplete={isAutoComplete ? "on" : "off"}
          value={inputValue}
          ref={customRef}
          {...(inputValue && label ? { "data-te-input-state-active": true } : {})}
          {...restParams}
        />
        {label && (
          <label
            data-testid="text_field_label"
            htmlFor={id}
            className={`pointer-events-none absolute top-1 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.75rem] text-sm leading-[1.6] peer-focus:-translate-y-[0.5rem] peer-focus:bg-white-900 peer-focus:py-0 ${
              isError ? "text-red-100" : "text-grey-400"
            } peer-focus:text-primary peer-data-[te-input-state-active]:bg-white peer-focus:bg-white transition-all duration-200 ease-out peer-focus:scale-[0.7] peer-data-[te-input-state-active]:-translate-y-[0.5rem] peer-data-[te-input-state-active]:scale-[0.7] peer-data-[te-input-state-active]:bg-white-900 peer-data-[te-input-state-active]:py-0 motion-reduce:transition-none ${labelPositionClass} ${variant === "withComponent" ? "left-[6rem]" : ""} ${
              startAdornment
                ? "peer-focus:-translate-x-[0.1rem] peer-data-[te-input-state-active]:-translate-x-[0.1rem]"
                : ""
            }`}
          >
            {label}
          </label>
        )}
        {endAdornment && (
          <div
            className={`absolute inset-y-0 right-0 flex items-center pl-[6px] pr-[12px] ${
              endAdornment.props.onClick ? "cursor-pointer" : "pointer-events-none"
            }`}
            onClick={endAdornment?.props?.onClick ?? null}
          >
            {hasEndDivider && (
              <div
                data-testid="end_divider"
                className="absolute bottom-8 left-0 top-8 w-[1.5px] self-stretch border-t-0 bg-[#EEE] lg:block"
              />
            )}
            {endAdornment}
          </div>
        )}
      </div>
      {message && (isSuccess || isError) && (
        <span
          data-testid="input_related_message"
          className={`mt-10 flex items-center rounded-4 p-4 text-xs font-500 tracking-wide text-grey-500 ${
            isError ? "bg-red-100 bg-opacity-5" : ""
          } ${isSuccess ? "bg-fnp-200 bg-opacity-5 font-600 text-fnp-500" : ""} ${messageClass}`}
        >
          {isError && (
            <div>
              <CustomImage src="/icons/warning.svg" width={16} height={16} alt="" sizes="10vw" isPriority={false} />
            </div>
          )}
          <p className="ml-4">{message}</p>
        </span>
      )}
    </div>
  );
}
TextField.propTypes = {
  type: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  variant: PropTypes.oneOf(["primary", "withComponent", "outline-none", "primaryWithDarkBorder"]).isRequired,
  id: PropTypes.string.isRequired,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  label: PropTypes.string,
  inputValue: PropTypes.string,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  message: PropTypes.string,
  hasStartDivider: PropTypes.bool,
  hasEndDivider: PropTypes.bool,
  isAutoComplete: PropTypes.bool,
  placeholder: PropTypes.string,
  classes: PropTypes.string,
  messageClass: PropTypes.string,
  customRef: PropTypes.any,
};
