/* eslint-disable react/boolean-prop-naming */
"use client";

import { PropTypes } from "prop-types";
import React, { useEffect, useRef } from "react";
import ButtonComponent from "../../atoms/button";
import { lockBody, unlockBody } from "../../../utils/document";
/**
 * PopOver Component.
 *
 * @param {object} props - The props object.
 * @param {boolean} props.isVisible - Flag indicating whether the popover is visible.
 * @param {Function} props.onTogglePopover - Function to toggle the visibility of the popover.
 * @param {string} props.isBackdrop - The variant of the popover (e.g., "backdrop").
 * @param {string} props.classes - Additional classes to apply to the popover.
 * @param {React.ReactNode} props.children - The content to display inside the popover.
 * @param {React.RefObject} props.triggerRef - A reference to the element that triggers the popover.
 * @param {string} props.title - The title to display in the popover.
 * @param {number} props.leftOffset - The left offset for positioning the popover.
 * @param {number} props.topOffset - The top offset for positioning the popover.
 * @param {boolean} props.isShowCancelIcon - Flag indicating whether to show a cancel icon.
 * @param {boolean} props.isShowPageScroll - Flag indicating whether the page scrolls when the popover is visible.
 * @returns {JSX.Element} The PopOver component.
 */
const PopOver = ({
  isVisible = false,
  onTogglePopover,
  isBackdrop = false,
  classes = "",
  parentClass = "relative",
  children,
  triggerRef = null,
  title = "",
  topOffset = 0,
  isShowCancelIcon = false,
  isShowPageScroll = false,
  leftOffset = 0,
}) => {
  const popoverRef = useRef(null);
  /**
   * Handles click outside of the popover to close it.
   * @param {MouseEvent} event - The mouse event.
   */
  const onOutsideClick = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target) && onTogglePopover !== undefined) {
      onTogglePopover(true);
    }
  };

  useEffect(() => {
    if (isVisible && !isShowPageScroll) {
      document.addEventListener("mousedown", onOutsideClick);
      lockBody();
    }
    return () => {
      if (isVisible && !isShowPageScroll) {
        document.removeEventListener("mousedown", onOutsideClick);
        unlockBody();
      }
    };
  }, [isVisible]);

  return (
    <div className={parentClass}>
      {isVisible && (
        <>
          {isBackdrop && <div className="fixed inset-0 z-10 bg-white-900 opacity-100 md:bg-black-900 md:opacity-50" />}
          <div
            ref={popoverRef}
            className={`border-grey-300 fixed z-10 inline-block rounded-2xl border bg-white-900 text-sm text-black-900 shadow-sm transition-opacity duration-300 ${classes}`}
            style={{
              top: topOffset,
              left: triggerRef?.current?.offsetLeft || document.body.offsetLeft + leftOffset,
              transformOrigin: "top center",
            }}
            role="tooltip"
            data-popper-placement="bottom-start"
          >
            <div className={`relative w-full	rounded-lg bg-white-900`}>
              {isShowCancelIcon && (
                <div className="flex min-w-3 items-center justify-between ">
                  <div>{title}</div>
                  <div className={`${!title && "absolute"} right-1 top-0 z-10`}>
                    <ButtonComponent
                      buttonVariant="icon"
                      icon="/icons/close-circle.svg"
                      onClick={onTogglePopover}
                      classes="border-0"
                      size="large"
                    />
                  </div>
                </div>
              )}

              <div className="flex flex-col">{children}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

PopOver.propTypes = {
  isVisible: PropTypes.bool,
  onTogglePopover: PropTypes.func,
  isBackdrop: PropTypes.bool,
  classes: PropTypes.string,
  parentClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  triggerRef: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  leftOffset: PropTypes.number,
  topOffset: PropTypes.number,
  isShowCancelIcon: PropTypes.bool,
  isShowPageScroll: PropTypes.bool,
  noOverflow: PropTypes.bool,
};

export default PopOver;
