import { useEffect, useRef, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams, usePathname } from "next/navigation";
import dynamic from "next/dynamic";
import CookieUtil from "../../../../utils/storage/cookieUtil";
import CustomImage from "../../../atoms/customImage";
import { CHANGE, COUNTRIES, DELIVER_TO, ENTER_LOCATION_AVAILABILITY } from "../../../../constants/common/common";
import {
  setIsShowMobileLocationLockAction,
  setSelectedIntLocation,
  setSelectedCountryAction,
  setSelectedDomesticLocation,
  setIsShowMobileLocationLockDrawerAction,
  getLocationLockConfigDataAction,
  onFocusRequestFromTextfield,
} from "../../../../store/slices/locationLock";
import { LAST_VISITED_ADDRESS, LAST_VISITED_PINCODE, SELECTED_INTERNATIONAL_CITY } from "../../../../constants/storage";
import isEmpty from "lodash.isempty";
import MobileLocationLockDrawer from "../mobileLocationLockDrawer";
import { lockBody, unlockBody } from "../../../../utils/document";
import { AUTO_TRIGGER_LOCK, FROM_LOCATION_LOCK } from "../../../../constants/common/locationFieldConstants";
import { getHash, pageName } from "../../../../utils/common";
import useAnalytics from "../../../../analytics/useAnalytics";
import { GA4_EVENTS } from "../../../../analytics/gaConstant";
import { cleverTapCommonEvents } from "../../../../analytics/clevertapConstants";

const MobileLocationLock = dynamic(() => import("../mobileLocationLock"), { ssr: false });

/**
 * LocationLockStrip component renders a strip to display the user's last visited location.
 * If the location is available, it shows the pincode and address; otherwise, it prompts
 * the user to enter their location. It also allows toggling the visibility of a location lock popover.
 *
 * @returns {JSX.Element} The rendered LocationLockStrip component.
 */
const LocationLockStrip = () => {
  const triggerRef = useRef();
  const dispatch = useDispatch();
  const pathname = usePathname();
  const { trackEvent } = useAnalytics();
  const params = useParams();
  const [checkIfMandatory, setCheckIfMandatory] = useState(false);
  const {
    isShowMobileLocationLockAction,
    isShowMobileLocationLockDrawer,
    selectedDomesticLocation,
    selectedIntLocation,
    selectedCountryFromList,
    catalogId,
    configData,
  } = useSelector(
    (state) => ({
      isShowMobileLocationLockAction: state?.locationLock?.isShowMobileLocationLock,
      isShowMobileLocationLockDrawer: state?.locationLock?.isShowMobileLocationLockDrawer,
      selectedDomesticLocation: state.locationLock.selectedDomesticLocation,
      selectedIntLocation: state.locationLock.selectedIntLocation,
      selectedCountryFromList: state?.locationLock?.selectedCountryFromList,
      catalogId: state.appConfig.catalogId,
      configData: state?.locationLock?.configData,
    }),
    shallowEqual,
  );

  const { isAutoGateEnabledOnMobilePLP, isAutoGateMandateOnMobilePLP } = configData;

  const isAutoTriggerLock = CookieUtil.getCookie(AUTO_TRIGGER_LOCK);
  const isMandateForMobile =
    !isAutoTriggerLock &&
    isAutoGateMandateOnMobilePLP &&
    catalogId === COUNTRIES.INDIA &&
    selectedCountryFromList === COUNTRIES.INDIA;
  const isAutoForMobile = !isAutoTriggerLock && isAutoGateEnabledOnMobilePLP;

  const selectedIntlCity = CookieUtil.getCookie(SELECTED_INTERNATIONAL_CITY);

  const lastVisitedAddress = CookieUtil.getCookie(LAST_VISITED_ADDRESS);
  const lastVisitedPincode = CookieUtil.getCookie(LAST_VISITED_PINCODE);

  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");
  const commonPageData = {
    page_name: pageName(isHome, isPLP, pathname, "urlIdentifier"),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
    mandatory: isMandateForMobile,
    auto: isAutoForMobile,
  };
  const ishomePlpPdpLocationData = (isHome || isPLP) && commonPageData;

  useEffect(() => {
    if (getHash() === FROM_LOCATION_LOCK) {
      dispatch(setIsShowMobileLocationLockAction(true));
    }
  }, [params]);

  useEffect(() => {
    const jsonSelectedIntlCity = JSON.parse(selectedIntlCity);

    if (!isEmpty(jsonSelectedIntlCity) && catalogId !== COUNTRIES.INDIA) {
      const selectedCountriesValues = Object.values(jsonSelectedIntlCity);

      const selectedCountry = selectedCountriesValues.filter((country) => {
        return country.catalogId === catalogId;
      })?.[0];

      dispatch(setSelectedIntLocation({ pincode: selectedCountry?.cityId, location: selectedCountry?.city }));
      dispatch(setSelectedCountryAction(selectedCountry));
    } else {
      if (lastVisitedAddress && lastVisitedPincode) {
        dispatch(
          setSelectedDomesticLocation({
            pincode: lastVisitedPincode,
            address: lastVisitedAddress,
          }),
        );
      }
    }
  }, [selectedIntlCity, catalogId, lastVisitedAddress, lastVisitedPincode]);

  useEffect(() => {
    if (!isEmpty(selectedDomesticLocation)) {
      trackEvent(GA4_EVENTS?.PINCODE_AVAILABLE, { pincode: selectedDomesticLocation?.pincode }, ["ga4"]);
    }
  }, [selectedDomesticLocation]);

  useEffect(() => {
    if (
      ((isEmpty(selectedDomesticLocation) && catalogId === COUNTRIES.INDIA) ||
        (isEmpty(selectedIntLocation?.pincode) && catalogId !== COUNTRIES.INDIA)) &&
      !isAutoTriggerLock
    ) {
      if (!Object.keys(configData)?.length) {
        dispatch(getLocationLockConfigDataAction());
      } else if (isAutoForMobile) {
        dispatch(setIsShowMobileLocationLockDrawerAction(true));
      }
    } else {
      setCheckIfMandatory(false);
    }
  }, [configData, selectedDomesticLocation, selectedIntLocation?.pincode]);

  /**
   * Toggles the visibility of the LocationLock popover and manages the state for outside click handling.
   * @function
   */
  const onShowLocationLock = useCallback(() => {
    if (!isShowMobileLocationLockAction) {
      if (catalogId !== COUNTRIES.INDIA ? isEmpty(selectedIntLocation) : isEmpty(selectedDomesticLocation)) {
        dispatch(onFocusRequestFromTextfield(true));
      } else {
        dispatch(onFocusRequestFromTextfield(false));
      }
      dispatch(setIsShowMobileLocationLockAction(true));
      trackEvent(
        cleverTapCommonEvents.locationClicked,
        {
          ...ishomePlpPdpLocationData,
          is_location_available: !!(lastVisitedPincode || lastVisitedAddress),
        },
        ["clevertap"],
      );
    }
  }, [isShowMobileLocationLockAction]);

  /**
   * Computes the CSS class for text color based on the presence of location data.
   * @returns {string} The appropriate CSS class for the text color.
   */
  const textClass = useMemo(
    () => (selectedDomesticLocation?.pincode && selectedDomesticLocation?.address ? "text-grey-400" : "text-fnp-300"),
    [selectedDomesticLocation],
  );

  /**
   * Renders the default view when no location data is available.
   * @returns {JSX.Element} The rendered default view.
   */
  const renderDefault = () => {
    return (
      <>
        <div className="flex flex-1 gap-8">
          <CustomImage
            src="/icons/question-location.svg"
            alt="question location icon"
            width={20}
            height={20}
            isPriority={false}
            sizes="10vw"
          />
          <span className="flex w-0 flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-14 font-500">
            {ENTER_LOCATION_AVAILABILITY}
            <CustomImage
              src="/icons/green-chevron.svg"
              alt="green chevron"
              width={16}
              height={16}
              isPriority={false}
              sizes="10vw"
            />
          </span>
        </div>
      </>
    );
  };

  /**
   * Renders the view when location data (pincode and address) is available.
   * @returns {JSX.Element} The rendered view with location data.
   */
  const renderPincodeAddress = (value) => {
    return (
      <>
        <div className="flex flex-1 gap-8">
          <CustomImage
            src="/icons/location-Icon.svg"
            alt="location icon"
            width={20}
            height={20}
            isPriority={false}
            sizes="10vw"
          />
          <span className="w-0 flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-14 font-500 text-fnp-500">{`${DELIVER_TO} ${value}`}</span>
        </div>
        <div className="flex-1/2 flex gap-4 text-14 font-500 text-fnp-300">
          {CHANGE}
          <CustomImage
            src="/icons/green-chevron.svg"
            alt="green chevron"
            width={16}
            height={16}
            isPriority={false}
            sizes="10vw"
          />
        </div>
      </>
    );
  };

  /**
   * Determines the view to render based on the selected location and catalog.
   * If an international location with a pincode and location name is selected, it renders the location view.
   * Otherwise, if a domestic location with a pincode and address is selected, it renders the address view.
   * If neither condition is met, it renders a default view.
   *
   * @returns {JSX.Element} - The rendered view based on the location data.
   */
  const handleLocationView = () => {
    if (catalogId !== COUNTRIES.INDIA) {
      if (!isEmpty(selectedIntLocation?.location)) {
        return renderPincodeAddress(`${selectedIntLocation?.location}`);
      }
      return renderDefault();
    }

    if (selectedDomesticLocation?.pincode && selectedDomesticLocation?.address && catalogId === COUNTRIES.INDIA) {
      return renderPincodeAddress(`${selectedDomesticLocation?.pincode}, ${selectedDomesticLocation?.address}`);
    }
    return renderDefault();
  };

  /**
   * To Continue Shopping by selecting location.
   */
  const onContinueShoppingClick = () => {
    if (!isMandateForMobile) {
      unlockBody();
      dispatch(setIsShowMobileLocationLockDrawerAction(false));
    } else if (isEmpty(selectedDomesticLocation)) {
      lockBody();
      setCheckIfMandatory(isMandateForMobile);
    }
  };
  useEffect(() => {
    if (isShowMobileLocationLockDrawer) {
      const pageLocationPopupData = {
        ...commonPageData,
        is_location_available: !!(lastVisitedPincode || lastVisitedAddress),
      };
      trackEvent(cleverTapCommonEvents.locationPopupViewed, pageLocationPopupData, ["clevertap"]);
    }
  }, [isShowMobileLocationLockDrawer]);
  return (
    <>
      <div
        className={`flex w-full justify-between gap-16 bg-gradient-to-r from-fnp-100 to-white-900 px-16 py-10 text-14 ${textClass}`}
        onClick={onShowLocationLock}
        ref={triggerRef}
      >
        {handleLocationView()}
      </div>
      {isShowMobileLocationLockDrawer && (
        <MobileLocationLockDrawer
          isShowMobileLocationLockDrawer={isShowMobileLocationLockDrawer}
          onContinueShoppingClick={onContinueShoppingClick}
          isMandateForMobile={isMandateForMobile}
          isAutoForMobile={isAutoForMobile}
          checkIfMandatory={checkIfMandatory}
          setMandatoryError={(isDisable) => setCheckIfMandatory(isDisable)}
        />
      )}
      {isShowMobileLocationLockAction && (
        <MobileLocationLock
          isMandatoryLocation={checkIfMandatory}
          isAutoLocation={isAutoForMobile}
          setMandatoryError={(isDisable) => setCheckIfMandatory(isDisable)}
        />
      )}
    </>
  );
};

export default LocationLockStrip;
