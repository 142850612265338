import PropTypes from "prop-types";
import { PLP_BANNERS_OPTIONS } from "../../../constants/common/splideOptions";
import CustomImage from "../../atoms/customImage";
import Link from "next/link";
import { SlickNextArrow, SlickPrevArrow } from "../carouselHelpers";
import Slider from "react-slick";

/**
 * CarouselContainer component renders a carousel using the Splide library.
 * Each slide in the carousel is an image that links to a specific URL.
 * @param {Object} props
 * @param {Array<Object>} props.bannerList - Array of banner objects to be displayed in the carousel.
 * @returns {JSX.Element} The rendered CarouselContainer component.
 */
const CarouselContainer = ({ bannerList }) => {
  return (
    <Slider
      {...PLP_BANNERS_OPTIONS}
      nextArrow={<SlickNextArrow />}
      prevArrow={<SlickPrevArrow />}
      className="relative mb-16 w-full"
    >
      {bannerList?.map((item, index) => (
        <Link href={item.landingUrl} key={index}>
          <CustomImage
            src={item.imageUrl}
            width={1520}
            height={190}
            alt={item.altTag}
            isPriority={true}
            customStyle={{ width: "100%", height: "100%" }}
          />
        </Link>
      ))}
    </Slider>
  );
};

CarouselContainer.propTypes = {
  bannerList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CarouselContainer;
