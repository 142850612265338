import React from "react";
import PropTypes from "prop-types";
import { plpPageConstants } from "../../../constants/pageConstants/plpConstants";

/**
 * Component displaying the earliest delivery date for a product.
 * @param {Object} props - The component props.
 * @param {string} props.earliestDate - The ID of the product.
 * @returns {JSX.Element} - The JSX for the component.
 */
const EarliestDeliveryDate = ({ earliestDate }) => {
  const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  /**
   *
   * @param {*} dateString
   * @param {*} format
   * @returns
   */
  const getFormattedDate = (dateString, format = "DD/MM") => {
    if (!dateString) return "";
    const dateToConvertToDate = new Date(dateString.split("-").reverse().join("-"));
    return format && `${dateToConvertToDate.getDate()} ${MONTH_NAMES[dateToConvertToDate.getMonth()]}`;
  };

  /**
   *
   * @param {*} earliestDate
   * @returns
   */
  const renderEarliestDeliveryDateFormat = (date) => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    let nd = today.getDate() + 1;
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    if (nd < 10) nd = "0" + nd;
    const Today = dd + "-" + mm + "-" + yyyy;
    const Tomorrow = nd + "-" + mm + "-" + yyyy;
    if (date === Today) {
      return "Today";
    } else if (date === Tomorrow) {
      return "Tomorrow";
    } else {
      return getFormattedDate(date);
    }
  };

  return earliestDate ? (
    <div className={"text-xs"}>
      {earliestDate === "_NA_" ? (
        <span className={`text-10 font-500 text-red-600 md:text-12`}>{plpPageConstants.OUT_OF_STOCK}</span>
      ) : (
        <span className="text-10 font-500 text-blue-200 md:text-12">
          {plpPageConstants.EARLIEST_DELIVERY}: {renderEarliestDeliveryDateFormat(earliestDate)}
        </span>
      )}
    </div>
  ) : (
    <span className="dynamicHeight hidden" />
  );
};

EarliestDeliveryDate.propTypes = {
  earliestDate: PropTypes.string,
};

export default EarliestDeliveryDate;
