import PropTypes from "prop-types";
import CustomImage from "../../../components/atoms/customImage";

/** */
const DownloadApp = ({ isMobile, data }) => {
  return (
    data?.bannerLink && (
      <div className={`relative w-full overflow-hidden px-0 md:rounded-2xl md:px-40`}>
        <a href={`${data?.bannerLink}`}>
          <CustomImage
            width={isMobile ? data?.mobileBanner?.dimensions?.width : data?.desktopBanner?.dimensions?.width}
            height={isMobile ? data?.mobileBanner?.dimensions?.height : data?.desktopBanner?.dimensions?.height}
            src={isMobile ? data?.mobileBanner?.banner_image_url : data?.desktopBanner?.banner_image_url}
            alt="Download App"
          />
        </a>
      </div>
    )
  );
};
DownloadApp.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
};
export default DownloadApp;
