import PropType from "prop-types";
/**
 * A reusable React component for rendering a modal content.
 * @param {params} props
 * @typedef {Object} params
 * @property {JSX.Element} children - The content to be displayed inside the modal content. (Required)
 * @returns {JSX.Element} The Modal Content.
 */
const ModalContent = ({ children }) => {
  return (
    <div className="h-full max-h-[400px] overflow-y-auto p-16 scrollbar-thin scrollbar-webkit" role="modal-content">
      {children}
    </div>
  );
};
ModalContent.propTypes = {
  children: PropType.node.isRequired,
};

export default ModalContent;
