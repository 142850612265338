/* eslint-disable react/jsx-handler-names */
import PropTypes from "prop-types";
import Typography from "../../../components/atoms/typography";
import CategoryImage from "../../../components/molecules/categoryImage";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";

/**
 * SendGiftAbroadComponent displays a list of gift categories for sending abroad in a carousel.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Object} props.sendGiftsAbroad - The data object containing the gift categories
 * @param {string} props.sendGiftsAbroad.options - Slide options

 * @returns {JSX.Element}
 */
function SendGiftAbroadComponent({ sendGiftsAbroad, dimensions = { width: 390, height: 390 } }) {
  const { trackEvent } = useAnalytics();
  const pageBannerData = {
    page_name: cleverTapCommonWords.HOME,
    page_type: cleverTapCommonWords.HOME,
  };
  return (
    <div className="mb-16 w-full bg-gradient-to-b from-fnp-100/60  to-fnp-100/0 px-16 py-16 sm:px-40">
      <div className="mb-16 flex w-full items-center justify-between">
        <Typography variant="h1" Tag="h4" classes={`font-600 text-16 sm:text-24`}>
          {sendGiftsAbroad?.category_Name}
        </Typography>
        <a href={sendGiftsAbroad?.category_link} className="flex items-center text-fnp-300">
          All Countries <img src="/icons/arrow-right-fnp.svg" className="text-fnp-300" alt="arrow-right-fnp" />
        </a>
      </div>
      <div className="flex flex-wrap justify-evenly gap-12">
        {sendGiftsAbroad?.list?.length > 0 &&
          sendGiftsAbroad?.list?.map((icon, index) => (
            <div key={index} className="w-[70px] md:w-[100px] lg:w-[125px] xxl:w-[200px]">
              <a
                key={index}
                href={icon.category_link}
                onClick={() => {
                  trackEvent(
                    cleverTapCommonEvents.bannerClicked,
                    {
                      ...pageBannerData,
                      banner_id: index,
                      banner_name: icon?.category_Name,
                    },
                    ["clevertap"],
                  );
                }}
              >
                <CategoryImage
                  varient="imageWithText"
                  key={index}
                  imageSrc={icon.banner_image_url}
                  label={icon.category_Name}
                  alt={icon.category_Name}
                  width={dimensions?.width}
                  height={dimensions?.height}
                  sizes="12vw"
                  classes="overflow-hidden rounded-24"
                  isPriority={false}
                />
              </a>
            </div>
          ))}
      </div>
    </div>
  );
}

export default SendGiftAbroadComponent;

SendGiftAbroadComponent.propTypes = {
  sendGiftsAbroad: PropTypes.object.isRequired,
  dimensions: PropTypes.object.isRequired,
};
