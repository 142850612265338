import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Drawer from "../../molecules/drawer";
import RadioButton from "../../atoms/radioButton";
import { plpCleverTapEvents, plpPageConstants } from "../../../constants/pageConstants/plpConstants";
import { getReplaceURL } from "./helper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { requestToFetchProductListingAction } from "../../../store/slices/plpSlice";
import { COUNTRIES, fnpPageType } from "../../../constants/common/common";
import { unlockBody } from "../../../utils/document";
import useAnalytics from "../../../analytics/useAnalytics";
import { GA4_EVENTS } from "../../../analytics/gaConstant";
import { usePathname } from "next/navigation";

/**
 * SortMobile component for sorting data on mobile devices.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Flag indicating whether the drawer is open.
 * @param {Function} props.onClose - Callback function to close the drawer.
 * @param {Array} props.data - Array of objects containing data to be sorted.
 * @returns {JSX.Element} SortMobile component JSX.
 */
export default function SortMobile({ isOpen, onClose: onClose, data, categoryId, selectSortName, setSelectSortName }) {
  const { appliedFilters, appliedSort, pincode, intPincode, catalogId } = useSelector(
    (state) => ({
      appliedFilters: state?.plp?.appliedFilters,
      appliedSort: state?.plp?.appliedSort,
      pincode: state?.locationLock?.selectedDomesticLocation?.pincode,
      intPincode: state?.locationLock?.selectedIntLocation?.pincode,
      catalogId: state.appConfig.catalogId,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const pathname = usePathname();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (appliedSort !== "") {
      const activeSort = data?.filter((item) => item.value === appliedSort);
      setSelectSortName(activeSort?.[0]?.level);
    }
  }, [appliedSort]);

  /**
   * Handles the change event when a radio button is selected.
   * @param {Object} event - The event object.
   */
  const onChange = (value, label) => {
    setSelectSortName?.(label);
    if (value != null && value != undefined) {
      const urlParams = new URLSearchParams(window?.location?.search);
      const newUrl = getReplaceURL(urlParams, value);
      const qs = urlParams.has("qs") ? urlParams.get("qs") : null;
      window.scrollTo(0, 0);
      const commonParams = {
        page: 0,
        size: plpPageConstants.MOBILE_PRODUCT_PER_PAGE,
        geoId: catalogId || COUNTRIES?.INDIA,
        sortOption: encodeURIComponent(value),
      };

      const actionParams = qs
        ? { ...commonParams, pageType: plpPageConstants.SEARCH, qs }
        : {
            ...commonParams,
            categoryUrl: `fnp.com/${categoryId}`,
            pincode: catalogId !== COUNTRIES.INDIA ? intPincode : pincode,
            filters: encodeURIComponent(appliedFilters),
          };

      dispatch(requestToFetchProductListingAction(actionParams));
      trackEvent(
        plpCleverTapEvents.SORT_SELECTED,
        {
          page_name: pathname,
          page_type: fnpPageType.CATEGORY,
          updated_sort: label,
        },
        ["clevertap"],
      );
      trackEvent(GA4_EVENTS.FILTER_CLICKED, { active_filters: null, selected_filter: label }, ["ga4"]);
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);
      unlockBody();
      onClose();
    }
  };

  return (
    <Drawer position="bottom" isOpen={isOpen} onDrawerClose={onClose} styles="p-0">
      <div>
        <div className="border-b border-gray-200 px-16 pb-12">
          <span className="text-16 font-600 text-fnp-500">{plpPageConstants.SORT}</span>
        </div>
        <div className="px-16 pt-12">
          {data?.map((item, index) => (
            <RadioButton
              id={`${index}`}
              label={item?.level}
              key={item?.value}
              labelPlacement="right"
              name={item?.value}
              onChange={() => onChange(item?.value, item?.level)}
              isChecked={selectSortName === item?.level}
              labelColor={`text-14 ${item?.level === selectSortName ? "text-fnp-500 font-600" : "text-grey-500 font-400"}`}
              styles="py-10"
            />
          ))}
        </div>
      </div>
    </Drawer>
  );
}

SortMobile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  categoryId: PropTypes.string.isRequired,
  selectSortName: PropTypes.string.isRequired,
  setSelectSortName: PropTypes.func.isRequired,
};
