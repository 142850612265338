import PropTypes from "prop-types";
import CustomImage from "../../atoms/customImage";

/**
 * Description placeholder
 * @date 08/04/2024 - 15:34:43
 *
 * @param {{ children: any; index: any; changeActiveIndex: any; activeIndex: any; activeClass: any; inactiveClass: any; styles: any; }} param
 * @param {*} param.children // A react element node
 * @param {*} param.index // Accordion index to be passed to accordion content to render accordion body
 * @param {*} param.changeActiveIndex // Callback function to change active index
 * @param {*} param.activeIndex // Index of the active accordion
 * @param {*} param.activeClass // CSS class name for the active accordion
 * @param {*} param.inactiveClass // CSS class name for the inactive accordion
 * @param {*} param.styles // CSS class name
 * @returns{React.Component}
 */
const AccordionTitle = ({
  children,
  index,
  changeActiveIndex,
  activeIndex,
  activeClass = "",
  inactiveClass = "",
  styles = "",
}) => {
  /**
   * Description placeholder
   * @date 08/04/2024 - 15:34:43
   *
   * @returns {void} // changes only the active index
   */
  const onClick = () => {
    if (activeIndex === index) {
      changeActiveIndex(-1);
    } else {
      changeActiveIndex(index);
    }
  };

  return (
    <button
      onClick={onClick}
      data-testid="accrodion_title"
      className={`w-full text-left ${index === activeIndex ? activeClass : inactiveClass} ${styles} items-center`}
    >
      {children}
      <div className={`transform transition duration-300 ${activeIndex === index ? "-rotate-180" : ""}`}>
        <CustomImage
          src="/icons/arrow-down.svg"
          height={12}
          width={12}
          sizes="10vw"
          alt="down-arrow"
          isPriority={false}
        />
      </div>
    </button>
  );
};
AccordionTitle.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  changeActiveIndex: PropTypes.func,
  activeIndex: PropTypes.number,
  activeClass: PropTypes.string,
  inactiveClass: PropTypes.string,
  styles: PropTypes.string,
};
export default AccordionTitle;
