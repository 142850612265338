import { InterceptorClient } from "../../interceptor/interceptorClient";
import { ADD_ONS_TO_CART, GET_ADD_ON_INFO } from "./constant";

const baseURL = process.env.NEXT_PUBLIC_BASE_URL;
/**
 * Method to fetch deliveryDates on client side
 *
 * @param {string} contentURL page content slug
 * @returns {string} returns html file data
 */
export async function fetchAddOnInfo(props) {
  try {
    const res = await InterceptorClient.getFTSInstance(baseURL).get(`${GET_ADD_ON_INFO(props)}`, false);
    return res;
  } catch (err) {
    throw new Error(err.message);
  }
}

/**
 * Method to add an add-on to the shopping cart on the client side
 *
 * @param {Object} payload - The data required to add the add-on to the cart.
 * @returns {Object} Returns the response from the server after adding the add-on.
 */
export async function addAddonToCart(payload) {
  try {
    const res = await InterceptorClient.getFTSInstance(baseURL).post(payload, ADD_ONS_TO_CART(), false);
    return res;
  } catch (err) {
    throw new Error(err.message);
  }
}
