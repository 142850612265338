import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientVariants: [],
  clientVariantType: "",
  clientFlavourType: "",
  clientVariantLabel: "",
  clientCurrentClickedItem: {},
  checkBuyNowOrProceedToPay: "",
  deliveryType: "",
  userSelectedDelivery: {},
  isLoaded: false,
  fullProductDetails: {},
  flavorList: [],
  clientVariantFlavour: "",
  galleryClickedImage: "",
  deliveryTimeLeft: {},
  pincodeError: false,
  deliveryDateError: false,
  personalizationError: false,
  surgePriceDetails: {},
};

export const PDPSlice = createSlice({
  name: "ProductDescription",
  initialState,
  reducers: {
    setClientVariants: (state, action) => {
      state.clientVariants = action.payload;
    },
    setClientVariantType: (state, action) => {
      state.clientVariantType = action.payload;
    },
    setClientVariantsLabel: (state, action) => {
      state.clientVariantLabel = action.payload;
    },
    setClientFlavorType: (state, action) => {
      return {
        ...state,
        clientVariantFlavour: action.payload,
      };
    },
    setClientCurrentClickedItem: (state, action) => {
      state.clientCurrentClickedItem = action.payload;
    },
    setBuyNowOrProceedToPayClick: (state, action) => {
      state.checkBuyNowOrProceedToPay = action.payload;
    },
    setDeliveryType: (state, action) => {
      state.deliveryType = action.payload;
    },
    setUserSelectedDeliveryType: (state, action) => {
      state.userSelectedDelivery = {
        ...state.userSelectedDelivery,
        deliveryType: action.payload,
      };
    },
    requestToUpdateProductDetails: (state) => {
      return {
        ...state,
        isLoaded: false,
      };
    },
    responseFromUpdateProductDetails: (state, action) => {
      return {
        ...state,
        clientCurrentClickedItem: {
          ...action.payload.data,
          productStockAvailable: state?.clientCurrentClickedItem?.productStockAvailable,
        },
        isLoaded: true,
      };
    },
    requestToFetchProductStockAvailable: (state) => {
      return {
        ...state,
        isLoaded: false,
      };
    },
    responseFromFetchProductStockAvailable: (state, action) => {
      return {
        ...state,
        clientCurrentClickedItem: {
          ...state.clientCurrentClickedItem,
          productStockAvailable: action.payload,
        },
        isLoaded: true,
      };
    },
    failedToFetchProductDetailAction: (state, action) => {
      return {
        ...state,
        failedToProductDetailDetails: true,
        error: action.payload,
        isLoaded: true,
      };
    },
    setFullProductDetails: (state, action) => {
      state.fullProductDetails = action.payload;
    },
    setFlavorListForCakeTypeProduct: (state, action) => {
      return {
        ...state,
        flavorList: action.payload,
      };
    },
    setDisplayGalleryClickedImage: (state, action) => {
      state.galleryClickedImage = action.payload;
    },
    requestGetDeliveryTimeLeft: () => {},
    responseGetDeliveryTimeLeft: (state, action) => {
      state.deliveryTimeLeft = action.payload;
    },
    setPincodeErrorFromAddToCart: (state, action) => {
      return {
        ...state,
        pincodeError: action.payload,
      };
    },
    setDeliveryDateErrorFromAddToCart: (state, action) => {
      return {
        ...state,
        deliveryDateError: action.payload,
      };
    },
    setPersonalizationErrorFromAddToCart: (state, action) => {
      return {
        ...state,
        personalizationError: action.payload,
      };
    },
    requestSurgePriceDetails: () => {},
    setSurgePriceDetailsData: (state, action) => {
      return {
        ...state,
        surgePriceDetails: action.payload,
      };
    },
  },
});

export default PDPSlice.reducer;
export const {
  setClientVariants,
  setClientVariantType,
  setClientVariantsLabel,
  setClientCurrentClickedItem,
  setBuyNowOrProceedToPayClick,
  setDeliveryType,
  setUserSelectedDeliveryType,
  requestToUpdateProductDetails,
  responseFromUpdateProductDetails,
  requestToFetchProductStockAvailable,
  responseFromFetchProductStockAvailable,
  failedToFetchProductDetailAction,
  setFullProductDetails,
  setFlavorListForCakeTypeProduct,
  setClientFlavorType,
  setDisplayGalleryClickedImage,
  requestGetDeliveryTimeLeft,
  responseGetDeliveryTimeLeft,
  setPincodeErrorFromAddToCart,
  setDeliveryDateErrorFromAddToCart,
  setPersonalizationErrorFromAddToCart,
  requestSurgePriceDetails,
  setSurgePriceDetailsData,
} = PDPSlice.actions;
