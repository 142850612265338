import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import {
  addToCartService,
  deleteCartItemService,
  setCartItemValuesService,
  viewCartService,
  updateAddOnQuantity,
} from "../../../api/cartApi/index.js";
import {
  failedAddToCart,
  failedDeleteCartItem,
  failedViewCart,
  failedSetCartItemValues,
  requestAddToCart,
  requestDeleteCartItem,
  requestViewCart,
  requestSetCartItemValues,
  responseAddToCart,
  responseViewCart,
  responseSetCartItemValues,
  responseDeleteCartItem,
  requestUpdateCart,
  responseUpdateCart,
  failedUpdateCart,
  requestToCartNotification,
  setIsCartVisible,
  setPdpCartNotificationPopUp,
  setIsSameItemInCart,
} from "../../slices/cartSlice.js";
import { initiateFusCall } from "../commonSaga/index.js";
import { requestToFetchAddOnInfoAction, resetAddOnState } from "../../slices/common/addOn.js";
import updateViewCartJSON from "../../../utils/updateViewCartJSON/index.js";
import { ADD_ON, FORMAT, PDP } from "../../../constants/common/common.js";
import { resetPersonalizationValues, toggleClearPersonlizedData } from "../../slices/personalizationSlice/index.js";
import { removeLocalStorageItem } from "../../../utils/storage/localStorageUtil/index.js";

/**
 * Saga to fetch and handle viewing cart data.
 */
function* viewCartSaga() {
  try {
    const response = yield viewCartService();
    if (response) {
      yield put(responseViewCart(updateViewCartJSON(response)));
    } else {
      yield put(failedViewCart(response));
    }
  } catch (error) {
    yield put(failedViewCart(error));
  }
}

/**
 * Saga to handle adding an item to the cart, including API calls and state updates.
 * @param {object} payload - The payload containing data for the add-to-cart request.
 */
function* addToCartSaga({ payload }) {
  try {
    yield put(toggleClearPersonlizedData());
    const catalogId = yield select((state) => state?.appConfig?.catalogId);
    const encodedPayload = new URLSearchParams(payload).toString();
    const response = yield call(addToCartService, encodedPayload);
    if (response) {
      yield call(initiateFusCall);
      yield put(responseAddToCart(response));
      yield put(resetAddOnState());
      if (response?.isSuccess) {
        yield put(resetPersonalizationValues());
        removeLocalStorageItem(payload?.add_product_id);
      }

      // Prepare and dispatch add-on payload
      const addOnPayload = {
        FNP_CURRENT_CATALOG_ID: catalogId,
        addProductId: payload?.add_product_id,
        productType: payload?.productType,
        shippingMethod: payload?.shippingMethodId,
        timeSlotId: payload?.timeSlotId,
        orderItemSeqId: response.orderItemSeqId,
        format: FORMAT.JSON,
        isbuynow: "N",
        pinCode: payload?.pinCode,
        productId: payload?.add_product_id,
      };

      yield put(requestToFetchAddOnInfoAction(addOnPayload));
    } else {
      yield put(failedAddToCart(response?.data));
    }
  } catch (error) {
    yield put(failedAddToCart(error));
  }
}

/**
 * Saga to set values for a cart item and update the state accordingly.
 * @param {object} action - The action containing payload data.
 */
function* setCartItemValuesSaga(action) {
  try {
    const stringifiedParams = new URLSearchParams(action.payload).toString();
    const response = yield setCartItemValuesService(stringifiedParams);
    if (response?.isSuccess) {
      yield put(responseSetCartItemValues(response));
      yield put(requestViewCart());
    } else {
      yield put(failedSetCartItemValues(response?.data));
    }
  } catch (error) {
    yield put(failedSetCartItemValues(error));
  }
}

/**
 * Saga to delete an item from the cart and update the state.
 * @param {object} action - The action containing payload data.
 */
function* deleteCartItemSaga(action) {
  try {
    const encoded = new URLSearchParams(action.payload).toString();
    const response = yield deleteCartItemService(encoded);

    if (response?.isSuccess) {
      yield put(responseDeleteCartItem());
      yield call(initiateFusCall);
      yield put(requestViewCart());
    } else {
      yield put(failedDeleteCartItem(response?.data));
    }
  } catch (error) {
    yield put(failedDeleteCartItem(error));
  }
}

/**
 * Saga to update the cart, making necessary API calls and updating the cart state.
 * @param {object} action - The action containing payload data.
 */
function* updateCartSaga({ payload }) {
  try {
    const response = yield updateAddOnQuantity(payload);
    yield put(responseUpdateCart(response));
    yield put(requestViewCart());
  } catch (error) {
    yield put(failedUpdateCart(error));
  }
}

/**
 * Saga to handle cart notifications based on the device type.
 * @param {object} action - The action containing payload data.
 */
function* handleCartNotification({ payload }) {
  const { isMobile, screen, productId } = payload;

  /**
   * Handles cart notification for mobile devices on the addon screen.
   * Displays a popup and hides it after a delay.
   */
  const handleMobileAddonNotification = function* () {
    window.scrollTo({ top: 0, behavior: "smooth" });
    yield put(setPdpCartNotificationPopUp(true));
    // Wait for 3 seconds before hiding the notification
    yield delay(3000);
    yield put(setPdpCartNotificationPopUp(false));
  };

  /**
   * Handles cart notification for desktop devices on the addon screen.
   * Triggers actions to view and show the cart.
   */
  const handleDesktopAddonNotification = function* () {
    yield put(requestViewCart());
    yield put(setIsCartVisible(true));
  };

  /**
   * Handles cart notification when on the product detail page (PDP).
   * Fetches cart data, checks for the same item in the cart, and updates the state accordingly.
   */
  const handlePdpNotification = function* () {
    try {
      const response = yield viewCartService();
      if (response) {
        const checkSameItemInCart = response?.items?.find(
          (item) => item.productId === productId?.variantProductId || item?.productId === productId?.mainProductId,
        );
        yield put(setIsSameItemInCart(checkSameItemInCart));

        if (checkSameItemInCart) {
          yield put(requestViewCart());
          if (!isMobile) {
            yield put(setIsCartVisible(true));
          }
        }
      } else {
        yield put(failedViewCart(response));
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (screen === ADD_ON) {
    yield isMobile ? handleMobileAddonNotification() : handleDesktopAddonNotification();
  } else if (screen === PDP) {
    yield handlePdpNotification();
  }
}

/**
 * Watcher saga for various cart actions, including adding, deleting, and viewing items in the cart.
 */
export function* watcherForCartSaga() {
  yield takeLatest(requestUpdateCart.type, updateCartSaga);
  yield takeLatest(requestAddToCart.type, addToCartSaga);
  yield takeLatest(requestViewCart.type, viewCartSaga);
  yield takeLatest(requestSetCartItemValues.type, setCartItemValuesSaga);
  yield takeLatest(requestDeleteCartItem.type, deleteCartItemSaga);
  yield takeLatest(requestToCartNotification.type, handleCartNotification);
}
