import { getCityList, getCityListPDP, getLocationLockConfigData } from "../../../api/locationLock";
import { call, put, takeLatest } from "redux-saga/effects";
// import { SET_ERROR } from "../../../constants/actionType/locationLock";
import {
  getCountryListsData,
  getInternationalCityListPDP,
  setCountryListData,
  setInternationalCityListDataPDP,
  getInternationalCityList,
  setInternationalCityListData,
  setLocationLockConfigDataAction,
  getLocationLockConfigDataAction,
} from "../../slices/locationLock";
import { getCountryLists } from "../../../api/loginApi";

/**
 * Generator function to handle fetching the city list based on the provided action payload.
 *
 * @param {object} action - The dispatched action containing the payload.
 * Dispatches an action with the retrieved city list data.
 */
export function* getCityListPDPInfo(action) {
  try {
    const list = yield call(getCityListPDP, action?.payload);
    yield put(setInternationalCityListDataPDP(list?.cities));
  } catch (error) {
    throw Error(error);
  }
}

/** */
export function* getCountryListsHandler() {
  try {
    const list = yield call(getCountryLists);
    yield put(setCountryListData(list));
  } catch (error) {
    throw Error(error);
  }
}

/**
 * Generator function to handle fetching the city list based on the provided action payload.
 *
 * @param {object} action - The dispatched action containing the payload.
 * Dispatches an action with the retrieved city list data.
 */
export function* getCityListInfo({ payload }) {
  try {
    const list = yield call(getCityList, payload);

    yield put(setInternationalCityListData(list?.data));
  } catch (error) {
    // yield put({ type: SET_ERROR, payload: error });
  }
}

/**
 * To get the location lock config data.
 */
export function* getLocationLockConfig() {
  try {
    const response = yield call(getLocationLockConfigData);
    yield put(setLocationLockConfigDataAction(response));
  } catch (error) {
    throw Error(error);
  }
}

/**
 * Watcher saga for location lock related actions.
 * Listens for specific action types and triggers the appropriate generator functions.
 */
function* watchLocationLockSaga() {
  yield takeLatest(getInternationalCityListPDP.type, getCityListPDPInfo);
  yield takeLatest(getInternationalCityList.type, getCityListInfo);
  yield takeLatest(getCountryListsData.type, getCountryListsHandler);
  yield takeLatest(getLocationLockConfigDataAction.type, getLocationLockConfig);
}

export default watchLocationLockSaga;
