import { GET_APP_QUICK_SEARCH, GET_HOME_PAGE_INFO, GET_PRODUCT_LIST, getProductDeliveryTimeLeft } from "./constant";
import { InterceptorClient } from "../../interceptor/interceptorClient";
const basicHeaders = {
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Headers": "Content-Type",
  "Content-Type": "text/html;charset=UTF-8",
  "X-made-with": "Made with <3 in India",
};
/**
 * API for the data for home page
 * @returns
 */
export const getHomeAPI = async (isMobile, headersList) => {
  return await InterceptorClient.getGatewayInstance().get(`${GET_HOME_PAGE_INFO}`, false, null, {
    ...basicHeaders,
    "x-device-type": isMobile ? "mobile" : "desktop",
    "x-domain": "www.fnp.com",
    "edge-control": headersList?.["edge-control"],
    "edge-cache-tag": headersList?.["edge-cache-tag"],
    expires: headersList?.expires || headersList?.Expires,
  });
};

/**
 * API for the data for Gift Finder
 * @returns
 */
export const getQuickSearch = async () => {
  return await InterceptorClient.getGatewayInstance().get(`${GET_APP_QUICK_SEARCH}`);
};

/**
 * API for the list of products
 */
export const getProductList = async () => {
  return await InterceptorClient.getGatewayInstance().get(`${GET_PRODUCT_LIST()}`);
};

/**
 * Fetches the remaining time for product delivery from the API.
 *
 * This asynchronous function sends a request to the API endpoint returned by `getProductDeliveryTimeLeft()`
 * and retrieves the remaining delivery time for a product.
 *
 * @async
 * @function
 * @returns {Promise<Object>} A promise that resolves to the JSON response from the API, containing the delivery time left.
 * @throws {Error} Will throw an error if the fetch request fails.
 */
export const getDeliveryTimeLeft = async (productId) => {
  return await InterceptorClient.getFTSInstance(process.env.NEXT_PUBLIC_BASE_URL).get(
    `${getProductDeliveryTimeLeft(productId)}`,
  );
};
