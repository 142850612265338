/* eslint-disable react/jsx-handler-names */
import PropTypes from "prop-types";
import Slider from "react-slick";
import CustomImage from "../../../components/atoms/customImage";
import { useRef } from "react";
import {
  appendDots,
  customPaging,
  SlickNextArrow,
  SlickPrevArrow,
} from "../../../components/molecules/carouselHelpers";
import useAnalytics from "../../../analytics/useAnalytics";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";

/**
 * DesktopBannerCarousel component displays a banner carousel with optional slides and navigation.
 * It uses Splide for carousel functionality, and the visibility of the carousel is controlled by
 * the component's mounted state. Before mounting, a hero banner image is displayed.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Array<Object>} props.data - Array of banner data objects, where each object contains
 * @param {Object} props.dimensions - Object containing width and height for the banner images.
 * @param {number} props.dimensions.width - The width of the banner images.
 * @param {number} props.dimensions.height - The height of the banner images.
 *
 * @returns {JSX.Element} The rendered DesktopBannerCarousel component.
 */
const DesktopBannerCarousel = ({
  data,
  dimensions = { width: 808, height: 436 },
  sliderOptions = {
    dots: false,
    infinite: false,
    arrows: true,
    autoplay: false,
    slidesToShow: 1,
  },
}) => {
  const { trackEvent } = useAnalytics();
  const activeSlide = useRef(0);

  var settings = {
    ...sliderOptions,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
    beforeChange: (current, next) => (activeSlide.current = next),
    appendDots: (dots) =>
      appendDots(dots, activeSlide.current, data.length, sliderOptions?.slidesToShow, sliderOptions?.infinite),
    customPaging: (i) => customPaging(activeSlide.current, data)(i),
  };

  const pageBannerData = {
    page_name: cleverTapCommonWords.HOME,
    page_type: cleverTapCommonWords.HOME,
  };

  return (
    <div id="heroBanner">
      <Slider {...settings} className={`relative mt-32 w-full px-40 ${sliderOptions?.dots ? "pb-40" : ""}`}>
        {data.map((item, index) => (
          <a
            className="overflow-hidden rounded-2xl"
            href={item.category_link}
            key={index}
            onClick={() => {
              trackEvent(
                cleverTapCommonEvents.bannerClicked,
                {
                  ...pageBannerData,
                  banner_id: index,
                  banner_name: item?.altTag,
                },
                ["clevertap"],
              );
            }}
          >
            <CustomImage
              src={item.banner_image_url}
              width={dimensions?.width}
              height={dimensions?.height}
              alt={item.altTag || `banner-${index}`}
              isPriority={index === 0 ? true : false}
              customStyle={{ width: "100%", height: "100%" }}
            />
          </a>
        ))}
      </Slider>
    </div>
  );
};

DesktopBannerCarousel.propTypes = {
  data: PropTypes.array.isRequired, // Corrected PropTypes import
  dimensions: PropTypes.object.isRequired,
  sliderOptions: PropTypes.object.isRequired,
};

export default DesktopBannerCarousel;
