"use client";
import { Provider } from "react-redux";
import PropType from "prop-types";
import _store from "./index";
/**
 *
 * @param {*} param
 * @returns
 */
const ReduxProvider = ({ children }) => {
  return <Provider store={_store}>{children}</Provider>;
};
ReduxProvider.propTypes = {
  children: PropType.node,
};
export default ReduxProvider;
