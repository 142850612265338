import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: {},
  error: null,
};

/**
 * Slice for managing web feature controls state.
 * Includes reducers for fetching controls, setting controls, and handling errors.
 *
 * @type {import("@reduxjs/toolkit").Slice}
 */
export const webFeatureControls = createSlice({
  name: "webFeatureControls",
  initialState,
  reducers: {
    /**
     * Reducer to initiate fetching web feature controls.
     * @param {Object} state - Current state.
     */
    requestToGetWebFeatureControls: (state) => {
      return {
        ...state,
      };
    },
    /**
     * Reducer to set fetched web feature controls.
     * @param {Object} state - Current state.
     * @param {Object} action - Action object containing web feature controls payload.
     */
    setGetWebFeatureControls: (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    },
    /**
     * Reducer to set errors while fetching web feature controls.
     * @param {Object} state - Current state.
     * @param {string} action.payload - Error message related to fetching controls.
     */
    setGetWebFeatureControlsErrors: (state, action) => {
      return {
        ...state,
        error: action.payload,
      };
    },
  },
});
export default webFeatureControls.reducer;
export const { requestToGetWebFeatureControls, setGetWebFeatureControls, setGetWebFeatureControlsErrors } =
  webFeatureControls.actions;
