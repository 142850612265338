/* eslint-disable max-lines */
/* eslint-disable react/jsx-handler-names */
"use client";

import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getAvailablePincodes,
  getCityPincodeBasedOnLocation,
  getPinCode,
  getPincodeSetter,
  getPincodes,
} from "../../../api/pincodeLocationSuggestion";
import { GOOGLE_KEYS, googleApiUrl } from "../../../constants/keys/locationLock";
import { charactersAndDigitsOnlyRegex, digitsOnlyRegex } from "../../../constants/regex";
import {
  AUTO_TRIGGER_LOCK,
  FROM_LOCATION_LOCK,
  INTEGRATED_PINCODE_GOOGLE_SUGGESTION,
  LOCATION_MANDATORY,
} from "../../../constants/common/locationFieldConstants";
import {
  onFocusRequestFromTextfield,
  setIsHideSavedAddresses,
  setIsSavedAddressUserName,
  setIsShowDesktopLocationLockAction,
  setIsShowMobileLocationLockAction,
  setIsShowMobileLocationLockDrawerAction,
  setSelectedDomesticLocation,
} from "../../../store/slices/locationLock";
import { debounce, pageName, removeHashtagFromURL } from "../../../utils/common";
import CustomImage from "../../atoms/customImage";
import TextField from "../../atoms/textField";
import {
  LAST_VISITED_PINCODE,
  SELECTED_SAVED_ADDRESS,
  SELECTED_SAVED_ADDRESS_USERNAME,
} from "../../../constants/storage";
import { getFilteredCitiesList } from "../../../utils/locationLock";
import ListDropDownSection from "./ListDropDownSection";
import { CONTINUE_SHOPPING, COUNTRIES } from "../../../constants/common/common";
import CookieUtil from "../../../utils/storage/cookieUtil";
import useAnalytics from "../../../analytics/useAnalytics";
import { cleverTapCommonEvents, validationFor } from "../../../analytics/clevertapConstants";
import {
  setDeliveryDetailsAction,
  successToFetchBlockedDatesAction,
} from "../../../store/slices/common/shippingDateAndSlotSelection";
import { unlockBody } from "../../../utils/document";
import ButtonComponent from "../../atoms/button";
import Portal from "../Portal";
import isEmpty from "lodash.isempty";
import { usePathname } from "next/navigation";
import { setPincodeErrorFromAddToCart } from "../../../store/slices/pdpSlice";
import { GA4_EVENTS } from "../../../analytics/gaConstant";

/**
 * PincodeLocationSuggestion component provides suggestions for pincode or location input
 * based on user input, using Google Places API and domestic pincode list.
 * @component
 */
const PincodeLocationSuggestion = ({
  selectedCountry,
  isSuggestionSelected,
  setSuggestionSelected,
  searchValue,
  setSearchValue,
  onHandleContinueShopping,
  isFromPDP = false,
  isError,
  messageText,
  messageClass,
  isDisable,
  onPincodeLocationClickHandler,
  urlIdentifier,
  cleverTapPDPCommonData,
}) => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const pathname = usePathname();
  const {
    isMobile,
    cityListData,
    domesticPincodeList,
    configData,
    selectedDomesticLocation,
    isShowMobileLocationLock,
    isShowMobileLocationLockDrawer,
    savedAddresses,
    onFocusChangeFromTextfield,
  } = useSelector(
    (state) => ({
      isMobile: state?.appConfig?.isMobile,
      cityListData: state.locationLock.cityListData,
      domesticPincodeList: state?.pincodeLocationSuggestion?.pincodeList,
      configData: state?.locationLock?.configData,
      selectedDomesticLocation: state?.locationLock.selectedDomesticLocation,
      isShowMobileLocationLock: state?.locationLock?.isShowMobileLocationLock,
      isShowMobileLocationLockDrawer: state?.locationLock?.isShowMobileLocationLockDrawer,
      savedAddresses: state?.savedAddress?.savedAddresses || [],
      onFocusChangeFromTextfield: state.locationLock.onFocusChangeFromTextfield,
    }),
    shallowEqual,
  );

  const {
    isAutoGateMandateOnDesktopHome,
    isAutoGateMandateOnDesktopPLP,
    isAutoGateMandateOnMobileHome,
    isAutoGateMandateOnMobilePLP,
    isAutoGateEnabledOnDesktopHome,
    isAutoGateEnabledOnMobileHome,
    isAutoGateEnabledOnDesktopPLP,
    isAutoGateEnabledOnMobilePLP,
  } = configData;
  const [selectedRow, setSelectedRow] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [availablePincodes, setAvailablePincodes] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [deliveryType, setDeliveryType] = useState("");
  const [eventTriggered, setEventTriggered] = useState(false);
  const [selectedLocationDetails, setSelectedLocationDetails] = useState({});
  const [isMandatoryError, setIsMandatoryError] = useState(false);
  const [statusText, setStatusText] = useState("");
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");
  const isAutoForDesktop = (isAutoGateEnabledOnDesktopHome && isHome) || (isAutoGateEnabledOnDesktopPLP && isPLP);
  const isAutoForMobile = (isAutoGateEnabledOnMobileHome && isHome) || (isAutoGateEnabledOnMobilePLP && isPLP);
  const isAutoTriggerLock = CookieUtil.getCookie(AUTO_TRIGGER_LOCK);
  const isMandateForDesktop =
    !isAutoTriggerLock && ((isAutoGateMandateOnDesktopHome && isHome) || (isAutoGateMandateOnDesktopPLP && isPLP));
  const isMandateForMobile =
    !isAutoTriggerLock && ((isAutoGateMandateOnMobileHome && isHome) || (isAutoGateMandateOnMobilePLP && isPLP));

  const lastVisitedPincode = CookieUtil.getCookie(LAST_VISITED_PINCODE);
  const lastVisitedAddress = CookieUtil.getCookie("lastVisitedAddress");

  const checkLoginStatus = useSelector((state) => state.fus.loginDetail?.loggedin);

  const isVisibleLocationMobileDrawer = isShowMobileLocationLockDrawer && !isShowMobileLocationLock;

  const isAutoFocus = isVisibleLocationMobileDrawer
    ? false
    : isEmpty(savedAddresses)
      ? onFocusChangeFromTextfield
      : false;

  const commonPageData = {
    page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
    mandatory: isMobile ? isMandateForMobile : isMandateForDesktop,
    auto: isMobile ? isAutoForMobile : isAutoForDesktop,
  };
  const ishomePlpPdpLocationData = isHome || isPLP ? commonPageData : cleverTapPDPCommonData;

  useEffect(() => {
    getAvailablePincodes().then((res) => {
      setAvailablePincodes(res);
    });

    // In order to load Google script
    const existingScript = document.getElementById("googleMaps");
    const currentKey = GOOGLE_KEYS[Math.floor(Math.random() * GOOGLE_KEYS.length)];
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `${googleApiUrl}?key=${currentKey}&libraries=places`;
      script.id = "googleMaps";
      document.body.appendChild(script);
    }
  }, []);

  /**
   * Google API place prediction service to provide address suggestions
   * @param {string} value - The input value for which predictions are to be fetched.
   */
  const googlePlacePredictionCall = useCallback(async (value) => {
    const autocompleteService = new window.google.maps.places.AutocompleteService();
    const request = {
      input: value,
      componentRestrictions: { country: "in" },
      types: ["geocode"],
    };
    await autocompleteService?.getPlacePredictions(request, (predictions) => {
      setDeliveryType("location");

      setSuggestions(predictions);
    });
  }, []);

  useEffect(() => {
    let customPincode = "";
    if (searchValue.length > 1 && searchValue.length <= 6) {
      customPincode = searchValue.trim();
      const newPinCodes = getPincodeSetter(domesticPincodeList, customPincode);

      setSuggestions(newPinCodes.slice(0, 5));
    }
  }, [searchValue, domesticPincodeList]);

  // Memoize the debounce call with useMemo
  const debouncedPredictionCall = useMemo(() => {
    return debounce(googlePlacePredictionCall, 600);
  }, [googlePlacePredictionCall]);

  /**
   * Continue Shopping Handler.
   */
  const onContinueClick = (locationDetails) => {
    const customDimension = {
      interaction_type: lastVisitedPincode && "automatically",
      page_type: window?.fnpPageType,
    };
    dispatch(setPincodeErrorFromAddToCart(false));
    if (isEmpty(locationDetails) && ((isMandateForDesktop && !isMobile) || (isMandateForMobile && isMobile))) {
      setIsMandatoryError(isMobile ? isMandateForMobile : isMandateForDesktop);
      const cleverTapData = {
        page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
        pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
        validation_text: LOCATION_MANDATORY,
        validation_for: validationFor.LOCATION_FIELD,
      };
      trackEvent(cleverTapCommonEvents.validationViewed, cleverTapData, ["clevertap"]);

      setStatusText(LOCATION_MANDATORY);
    } else if (
      (!isMandateForMobile && isMobile) ||
      (!isMandateForDesktop && !isMobile) ||
      !isEmpty(selectedDomesticLocation) ||
      !isEmpty(locationDetails)
    ) {
      if (!isEmpty(locationDetails)) {
        dispatch(
          setSelectedDomesticLocation({
            pincode: locationDetails?.pincode,
            address: locationDetails?.location,
          }),
        );
        customDimension.interaction_type = "manual";
        trackEvent(
          GA4_EVENTS.DELIVERY_LOCATION_ADDED,
          {
            eventCategory: "delivery_location_interaction",
            eventAction: "delivery_location_added",
            eventLabel: "continue_click",
            ...customDimension,
          },
          ["ga4"],
        );
      }
      if (isMobile) {
        dispatch(setIsShowMobileLocationLockAction(false));
        dispatch(setIsShowMobileLocationLockDrawerAction(false));
      } else {
        dispatch(setIsShowDesktopLocationLockAction(false));
      }
      if (onHandleContinueShopping !== undefined) {
        onHandleContinueShopping(locationDetails);
      }
      trackEvent(
        GA4_EVENTS.DELIVERY_LOCATION_ADDED,
        {
          eventCategory: "delivery_location_interaction",
          eventAction: "delivery_location_added",
          eventLabel: "continue_click",
          ...customDimension,
        },
        ["ga4"],
      );

      unlockBody();
    }
  };

  /**
   * Handles the change in pincode or location input.
   * @param {object} event - The event object.
   */
  const onHandlePincodeLocationChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    setIsMandatoryError(false);
    if (selectedCountry?.catelogID !== COUNTRIES.INDIA && value.length >= 2) {
      clearTimeout(timeoutId);
      const timeoutRef = setTimeout(() => {
        setSuggestions(getFilteredCitiesList(value, cityListData, "cityName").slice(0, 5));
      }, 500);
      setTimeoutId(timeoutRef);
      return;
    }
    const filterSearch = getPincodeSetter(domesticPincodeList, value);
    if (value.length >= 2 && value.match(digitsOnlyRegex)) {
      setDeliveryType("pincode");
      // Fetch pincode suggestions
      const currentPin = value.substr(0, 2);
      const firstElement = domesticPincodeList[0]?.pincode;
      if (!firstElement || firstElement.slice(0, 2) !== currentPin) {
        getPincodes(currentPin, dispatch, availablePincodes);
      } else if (value.length >= 6 && filterSearch.length === 0) {
        setDeliveryType("location");
        debouncedPredictionCall(value);
      }
      setSearchValue(value);
    } else if (value.length >= 3 && value.match(charactersAndDigitsOnlyRegex)) {
      setDeliveryType("location");
      // Fetch pincode suggestions using Google Maps API
      debouncedPredictionCall(value);
    } else {
      setDeliveryType("");
      setSuggestions([]);
    }
  };

  /**
   * Handles the selection of a pincode suggestion.
   * @param {object} suggestion - The selected suggestion.
   */
  const onHandlePincodeSelection = (suggestion) => {
    const { location, pincode: selectedPincode } = suggestion;

    setSearchValue(`${selectedPincode}, ${location}`);
    const locationDetails = {
      pincode: selectedPincode,
      location,
    };
    setSelectedLocationDetails(locationDetails);
    removeHashtagFromURL(FROM_LOCATION_LOCK);
    setSuggestionSelected(true);
    setSuggestions([]);
    if (isFromPDP) {
      onContinueClick(locationDetails);
    }

    const pageLocationPincodeData = {
      ...ishomePlpPdpLocationData,
      pincode: selectedPincode,
      system_pincode: selectedPincode,
    };
    trackEvent(cleverTapCommonEvents.locationPincodeSelected, pageLocationPincodeData, ["clevertap"]);
    trackEvent(GA4_EVENTS?.PINCODE_ENTERED, { pincode: selectedPincode, pageType: window?.fnpPageType }, ["ga4"]);
    trackEvent(GA4_EVENTS?.PINCODE_AVAILABLE, { pincode: selectedPincode || lastVisitedPincode }, ["ga4"]);
    trackEvent(
      GA4_EVENTS.DELIVERY_LOCATION_ADDED,
      {
        eventCategory: "delivery_location_interaction",
        eventAction: "delivery_location_added",
        eventLabel: "pincode_selection",
      },
      ["ga4"],
    );
  };

  /**
   * Handles the selection of a place suggestion from Google Places API.
   * @param {object} suggestion - The selected suggestion.
   */
  const onHandleSelectedPlace = (suggestion) => {
    if (suggestion) {
      const service = new window.google.maps.places.PlacesService(document.createElement("autocomplete-input"));

      service.getDetails({ placeId: suggestion?.place_id, fields: ["geometry"] }, async (place) => {
        let pincodeSelected = "";
        const response = await getCityPincodeBasedOnLocation(
          place?.geometry?.location?.lat(),
          place?.geometry?.location?.lng(),
        );
        pincodeSelected = response?.pincode;
        const mappedPinList = await getPinCode();
        if (mappedPinList[response?.pincode]) {
          pincodeSelected = mappedPinList[response?.pincode];
        }
        setSearchValue(`${pincodeSelected}, ${suggestion?.description}`);
        const locationDetails = {
          pincode: pincodeSelected,
          location: suggestion?.description,
        };
        setSelectedLocationDetails(locationDetails);
        removeHashtagFromURL(FROM_LOCATION_LOCK);
        setSuggestionSelected(true);
        if (isFromPDP) {
          onContinueClick(locationDetails);
        }

        const pageLocationAreaData = {
          ...ishomePlpPdpLocationData,
          area: suggestion?.description,
          system_pincode: pincodeSelected,
          pincode: pincodeSelected,
        };
        trackEvent(cleverTapCommonEvents.locationAreaSelected, pageLocationAreaData, ["clevertap"]);
        trackEvent(GA4_EVENTS?.PINCODE_ENTERED, { pincode: pincodeSelected, pageType: window?.fnpPageType }, ["ga4"]);
        trackEvent(GA4_EVENTS?.PINCODE_AVAILABLE, { pincode: pincodeSelected }, ["ga4"]);
        trackEvent(
          GA4_EVENTS.DELIVERY_LOCATION_ADDED,
          {
            eventCategory: "delivery_location_interaction",
            eventAction: "delivery_location_added",
            eventLabel: "pincode_selection",
          },
          ["ga4"],
        );
      });
      setSuggestions([]);
    }
  };

  /**
   * Clears the suggestion field and resets the input value.
   */
  const onHandleClearSuggestionField = (e) => {
    e.stopPropagation();
    if (eventTriggered) return;
    setEventTriggered(true);
    setSearchValue("");
    dispatch(setSelectedDomesticLocation({}));
    dispatch(setIsSavedAddressUserName(null));
    setSuggestionSelected(false);
    setSelectedRow(0);
    dispatch(setDeliveryDetailsAction({}));
    dispatch(successToFetchBlockedDatesAction({}));
    const pathOptions = {
      path: "/",
    };
    CookieUtil.deleteCookie(SELECTED_SAVED_ADDRESS, pathOptions);
    CookieUtil.deleteCookie(SELECTED_SAVED_ADDRESS_USERNAME, pathOptions);
    const pageLocationRemovedData = {
      ...ishomePlpPdpLocationData,
      removed_location: searchValue,
    };
    trackEvent(cleverTapCommonEvents.locationRemoved, pageLocationRemovedData, ["clevertap"]);
    setTimeout(() => {
      setEventTriggered(false);
    }, 5000);
  };

  /**
   * This function is used for keyboard Up/Down and Enter keys for navigating through Location/Pincode field.
   * @param {event} event object for getting keyboard keys.
   * @returns {void}
   */
  const onHandleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      if (selectedRow <= 0) {
        setSelectedRow(suggestions?.length - 1);
      } else {
        setSelectedRow((prevPin) => prevPin - 1);
      }
    } else if (event.key === "ArrowDown") {
      if (selectedRow >= suggestions?.length - 1) {
        setSelectedRow(0);
      } else {
        setSelectedRow((prevPin) => prevPin + 1);
      }
    } else if (event.key === "Enter" && selectedRow >= 0) {
      if (deliveryType === "pincode") {
        onHandlePincodeSelection(suggestions[selectedRow]);
        setSuggestions([]);
        setSuggestionSelected(true);
      } else if (deliveryType === "location") {
        onHandleSelectedPlace(suggestions[selectedRow]);
      }
      setSelectedRow(0);
    } else if (event.key === "Backspace") {
      if (suggestions?.length === 0) {
        setSelectedRow(0);
      }
    }
  };

  /** onHandleFocus handles*/
  const onHandleFocus = () => {
    dispatch(setIsHideSavedAddresses(true));
    dispatch(onFocusRequestFromTextfield(true));
    if (typeof document !== "undefined" && isMobile && checkLoginStatus) {
      const element = document.getElementById("location-and-country-popup");
      if (element) {
        const rect = element.getBoundingClientRect();
        const offsetTop = window.scrollY + rect.top;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }

    dispatch(setPincodeErrorFromAddToCart(false));
    if (isFromPDP && !isMobile) {
      trackEvent(
        cleverTapCommonEvents.locationClicked,
        {
          ...ishomePlpPdpLocationData,
          is_location_available: !!(lastVisitedPincode || lastVisitedAddress),
        },
        ["clevertap"],
      );
    }
  };

  /**
   * Handles the selection of an international location.
   * @param {Object} e - The selected location object containing city information.
   */
  const handleInternationSelection = (e) => {
    const locationDetails = {
      pincode: e?.cityId,
      location: e?.cityName,
    };
    onHandleContinueShopping(locationDetails);
  };

  return (
    <>
      <TextField
        placeholder={INTEGRATED_PINCODE_GOOGLE_SUGGESTION}
        type="text"
        id="pincode-location-input"
        isRequired={false}
        variant="primary"
        inputValue={searchValue}
        onChange={!isDisable ? onHandlePincodeLocationChange : () => null}
        onKeyDown={onHandleKeyDown}
        onFocus={onHandleFocus}
        onBlur={() => dispatch(onFocusRequestFromTextfield(false))}
        isError={isError || isMandatoryError}
        message={messageText || statusText}
        messageClass={messageClass}
        endAdornment={
          isSuggestionSelected && (
            <CustomImage
              src="/icons/cross-circle.svg"
              alt="clear_pin"
              width={22}
              height={22}
              onClick={onHandleClearSuggestionField}
            />
          )
        }
        autoFocus={isAutoFocus}
        readOnly={isSuggestionSelected}
        classes={`overflow-hidden text-ellipsis whitespace-nowrap ${isFromPDP && "rounded-s-none"} font-medium`}
        onClick={isDisable ? onPincodeLocationClickHandler : () => null}
      />
      <div
        className={`${isFromPDP && suggestions?.length > 0 && "absolute left-0 top-56 z-1 w-full rounded-8 bg-white-100 p-8"}`}
      >
        <ListDropDownSection
          suggestions={suggestions}
          searchValue={searchValue}
          deliveryType={deliveryType}
          onHandlePincodeSelection={onHandlePincodeSelection}
          selectedRow={selectedRow}
          onHandleSelectedPlace={onHandleSelectedPlace}
          selectedCountry={selectedCountry}
          handleInternationSelection={handleInternationSelection}
        />
      </div>
      {suggestions?.length === 0 && !isFromPDP ? (
        !isMobile ? (
          <div className="pt-16">
            <ButtonComponent
              size="medium"
              buttonVariant="filled"
              label={CONTINUE_SHOPPING}
              onClick={() => onContinueClick(selectedLocationDetails)}
            />
          </div>
        ) : (
          <Portal
            rootId={isVisibleLocationMobileDrawer ? "locationLockWrapper" : "mobileLocationLock"}
            renderOnDidMount={true}
          >
            <div className={`${!isVisibleLocationMobileDrawer ? "absolute bottom-0 p-16" : ""} w-full bg-white-900`}>
              <ButtonComponent
                size="medium"
                buttonVariant="filled"
                label={CONTINUE_SHOPPING}
                onClick={() => onContinueClick(selectedLocationDetails)}
              />
            </div>
          </Portal>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default PincodeLocationSuggestion;

PincodeLocationSuggestion.propTypes = {
  onHandleContinueShopping: PropTypes.func.isRequired,
  isFromPDP: PropTypes.bool,
  isError: PropTypes.bool,
  messageText: PropTypes.string,
  messageClass: PropTypes.string,
  isSuggestionSelected: PropTypes.bool,
  setSuggestionSelected: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  selectedCountry: PropTypes.object.isRequired,
  isDisable: PropTypes.bool,
  onPincodeLocationClickHandler: PropTypes.func,
  urlIdentifier: PropTypes.string,
  cleverTapPDPCommonData: PropTypes.object,
};
