"use client";
import PropTypes from "prop-types";
import ButtonComponent from "../../../atoms/button";
import Typography from "../../../atoms/typography";

/**
 * ChatWithUs component renders Chat With Us clickable button.
 * @param {Object} props - The props object
 * @param {Object} props.details - Object containing text details.
 * @param {string} props.chatWitUsSwitch - Text containing chat with us switch
 * @returns {JSX.Element} FooterInfo component JSX
 */
export const ChatWithUs = ({ details, chatWitUsSwitch }) => {
  /**
   * This function handles chat with us on click event
   */
  const onChatWithUsClick = () => {
    if (window.fcWidget) {
      window.fcWidget?.open();
      window.fcWidget?.show();
    }
  };

  return (
    <>
      {chatWitUsSwitch === "N" ? (
        <ButtonComponent
          size="textBtn"
          label={details.subheading2}
          classes="text-grey-500 justify-start"
          buttonVariant="underlineText"
          buttonTextSize="small"
          onClick={onChatWithUsClick}
        />
      ) : (
        <Typography variant="subheading4" Tag="p" classes="text-12 font-400 text-grey-500">
          {details.subheading1}
        </Typography>
      )}
    </>
  );
};

ChatWithUs.propTypes = {
  details: PropTypes.object.isRequired,
  chatWitUsSwitch: PropTypes.string.isRequired,
};

export default ChatWithUs;
