import {
  GET_EARLIEST_DELIVERY_DATE_URL,
  GET_META_TAGS_URL,
  GET_PLP_REVIEWS_URL,
  GET_PRODUCT_ATTR_TAG_URL,
  GET_PRODUCT_LISTING_URL,
  GET_SEARCH_INSTEAD,
  GET_WIDGET_PRODUCTS_URL,
  PLP_HEAD_ANALYTICS,
} from "./constants";
import { InterceptorClient } from "../../interceptor/interceptorClient";
const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

const basicHeaders = {
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Headers": "Content-Type",
  "Content-Type": "text/html;charset=UTF-8",
  "X-made-with": "Made with <3 in India",
};

/**
 * Fetches product listing details from the API.
 * @returns {Promise<unknown>} A promise that resolves to the product listing details.
 */
export const getProductListingDetailsAPI = async (params, isMobile, headersList) => {
  return await InterceptorClient.getGatewayInstance().get(`${GET_PRODUCT_LISTING_URL(params)}`, false, null, {
    ...basicHeaders,
    "x-device-type": isMobile ? "mobile" : "desktop",
    "x-domain": "www.fnp.com",
    "edge-control": headersList?.["edge-control"],
    "edge-cache-tag": headersList?.["edge-cache-tag"],
    expires: headersList?.expires || headersList?.Expires,
  });
};

/**
 *
 * @param {*} categoryID
 * @param {*} geoId
 * @param {*} size
 */
export const getPlpReviewsAPI = async (categoryID, geoId, size) => {
  return await InterceptorClient.getGatewayInstance().get(`${GET_PLP_REVIEWS_URL(categoryID, geoId, size)}`);
};

/**
 *
 * @param {*} productIDs
 * @returns
 */
export const getDeliveryDatesAPI = async (productIDs, pincode) => {
  const res = await InterceptorClient.getFNTSInstance(baseUrl).get(
    `${GET_EARLIEST_DELIVERY_DATE_URL(productIDs, pincode)}`,
  );
  return res;
};

/**
 *
 * @param {*}
 * @returns
 */
export const getProductsAttrTag = async () => {
  return InterceptorClient.getGatewayInstance().get(`${GET_PRODUCT_ATTR_TAG_URL()}`);
};

/**
 * @param {*} category
 * @returns
 */
export const getMetaTags = async (category, isMobile, headersList) => {
  return await InterceptorClient.getGatewayInstance().get(`${GET_META_TAGS_URL(category)}`, false, null, {
    ...basicHeaders,
    "x-device-type": isMobile ? "mobile" : "desktop",
    "x-domain": "www.fnp.com",
    "edge-control": headersList?.["edge-control"],
    "edge-cache-tag": headersList?.["edge-cache-tag"],
    expires: headersList?.expires || headersList?.Expires,
  });
};

/**
 * API for the data for Gift Finder
 * @returns
 */
export const getMicroSiteWidgetData = async (productIds) => {
  return await InterceptorClient.getGatewayInstance().get(`${GET_WIDGET_PRODUCTS_URL(productIds)}`);
};

/**
 * API for the data for Gift Finder
 * @returns
 */
export const getSearchInsteadData = async (catalogueId) => {
  return await InterceptorClient.getGatewayInstance().get(`${GET_SEARCH_INSTEAD(catalogueId)}`);
};

/**
 * Fetches analytics data from the analytics data API.
 *
 * @returns {Promise<Object>} Returns a promise that resolves to the response
 *                            from the analytics data API.
 */
export const plpHeadAnalytics = async (isMobile) => {
  return await InterceptorClient.getGatewayInstance().get(`${PLP_HEAD_ANALYTICS()}`, false, null, {
    "x-device-type": isMobile ? "mobile" : "desktop",
    "x-domain": "www.fnp.com",
  });
};
