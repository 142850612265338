/**
 *
 * @param {*} catalogId
 * @param {*} categoryId
 * @param {*} currencyUomId
 * @param {*} productId
 * @returns
 */
export const GET_PRODUCT_DETAILS_URL = (
  productId,
  currencyUomId,
  catalogId,
  categoryId,
  country = "india",
  urlIdentifier,
) => {
  if (!productId && urlIdentifier) {
    return `/productapi/api/rest/v1.3/getProductDetails?urlIdentifier=${country}/${urlIdentifier}&currencyUomId=INR&catalogId=${country}`;
  }
  return `/productapi/api/rest/v1.3/getProductDetails?productId=${productId}&currencyUomId=${currencyUomId}&catalogId=${catalogId}&categoryId=${categoryId}`;
};

/**
 *
 * @param {*} productId
 * @param {*} domainId
 * @param {*} NoOfReviewRequest
 * @returns
 */
export const GET_PRODUCT_REVIEWS_URL = (productId, domainId, NoOfReviewRequest) => {
  return `/moody/v1/product/reviews?productId=${productId}&domainId=${domainId}&size=${NoOfReviewRequest}`;
};

/**
 *
 * @returns
 */
export const GET_RECENTLY_VIEWED_BY_YOU_URL = (productsList) => {
  return `/productapi/api/rest/v1.3/getRecentlyViewed?productId=${productsList}`;
};

/**
 *
 * @param {*} NoOfProductRequest
 * @param {*} productId
 * @param {*} catalogId
 * @returns
 */
export const GET_YOU_MAY_ALSO_LIKE_URL = (NoOfProductRequest, productId, catalogId) => {
  return `/productapi/api/rest/v1.3/crossSaleProduct?viewSize=${NoOfProductRequest}&productId=${productId}&FNP_CURRENT_CATALOG_ID=${catalogId}`;
};

/**
 *
 * @param {*} NoOfProductRequest
 * @param {*} productId
 * @param {*} catalogId
 * @returns
 */
export const WHAT_OTHERS_ARE_VIEWING_URL = (catalogId, isRakhiProduct, productId) => {
  return `/productapi/api/rest/v1.3/getWOAVData?catalogId=${catalogId}&isRakhiProduct=${isRakhiProduct}&productId=${productId}&lastViewIndex=1`;
};

/**
 *
 * @param {*} ctId
 * @returns
 */
export const OFFERS_DATA_URL = (ctId) => {
  return `/productapi/api/rest/v1.3/getJsonFromContentId?ctId=${ctId}`;
};
/**
 *
 * @param {*} fileName image to be uploaded
 * @returns API endpoint
 */
export const AWS_PRE_SIGNED_URL = (fileName) => {
  return `/productapi/api/rest/v1.3/getAWSPreSignedUrl?fileName=${fileName}}`;
};
/**
 *
 * @param {*} params image to be uploaded
 * @returns API endpoint
 */
export const CUSTOMIZABLE_CONTENT_URL = ({ urlIdentifier, productId }) => {
  return `/info/_pp/m.fnp.com/${urlIdentifier}?pid=${productId}&hf=N&requestSource=MRPLP`;
};

/**
 * Returns the URL for fetching analytics data.
 *
 * @returns {string} The analytics data URL.
 */
export const ANALYTICS_DATA_URL = () => {
  return "/chocolate/v1/pdp/analytics";
};

/**
 * Constructs the URL for fetching breadcrumb details.
 *
 * @param {Object} params - The parameters to include in the URL.
 * @returns {string} The constructed breadcrumb details URL.
 */
export const GET_BREADCRUMBS_DETAILS = (params) => {
  const flattenedParams = new URLSearchParams(params).toString();
  return `/control/getBreadCrumbsDetails?${decodeURIComponent(flattenedParams)}`;
};
/**
 * Returns the URL for the calendar footer message.
 *
 * @function
 * @returns {string} The URL string for the calendar footer message.
 */
export const CALENDER_MSG_URL = () => {
  return "/chocolate/v1/global/static/web/calenderfootermsg";
};

/**
 * Get Product Stock Available details.
 * @param {*} productId
 * @returns
 */
export const GET_PRODUCT_STOCK_AVAILABLE_URL = (productId) => {
  return `/productapi/api/rest/v1.3/isProductStockAvailable?productId=${productId}`;
};

/**
 * Returns the Out of stock Redirection URL for Msite & Dsite.
 *
 * @function
 * @returns {string} The URL string for the Out of stock Redirection.
 */
export const OUT_OF_STOCK_URL = () => {
  return "/chocolate/v1/global/static/web/out_of_stock_redirection";
};

/**
 * Returns the Price Surge Details URL for Msite & Dsite.
 *
 * @function
 * @param {*} productId
 * @returns {string} The URL string for the Price Surge Details.
 */
export const PRICE_SURGE_DETAILS_ENDPOINT = (productId) => {
  return `/control/getPriceSurgeForProductId?productId=${productId}`;
};
