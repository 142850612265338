"use client";
import PropTypes from "prop-types";
import Typography from "../../atoms/typography";
import { SortDesktop } from "../sorts";
import PLPReviewRatings from "../../molecules/plpReviewRatings";

/**
 * Header component for desktop view of Product Listing Page.
 * @param {Object} props - Component props.
 * @param {Array} props.sortData - Sorting options data.
 * @param {number} props.totalProducts - Total number of products.
 * @param {number} props.bestRating - Best rating.
 * @param {string} props.productCategoryName - Product category name.
 * @param {number} props.productList - Number of products listed.
 * @returns {JSX.Element} - HeaderPlpDesktop component.
 */
const HeaderPlpDesktop = ({
  sortData,
  totalProducts,
  bestRating,
  productCategoryName,
  productList,
  reviewCount,
  onScrollToReviews,
  categoryId,
}) => {
  return (
    <>
      <div className="my-16 flex justify-between">
        <div className="mx-2 flex w-3/4 items-center justify-start gap-16">
          <Typography classes="mx-2 text-24 font-600" Tag={"h1"} variant="h2" itemProp="name">
            {productCategoryName}
          </Typography>
          <div className="mx-2 text-16 text-grey-500">
            {productList} of {totalProducts} Gifts
          </div>

          {reviewCount > 0 && (
            <>
              <div className="mx-2 text-16 font-400 text-grey-500">|</div>
              <PLPReviewRatings
                bestRating={bestRating}
                reviewCount={reviewCount}
                onScrollToReviews={onScrollToReviews}
              />
            </>
          )}
        </div>
        <div className="mx-2 flex w-1/4 items-center justify-end">
          <SortDesktop data={sortData} categoryId={categoryId} />
        </div>
      </div>
    </>
  );
};

HeaderPlpDesktop.propTypes = {
  sortData: PropTypes.array.isRequired,
  totalProducts: PropTypes.number.isRequired,
  bestRating: PropTypes.number.isRequired,
  productCategoryName: PropTypes.string.isRequired,
  productList: PropTypes.number.isRequired,
  reviewCount: PropTypes.number.isRequired,
  onScrollToReviews: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
};

export default HeaderPlpDesktop;
