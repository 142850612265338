/**
 * Returns the endpoint URL for fnp cash balance.
 */
export const getFnpCashBalanceEndpoint = () => {
  return "/d/control/gwb";
};

/**
 * Returns the endpoint URL for fnp cash label.
 */
export const getFnpCashLabelSystemProperty = () => {
  return "/control/getSystemPropertyValue?resource=fnp&name=WALLET_INFO";
};

/**
 * Returns the endpoint URL for usable balance term and condition.
 */
export const getUsableBalanceTncEndpoint = () => {
  return "global/static/web/usable_balance_tnc";
};
