/* eslint-disable no-console */
"use client";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StickyLoader from "../../atoms/stickyLoader";

/**
 * InfiniteScroll component that enables infinite scrolling behavior.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children elements to be rendered.
 * @param {number} props.currentPage - The current rendered page.
 * @param {Function} props.getMoreData - Function to fetch more data when scrolled to the bottom.
 * @param {number} props.totalPages - The total number of data items expected.
 * @returns {JSX.Element} InfiniteScroll component JSX.
 */
const InfiniteScroll = ({ children, currentPage, getMoreData, totalPages, containerRef }) => {
  const [isAllDataFetched, setIsAllDataFetched] = useState(false);
  const cart = useSelector((state) => state.cart);
  const { isLoaderVisible } = cart;

  useEffect(() => {
    /**
     * Event handler to check if scrolled to the bottom of the page and updates the data accordingly.
     * @returns {void}
     */
    const onScroll = () => {
      if (containerRef.current) {
        const { bottom } = containerRef.current.getBoundingClientRect();
        const scrolledToBottom = bottom < 2500;
        if (!isAllDataFetched && scrolledToBottom) {
          getMoreData();
        }
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllDataFetched, getMoreData]);

  useEffect(() => {
    if (currentPage === totalPages) {
      setIsAllDataFetched(true);
    } else {
      setIsAllDataFetched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      {React.Children.toArray(children)}
      {isLoaderVisible && <StickyLoader />}
    </>
  );
};

InfiniteScroll.propTypes = {
  children: PropTypes.node.isRequired,
  currentPage: PropTypes.number.isRequired,
  getMoreData: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  containerRef: PropTypes.object,
};

export default InfiniteScroll;
