export const INTEGRATED_PINCODE_GOOGLE_SUGGESTION = "Enter Receiver’s pincode, location, area";
export const LOCATION_LOCK_ICON_TEXT = "Where to deliver?";
export const LOCATION_MISSING = "Location missing";
export const LOCATION_LOCK_HEADING = "Let’s Personalize Your Experience!";
export const LOCATION_LOCK_SUB_HEADING = "Find the perfect gift for you or your loved ones - it’s like magic!";
export const LOCATION_CITY_SUGGESTION = "Enter Delivery City";
export const DELIVERY_LOCATION = "Delivery Location";
export const LOCATION_MANDATORY = "Location is mandatory to continue";
export const AUTO_TRIGGER_LOCK = "autoTriggerLock";
export const SINGAPORE_REDIRECTION_TEXT = "You will be redirected to our singapore website";
export const LOGIN_TO_SAVED_ADDRESSES = "Login to use saved addresses";
export const FROM_LOCATION_LOCK = "#fromLocationLock";
export const PLEASE_ENTER_DELIVERY_LOCATION = "Please enter delivery location";
export const WITHIN_INDIA = "Within India";
export const OUTSIDE_INDIA = "Outside India";
export const DELIVERY_TO = "Delivery to";
