import axios from "axios";
/**
 * Represents an HTTP client for making API requests.
 */
export class HttpClient {
  /**
   * Create a new instance of HttpClient.
   * @param {string} baseUrl - The base URL for the HTTP requests.
   */
  constructor(baseUrl) {
    /**
     * The Axios instance for making HTTP requests.
     * @type {Object}
     */
    this.instance = axios.create({ baseURL: baseUrl, withCredentials: true });

    /**
     * The token used for authentication.
     * @type {string}
     */
    this.token = "";

    // Initialize request and response interceptors
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  /**
   * Initialize the response interceptor for the Axios instance.
   * @private
   */
  _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this._handleResponse, this._handleError);
  };

  /**
   * Initialize the request interceptor for the Axios instance.
   * @private
   */
  _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this._handleRequest, this._handleError);
  };

  /**
   * Handle the request by adding the token to the request headers.
   * @param {Object} config - Axios request configuration.
   * @returns {Object} - Modified request configuration.
   * @private
   */
  _handleRequest = (config) => {
    if (config.url?.includes("homepage")) {
      // console.log("conifig url", config.url);
      // config.headers["Content-Type"] = "application/json";
    }
    return config;
  };

  /**
   * Handle the response by extracting the data.
   * @param {Object} response - Axios response object.
   * @returns {Object} - Response data.
   * @private
   */
  _handleResponse = ({ data }) => {
    return data;
  };

  /**
   * Handle errors by rejecting the Promise.
   * @param {Object} error - Axios error object.
   * @returns {Promise} - Rejected Promise.
   * @private
   */
  _handleError = (error) => {
    console.error("Error: ", error?.response);
    return { status: error?.response?.status, data: error?.response?.data };
  };
}
