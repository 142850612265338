import { InterceptorClient } from "../../interceptor/interceptorClient";
import { fetchContentFromCMSOnClient } from "../common";
import { getFnpCashBalanceEndpoint, getFnpCashLabelSystemProperty, getUsableBalanceTncEndpoint } from "./constant";

const devBaseUrl = process.env.NEXT_PUBLIC_BASE_URL;
/**
 * API for fetching fnp cash value/balance.
 *
 * @returns {Promise<Object>} - A promise that resolves to the JSON response containing fnpCash balance.
 */
export const getFnpCashBalance = async () => {
  try {
    return await InterceptorClient.getFTSInstance(devBaseUrl).get(getFnpCashBalanceEndpoint(), false);
  } catch (error) {
    console.log(error);
  }
};
/**
 * API for fetching FNP cash label.
 *
 * @returns {Promise<Object>} - A promise that resolves to the JSON response containing FNP cash label data.
 */
export const getFnpCashLabelInfo = async () => {
  try {
    return await InterceptorClient.getFNTSInstance(devBaseUrl).get(getFnpCashLabelSystemProperty(), false);
  } catch (error) {
    console.log(error);
  }
};

/**
 * API for fetching fnp cash usable balance TnC.
 *
 * @returns {Promise<Object>} - A promise that resolves to the JSON response containing usable balance terms and conditions.
 */
export const getUsableBalanceTnc = async () => {
  try {
    return await fetchContentFromCMSOnClient(getUsableBalanceTncEndpoint());
  } catch (error) {
    console.log(error);
  }
};
