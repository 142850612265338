/* eslint-disable max-lines */
import axios from "axios";
import { call, put, takeLatest, take, all } from "redux-saga/effects";
import {
  requestToCustomizableContent,
  setCustomizedImageContent,
  setImageUploadProgress,
  setMultiImageConfig,
  setPresignedUrl,
  setUploadErrorMessage,
  setUploadFinalStatus,
  uploadImageForPersonalization,
  uploadMultiImageForPersonalization,
} from "../../slices/personalizationSlice";
import {
  getProductDetailsAPI,
  getProductStockAvailableAPI,
  getCustomizableImageContent,
  uploadToAwsS3Bucket,
  getSurgePriceDetails,
} from "../../../api/pdpApi";
import { eventChannel, END } from "redux-saga";
import { generateFilePath } from "../../../utils/pdp/personalization";
import differenceWith from "lodash.differencewith";
import {
  failedToFetchProductDetailAction,
  requestGetDeliveryTimeLeft,
  requestSurgePriceDetails,
  requestToFetchProductStockAvailable,
  requestToUpdateProductDetails,
  responseFromFetchProductStockAvailable,
  responseFromUpdateProductDetails,
  responseGetDeliveryTimeLeft,
  setFullProductDetails,
  setSurgePriceDetailsData,
} from "../../slices/pdpSlice";
import { getDeliveryTimeLeft } from "../../../api/homeApi";

/**
 * Creates an event channel to track file upload progress via XMLHttpRequest.
 *
 * @param {string} presignedUrl - The pre-signed URL for uploading the file to AWS S3.
 * @param {File} file - The file object to be uploaded.
 * @returns {eventChannel} An event channel for emitting upload progress or completion events.
 */
function createUploadFileChannel(presignedUrl, file) {
  return eventChannel((emit) => {
    const source = axios.CancelToken.source();
    const config = {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const percentComplete = Math.ceil((progressEvent.loaded / progressEvent.total) * 100);
          emit({
            percentComplete,
          });
        }
      },
      cancelToken: source.token,
    };

    axios
      .put(presignedUrl, file, config)
      .then((response) => {
        emit({ success: response.data || "Upload Successful" });
        emit(END);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          emit(new Error(error.message));
        }
        emit(END);
      });
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  });
}

/**
 * Saga to handle file upload using a pre-signed URL.
 *
 * @param {object} action - The action containing `presignedUrl` and `file`.
 * @param {string} action.presignedUrl - The pre-signed URL to upload the file.
 * @param {File} action.file - The file to upload.
 */
function* uploadWithPresignedUrl({ presignedUrl, file, configs, indexConfig }) {
  const uploadChannel = yield call(createUploadFileChannel, presignedUrl, file);

  try {
    while (true) {
      const event = yield take(uploadChannel);

      if (event.success) {
        // Upload complete, set final status
        if (configs) {
          const updatedConfigs = [...configs];
          const url = new URL(presignedUrl);
          const imagePath = url.origin + url.pathname;

          updatedConfigs[indexConfig] = {
            ...updatedConfigs[indexConfig],
            uploading: false,
            sizeError: false,
            uploaded: true,
            imgUrl: imagePath,
            isBackendError: false,
          };

          yield put(setMultiImageConfig(updatedConfigs));
        }

        yield put(setUploadFinalStatus());
      } else if (event instanceof Error) {
        // Handle backend errors
        if (configs) {
          const updatedConfigs = [...configs];
          updatedConfigs[indexConfig] = {
            ...updatedConfigs[indexConfig],
            uploading: false,
            sizeError: false,
            uploaded: false,
            imgUrl: null,
            isBackendError: true,
          };

          yield put(setMultiImageConfig(updatedConfigs));
          yield put(setUploadErrorMessage());
        }

        throw event;
      } else {
        yield put(setImageUploadProgress({ percentComplete: event.percentComplete }));
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    uploadChannel.close();
  }
}

/**
 * Generator function to handle uploading an image for personalization.
 * Fetches a pre-signed URL and uploads the file to AWS S3.
 *
 * @param {object} action - The dispatched action containing the file details.
 * @param {object} action.payload - The payload containing file name and file object.
 * @param {string} action.payload.fileName - The name of the file to be uploaded.
 * @param {File} action.payload.file - The file object to be uploaded.
 */
export function* uploadImageForPersonalizationFunction(action) {
  try {
    const { fileName, file, configs, indexConfig } = action.payload;
    const data = yield call(uploadToAwsS3Bucket, fileName);
    if (data?.responseStatus === "success") {
      const presignedUrl = data?.data?.presignedUrl;
      if (!configs) {
        yield put(setPresignedUrl(presignedUrl));
      }
      yield call(uploadWithPresignedUrl, { presignedUrl, file, configs, indexConfig });
    } else {
      yield put(setUploadErrorMessage());
    }
  } catch (error) {
    yield put(setUploadErrorMessage());
    console.error(error);
  }
}

/**
 * A Redux Saga generator function to handle uploading multiple images for personalization.
 * This function manages the upload process, tracks progress, and updates the state with the upload results.
 *
 * @param {Object} action - The action object dispatched to trigger the upload process.
 * @param {Object[]} action.payload.sanitizedFileQueue - The list of file objects to be uploaded, containing `file` and other relevant information.
 * @param {Object[]} action.payload.allConfigs - The current configuration of all images, used to update after uploads.
 * @yields {Object[]} The updated configuration with the status of each image upload.
 * @throws {Error} Throws an error if an upload fails.
 *
 * @generator
 */
export function* uploadMultiImageForPersonalizationFunction(action) {
  try {
    const { sanitizedFileQueue, allConfigs } = action.payload;
    const totalFiles = sanitizedFileQueue.length;
    const fileProgress = new Array(totalFiles).fill(0); // Array to track progress for each file

    const fetchNewlyUploadedImage = yield all(
      sanitizedFileQueue.map(function* (item, index) {
        const fileName = generateFilePath(item.file.name);
        const response = yield call(uploadToAwsS3Bucket, fileName);
        if (response.responseStatus === "success") {
          const presignedUrl = response?.data?.presignedUrl;
          const uploadChannel = yield call(createUploadFileChannel, presignedUrl, item?.file);
          try {
            while (true) {
              const event = yield take(uploadChannel);
              if (event.success) {
                break; // Exit loop when upload is complete for this image
              } else if (event instanceof Error) {
                // Handle errors
                throw event;
              } else {
                // Update individual file progress
                fileProgress[index] = event.percentComplete / totalFiles;
                // Calculate total progress by averaging all file progress values
                const totalProgress = fileProgress.reduce((acc, curr) => acc + curr, 0);
                yield put(setImageUploadProgress({ percentComplete: Math.min(100, Math.ceil(totalProgress)) }));
              }
            }
          } catch (error) {
            console.error("Error during upload:", error);
          } finally {
            uploadChannel.close();
          }

          const imagePath = new URL(presignedUrl).origin + new URL(presignedUrl).pathname;
          return {
            ...item,
            uploading: false,
            sizeError: false,
            uploaded: true,
            imgUrl: imagePath,
            uploadError: false,
          };
        }

        // In case of error
        return {
          ...item,
          uploading: false,
          sizeError: false,
          uploaded: false,
          imgUrl: null,
          uploadError: true,
        };
      }),
    );

    const excludedConfigs = fetchNewlyUploadedImage.map((config) => config.id);
    const leftOverConfigs = differenceWith(allConfigs, excludedConfigs, ({ id }, configId) => id === configId);
    const updatedConfigs = [...fetchNewlyUploadedImage, ...leftOverConfigs].sort(
      (configA, configB) => configA.id - configB.id,
    );
    yield put(setMultiImageConfig(updatedConfigs));
    yield put(setUploadFinalStatus());
  } catch (error) {
    yield put(setUploadErrorMessage());
    console.error("Error uploading multiple images:", error);
  }
}

/**
 * Get the Product Stock Availability.
 * @param {*} action
 */
function* fetchProductStockAvailableSaga(action) {
  try {
    if (action) {
      const { productId } = action.payload;
      const response = yield getProductStockAvailableAPI(productId);
      yield put(responseFromFetchProductStockAvailable(response));
    } else {
      yield put(failedToFetchProductDetailAction({ message: "Product Stock Available Details not found", code: 404 }));
    }
  } catch (error) {
    yield put(failedToFetchProductDetailAction(error));
  }
}

/**
 *  Fetch Updated Product Details from API.
 * @param {*} action
 */
function* fetchUpdatedProductDetail(action) {
  try {
    if (action) {
      const { productId, currencyUomId, catalogId } = action.payload;
      const response = yield getProductDetailsAPI(productId, currencyUomId, catalogId);
      yield put(responseFromUpdateProductDetails(response));
      yield put(setFullProductDetails(response?.data));
    } else {
      yield put(failedToFetchProductDetailAction({ message: "Product Details not found", code: 404 }));
    }
  } catch (error) {
    yield put(failedToFetchProductDetailAction(error));
  }
}

/**
 * Saga to fetch customizable content based on action payload.
 *
 * @generator
 * @function fetchCustomizableContent
 * @param {Object} action - The dispatched action.
 * @param {Object} action.payload - Payload containing required data for fetching content.
 * @yields {Object} Response from the customizable content API.
 * @throws {Error} In case of a failure in fetching the content.
 */
export function* fetchCustomizableContent(action) {
  try {
    const response = yield call(getCustomizableImageContent, action.payload);
    yield put(setCustomizedImageContent(response));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Saga to fetch customizable content based on action payload.
 *
 * @generator
 * @function fetchCustomizableContent
 * @param {Object} action - The dispatched action.
 * @param {Object} action.payload - Payload containing required data for fetching content.
 * @yields {Object} Response from the customizable content API.
 * @throws {Error} In case of a failure in fetching the content.
 */
export function* fetchGetDeliveryTimeLeft({ payload }) {
  try {
    const response = yield call(getDeliveryTimeLeft, payload);

    yield put(responseGetDeliveryTimeLeft(response));
  } catch (error) {
    console.log(error);
  }
}
/**
 * Saga to fetch price surge details based on action payload.
 *
 * @generator
 * @function fetchSurgePriceDetails
 * @param {string} productId - productId.
 * @throws {Error} In case of a failure in fetching the content.
 */
export function* fetchSurgePriceDetails(action) {
  try {
    const { productId } = action.payload;
    const response = yield call(getSurgePriceDetails, productId);
    yield put(setSurgePriceDetailsData(response));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Watcher saga for the image upload process.
 * Listens for the `uploadImageForPersonalization` action and triggers the upload function.
 */
function* watchPdpSaga() {
  yield takeLatest(uploadImageForPersonalization.type, uploadImageForPersonalizationFunction);
  yield takeLatest(uploadMultiImageForPersonalization.type, uploadMultiImageForPersonalizationFunction);
  yield takeLatest(requestToFetchProductStockAvailable, fetchProductStockAvailableSaga);
  yield takeLatest(requestToUpdateProductDetails, fetchUpdatedProductDetail);
  yield takeLatest(requestToCustomizableContent.type, fetchCustomizableContent);
  yield takeLatest(requestGetDeliveryTimeLeft.type, fetchGetDeliveryTimeLeft);
  yield takeLatest(requestSurgePriceDetails.type, fetchSurgePriceDetails);
}

export default watchPdpSaga;
