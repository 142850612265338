/**
 * Function to get filtered city list
 *
 * @param {string} value value of input.
 * @param {Array} cityListData citylist
 * @returns {Array} filtered list of cities.
 */
export const getFilteredCitiesList = (value, cityListData, cityNameKey = "CITY_NAME") => {
  let filteredCitiesInclude = [];
  if (!value) {
    return filteredCitiesInclude;
  }
  const filteredCitiesStartsWith = cityListData?.filter((city) =>
    city?.[cityNameKey]?.toLowerCase().startsWith(value.toLowerCase()),
  );

  if (filteredCitiesStartsWith?.length === 0) {
    filteredCitiesInclude = cityListData?.filter((city) =>
      city?.[cityNameKey]?.toLowerCase().includes(value.toLowerCase()),
    );
  }
  return [...filteredCitiesStartsWith, ...filteredCitiesInclude].reduce((unique, cityObj) => {
    if (!unique.some((obj) => obj.cityId === cityObj.cityId && obj[cityNameKey] === cityObj[cityNameKey])) {
      unique.push(cityObj);
    }
    return unique;
  }, []);
};
