import { put, takeLatest } from "redux-saga/effects";
import {
  fetchBlockedDatesDetails,
  fetchGetDeliveryDateAndGeoPriceDetails,
  fetchInternationalDeliveryDates,
  fetchInternationalShippingAndTimeSlot,
  fetchPriceAndEarliestShipDetails,
  fetchShippingAndTimeSlotDetails,
} from "../../../api/common/shippingDateAndSlotSelection";
import {
  requestToFetchDeliveryDatesAction,
  successToFetchDeliveryDatesAction,
  failToFetchDeliveryDatesAction,
  successToFetchBlockedDatesAction,
  requestToFetchShippingAndTimeSlotAction,
  successToFetchShippingAndTimeSlotAction,
  failToFetchShippingAndTimeSlotAction,
  requestToFetchGetDeliveryDateAndGeoPriceAction,
  successToFetchGetDeliveryDateAndGeoPriceAction,
  failToFetchGetDeliveryDateAndGeoPriceAction,
  requestToFetchBlockedDatesAction,
  requestToFetchInternationalShippingMethod,
  responseFromInternationalShippingAndTimeSlot,
  responseFromInternationalDeliveryDates,
  requestToFetchInternationalDeliveryDates,
} from "../../slices/common/shippingDateAndSlotSelection";

/**
 *
 * @param {*} action
 */
function* getPriceAndEarliestShipDetailsSaga({ payload }) {
  try {
    const response = yield fetchPriceAndEarliestShipDetails(payload);
    if (response?.status === 500) {
      yield put(failToFetchDeliveryDatesAction());
    } else {
      yield put(successToFetchDeliveryDatesAction(response?.data));
    }
  } catch (error) {
    yield put(failToFetchDeliveryDatesAction(error));
  }
}

/**
 *
 * @param {*} action
 */
function* getBlockedDatesSaga({ payload }) {
  try {
    const blockedDates = yield fetchBlockedDatesDetails(payload);
    if (blockedDates) yield put(successToFetchBlockedDatesAction(blockedDates));
  } catch (error) {
    console.log(error);
  }
}

/**
 *
 * @param {*} action
 */
function* getShippingAndTimeSlotSaga({ payload }) {
  try {
    const response = yield fetchShippingAndTimeSlotDetails(payload);

    yield put(successToFetchShippingAndTimeSlotAction(response));
  } catch (error) {
    yield put(failToFetchShippingAndTimeSlotAction(error));
  }
}

/**
 *
 * @param {*} action
 */
function* getDeliveryDateAndGeoPrice({ payload }) {
  try {
    const response = yield fetchGetDeliveryDateAndGeoPriceDetails(payload);

    yield put(successToFetchGetDeliveryDateAndGeoPriceAction(response?.data));
  } catch (error) {
    yield put(failToFetchGetDeliveryDateAndGeoPriceAction(error));
  }
}
/**
 *
 * @param {*} action
 */
function* getInternationalShippingMethod({ payload }) {
  try {
    const response = yield fetchInternationalShippingAndTimeSlot(payload);
    if (response?.responseStatus === "success") {
      const parsedData = JSON.parse(response?.data || {});
      yield put(responseFromInternationalShippingAndTimeSlot(parsedData));
    }
  } catch (error) {
    console.log(error);
  }
}
/**
 *
 * @param {*} action
 */
function* getInternationalDeliveryDates({ payload }) {
  try {
    const response = yield fetchInternationalDeliveryDates(payload);
    const parsedData = JSON.parse(response?.data || {});
    if (response?.responseStatus === "success") {
      yield put(responseFromInternationalDeliveryDates(parsedData));
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Description placeholder
 *
 * @export
 * @returns {{}}
 */
export function* watcherShippingDateAndSlotSelectionSaga() {
  yield takeLatest(requestToFetchDeliveryDatesAction.type, getPriceAndEarliestShipDetailsSaga);
  yield takeLatest(requestToFetchBlockedDatesAction.type, getBlockedDatesSaga);
  yield takeLatest(requestToFetchShippingAndTimeSlotAction.type, getShippingAndTimeSlotSaga);
  yield takeLatest(requestToFetchGetDeliveryDateAndGeoPriceAction.type, getDeliveryDateAndGeoPrice);
  yield takeLatest(requestToFetchInternationalShippingMethod.type, getInternationalShippingMethod);
  yield takeLatest(requestToFetchInternationalDeliveryDates.type, getInternationalDeliveryDates);
}
