import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedAddons: [],
  addOnInfo: {},
  loading: false,
  errorMsg: {},
  isModalOpen: false,
  continueAddon: false,
};

export const AddOn = createSlice({
  name: "addOn",
  initialState,
  reducers: {
    addOnModalVisibility: (state, action) => {
      return {
        ...state,
        isModalOpen: action?.payload,
        continueAddon: action?.payload === false ? false : state.continueAddon,
      };
    },
    handleSelectedAddon: (state, action) => {
      return {
        ...state,
        selectedAddons: action.payload,
      };
    },

    requestToFetchAddOnInfoAction: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    successToFetchAddOnInfoAction: (state, action) => {
      return {
        ...state,
        loading: false,
        addOnInfo: action.payload,
      };
    },
    failToFetchAddOnInfoAction: (state, action) => {
      return {
        ...state,
        loading: false,
        errorMsg: action.payload,
      };
    },
    resetAddOnState: (state) => {
      return {
        ...state,
        loading: false,
        errorMsg: {},
        selectedAddons: [],
      };
    },
    addAddonToCartAction: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    successAddonToCartAction: (state) => {
      return {
        ...state,
        loading: false,
        continueAddon: true,
      };
    },
    failAddonToCartAction: (state, action) => {
      return {
        ...state,
        loading: false,
        errorMsg: action.payload,
      };
    },
  },
});

export default AddOn.reducer;
export const {
  addOnModalVisibility,
  handleSelectedAddon,
  requestToFetchAddOnInfoAction,
  successToFetchAddOnInfoAction,
  failToFetchAddOnInfoAction,
  resetAddOnState,
  addAddonToCartAction,
  successAddonToCartAction,
  failAddonToCartAction,
} = AddOn.actions;
