export const cleverTapCommonEvents = {
  pageViewed: "Page Viewed",
  homePageViewed: "Home Page Viewed",
  micrositePageViewed: "Microsite Page Viewed",
  pdpPageViewed: "PDP Page Viewed",
  loginPageViewed: "Login Page Viewed",
  googleLoginSelected: "Google Login Selected",
  emailEntered: "Email Entered",
  registrationViewed: "Registration Page Viewed",
  regisDetEnter: "Registration Detail Entered",
  otpVerfViewed: "OTP Verification Viewed",
  otpVerfDone: "OTP Verification Done",
  resendOtpSelected: "Resend OTP Selected",
  loginSuccess: "Login Success",
  bannerClicked: "Banner Clicked",
  headerMenuClicked: "Header Clicked",
  searched: "Searched",
  megaMenuClicked: "Megamenu Clicked",
  giftFinderClicked: "Gift Finder Clicked",
  bottomNavigationNavIconClicked: "Bottom Navigation Nav Icon Clicked",
  locationClicked: "Location Clicked",
  locationPopupViewed: "Location Popup Viewed",
  locationPopupClosed: "Location Popup Closed",
  locationPincodeSelected: "Location Pincode Selected",
  locationAreaSelected: "Location Area Selected",
  locationRemoved: "Location Removed",
  locationSaved: "Location Saved",
  locationCountrySelected: "Location Country Selected",
  locationCitySelected: "Location City Selected",
  productClicked: "Product Clicked",
  widgetClicked: "Widget Clicked",
  variantSelected: "Variant Selected",
  pageScrolled: "Page Scrolled",
  sortSelected: "Sort Selected",
  filterSelected: "Filter Selected",
  validationViewed: "Validation Viewed",
  addToCartClicked: "Add To Cart Clicked",
  buyNowClicked: "Buy Now Clicked",
  messageOnCakeEntered: "Message On Cake Entered",
  nameToPrintEntered: "Name To Print Entered",
  uploadPhotoDone: "Upload Photo Done",
  deliveryTypeSelected: "Delivery Type Selected",
  wantSameDayClicked: "Want Same Day Clicked",
  deliveryPopupViewed: "Delivery Popup Viewed",
  deliveryDateSelected: "Delivery Date Selected",
  deliveryShippingSelected: "Delivery Shipping Selected",
  deliverySlotSelected: "Delivery Slot Selected",
  deliveryPopupClosed: "Delivery Popup Closed",
  addonViewed: "Addon Viewed",
  addonNotViewed: "Addon Popup Not Viewed",
  addonClosed: "Addon Closed",
  addonAdded: "Addon Added",
  addonRemoved: "Addon Removed",
  productRemoved: "Product Removed",
  addonContinueClicked: "Addon Continue Clicked",
  cartViewed: "Cart Page Viewed",
  proceedToCheckoutClicked: "Proceed To Checkout Clicked",
  fnpCashApplied: "Fnp Cash Applied",
};

export const cleverTapCommonWords = {
  PDP: "PDP",
  HOME: "HOME",
  Microsite: "MICROSITE",
  PLP: "PLP",
  LOGIN: "LOGIN",
  userLogin: "UserLogin",
  CART: "CART",
  REGISTRATION: "Registration",
};

export const validationFor = {
  OTP_VERIFICATION: "OTP verification",
  LOCATION_FIELD: "Location Field",
  NAME_TO_PRINT: "Name to Print",
  PERSONALIZED_IMAGE: "Personalized Image",
  OUT_OF_STOCK: "Out of Stock",
  EMAIL_ID: "Email ID",
  NAME: "Name",
};
