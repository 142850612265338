/**
 * Adding the current route with the added filters.
 * @param {*} urlParams
 * @param {*} appliedParams
 * @returns
 */
export const getReplaceFiltersURL = (urlParams, appliedParams) => {
  if (appliedParams?.length > 0) {
    urlParams.set("fq", encodeURIComponent(appliedParams));
  }
  const newSearch = urlParams.toString();
  const url = `${window.location.pathname}${newSearch ? `?${newSearch}` : ""}`;
  return url;
};

/**
 * Remove Filters from URL
 * @param {*} urlParams
 * @param {*} param
 * @returns
 */
export const removeFiltersParamsFromURL = (urlParams, param) => {
  urlParams.delete(param);
  const newSearch = urlParams.toString();
  const url = `${window.location.pathname}${newSearch ? `?${newSearch}` : ""}`;
  return url;
};
