import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedDeliveryDetails: {},
  deliveryDates: {},
  shippingDetails: {},
  loading: true,
  errorMsg: {},
  blockedDates: {},
  shippingAndTimeSlot: {},
  updatedDeliveryDatesAndGeoPrice: {},
  internationalDeliveryMethodAndSlot: {},
  internationalDeliveryDates: {},
};

export const ShippingDateAndSlotSelectionSlice = createSlice({
  name: "ShippingDateAndSlotSelection",
  initialState,
  reducers: {
    requestToFetchDeliveryDatesAction: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    successToFetchDeliveryDatesAction: (state, action) => {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    },
    failToFetchDeliveryDatesAction: (state, action) => {
      return {
        ...state,
        loading: false,
        deliveryDates: {},
        shippingDetails: {},
        errorMsg: action.payload,
      };
    },

    requestToFetchBlockedDatesAction: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    successToFetchBlockedDatesAction: (state, action) => {
      return {
        ...state,
        loading: false,
        blockedDates: action.payload,
      };
    },

    requestToFetchShippingAndTimeSlotAction: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    successToFetchShippingAndTimeSlotAction: (state, action) => {
      return {
        ...state,
        loading: false,
        shippingAndTimeSlot: action.payload,
      };
    },
    failToFetchShippingAndTimeSlotAction: (state, action) => {
      return {
        ...state,
        loading: false,
        errorMsg: action.payload,
      };
    },
    setProductDeliveryShippingDetailsAction: (state, action) => {
      return {
        ...state,
        deliveryDates: action?.payload?.deliveryDates,
        shippingDetails: action?.payload?.shippingDetails,
        loading: false,
      };
    },
    setDeliveryDetailsAction: (state, action) => {
      return {
        ...state,
        selectedDeliveryDetails: action.payload,
      };
    },

    requestToFetchGetDeliveryDateAndGeoPriceAction: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    successToFetchGetDeliveryDateAndGeoPriceAction: (state, action) => {
      return {
        ...state,
        loading: false,
        updatedDeliveryDatesAndGeoPrice: action.payload,
      };
    },
    failToFetchGetDeliveryDateAndGeoPriceAction: (state, action) => {
      return {
        ...state,
        loading: false,
        errorMsg: action.payload,
      };
    },
    resetGetDeliveryDateAndGeoPriceAction: (state) => {
      return {
        ...state,
        updatedDeliveryDatesAndGeoPrice: {},
      };
    },
    requestToFetchInternationalShippingMethod: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    requestToFetchInternationalDeliveryDates: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    responseFromInternationalShippingAndTimeSlot: (state, action) => {
      return {
        ...state,
        loading: false,
        internationalDeliveryMethodAndSlot: action.payload,
      };
    },
    responseFromInternationalDeliveryDates: (state, action) => {
      return {
        ...state,
        loading: false,
        internationalDeliveryDates: action.payload,
      };
    },
  },
});

export default ShippingDateAndSlotSelectionSlice.reducer;
export const {
  requestToFetchDeliveryDatesAction,
  successToFetchDeliveryDatesAction,
  failToFetchDeliveryDatesAction,
  requestToFetchBlockedDatesAction,
  successToFetchBlockedDatesAction,
  requestToFetchShippingAndTimeSlotAction,
  successToFetchShippingAndTimeSlotAction,
  failToFetchShippingAndTimeSlotAction,
  setDeliveryDetailsAction,
  setProductDeliveryShippingDetailsAction,
  requestToFetchGetDeliveryDateAndGeoPriceAction,
  successToFetchGetDeliveryDateAndGeoPriceAction,
  failToFetchGetDeliveryDateAndGeoPriceAction,
  resetGetDeliveryDateAndGeoPriceAction,
  requestToFetchInternationalShippingMethod,
  requestToFetchInternationalDeliveryDates,
  responseFromInternationalShippingAndTimeSlot,
  responseFromInternationalDeliveryDates,
} = ShippingDateAndSlotSelectionSlice.actions;
