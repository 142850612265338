/**
 * This function will helps to lock the body element.
 */
export const lockBody = () => {
  document.body.style.overflow = "hidden";
};

/**
 * This function will helps to unlock the body element.
 */
export const unlockBody = () => {
  document.body.style.overflow = "unset";
};

/**
 * This function will helps to hide the footer and long description element.
 */
export const hideElementsForPLP = () => {
  if (document.getElementById("footer")) {
    document.getElementById("footer").style.cssText = "display:none !important";
  }
  if (document.getElementById("longDescription")) {
    document.getElementById("longDescription").style.display = "none";
  }
};

/**
 * This function will helps to show the footer and long description element.
 */
export const showElementsForPLP = () => {
  if (document.getElementById("footer")) {
    document.getElementById("footer").style.cssText = "display:flex !important";
  }
  if (document.getElementById("longDescription")) {
    document.getElementById("longDescription").style.display = "block";
  }
};
