import { useEffect } from "react";

/**
 * Omniture setup hook
 *
 * @param {object} state redux state data for omniture
 * @param {object} dependency .
 */
export default function useOmnitureSetup(state, dependency) {
  const setupDependency = dependency || [state];

  useEffect(() => {
    /**
     * set omniture data
     */
    const loadOmniture = async () => {
      if (typeof window !== "undefined") {
        const { default: initOmniSetup } = await import("../../analytics/omniture/omniture");
        if (typeof initOmniSetup === "function") {
          initOmniSetup(state);
        }
      }
    };

    loadOmniture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, setupDependency);
}
