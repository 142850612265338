import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getLocalStorageItem } from "../../../utils/storage/localStorageUtil";
import Typography from "../../../components/atoms/typography";
import { getRecentViewedByYouAPI } from "../../../api/pdpApi";
import Widget from "../../../components/organisms/widget";
import get from "lodash.get";
import { NO_PRODUCT_FOUND } from "../../../constants/common/common";
import { PageTypes } from "../../../utils/fnpPageType";

/** */
const HomeWidgetSection = ({ isMobile, data, headingClass }) => {
  const [products, setProducts] = useState([]);
  let recentlyViewedProducts = {};

  /**
   * * @returns {void}
   */
  const fetchRecentlyViewedByYou = async () => {
    const response = await getRecentViewedByYouAPI(data?.products || recentlyViewedProducts);
    setProducts(response?.data);
  };

  useEffect(() => {
    recentlyViewedProducts = JSON.parse(getLocalStorageItem("recentlyViewedProducts") || "[]");
    fetchRecentlyViewedByYou();
  }, []);
  return (
    (data?.products || recentlyViewedProducts) &&
    products?.productResults?.length > 0 && (
      <div className={`relative w-full md:px-40`}>
        {data?.category_Name && (
          <Typography
            variant="h1"
            Tag="p"
            classes={`font-600 text-16 mb-16 md:mb-0 px-16 md:px-0 lg:text-24 xxl:text-32 ${headingClass} capitalize`}
          >
            {data?.category_Name}
          </Typography>
        )}
        <div className="flex w-full px-0">
          {products?.productResults?.length > 0 ? (
            <Widget
              productsList={get(products, "productResults", [])}
              isMobile={isMobile}
              pageType={PageTypes?.HOME}
              isHideSavedPercent={true}
            />
          ) : (
            NO_PRODUCT_FOUND
          )}
        </div>
      </div>
    )
  );
};

HomeWidgetSection.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  headingClass: PropTypes.string,
};
export default HomeWidgetSection;
