/* eslint-disable react/jsx-handler-names */
import PropType from "prop-types";
import Link from "next/link";
import CustomImage from "../../../../atoms/customImage";
import IconButtonComponent from "../../../../atoms/iconButton";
import { desktopIcons } from "../../../../../constants/common/headerToolbarConstants";
import { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CART } from "../../../../../constants/common/common";
import HeaderDropDownList from "./headerDropDownList";
import { requestViewCart, setIsCartVisible } from "../../../../../store/slices/cartSlice";
import useAnalytics from "../../../../../analytics/useAnalytics";
import { cleverTapCommonEvents } from "../../../../../analytics/clevertapConstants";
import { iconsForSelectedCurrency } from "../../../../../utils/currency";
import { usePathname } from "next/navigation";
import { pageName } from "../../../../../utils/common";

/**
 * DesktopToolBar component renders the toolbar for the desktop view.
 * Handles tab navigation, click events, and dropdowns for active tabs.
 *
 * @component
 * @returns {JSX.Element} The rendered toolbar component.
 */
const DesktopToolBar = ({ cartCount, serverSelectedCurrency }) => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const iconRefs = useRef([]);
  const pathname = usePathname();
  const [activeTab, setActiveTab] = useState("");
  const loginDetail = useSelector((state) => state?.fus?.loginDetail);
  const selectedCurrencyFromState = useSelector((state) => state?.currencyList?.userSelectedCurrency);
  const { product } = useSelector(
    (state) => ({
      product: state?.pdp?.clientCurrentClickedItem,
    }),
    shallowEqual,
  );
  const urlIdentifier = product?.urlIdentifier;
  const [selectedCurrency, setSelectedCurrency] = useState(
    selectedCurrencyFromState?.currency?.id || serverSelectedCurrency?.currency?.id,
  );
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");

  const commonData = {
    page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
  };

  useEffect(() => {
    if (selectedCurrencyFromState?.currency?.id && selectedCurrencyFromState?.currency?.id !== selectedCurrency) {
      setSelectedCurrency(selectedCurrencyFromState?.currency?.id);
    }
  }, [selectedCurrencyFromState, selectedCurrency]);

  /**
   * This function for handling the tab click.
   *
   * @functionP
   * @returns {void}
   */
  const onTabClick = useCallback(
    (tabName) => {
      const pageHeaderMenuData = {
        ...commonData,
        menu_name: tabName,
      };
      if (tabName) {
        trackEvent(cleverTapCommonEvents.headerMenuClicked, pageHeaderMenuData, ["clevertap"]);
      }
      setActiveTab(tabName);
      switch (tabName) {
        case "cart":
          cartCount > 0 && dispatch(requestViewCart());
          dispatch(setIsCartVisible(true));
          setActiveTab("");
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, cartCount],
  );
  /**
   * Handles clicks outside of any icons in the toolbar.
   * If the click is outside, it triggers onTabClick with null.
   *
   * @function handleClickOutside
   * @param {MouseEvent} event - The click event.
   */
  const handleClickOutside = useCallback(
    (event) => {
      const clickedRef = iconRefs.current.find((ref) => ref && ref.contains(event.target));
      onTabClick(clickedRef ? clickedRef.id : null);
    },
    [onTabClick],
  );

  /**
   * Adds event listener for mouse clicks outside the component when it mounts
   * and removes it when the component unmounts.
   */
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  /** */
  const setLabel = (label, id) => {
    if (loginDetail?.loggedin && id === "account") {
      return loginDetail?.fullname;
    }
    if (selectedCurrency && id === "currency") {
      return selectedCurrency;
    }
    return label;
  };

  /** */
  const setIconSrc = (url, id) => {
    if (selectedCurrency && id === "currency") {
      return iconsForSelectedCurrency(selectedCurrency);
    }
    return url;
  };

  /**
   * Renders an individual toolbar icon.
   *
   * @function renderIcons
   * @param {Object} item - The icon data including id, url, label, etc.
   * @param {number} index - The index of the current icon in the list.
   * @returns {JSX.Element} The rendered icon element.
   */
  const renderIcons = (item, index) => {
    /**
     * A callback function for assigning refs to icon elements.
     * This stores the DOM element reference for each icon in the `iconRefs` array.
     *
     * @function
     * @param {HTMLElement} el - The DOM element of the icon being referenced.
     */
    const ref = (el) => {
      iconRefs.current[index] = el;
    };
    let content;
    if (item.landingUrl) {
      content = (
        <Link
          href={item.landingUrl}
          key={index}
          className="flex cursor-pointer items-center justify-center border-b-4 border-white-900"
          tabIndex="0"
          id={item?.id}
          onClick={() => {
            const pageHeaderMenuData = {
              ...commonData,
              menu_name: item.label,
            };
            trackEvent(cleverTapCommonEvents.headerMenuClicked, pageHeaderMenuData, ["clevertap"]);
          }}
        >
          <div className="flex flex-col items-center justify-center gap-4">
            <CustomImage
              className="w-auto max-w-full"
              src={item?.url}
              width={25}
              height={24}
              isPriority={false}
              sizes="10vw"
              alt="desktop icons"
            />
            <div className="whitespace-nowrap text-center text-12 font-400 not-italic leading-4 text-grey-500 md:flex xxl:text-14">
              {item?.label}
            </div>
          </div>
        </Link>
      );
    } else {
      content = (
        <div
          key={index}
          className={`relative flex cursor-pointer items-center justify-center ${activeTab === item?.id ? "border-b-4 border-fnp" : "border-b-4 border-white-900"}`}
          tabIndex="0"
          id={item?.id}
          ref={ref}
        >
          {item?.label === CART && cartCount > 0 && (
            <span className="absolute right-0 top-4 flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-r from-notification-secondary to-notification-primary p-4 text-10 font-500 text-white-900">
              {cartCount}
            </span>
          )}
          <IconButtonComponent
            classes="flex flex-col justify-center items-center gap-4 py-10 w-full overflow-hidden text-ellipsis max-w-[85px]"
            imgWidth={item?.width}
            iconSrc={setIconSrc(item?.url, item?.id)}
            labelColor="w-full text-grey-500 font-normal"
            label={setLabel(item?.label, item?.id)}
          />
          {activeTab === item?.id && (
            <>
              <HeaderDropDownList
                activeTab={activeTab}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                onTabClick={onTabClick}
                commonData={commonData}
              />
            </>
          )}
        </div>
      );
    }
    return content;
  };
  return <>{desktopIcons?.map((item, index) => renderIcons(item, index))}</>;
};
DesktopToolBar.propTypes = {
  cartCount: PropType.number,
  serverSelectedCurrency: PropType.object,
};
export default DesktopToolBar;
