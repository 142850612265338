import { COUNTRIES } from "../../constants/common/common";
import { removeEmptyNullAndUndefinedFromObject } from "../../utils/common";

/**
 * Represents the API endpoint for the home page data in the website.
 * @constant
 * @type {string}
 */
export const CMS_API_VERSION = `/chocolate/v1/`;

/**
 *
 * @param {*} productId
 * @param {*} pinCode
 * @param {*} currencyUomId
 * @param {*} catalogId
 * @param {*} countryGeoId
 * @returns
 */
export const GET_PRICE_AND_EARLIEST_SHIP_DETAILS_URL = (productId, pinCode, currencyUomId, catalogId, countryGeoId) => {
  return `/productapi/api/rest/v1.3/getPriceAndEarliestShipDetails?productId=${productId}&pinCode=${pinCode}&currencyUomId=${currencyUomId}&catalogId=${catalogId}&countryGeoId=${countryGeoId}`;
};

/**
 *
 * @param {*} productId
 * @param {*} pinCode
 * @returns
 */
export const GET_BLOCKED_DATES_URL = (productId, pinCode) => {
  return `/productapi/api/rest/v1.3/getBlockedDates?pinCode=${pinCode}&productId=${productId}`;
};

/**
 *
 * @param {*} productId
 * @param {*} pinCode
 * @param {*} deliveryDate
 * @param {*} countryGeoId
 * @returns
 */
export const GET_SHIPPING_AND_TIME_SLOT_URL = (productId, pinCode, deliveryDate, countryGeoId) => {
  return `/productapi/api/rest/v1.3/shippingAndTimeSlot?productId=${productId}&pinCode=${pinCode}&deliveryDate=${deliveryDate}&countryGeoId=${countryGeoId}`;
};

/**
 *
 * @param {*} productId
 * @param {*} pinCode
 * @param {*} currencyUomId
 * @param {*} deliveryDate
 * @param {*} catalogId
 * @returns
 */
export const GET_DELIVERY_DATE_AND_GEO_PRICE = (productId, pinCode, currencyUomId, deliveryDate, catalogId) => {
  return `/productapi/api/rest/v1.3/getDeliveryDateAndGeoPrice?productId=${productId}&geoId=${pinCode}&currencyUomId=${currencyUomId}&deliveryDate=${deliveryDate}&catalogId=${catalogId}`;
};

/** */
export const GET_SEARCH_SUGGESTIONS = (geoId, keyword, uuid, userId) => {
  return `/columbus/v1/suggestions?geoId=${geoId}&keyword=${keyword}&domainId=fnp.com&uuid=${uuid}&lang=en${userId ? `&userId=${userId}` : ""}`;
};

/** */
export const GET_CURRENCY_LIST = () => {
  return "/control/getCurrencies";
};
/**
 * @returns
 */
export const GET_DYNAMIC_SEARCH_TEXT_PLACEHOLDER = () => {
  return `/chocolate/v1/global/static/web/appdynamicsearchtextsuggestions`;
};

/** Export FUS API Endpoint constant
 * @returns FUS endpoint
 */
export const FUS_API = () => {
  return "/control/fus";
};

/**
 * Constructs the URL for fetching add-on information based on the provided parameters.
 *
 * @param {Object} props - An object containing parameters to filter the add-on information.
 * @returns {string} Returns the constructed URL for the add-on info API endpoint,
 *                  including query string parameters for filtering.
 */
export const GET_ADD_ON_INFO = (props) => {
  const filteredParams = removeEmptyNullAndUndefinedFromObject(props);
  const flattenedParams = new URLSearchParams(filteredParams).toString();

  return `/control/getAddonsInfo-rj?${flattenedParams}`;
};

/**
 * Constructs the URL for adding add-ons to the shopping cart.
 *
 * @returns {string} Returns the API endpoint URL for adding add-ons to the cart.
 */
export const ADD_ONS_TO_CART = () => {
  return `/control/addAddonsToCart-rj`;
};

/** Get the Mega Menu content for mobile.
 * @returns {string} Returns the API endpoint URL for Mega Menu.
 */
export const GET_MEGA_MENU = () => {
  return "/chocolate/v1/menus-alt/default-menu-hm";
};

/** Get the Mega Menu content for desktop.
 * @returns {string} Returns the API endpoint URL for Mega Menu.
 */
export const GET_MEGA_MENU_DESKTOP = (catalogID) => {
  const countryID = catalogID !== COUNTRIES.INDIA ? `${catalogID}/` : "";
  return `/chocolate/v1/menus/${countryID}default-menu-hm`;
};

/**
 * API endpoint URL to fetch the SUBSCRIBE_NEW_USER response.
 */
export const SUBSCRIBE_NEW_USER = () => {
  return `/control/subscribe-user`;
};

/**
 * This method fetches Timing for CALL and CHAT in my account.
 */
export const GET_CALL_CHAT_TIME = () => {
  return `/chocolate/v1/global/static/web/configurecallandchattime`;
};

/**
 * This method fetches International shipping method and time slot.
 */
export const GET_INTERNATIONAL_SHIPPING_AND_TIME_SLOT = (productId, city) => {
  return `/productapi/api/rest/v1.3/getShippingMethodsAndTimeSlotsForINTL?productId=${productId}&city=${city}`;
};

/**
 * This method fetches International delivery dates list.
 */
export const GET_INTERNATIONAL_DELIVERY_DATES = (productId, city, shipmentType, timeSlotId) => {
  const localParams = { productId, city, shipmentType, timeSlotId };
  const filteredParams = removeEmptyNullAndUndefinedFromObject(localParams);
  const flattenedParams = new URLSearchParams(filteredParams).toString();
  return `/productapi/api/rest/v1.3/getDeliveryDatesForINTL?${decodeURIComponent(flattenedParams)}`;
};

/**
 * API endpoint URL to fetch the Saved Address response.
 */
export const GET_SAVED_ADDRESSES = () => {
  return `/control/getSavedRecipientAddresses?viewSize=40&viewIndex=0`;
};

/**
 * This method fetches popular link desktop data for OOPS 404 page.
 */
export const GET_POPULAR_LINK404_DESKTOP = () => {
  return `/chocolate/v1/global/static/web/desktop/popular-link-404-desktop-new`;
};

/**
 * This method fetches popular link mobile data for OOPS 404 page.
 */
export const GET_POPULAR_LINK404_MOBILE = () => {
  return `/chocolate/v1/global/static/web/mobile/popular-link-404-mobile-new`;
};

/**
 * Returns the endpoint URL for Top app banner data. .
 */
export const GET_APP_BANNER_DATA_URL = () => {
  return "global/static/web/mobile/fnp_app_redirect";
};

/**
 * Returns the endpoint URL for Clevertap Publish. .
 */
export const GET_APP_CLEVERTAP_PUBLISH_URL = () => {
  return `/${process.env.NEXT_SLS_API_VERSION}/publish`;
};
