import Image from "next/image";
import PropTypes from "prop-types";
import React from "react";

/**
 * Description placeholder
 * @date 13/03/2024 - 11:42:17
 *
 * @param {{ id?: string; radioListType?: string; isShowCountryCode?: boolean; listContent?: {}; }} param
 * @param {string} [param.id="radio"]
 * @param {string} [param.radioListType=""]
 * @param {boolean} [param.isShowCountryCode=false]
 * @param {{}} [param.listContent={}]
 * @returns
 */
const RadioListContent = ({
  id = "radio",
  radioListType = "",
  isShowCountryCode = false,
  listContent = {},
  isChecked,
}) => {
  switch (radioListType) {
    case "savedAddress":
      return (
        <label htmlFor={id} className={`flex cursor-pointer flex-col items-start`}>
          <span className={`text-14 font-600 capitalize text-black-100`}>{listContent?.toName}</span>
          <span className={`text-12 font-500 capitalize text-grey-400`}>
            {listContent?.city}, {listContent?.postalcode}
          </span>
        </label>
      );
    case "countrySelection":
      return (
        <label htmlFor={id} className={`flex items-center justify-between gap-10`}>
          <figure className={`m-0 flex h-20 w-26`}>
            <Image src={listContent.url} width={500} height={500} alt="flags" priority={false} />
          </figure>
          {isShowCountryCode && <span className={`text-14 font-500`}>{listContent.countryCode}</span>}
          <span className="text-14 text-fnp-500">{listContent.name}</span>
        </label>
      );
    case "flavorType":
      return (
        <label htmlFor={id} className={`flex items-center justify-between gap-4 pl-12`}>
          <span
            className={`[font-variant-numeric:lining-nums proportional-nums] text-center font-sans text-14  capitalize leading-18  ${isChecked ? "font-medium text-fnp-500" : "font-normal text-grey-500"} [font-feature-settings:'dlig'_on]`}
          >
            {listContent.name}
          </span>
        </label>
      );
    default:
      return null;
  }
};
RadioListContent.propTypes = {
  id: PropTypes.string.isRequired,
  radioListType: PropTypes.string.isRequired,
  listContent: PropTypes.object.isRequired,
  isShowCountryCode: PropTypes.bool,
  isChecked: PropTypes.bool,
};

export default RadioListContent;
