/**
 * Represents the API endpoint for the home page data in the website.
 * @constant
 * @type {string}
 */
export const GET_HOME_PAGE_INFO = `/chocolate/v1/homepage`; //TBD: Update CMS path

/**
 * Represents the API endpoint for Gift Finder data in the website
 * @constant
 * @type {string}
 */
export const GET_APP_QUICK_SEARCH = `/chocolate/v1/global/static/app_quick_search`;
/**
 *
 * @returns
 */
export const GET_PRODUCT_LIST = (
  FNP_CURRENT_CATALOG_ID = "india",
  catalogId = "india",
  pageType = "recentlyviewed",
  productIds = "COUR252085,COUR268951,ITNL335866,ITNL203269,ITNL418801,COUR269033,COUR269388,PERS2034",
) => {
  return `/d/control/getProductListing-rj?FNP_CURRENT_CATALOG_ID=${FNP_CURRENT_CATALOG_ID}&catalogId=${catalogId}&pageType=${pageType}&productIds=${productIds}`;
};

/**
 * Returns the endpoint URL for fetching the product delivery time left.
 *
 * This function is used to get the API endpoint that provides the remaining time for a product delivery.
 *
 * @returns {string} The URL of the API endpoint to fetch the product delivery time left.
 */
export const getProductDeliveryTimeLeft = (productId) => {
  return `/control/getProductDeliveryTimeLeft${productId ? `?productId=${productId}` : ""}`;
};
