/* eslint-disable react/jsx-handler-names */
"use client";
import isArray from "lodash.isarray";
import PropTypes from "prop-types";
import Typography from "../../../components/atoms/typography";
import CustomImage from "../../../components/atoms/customImage";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import {
  appendDots,
  customPaging,
  SlickNextArrow,
  SlickPrevArrow,
} from "../../../components/molecules/carouselHelpers";
import useAnalytics from "../../../analytics/useAnalytics";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";

/**
 * CategoryCarousel component displays a list of product tiles in a carousel.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Object} props.list - The list of products to display
 * @param {string} props.list.category_Name - The name of the category
 * @param {Array} props.list.list - The list of product items
 * @param {string} props.list.list[].category_link - The link to the category
 * @param {string} props.list.list[].banner_image_url - The image URL for the product
 * @param {number} props.slidesToSlide - The number of slides to slide at once
 * @returns {JSX.Element}
 */
function CategoryCarousel({ data, headingClass, options, dimensions = { width: 1184, height: 460 }, isOld = false }) {
  const { trackEvent } = useAnalytics();
  const carouselImages = isArray(data?.list) ? data?.list : [];
  const [isAddPadding, setIsAddPadding] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    /**
     * Adjusts padding based on window width and carousel responsive settings.
     * Disables padding if the window width is below defined breakpoints and `slidesToShow` is 1.
     *
     * @returns {void}
     */
    const updatePadding = () => {
      if (options?.responsive && typeof window !== "undefined") {
        const width = window.innerWidth;
        let addPadding = true;

        options.responsive.forEach((item) => {
          if (width < item.breakpoint && item.settings.slidesToShow === 1) {
            addPadding = false;
          }
        });
        setIsAddPadding(addPadding);
      }
    };

    updatePadding();
    window.addEventListener("resize", updatePadding);
    return () => window.removeEventListener("resize", updatePadding);
  }, [options]);

  const activeSlide = useRef(0);

  const setting = {
    ...options,
    nextArrow: <SlickNextArrow right="0px" />,
    prevArrow: <SlickPrevArrow left="0px" />,
    beforeChange: (current, next) => (activeSlide.current = next),
    appendDots: (dots) => appendDots(dots, activeSlide.current),
    customPaging: (i) => customPaging(activeSlide.current, carouselImages)(i),
  };

  const pageBannerData = {
    page_name: cleverTapCommonWords.HOME,
    page_type: cleverTapCommonWords.HOME,
  };
  return (
    <div className={`${isOld ? "" : "categorySlider"} relative w-full px-16 md:px-40`}>
      {data?.category_Name && (
        <Typography
          variant="h1"
          Tag="p"
          classes={`font-600 text-16 mb-12 md:mb-20 lg:text-24 xxl:text-32 ${headingClass}`}
        >
          {data?.category_Name}
        </Typography>
      )}
      <Slider
        {...setting}
        className={`relative w-full ${options.responsive.some((resp) => resp.settings?.dots) ? "pb-36" : ""} ${options?.dots ? "md:pb-36" : "md:pb-0"}`}
      >
        {carouselImages?.map((ele, index) => {
          const hoverTextColor = ele?.textConfig?.textColorHover || ele?.textConfig?.textColor;
          return (
            <a
              href={`${ele?.category_link}${ele?.productId ? (ele?.category_link?.includes("?") ? `&productId=${ele?.productId}&currencyUomId=INR&categoryId=gift&catalogId=india` : `?productId=${ele?.productId}&currencyUomId=INR&categoryId=gift&catalogId=india`) : ""}`}
              key={index}
              className={isAddPadding ? (isOld ? "pr-16" : "") : ""}
              onClick={() => {
                trackEvent(
                  cleverTapCommonEvents.bannerClicked,
                  {
                    ...pageBannerData,
                    banner_id: index,
                    banner_name: ele?.categoryName,
                  },
                  ["clevertap"],
                );
              }}
            >
              <div
                className="relative w-full overflow-hidden rounded-2xl" // eslint-disable-next-line react/jsx-handler-names
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <CustomImage
                  src={ele?.banner_image_url}
                  alt={ele?.altTag ?? "Banner image"}
                  width={dimensions?.width}
                  height={dimensions?.height}
                  sizes="20vw"
                  customStyle={{ width: "100%", height: "100%" }}
                />
                {ele?.textConfig && (
                  <div
                    className="absolute bottom-0 left-0 block w-full truncate p-8 text-center text-14 font-600 transition duration-150 ease-out hover:ease-in md:p-16 md:text-18"
                    style={{
                      color: hoveredIndex === index ? hoverTextColor : ele?.textConfig?.textColor,

                      background: ele?.textConfig?.bgColor,
                    }}
                  >
                    {ele?.textConfig?.categoryName}
                  </div>
                )}
              </div>
            </a>
          );
        })}
      </Slider>
    </div>
  );
}

export default CategoryCarousel;

CategoryCarousel.propTypes = {
  data: PropTypes.object.isRequired,
  headingClass: PropTypes.string,
  options: PropTypes.object.isRequired,
  dimensions: PropTypes.object,
  isOld: PropTypes.bool,
};
