/* eslint-disable react/jsx-handler-names */
import PropTypes from "prop-types";
import Typography from "../../../components/atoms/typography";
import CustomImage from "../../../components/atoms/customImage";
import Widget from "../../../components/organisms/widget";
import { useEffect, useState, useRef } from "react";
import { getRecentViewedByYouAPI } from "../../../api/pdpApi";
import Loader from "../../../components/atoms/loader";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";
import { PageTypes } from "../../../utils/fnpPageType";

/**
 * CelebSection component displays a section with a background image,
 * a category name, a list of product categories, a left image,
 * and a carousel of product cards.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Object} props.fetchData - The data for the product tiles
 * @returns {JSX.Element}
 */
function CelebSection({ fetchData, dimensions = { width: 532, height: 780 }, isMobile }) {
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(true);
  const sectionRef = useRef(null);
  const { trackEvent } = useAnalytics();
  const pageBannerData = {
    page_name: cleverTapCommonWords.HOME,
    page_type: cleverTapCommonWords.HOME,
  };

  useEffect(() => {
    /**
     * Function to handle the API call when the section is in the viewport
     */
    const fetchRecentViewed = async () => {
      if (fetchData.products) {
        const data = await getRecentViewedByYouAPI(fetchData.products);
        setProducts(data);
        setLoading(false);
      }
    };

    /**
     * Intersection Observer callback
     * @param {IntersectionObserverEntry[]} entries
     */
    const handleIntersection = (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        fetchRecentViewed();
        // eslint-disable-next-line no-use-before-define
        observer.unobserve(sectionRef.current); // Stop observing once data is fetched
      }
    };

    /**
     * Create and configure Intersection Observer
     */
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1, // Trigger when 10% of the section is visible
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (sectionRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(sectionRef.current);
      }
    };
  }, [fetchData.products]);

  return (
    <div ref={sectionRef} className={isMobile ? " flex flex-col" : "relative flex w-full"}>
      {isMobile ? (
        <>
          <div className="relative flex px-16">
            <div className="absolute bottom-0 left-16 flex h-full w-[40%] justify-center">
              <CustomImage
                src={fetchData?.leftImage}
                width={dimensions?.width}
                height={dimensions?.height}
                sizes="20vw"
                className="absolute bottom-0"
                alt={fetchData.category_Name}
                isPriority={false}
                object-fit="contains"
                object-position="left bottom"
              />
            </div>
            <div className="flex w-full flex-col">
              <div className="pl-[50%]">
                <Typography
                  variant="h1"
                  Tag="p"
                  classes="flex justify-start text-16 inter font-600 mx-6 my-6 py-4 sm:text-36"
                  style={{ color: fetchData?.bannerStyle?.category_Title_Color }}
                >
                  {fetchData.category_Name}
                </Typography>
              </div>
              <div
                className="flex flex-wrap gap-8 py-16 pl-[50%] pr-16"
                style={{
                  backgroundImage: `url(${fetchData.backGroundImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {fetchData.list?.map((product, index) => (
                  <div
                    className={`w-full rounded-8`}
                    key={index}
                    style={{
                      backgroundImage: `linear-gradient(to right, ${fetchData?.bannerStyle?.gradient2}, ${fetchData?.bannerStyle?.gradient1})`,
                    }}
                  >
                    <a
                      href={product.category_link}
                      onClick={() => {
                        trackEvent(
                          cleverTapCommonEvents.bannerClicked,
                          {
                            ...pageBannerData,
                            banner_id: index,
                            banner_name: product?.categoryName,
                          },
                          ["clevertap"],
                        );
                      }}
                    >
                      <span className="flex items-center justify-between gap-8 p-8">
                        <CustomImage
                          src={product?.banner_image_url}
                          width={40}
                          height={40}
                          sizes="20vw"
                          alt={product.category_Name}
                          isPriority={false}
                        />
                        <Typography
                          Tag="p"
                          classes="font-semibold py-8 text-12 font-semibold inter flex-1"
                          style={{ color: fetchData?.bannerStyle?.category_Name_Color }}
                        >
                          {product.category_Name}
                        </Typography>
                        <img src="/icons/arrow-right-fnp.svg" className="text-fnp-300" alt="arrow-right-fnp" />
                      </span>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="p-16 pb-0" style={{ background: fetchData?.bannerStyle?.widgetBackground }}>
            {loading ? (
              "loading....."
            ) : (
              <Widget
                productsList={products?.data?.productResults}
                isMobile={isMobile}
                isShowEarliestDate={false}
                isHideSavedPercent={true}
                pageType={PageTypes?.HOME}
              />
            )}
          </div>
        </>
      ) : (
        <div className="relative flex w-full">
          <div className="absolute bottom-0 left-40 flex h-full w-[22%] justify-center">
            <CustomImage
              src={fetchData?.leftImage}
              width={dimensions?.width}
              height={dimensions?.height}
              sizes="20vw"
              className="absolute bottom-0"
              alt={fetchData.category_Name}
              isPriority={false}
              object-fit="cover"
              object-position="left bottom"
            />
          </div>
          <div className="flex w-full flex-col">
            <div className="pl-[30%] pr-40">
              <Typography
                variant="h1"
                Tag="p"
                classes="flex justify-start text-16 inter font-600 leading-none mb-20 py-4 md:text-36 xxl:text-44"
                style={{ color: fetchData?.bannerStyle?.category_Title_Color }}
              >
                {fetchData.category_Name}
              </Typography>
            </div>
            <div className="flex gap-16 pl-[30%] pr-40">
              {fetchData.list?.map((product, index) => (
                <div
                  className={`w-full rounded-8`}
                  key={index}
                  style={{
                    backgroundImage: `linear-gradient(to right, ${fetchData?.bannerStyle?.gradient2}, ${fetchData?.bannerStyle?.gradient1})`,
                  }}
                >
                  <a
                    href={product.category_link}
                    onClick={() => {
                      trackEvent(
                        cleverTapCommonEvents.bannerClicked,
                        {
                          ...pageBannerData,
                          banner_id: index,
                          banner_name: product?.categoryName,
                        },
                        ["clevertap"],
                      );
                    }}
                  >
                    <span className="flex items-center justify-between gap-8 p-8">
                      <CustomImage
                        src={product?.banner_image_url}
                        width={60}
                        height={60}
                        sizes="20vw"
                        alt={product.category_Name}
                        isPriority={false}
                      />
                      <Typography
                        Tag="p"
                        classes="font-600 py-8 text-16 font-semibold inter flex-1 xxl:text-20"
                        style={{ color: fetchData?.bannerStyle?.category_Name_Color }}
                      >
                        {product.category_Name}
                      </Typography>
                      <img src="/icons/arrow-right-fnp.svg" className="text-fnp-300" alt="arrow-right-fnp" />
                    </span>
                  </a>
                </div>
              ))}
            </div>
            <div
              className="mt-16 min-h-[310px] py-16 pl-[30%] pr-40 xl:min-h-[364px] xxl:min-h-[450px]"
              style={{ background: fetchData?.bannerStyle?.widgetBackground }}
            >
              {loading ? (
                <div className="relative flex min-h-[310px] xl:min-h-[364px] xxl:min-h-[450px]">
                  <Loader position="absolute" zIndex="z-1" />
                </div>
              ) : (
                <Widget
                  productsList={products?.data?.productResults}
                  isMobile={isMobile}
                  isShowEarliestDate={false}
                  isHideSavedPercent={true}
                  pageType={PageTypes?.HOME}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

CelebSection.propTypes = {
  fetchData: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  dimensions: PropTypes.object.isRequired,
};

export default CelebSection;
