const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  savedAddressesErrorMessage: "",
  savedAddresses: [],
};

const SavedAddressesSlice = createSlice({
  name: "SavedAddressesSlice",
  initialState,
  reducers: {
    /**
     * Reducer to fetch the saved addresses.
     * @param {Object} state - Current state.
     */
    requestToSavedAddress: (state) => {
      return {
        ...state,
      };
    },
    /**
     * Reducer to updated error Messages.
     * @param {Object} state - Current state.
     * @param {string} action.payload - Error message related to OTP verification.
     */
    savedAddressError: (state, action) => {
      state.savedAddressesErrorMessage = action.payload;
    },
    /**
     * Reducer to updated saved Addresses.
     * @param {Object} state - Current state.
     * @param {string} action.payload - Error message related to OTP verification.
     */
    setSavedAddress: (state, action) => {
      return {
        ...state,
        savedAddresses: action.payload,
      };
    },
  },
});
export default SavedAddressesSlice.reducer;
export const { requestToSavedAddress, savedAddressError, setSavedAddress } = SavedAddressesSlice.actions;
