import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  requestToGetUsl: false,
  requestToOtp: false,
  requestToCustomerData: false,
  requestToSignUp: false,
  uslErrorMessage: "",
  customerDataErrorMessage: "",
  signUpErrorMessage: "",
  openLoginDrawer: false,
  loading: false,
  showTNC: false,
  userDetails: {},
  showRegister: false,
  otpSuccess: false,
  otpDetails: "",
  otpErrorMessage: "",
  otpVerifyErrorMessage: "",
  changePhoneNumber: false,
  tokens: {},
  existingUser: {},
  loginSignUpSuccessfullyText: "",
  loginSignUpSuccessfully: false,
  customerDetailsErrorMessage: "",
};

/**
 * Slice for managing login and signup related state.
 * Includes reducers for various actions related to user details, OTP handling, and UI state.
 *
 * @type {import("@reduxjs/toolkit").Slice}
 */
export const LoginSignupSlice = createSlice({
  name: "LoginSignup",
  initialState,
  reducers: {
    /**
     * Reducer to handle opening and closing of the drawer.
     * @param {Object} state - Current state.
     */
    requestToFetchUsl: (state) => {
      state.requestToGetUsl = true;
    },
    /**
     * Reducer to handle OTP error.
     * @param {Object} state - Current state.
     * @param {string} action.payload - Error message related to OTP verification.
     */
    uslError: (state, action) => {
      state.uslErrorMessage = action.payload;
    },
    /**
     * Reducer to handle opening and closing of the drawer.
     * @param {Object} state - Current state.
     */
    requestToSendOtp: (state) => {
      state.requestToOtp = true;
    },

    /**
     * Reducer to handle opening and closing of the drawer.
     * @param {Object} state - Current state.
     */
    requestToFetchSignUp: (state) => {
      state.requestToSignUp = true;
    },

    /**
     * Reducer to handle opening and closing of the drawer.
     * @param {Object} state - Current state.
     */
    signUpError: (state, action) => {
      state.signUpErrorMessage = action.payload;
    },
    /**
     * Reducer to handle opening and closing of the drawer.
     * @param {Object} state - Current state.
     */
    customerDataError: (state, action) => {
      state.customerDataErrorMessage = action.payload;
    },
    /**
     * Reducer to handle opening and closing of the drawer.
     * @param {Object} state - Current state.
     * @param {Object} action - Action object containing payload.
     */
    requestToOpenLoginDrawer: (state, action) => {
      state.openLoginDrawer = action.payload;
    },
    /**
     * Reducer to toggle visibility of Terms and Conditions.
     * @param {Object} state - Current state.
     * @param {Object} action - Action object containing payload.
     */
    requestToShowTNC: (state, action) => {
      state.showTNC = action.payload;
    },
    /**
     * Reducer to save user details to state.
     * @param {Object} state - Current state.
     * @param {Object} action - Action object containing user details payload.
     */
    requestToSaveUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    /**
     * Reducer to toggle registration form visibility.
     * @param {Object} state - Current state.
     * @param {boolean} action.payload - Flag indicating whether to show or hide the registration form.
     */
    requestToRegister: (state, action) => {
      state.showRegister = action.payload;
    },
    /**
     * Reducer to handle successful OTP verification.
     * @param {Object} state - Current state.
     * @param {Object} action - Action object containing OTP details payload.
     */
    requestToOTP: (state, action) => {
      state.otpSuccess = true;
      state.otpDetails = action.payload;
    },
    /**
     * Reducer to handle hiding OTP input.
     * @param {Object} state - Current state.
     * @param {boolean} action.payload - Flag indicating whether OTP input is hidden.
     */
    requestToHideOTP: (state, action) => {
      state.otpSuccess = action.payload;
      state.otpDetails = "";
    },
    /**
     * Reducer to handle OTP error.
     * @param {Object} state - Current state.
     * @param {string} action.payload - Error message related to OTP verification.
     */
    otpError: (state, action) => {
      state.otpSuccess = false;
      state.otpDetails = "";
      state.otpErrorMessage = action.payload;
    },
    /**
     * Reducer to handle OTP verification error.
     * @param {Object} state - Current state.
     * @param {string} action.payload - Error message related to OTP verification.
     */
    otpVerifyError: (state, action) => {
      state.otpVerifyErrorMessage = action.payload;
    },
    /**
     * Reducer to toggle phone number change state.
     * @param {Object} state - Current state.
     * @param {boolean} action.payload - Flag indicating whether phone number change is requested.
     */
    changePhoneNumber: (state, action) => {
      state.changePhoneNumber = action.payload;
    },

    requestToGetCsrfTokens: () => {},

    setCsrfTokens: (state, action) => {
      return {
        ...state,
        tokens: action.payload,
      };
    },
    requestToValidateExistingUser: (state) => {
      return {
        ...state,
        requestToGetUsl: true,
      };
    },
    setValidateExistingUser: (state, action) => {
      return {
        ...state,
        existingUser: action.payload,
      };
    },
    requestLogout: (state) => {
      state.userDetails = {};
      state.tokens = {};
      state.otpSuccess = false;
    },
    resetLoginValue: () => initialState,

    requestToHandleSuccessfullToast: (state, action) => {
      state.loginSignUpSuccessfullyText = action.payload;
      state.loginSignUpSuccessfully = true;
    },
    requestToResetLoginSuccessfullToast: (state) => {
      state.loginSignUpSuccessfully = false;
    },
    requestToGetCustomerDetails: (state) => {
      return {
        ...state,
      };
    },
    responseFromGetCustomerDetails: (state, action) => {
      return {
        ...state,
        userDetails: action.payload.customerDetails,
      };
    },
    customerDetailsError: (state, action) => {
      state.customerDetailsErrorMessage = action.payload;
    },
  },
});

export default LoginSignupSlice.reducer;

export const {
  requestToOpenLoginDrawer,
  requestLogout,
  requestToShowTNC,
  requestToRegister,
  requestToOTP,
  otpError,
  requestToSaveUserDetails,
  requestToHideOTP,
  otpVerifyError,
  changePhoneNumber,
  requestToFetchUsl,
  uslError,
  requestToSendOtp,
  customerDataError,
  requestToFetchSignUp,
  signUpError,
  requestToGetCsrfTokens,
  setCsrfTokens,
  requestToValidateExistingUser,
  setValidateExistingUser,
  resetLoginValue,
  requestToHandleSuccessfullToast,
  requestToResetLoginSuccessfullToast,
  requestToGetCustomerDetails,
  responseFromGetCustomerDetails,
  customerDetailsError,
} = LoginSignupSlice.actions;
