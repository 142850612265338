import PropType from "prop-types";
import ModalFooter from "./modalFooter";
import ModalHeader from "./modalHeader";
import ModalContent from "./modelContent";
/**
 * @typedef {Object} params
 * @property {JSX.Element} children - The content to be displayed inside the modal body. (Required)
 * @property {string} containerClass - Optional class name(s) to be applied to the modal container.
 * @property {string} position - The position of the modal (e.g., "absolute", "relative", "fixed"). (Required)
 * @property {function} onToggleModal - Function to be called when the user toggles the modal visibility. (Required)
 * @property {JSX.Element} headerChildren - Content to be displayed in the modal header.
 * @property {JSX.Element} footerChildren - Content to be displayed in the modal footer.
 * @property {boolean} isShowHeaderBorder - Flag to show header border.
 * @property {boolean} isShowFooterBorder - Flag to show footer border.
 * @property {boolean} headerClasses - classes for more formatting header.
 * @property {boolean} footerClasses - classes for more formatting footer.
 * @property {string} parentClass - classes for parent container.
 */

/**
 * A reusable React component for rendering a modal window.
 *
 * @param {params} props
 * @returns {JSX.Element} The Modal component.
 */
const Modal = ({
  children,
  containerClass = "",
  position = "fixed",
  onToggleModal,
  headerChildren,
  footerChildren,
  isShowHeaderBorder,
  isShowFooterBorder,
  headerClasses,
  footerClasses,
  parentClass = "",
}) => {
  return (
    <div
      className={`${position} left-0 top-0 z-50 flex h-full w-full flex-col items-center bg-black-900 bg-opacity-65 ${parentClass ? parentClass : "justify-center"}`}
      role="modal"
    >
      <div className={`${containerClass} max-h-[calc(70% - 114px)] min-w-[300px] max-w-[70%] bg-white-900`}>
        {headerChildren && (
          <ModalHeader onToggleModal={onToggleModal} isShowBorder={isShowHeaderBorder} headerClasses={headerClasses}>
            {headerChildren}
          </ModalHeader>
        )}
        <ModalContent>{children}</ModalContent>
        {footerChildren && (
          <ModalFooter isShowBorder={isShowFooterBorder} footerClasses={footerClasses}>
            {footerChildren}
          </ModalFooter>
        )}
      </div>
    </div>
  );
};
Modal.propTypes = {
  children: PropType.node.isRequired,
  containerClass: PropType.string,
  position: PropType.string.isRequired,
  onToggleModal: PropType.func,
  headerChildren: PropType.node,
  footerChildren: PropType.node,
  isShowHeaderBorder: PropType.bool,
  isShowFooterBorder: PropType.bool,
  headerClasses: PropType.string,
  footerClasses: PropType.string,
  parentClass: PropType.string,
};
export default Modal;
