"use client";
import { usePathname, useRouter } from "next/navigation";
import PropTypes from "prop-types";

import IconButtonComponent from "../../../../components/atoms/iconButton";
import { shallowEqual, useSelector } from "react-redux";
import { COUNTRIES } from "../../../../constants/common/common";
import useAnalytics from "../../../../analytics/useAnalytics";
import { cleverTapCommonEvents } from "../../../../analytics/clevertapConstants";
import { pageName } from "../../../../utils/common";

/**
 * FNPIcon component represents the FNP (Ferns N Petals) icon.
 * Clicking on this icon redirects the user to the FNP homepage.
 * @returns {JSX.Element} The JSX representation of the FNPIcon component.
 */
const FNPIcon = ({ imgWidth = 75, imgHeight = 37 }) => {
  const router = useRouter();
  const pathname = usePathname();
  const { trackEvent } = useAnalytics();
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");
  const { product, catalogId } = useSelector(
    (state) => ({
      product: state?.pdp?.clientCurrentClickedItem,
      catalogId: state.appConfig.catalogId,
    }),
    shallowEqual,
  );
  const urlIdentifier = product?.urlIdentifier;

  /**
   * Handles the click event on the FNP icon.
   * Redirects the user to the FNP homepage.
   */
  const onClick = () => {
    const pageHeaderMenuData = {
      page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
      pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
      menu_name: "FNP",
    };
    trackEvent(cleverTapCommonEvents.headerMenuClicked, pageHeaderMenuData, ["clevertap"]);
    const homeURL = catalogId === COUNTRIES.INDIA ? "/" : `/${catalogId}/gifts-lp`;
    return router.push(homeURL);
  };

  return (
    <span
      className="cursor-pointer"
      onClick={onClick}
      role="button"
      aria-labelledby="Visiting FNP homepage"
      id="header_icon"
    >
      <IconButtonComponent
        labelColor="w-full font-normal"
        classes="py-2"
        imgWidth={imgWidth}
        imgHeight={imgHeight}
        iconSrc="/icons/fnp-gift.svg"
        alt="fnp_icon"
      />
    </span>
  );
};

FNPIcon.propTypes = {
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
};

export default FNPIcon;
