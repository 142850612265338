/* eslint-disable react/boolean-prop-naming */
import PropTypes from "prop-types";
import LocationLockFields from "../locationLockFields";
import Drawer from "../../drawer";
import { LOCATION_MANDATORY } from "../../../../constants/common/locationFieldConstants";

/**
 * MobileLocationLocationDrawer to select the location.
 * @param {*} param0
 * @returns
 */
const MobileLocationLockDrawer = ({
  isShowMobileLocationLockDrawer,
  onContinueShoppingClick,
  isMandateForMobile,
  isAutoForMobile,
  checkIfMandatory,
  setMandatoryError,
}) => {
  return (
    <Drawer
      position="bottom"
      isOpen={isShowMobileLocationLockDrawer}
      onDrawerClose={onContinueShoppingClick}
      styles="px-16 pt-20"
      isHideCloseBtn={isMandateForMobile}
      isHideOverlay={!isMandateForMobile}
    >
      <LocationLockFields
        isMandatoryLocation={checkIfMandatory}
        isAutoForMobile={isAutoForMobile}
        messageText={LOCATION_MANDATORY}
        parentClass="border-1 outlineColor-grey-200 mb-20"
        setMandatoryError={setMandatoryError}
      />
    </Drawer>
  );
};
MobileLocationLockDrawer.propTypes = {
  isShowMobileLocationLockDrawer: PropTypes.bool,
  onContinueShoppingClick: PropTypes.func,
  isMandateForMobile: PropTypes.bool,
  isAutoForMobile: PropTypes.bool,
  checkIfMandatory: PropTypes.bool,
  setMandatoryError: PropTypes.func,
};

export default MobileLocationLockDrawer;
