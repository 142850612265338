/* eslint-disable max-lines */
import { COUNTRIES } from "../constants/common/common";
import { GA4_EVENTS, PASSWORD, SOCIAL_LOGIN } from "./gaConstant";

const MS_PER_DAY = 1000 * 60 * 60 * 24;

/**
 * a and b are javascript Date objects
 *
 * @param {object} currDate current date
 * @param {object} selectedDate selected date
 * @returns {string} returns diff in days
 */
const dateDiffInDays = (currDate, selectedDate) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(currDate.getFullYear(), currDate.getMonth(), currDate.getDate());
  const utc2 = Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

/**
 * @function sortvalue gettting the sort filter value and return the filer name
 * @param {string} val - filter name
 * @returns {string} filterName
 *
 */
const sortvalue = (val) => {
  const sortValues = {
    "listprice_fnp.com_INR|asc": "Low to High",
    "listprice_fnp.com_INR|desc": "High to Low",
    "created_stamp|desc": "New",
  };
  if (val && val?.includes("sequence")) {
    return "Recommended";
  }
  return val ? sortValues[val] : null;
};
/**
 * Get GA4 Viewlist Plp data
 *
 * @param {object} productList productsList
 * @param {string} pincode lastSelectec pincode
 * @param {string} currency selected currency
 * @param {boolean} isLogin isLogin
 * @param {string} categoryId category Id
 * @param {string} categoryName categoty name
 * @param {boolean} capsuleVisible capsuleVisible
 * @param {any} sort is sort filter avialable or which filter apply
 * @param {any} filter is filter avialable or which filter apply
 *
 */
const setGA4ViewItemList = (
  productList,
  pincode,
  currency,
  isLogin,
  categoryId,
  categoryName,
  capsuleVisible,
  sort,
  filter,
  catalogueId = COUNTRIES?.INDIA,
  city = null,
) => {
  const products = [];
  productList?.forEach((item, index) => {
    const product = {};
    product.index = index;
    product.item_name = item?.productName;
    product.item_id = item?.productId;
    product.price = item?.price?.listPrice;
    product.mrp = item?.price?.mrp || null;
    product.item_brand = item?.brandName || "FNP-India";
    product.item_variant = item?.variant;
    product.item_category = item?.primaryProductType || null;
    product.item_category2 = item?.productTypeId || null;
    product.item_category3 = item?.fnpProductType || null;
    products.push(product);
  });
  const ga4ViewItemList = {
    login_state: isLogin || false,
    ecommerce: {
      currency: "INR",
      selected_currency: currency || null,
      capsule_visible: capsuleVisible || null,
      item_list_id: categoryId || null,
      item_list_name: categoryName || null,
      sort: sortvalue(sort) || "Recommended",
      filter: filter || null,
      items: products,
    },
  };
  if (catalogueId === "india") {
    ga4ViewItemList["pincode"] = pincode;
  } else {
    ga4ViewItemList["country"] = catalogueId;
    if (city) ga4ViewItemList["city"] = city;
  }
  return ga4ViewItemList;
};
/**
 * Get GA4 Selected Product
 *
 * @param {string} itemIndex product index
 * @param {object} product Selected product
 * @param {string} pincode lastSelectec pincode
 * @param {string} currency selected currency
 * @param {boolean} isLogin isLogin
 * @param {string} categoryId category Id
 * @param {string} categoryName categoty name
 * @param {boolean} capsuleVisible capsuleVisible
 * @param {any} sort is sort filter avialable or which filter apply
 * @param {any} filter is filter avialable or which filter apply
 *
 */
const setGA4SelectedProduct = (
  itemIndex,
  product,
  pincode,
  currency,
  isLogin,
  categoryId,
  categoryName,
  capsuleVisible,
  sort,
  filter,
  catalogueId = "india",
  city = null,
) => {
  const products = [];
  const productItem = {};
  productItem.index = itemIndex;
  productItem.item_name = product?.productName;
  productItem.item_id = product?.productId;
  productItem.price = product?.price?.listPrice;
  productItem.mrp = product?.price?.mrp || null;
  productItem.item_brand = product?.brandName || "FNP-India";
  productItem.item_variant = product?.varian;
  productItem.item_category = categoryId;
  productItem.item_category2 = categoryName;
  productItem.item_category3 = product?.productTypeId || null;
  productItem.item_category4 = product?.fnpProductType || null;
  products.push(productItem);
  const ga4ViewItemList = {
    login_state: isLogin || false,
    ecommerce: {
      capsule_visible: capsuleVisible || null,
      currency: "INR",
      selected_currency: currency || null,
      item_list_id: categoryId || null,
      item_list_name: categoryName || null,
      sort: sortvalue(sort) || null,
      filter: filter || null,
      items: products,
    },
  };
  if (catalogueId === "india") {
    ga4ViewItemList["pincode"] = pincode;
  } else {
    ga4ViewItemList["country"] = catalogueId;
    if (city) ga4ViewItemList["city"] = city;
  }
  return ga4ViewItemList;
};

/** Sets the login and sign-up GA4 Event.
 *
 * @param {object} userDetails - The details of the customer.
 * @param {boolean} googleLogin - A flag indicating if the login type is Google login.
 * @returns {object}
 */
const setGA4LoginAndSignUp = (userDetails, googleLogin) => {
  const loginAndSignUpObj = {
    event: "",
    login_type: googleLogin ? SOCIAL_LOGIN : PASSWORD,
    name: userDetails?.userName,
    email: userDetails?.emailID,
    phone_number: userDetails?.mobNo,
    customer_party_id: userDetails?.customerID,
  };
  if (userDetails?.userType === "Existing") {
    return { ...loginAndSignUpObj, event: GA4_EVENTS.LOGIN };
  } else {
    return { ...loginAndSignUpObj, event: GA4_EVENTS.SIGNUP };
  }
};

/**
 * @function setPdpPageGaEvent Setting the view product event object for GA.
 *
 * @param {event} event type of event
 * @param {object} viewProduct login state
 * @param {string} pincode selected pincode
 * @param {boolean} isUserLoggedIn login state
 * @param {string} selectedCurrency login state
 * @param {object} productDetail product detail
 */
const setGA4PdpPageGaEvent = (
  viewProduct,
  pincode,
  isUserLoggedIn,
  selectedCurrency,
  productDetail,
  title,
  catalogueId = COUNTRIES?.INDIA,
  city = null,
  type = GA4_EVENTS?.VIEW_ITEM,
  widgetType,
  position,
) => {
  const items = [];
  const product = {};
  product.item_name = viewProduct?.productName || null;
  product.item_id = viewProduct?.productId || null;
  if (type === GA4_EVENTS?.WIDGET_VIEWED) {
    product.parent_name = productDetail?.productId || null;
    if (productDetail?.variantProducts) {
      product.item_variant = `${title}_${viewProduct?.productId}`;
    } else {
      product.item_variant = null;
    }
  } else {
    product.parent_id = productDetail?.productId || null;
  }

  product.mrp = viewProduct?.productPrice?.listPrice || null;
  product.price = viewProduct?.productPrice?.price || null;
  product.item_brand = viewProduct?.brandName || "FNP-India";

  product.item_category = viewProduct?.primaryKeywordId || null;
  product.item_category2 = viewProduct?.productTypeId || null;
  product.item_category3 = viewProduct?.primaryCategoryId || null;
  product.quantity = 1;
  items.push(product);
  if (productDetail?.variantProducts) {
    product.item_variant = `${title}_${viewProduct?.productId}` || null;
  }
  const gaPdpPageEvent = {
    login_state: isUserLoggedIn,
    ecommerce: {
      currency: "INR",
      selected_currency: selectedCurrency,
      value: viewProduct?.productPrice?.price,
      items,
    },
  };
  if (catalogueId === COUNTRIES?.INDIA) {
    gaPdpPageEvent["pincode"] = pincode || null;
  } else {
    gaPdpPageEvent["country"] = catalogueId;
    if (city) gaPdpPageEvent["city"] = city;
  }

  if (type !== GA4_EVENTS?.VIEW_ITEM) {
    gaPdpPageEvent.ecommerce["widget_clicked"] = widgetType || null;
    gaPdpPageEvent.ecommerce["widget_rank"] = position;
  }

  return gaPdpPageEvent;
};

/**
 * @function nsaOrOutOfStockGaEvent Setting the NSA and out of stock event object for GA.
 * @param {object} event event
 * @param {object} pincode pincode
 * @param {boolean} product product detail
 */
const nsaOrOutOfStockGaEvent = (pincode, product) => {
  const gaNsaOrOutOfStockEvent = {
    pincode: pincode || null,
    item_id: product?.productId || null,
    item_name: product?.productName || null,
    price: product?.productPrice?.price || null,
  };
  return gaNsaOrOutOfStockEvent;
};
/**
 * Sets the selected shipping method name.
 *
 * @param {string} shippingMethodName - The name of the selected shipping method.
 * @returns {void}
 */
const setGaShippingMethodName = (shippingMethodName) => {
  const selectedShippingMethodNameObj = {
    shipping_method: shippingMethodName,
  };
  return selectedShippingMethodNameObj;
};

/** */
const setGaDateSelection = (selectedDate, eventCategory, eventAction) => {
  const [day, month, year] = selectedDate.split("-");
  const currentDate = new Date();
  const selectedDay = new Date(`${year}-${month}-${day}`);
  const difference = dateDiffInDays(currentDate, selectedDay);

  const selectDateLabel = selectedDay.getDate();
  const currentDateLabel = currentDate.getDate();

  const selectedMonth = selectedDay.toLocaleString("default", { month: "long" }).substring(0, 3);
  const currentMonth = currentDate.toLocaleString("default", { month: "long" }).substring(0, 3);
  const currentSelectedDate = `${selectDateLabel} ${selectedMonth} | ${currentDateLabel} ${currentMonth}`;

  const selecteddateEvent = {
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: currentSelectedDate,
    difference_in_days: `${difference} days`,
  };
  return selecteddateEvent;
};
/**
 * Sets the selected time slot.
 *
 * @param {string} startTime - The start time of the selected time slot.
 * @param {string} endTime - The end time of the selected time slot.
 * @returns {void}
 */
const setGaTimeSlotSelected = (startTime, endTime) => {
  const selectedTimeSlotObj = {
    time_slot: `${startTime} - ${endTime}`,
  };
  return selectedTimeSlotObj;
};

/**
 * @function changeVariantGaEvent Setting the change variant event object for GA.
 *
 * @param {object} selectedVariant selectedVariant
 * @param {object} pincode pincode
 * @param {boolean} product variants
 */
const changeVariantGaEvent = (selectedVariant, pincode, parentProductId) => {
  const gaChangeVariantEvent = {
    pincode: pincode || null,
    item_id: parentProductId || null,
    item_name: selectedVariant?.productName || null,
    variants_available: "Y",
    selected_variant_name: selectedVariant?.productName || null,
  };
  return gaChangeVariantEvent;
};

/**
 * @function removeFromCartGaEvent tracking removing cart event object for GA.
 * @param {object} cartData cart  detail
 * @param {boolean} isUserLoggedIn login state
 * @param {string} selectedCurrency selected currency
 */
const removeFromCartGaEvent = (cartData, isUserLoggedIn, selectedCurrency) => {
  const items = [];
  const product = {};
  product.item_name = cartData?.productName || null;
  product.item_id = cartData?.productId || null;
  product.price = cartData?.price || null;
  product.item_brand = cartData?.brandName || "FNP-India";
  product.pincode = cartData?.pincode || null;
  product.item_variant = cartData?.variantType || null;
  product.item_category = cartData?.category || null;
  product.item_category2 = cartData?.productTypeId || null;
  product.item_category3 = cartData?.primaryProductCategoryId || null;
  product.quantity = 1;
  items.push(product);

  const gaRemoveFromCartEvent = {
    login_state: isUserLoggedIn,
    ecommerce: {
      currency: "INR",
      selected_currency: selectedCurrency,
      value: cartData?.price || null,
      items,
    },
  };
  return gaRemoveFromCartEvent;
};

/**
 * GA4 continue with or without addon selected button
 *
 * @param {object} addons selected addons
 * @param {string} pinCode selected pincode
 * @param {boolean} loggedIn is userLoggedin
 * @param {string} pageType what type of page
 * @param {localCurrency} localCurrency user selected currency
 * @param {number} quantity increase quantity on plus on DA page
 */
const ga4addonAdded = (addons, pinCode, loggedIn, pageType, localCurrency, quantity) => {
  if (addons === null || addons === undefined) {
    return;
  }
  const gaaddonContinue = {};
  if (addons.length > 0) {
    const products = [];
    addons?.forEach((item, index) => {
      const product = {};
      product.index = index;
      product.item_name = item?.productName || null;
      product.item_id = item?.productId || null;
      product.price = item?.unitPrice ? item?.unitPrice : item?.price || null;
      product.mrp = item?.priceDetails?.defaultPrice || null;
      product.quantity = quantity || item?.quantity;
      products.push(product);
    });
    gaaddonContinue.pincode = pinCode || null;
    gaaddonContinue.login_state = loggedIn;
    gaaddonContinue.page_type = pageType || null;
    gaaddonContinue.ecommerce = {};
    gaaddonContinue.ecommerce.currency = "INR";
    gaaddonContinue.ecommerce.selected_currency = localCurrency || null;
    gaaddonContinue.ecommerce.items = products;
  }
  return gaaddonContinue;
};

/**
 * @function removeFromCartGaEvent tracking removing cart event object for GA.
 *
 * @param {object} cartData cart  detail
 * @param {boolean} isUserLoggedIn login state
 * @param {string} selectedCurrency selected currency
 * @param {string} giftItemPincode pincode
 * @param {object} singleAddon removed addon
 */
const removeAddonsFromCartGaEvent = (cartData, isUserLoggedIn, selectedCurrency, giftItemPincode, singleAddon) => {
  const items = [];
  const product = {};
  product.item_name = cartData?.productName || null;
  product.item_id = cartData?.productId || null;
  product.price = cartData?.unitPrice || cartData?.price || null;
  product.quantity = singleAddon ? 1 : cartData?.quantity || cartData?.selectedNumber;
  product.pincode = giftItemPincode || null;
  items.push(product);

  const gaRemoveFromCartEvent = {
    login_state: isUserLoggedIn,
    ecommerce: {
      currency: "INR",
      selected_currency: selectedCurrency,
      items,
    },
  };
  return gaRemoveFromCartEvent;
};

/**
 * GA4 addon selected
 *
 * @param {string} pinCode selected pincode
 * @param {boolean} loggedIn is userLoggedin
 * @param {string} pageType what type of page
 * @param {string} interactionType type interactionType is plus icon or minus icon
 */
const ga4addonSelected = (pinCode, loggedIn, pageType, interactionType) => {
  const gaaddonSelected = {
    pincode: pinCode || null,
    login_state: loggedIn || null,
    page_type: pageType || null,
    interactionType: interactionType || null,
  };
  return gaaddonSelected;
};
export {
  setGA4ViewItemList,
  setGA4SelectedProduct,
  setGA4PdpPageGaEvent,
  nsaOrOutOfStockGaEvent,
  setGaShippingMethodName,
  setGaTimeSlotSelected,
  setGaDateSelection,
  changeVariantGaEvent,
  removeFromCartGaEvent,
  ga4addonAdded,
  removeAddonsFromCartGaEvent,
  ga4addonSelected,
  setGA4LoginAndSignUp,
};
