import PropTypes from "prop-types";
import CustomImage from "../../atoms/customImage";
import React from "react";
/**
 * Renders the custom next arrow for the carousel.
 *
 * @param {Object} props - The props passed to the arrow component.
 * @param {string} props.className - The class name of the arrow element.
 * @param {Object} props.style - The inline style for the arrow element.
 * @param {Function} props.onClick - The function to be called when the arrow is clicked.
 *
 * @returns {JSX.Element} The rendered next arrow component.
 */
export function SlickNextArrow({ className, style, onClick, right = "10px" }) {
  return (
    <div className={`${className} custom-arrow`} style={{ ...style, display: "block", right: right }} onClick={onClick}>
      <CustomImage src="/icons/arrow-right.svg" alt="right arrow" width={24} height={24} sizes="10vw" />
    </div>
  );
}
SlickNextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  right: PropTypes.string,
};
/**
 * Renders the custom previous arrow for the carousel.
 *
 * @param {Object} props - The props passed to the arrow component.
 * @param {string} props.className - The class name of the arrow element.
 * @param {Object} props.style - The inline style for the arrow element.
 * @param {Function} props.onClick - The function to be called when the arrow is clicked.
 *
 * @returns {JSX.Element} The rendered previous arrow component.
 */
export function SlickPrevArrow({ className, style, onClick, left = "10px" }) {
  return (
    <div
      className={`${className} custom-arrow`}
      style={{ ...style, display: "block", left: left, zIndex: 10 }}
      onClick={onClick}
    >
      <CustomImage src="/icons/arrow-left.svg" alt="left arrow" width={24} height={24} sizes="10vw" />
    </div>
  );
}
SlickPrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  left: PropTypes.string,
};
/**
 * Renders custom dots for the carousel.
 *
 * @param {Array} dots - The list of dot elements that will be rendered.
 *
 * @returns {JSX.Element} The rendered dot container element.
 */
export function appendDots(dots, activeSlide, totalSlides, slidesToShow, infinite, customClass = "") {
  return (
    <div>
      <ul className={`customDots ${customClass}`}>
        {dots.map((dot, index) => {
          let dotClass = "";

          const lastVisibleSlide = totalSlides - slidesToShow;

          if (index === activeSlide) {
            dotClass = "active";
          } else if (index === activeSlide + 1 || index === activeSlide - 1) {
            dotClass = "next";
          } else if (index === activeSlide + 2 || index === activeSlide - 2) {
            dotClass = "next-smaller";
          } else if (index === activeSlide + 3 || index === activeSlide - 3) {
            dotClass = "next-smallest";
          }

          // Adjust for when `activeSlide` is near the end
          if (!infinite && activeSlide >= lastVisibleSlide) {
            if (index >= lastVisibleSlide && index <= activeSlide) {
              dotClass = "active";
            } else if (index >= lastVisibleSlide - 1) {
              dotClass = "next";
            } else if (index >= lastVisibleSlide - 2) {
              dotClass = "next-smaller";
            } else if (index >= lastVisibleSlide - 3) {
              dotClass = "next-smallest";
            }
          }

          return React.cloneElement(dot, {
            className: `${dot.props.className} ${dotClass}`,
          });
        })}
      </ul>
    </div>
  );
}

/**
 * Custom paging logic for the carousel.
 * @param {number} activeSlide - The index of the currently active slide.
 * @param {Array} data - The array of carousel data used for determining total slides.
 * @returns {Function} A function that returns the custom dot for the specified slide index.
 */
export function customPaging(activeSlide, data) {
  /**
   * Custom paging logic for the carousel. Renders a custom dot that shows the current slide number and total slides.
   * @param {number} i - The index of the current slide being processed.
   * @returns {JSX.Element} A JSX element that renders the custom dot with the current slide number and total slides.
   */
  const paging = (i) => <div className="customDot">{i === activeSlide ? `${i + 1} / ${data.length}` : ""}</div>;

  // Adding a display name to the function for debugging
  paging.displayName = "CustomPaging";

  return paging;
}
