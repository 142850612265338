import { put, takeLatest } from "redux-saga/effects";
import { getWebFeatureControls } from "../../../api/webFeatureControls";
import {
  requestToGetWebFeatureControls,
  setGetWebFeatureControls,
  setGetWebFeatureControlsErrors,
} from "../../slices/webFeatureControls";

/**
 * Saga to fetch web feature controls.
 * Tries to fetch the controls and dispatches the appropriate actions based on the result.
 * @generator
 * @function fetchWebFeatureControlsSaga
 */
function* fetchWebFeatureControlsSaga() {
  try {
    const response = yield getWebFeatureControls();
    yield put(setGetWebFeatureControls(response));
  } catch (error) {
    yield put(setGetWebFeatureControlsErrors(error));
  }
}
/**
 * Watches for the request to get web feature controls action and triggers the corresponding saga.
 *
 * @export
 * @generator
 * @function watcherFetchWebFeatureControls
 * @yields {void}
 */
export function* watcherFetchWebFeatureControls() {
  yield takeLatest(requestToGetWebFeatureControls.type, fetchWebFeatureControlsSaga);
}
