export const plpPageConstants = {
  SORT_BY: "Sort By",
  FILTER: "Filter",
  RECOMMENDED: "Recommended",
  NOT_FILTER: "No Filter",
  APPLIED: "Applied",
  TO: "to",
  INITIAL_PAGE: 0,
  DESKTOP_PRODUCT_PER_PAGE: 36,
  MOBILE_PRODUCT_PER_PAGE: 40,
  OUT_OF_STOCK: "OUT OF STOCK",
  EARLIEST_DELIVERY: "Earliest Delivery",
  SORT: "Sort",
  NO_PRODUCT_MATCH_FOUND: "No product match found.",
  NO_PRODUCT_FOUND: "No Products Found",
  NO_PRODUCT_IN_THIS_CATEGORY_FOR_YOUR_LOCATION: "No products in this category for your location",
  VIEW_AVAILABLE_GIFT_FOR: "View available gifts for",
  NOT_SERVICEABLE: "Not Serviceable",
  WE_ARE_SORRY: "We are sorry! We do not deliver in this location for now",
  CHANGE_DELIVERY_PINCODE: "Change Delivery Pincode/Area",
  WE_COULD_NOT_FIND_ANY_PRODUCTS: "We could not find any products for your selected criteria",
  RESET_FILTERS: "RESET FILTERS",
  NOT_SERVICABLE_CATEGORY: "No products found in this category",
  VIEW_AVAILABLE_GIFT: "View available gifts",
  SEE_ALL_AVAILABLE_PRODUCTS: "See all available products at ",
  SEARCH: "search",
  NO_RESULT_FOUND: "Sorry, No Results Found.",
  TRY_SOMETHING_ELSE: "Please try searching for something else.",
  SEARCH_INSTEAD: "Search Instead",
  SEARCH_TITLE: "Popular Search  - FNP",
  SHOW_RESULTS: "Show Results",
  SEARCH_RESULTS: "Search Results",
  AndAbove: "and Above",
  Asterisk: "*",
};

export const plpBannerConstants = {
  PLP_BANNER: "PLP Banner",
  CODE_COPIED: "Code Copied",
  USE_CODE: "Use Code",
  HRS: "Hrs",
  MIN: "Min",
  SEC: "Sec",
};

export const plpCleverTapEvents = {
  PAGE_VIEWED: "PLP Page Viewed",
  PRODUCT_CLICKED: "Product Clicked",
  PAGE_SCROLLED: "Page Scrolled",
  SORT_SELECTED: "Sort Selected",
  FILTER_SELECTED: "Filter Selected",
};
