import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recentlyViewedData: [],
  requestRecentlyViewed: false,
  responseRecentlyViewed: false,
  failedRecentlyViewed: false,
  errorRecentlyViewed: {},
  isMobileSearchDrawerVisible: false,
};

export const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    requestToFetchRecentlyViewedAction: (state) => {
      return {
        ...state,
        requestRecentlyViewed: true,
      };
    },
    responseFromFetchRecentlyViewedAction: (state, action) => {
      return {
        ...state,
        responseRecentlyViewed: true,
        recentlyViewedData: action?.payload,
      };
    },
    failedToFetchRecentlyViewedAction: (state, action) => {
      return {
        ...state,
        failedRecentlyViewed: true,
        errorRecentlyViewed: action.payload,
      };
    },
    setIsMobileSearchVisibleAction: (state, action) => {
      return {
        ...state,
        isMobileSearchDrawerVisible: action.payload,
      };
    },
  },
});

export default CommonSlice.reducer;
export const {
  failedToFetchRecentlyViewedAction,
  requestToFetchRecentlyViewedAction,
  responseFromFetchRecentlyViewedAction,
  setIsMobileSearchVisibleAction,
} = CommonSlice.actions;
