"use client";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IconButtonComponent from "../../atoms/iconButton";
import CountryListDropDown from "../countryListDropDown";
import { desktopIndiaConfig } from "../../../constants/mockData/mockData";
import Drawer from "../drawer";
import { useRouter } from "next/navigation";
import { useSelector } from "react-redux";
import useAnalytics from "../../../analytics/useAnalytics";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";
import isEmpty from "lodash.isempty";
import { COUNTRIES, COUNTRIES_URL } from "../../../constants/common/common";
import Loader from "../../atoms/loader";

/**
 * StickyBottomMenu component for displaying a fixed bottom menu with icons and text.
 * @component
 * @param {string} param.catalogId - The catalog identifier (e.g., "india").
 * @returns {JSX.Element} JSX representation of the component.
 */
const StickyBottomMenu = ({ catalogId = "", parentClass = "" }) => {
  const router = useRouter();
  const { trackEvent } = useAnalytics();
  const [selectedCountry, setSelectedCountry] = useState(desktopIndiaConfig?.defaultCountry);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [showCountryList, setShowCountryList] = useState(false);
  const [activeItem, setActiveItem] = useState({
    home: true,
    abroad: false,
  });
  const [loading, setLoading] = useState(false);

  const checkLoginStatus = useSelector((state) => state.fus.loginDetail?.loggedin);
  const countryListData = useSelector((state) => state.locationLock.countryListData);

  const pageStickyMenuData = {
    page_name: cleverTapCommonWords.HOME,
    page_type: cleverTapCommonWords.HOME,
    icon_name: null,
    icon_position: null,
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    if (selectedCountry.countryCategoryID === "gifts") {
      router.push("/");
    } else if (selectedCountry?.catelogID === COUNTRIES.SINGAPORE) {
      router.push(COUNTRIES_URL.SINGAPORE.url);
    } else {
      router.push(`/${selectedCountry.countryCategoryID}-lp`);
    }
    setActiveItem({
      home: true,
      abroad: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  useEffect(() => {
    const country = countryListData.filter((item) => catalogId === item?.catelogID)?.[0];

    if (!isEmpty(country)) setSelectedCountry(country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryListData]);

  /**
   * Toggles the visibility of the country list.
   * Sets the state to show or hide the country list.
   * @function onToggleCountryList
   */
  const onToggleCountryList = () => {
    setShowCountryList(!showCountryList);
    setActiveItem({
      home: true,
      abroad: false,
    });
  };

  /**
   * Handles click event for the Home menu item.
   * Toggles the active state of Home.
   * @function
   */
  const homeOnClick = () => {
    setActiveItem({
      home: true,
      abroad: false,
    });
  };

  /**
   * Handles click event for the Abroad menu item.
   * Toggles the active state of Abroad.
   * @function
   */
  const abroadOnClick = () => {
    setActiveItem({
      home: false,
      abroad: true,
    });
    setShowCountryList(true);
    trackEvent(
      cleverTapCommonEvents.bottomNavigationNavIconClicked,
      {
        ...pageStickyMenuData,
        icon_name: "Abroad",
        icon_position: 3,
      },
      ["clevertap"],
    );
  };

  /**
   * Handles the selection of a country.
   * Sets the selected country, hides the country list, and dispatches actions to update the state.
   * @function onCountryClickHandler
   * @param {Object} item - The selected country object.
   */
  const onCountryClickHandler = (item) => {
    setSelectedCountry(item);
    setLoading(true);
  };

  const menuItems = [
    {
      id: "home",
      iconSrc: "/icons/fnp-gift-grey.svg",
      activeIconSrc: "/icons/fnp-gift.svg",
      text: "Home",
      url: "/",
      onClick: homeOnClick,
    },
    {
      id: "sameDay",
      iconSrc: "/icons/same-day.svg",
      text: "Same Day",
      url: "/same-day-delivery-gifts-lp",
    },
    {
      id: "allGifts",
      iconSrc: "/icons/gift-box.svg",
      activeIconSrc: "/icons/gift-box-active.svg",
      text: "All Gifts",
      url: "/allgifts",
    },
    {
      id: "abroad",
      iconSrc: "/icons/global.svg",
      activeIconSrc: "/icons/global-active.svg",
      text: "Abroad",
      onClick: abroadOnClick,
    },
    {
      id: "myAccount",
      iconSrc: "/icons/user-square.svg",
      activeIconSrc: "/icons/user-square-active.svg",
      text: "Account",
      url: checkLoginStatus ? "/account/my-account" : "/login",
    },
  ];

  return (
    <>
      <Drawer
        position="bottom"
        isOpen={showCountryList}
        onDrawerClose={onToggleCountryList}
        styles={`px-16 max-h-[80vh] ${showCountryList ? "pb-[40px]" : "pb-0"}`}
      >
        {loading && <Loader />}
        <CountryListDropDown
          onCountryClick={onCountryClickHandler}
          selectedCountry={selectedCountry}
          isShowCountryCode={false}
          isSearchEnable={true}
        />
      </Drawer>
      <div
        className={`fixed bottom-0 left-0 z-40 w-full justify-center bg-white-900 shadow-top md:hidden ${parentClass}`}
        id="sticky_bottom_menu"
      >
        <ul className="flex w-full justify-between gap-8">
          {menuItems.map((item, index) => (
            <li
              key={item.id}
              className={`flex flex-1 flex-col items-center justify-center ${activeItem[item.id] ? "before:absolute before:top-0 before:h-4 before:w-40 before:rounded-full before:bg-fnp-600" : ""}`}
            >
              {item.url ? (
                <a
                  className="w-full py-8"
                  href={item.url}
                  onClick={() => {
                    trackEvent(
                      cleverTapCommonEvents.bottomNavigationNavIconClicked,
                      {
                        ...pageStickyMenuData,
                        icon_name: item.text,
                        icon_position: index,
                      },
                      ["clevertap"],
                    );
                  }}
                >
                  <IconButtonComponent
                    iconSrc={activeItem[item.id] ? item.activeIconSrc || item.iconSrc : item.iconSrc}
                    imgHeight={35}
                    imgWidth={42}
                    onClick={item.onClick}
                    label={item.text}
                    labelColor={`${activeItem[item.id]} ? "w-full text-fnp-300 font-normal" : "w-full text-grey-400 font-normal`}
                    classes="flex flex-col items-center justify-center gap-2 w-full"
                  />
                </a>
              ) : (
                <IconButtonComponent
                  iconSrc={activeItem[item.id] ? item.activeIconSrc || item.iconSrc : item.iconSrc}
                  imgHeight={35}
                  imgWidth={42}
                  onClick={item.onClick}
                  label={item.text}
                  labelColor={`${activeItem[item.id]} ? "w-full text-fnp-300 font-normal" : "w-full text-grey-400 font-normal`}
                  classes="py-8 flex flex-col items-center justify-center gap-2 w-full"
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

StickyBottomMenu.propTypes = {
  catalogId: PropTypes.string,
  parentClass: PropTypes.string,
};

export default StickyBottomMenu;
