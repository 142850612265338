import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { usePathname, useSearchParams } from "next/navigation";
import useCommonOmnitureData from "./useCommonOmnitureData";
import useOmnitureSetup from "./useOmnitureSetup";
import { COUNTRIES } from "../../constants/common/common";
import { gatePopupTracking } from "../../analytics/omniture/omniture-consts";
import { AUTO_TRIGGER_LOCK, OUTSIDE_INDIA, WITHIN_INDIA } from "../../constants/common/locationFieldConstants";
import CookieUtil from "../../utils/storage/cookieUtil";

/**
 * Listing page omniture setup
 */
export default function useListingOmnitureSetup(breadcrumbs, searchInstead) {
  const routerPath = usePathname();
  const searchParams = useSearchParams();
  const { rootGeoId, catalogId, checkLoginInfo } = useCommonOmnitureData();
  const { rootCatalogId, autoGatePopupConfig, productsList, total, pincode, selectedIntlCity } = useSelector(
    (state) => ({
      rootCatalogId: state?.appConfig?.catalogId,
      autoGatePopupConfig: state?.locationLock?.configData,
      productsList: state?.plp?.details?.data,
      total: state?.plp?.details?.total,
      pincode: state?.locationLock?.selectedDomesticLocation?.pincode,
      selectedIntlCity: state?.locationLock?.selectedIntLocation?.location,
    }),
    shallowEqual,
  );
  const searchOptions = Object.fromEntries(searchParams.entries());
  const noSearchResult = searchInstead?.length;
  const isPlpPage = routerPath?.includes("-lp");
  const { isAutoGateEnabledOnDesktopPLP, isAutoGateEnabledOnMobilePLP } = autoGatePopupConfig || {};
  const isAutoTriggerLock = CookieUtil.getCookie(AUTO_TRIGGER_LOCK);

  const gateData = {
    gate: {},
    category: {
      country: catalogId !== COUNTRIES.INDIA ? OUTSIDE_INDIA : WITHIN_INDIA,
      countryname: catalogId,
      city: catalogId !== COUNTRIES.INDIA ? selectedIntlCity : "",
      pincode: catalogId === COUNTRIES.INDIA ? pincode : "",
    },
  };

  if ((isAutoGateEnabledOnDesktopPLP || isAutoGateEnabledOnMobilePLP) && !isAutoTriggerLock && isPlpPage) {
    gateData.gate = { popUp: gatePopupTracking.AUTO_GATE_POPUP_VIEWED };
  }

  const omniData = useMemo(
    () => ({
      userDetails: {
        checkLoginInfo,
      },
      appConfigs: {
        rootGeoId,
        catalogId,
        rootCatalogId,
      },
      productsLists: {
        productsList,
        searchOptions,
        total,
        noSearchResult,
        selectedIntlCity,
      },
      commonPageData: {
        breadcrumbs,
      },
      gateDetails: {
        gateData,
        lastVisitedPincode: pincode,
        autoGatePopupConfig,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      checkLoginInfo,
      rootGeoId,
      catalogId,
      rootCatalogId,
      productsList,
      breadcrumbs,
      searchOptions,
      total,
      noSearchResult,
      selectedIntlCity,
      autoGatePopupConfig,
    ],
  );

  useOmnitureSetup(omniData, [omniData?.userDetails?.checkLoginInfo]);
}
