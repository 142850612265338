import { shallowEqual, useSelector } from "react-redux";

/**
 * Common omniture data
 *
 * @returns {object} common omniture data needed for every page load
 */
export default function useCommonOmnitureData() {
  const { catalogId, isMobile, rootGeoId, checkLoginInfo, existingUser, cartItems } = useSelector(
    (state) => ({
      catalogId: state?.appConfig?.catalogId,
      isMobile: state?.appConfig?.isMobile,
      rootGeoId: state?.appConfig?.catalogId,
      checkLoginInfo: state?.fus?.loginDetail,
      existingUser: state?.loginSignUp?.existingUser,
      cartItems: state?.cart?.cartDetails?.items,
    }),
    shallowEqual,
  );

  return {
    rootGeoId,
    catalogId,
    checkLoginInfo,
    isMobile,
    existingUser,
    cartItems,
  };
}
