import PropTypes from "prop-types";
import CustomImage from "../../../atoms/customImage";
import Typography from "../../../atoms/typography";
import RadioItemList from "../../radioItemList";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  setIsSavedAddressUserName,
  setIsShowDesktopLocationLockAction,
  setIsShowMobileLocationLockAction,
  setSelectedDomesticLocation,
} from "../../../../store/slices/locationLock";
import CookieUtil from "../../../../utils/storage/cookieUtil";
import {
  LAST_VISITED_ADDRESS,
  LAST_VISITED_INTERNATIONAL_LOCATION,
  LAST_VISITED_PINCODE,
  SELECTED_SAVED_ADDRESS,
  SELECTED_SAVED_ADDRESS_USERNAME,
} from "../../../../constants/storage";
import { usePathname } from "next/navigation";
import { requestToFetchProductListingAction } from "../../../../store/slices/plpSlice";
import { plpPageConstants } from "../../../../constants/pageConstants/plpConstants";
import { COUNTRIES } from "../../../../constants/common/common";
import {
  calDiscountPercentage,
  getProductDeliveryType,
  pageName,
  removeHashtagFromURL,
} from "../../../../utils/common";
import { AUTO_TRIGGER_LOCK, FROM_LOCATION_LOCK } from "../../../../constants/common/locationFieldConstants";
import { unlockBody } from "../../../../utils/document";
import useAnalytics from "../../../../analytics/useAnalytics";
import { cleverTapCommonEvents } from "../../../../analytics/clevertapConstants";
import { CAKE_TYPE } from "../../../../constants/pageConstants/pdpConstants";

/**
 * Description placeholder
 * @date 09/04/2024 - 10:32:28
 *
 * @export
 * @returns {string}
 */
export default function SavedAddress({ savedAddress, postalAddress }) {
  const dispatch = useDispatch();
  const pathname = usePathname();
  const { trackEvent } = useAnalytics();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const {
    details,
    appliedFilters,
    appliedSort,
    isMobile,
    catalogId,
    selectedCountryFromList,
    configData,
    product,
    clientVariantType,
  } = useSelector(
    (state) => ({
      details: state?.plp?.details || [],
      appliedFilters: state?.plp?.appliedFilters,
      appliedSort: state?.plp?.appliedSort,
      isMobile: state?.appConfig?.isMobile,
      catalogId: state.appConfig.catalogId,
      selectedCountryFromList: state?.locationLock?.selectedCountryFromList,
      configData: state?.locationLock?.configData,
      product: state?.pdp?.clientCurrentClickedItem,
      clientVariantType: state?.pdp?.clientVariantType,
    }),
    shallowEqual,
  );

  const {
    isAutoGateEnabledOnDesktopHome,
    isAutoGateMandateOnDesktopHome,
    isAutoGateMandateOnDesktopPLP,
    isAutoGateEnabledOnDesktopPLP,
    isAutoGateEnabledOnMobileHome,
    isAutoGateEnabledOnMobilePLP,
    isAutoGateMandateOnMobileHome,
    isAutoGateMandateOnMobilePLP,
  } = configData;
  const urlIdentifier = product?.urlIdentifier;
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");
  const isAutoTriggerLock = CookieUtil.getCookie(AUTO_TRIGGER_LOCK);
  const isInternational = catalogId !== COUNTRIES.INDIA;
  const isMandateForDesktop =
    !isAutoTriggerLock &&
    catalogId === COUNTRIES.INDIA &&
    selectedCountryFromList === COUNTRIES.INDIA &&
    ((isAutoGateMandateOnDesktopHome && isHome) || (isAutoGateMandateOnDesktopPLP && isPLP));
  const isMandateForMobile =
    !isAutoTriggerLock &&
    catalogId === COUNTRIES.INDIA &&
    selectedCountryFromList === COUNTRIES.INDIA &&
    ((isAutoGateMandateOnMobileHome && isHome) || (isAutoGateMandateOnMobilePLP && isPLP));
  const isAutoForDesktop =
    !isAutoTriggerLock && ((isAutoGateEnabledOnDesktopHome && isHome) || (isAutoGateEnabledOnDesktopPLP && isPLP));
  const isAutoForMobile =
    !isAutoTriggerLock && ((isAutoGateEnabledOnMobileHome && isHome) || (isAutoGateEnabledOnMobilePLP && isPLP));
  const commonPageData = {
    page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
    mandatory: isMobile ? isMandateForMobile : isMandateForDesktop,
    auto: isMobile ? isAutoForMobile : isAutoForDesktop,
  };
  const pageLocationSavedData = {
    ...commonPageData,
    area: null,
    pincode: null,
    system_pincode: null,
    country: null,
    city: null,
  };
  const discountPercentage = calDiscountPercentage(product?.productPrice?.listPrice, product?.productPrice?.price);
  const productDeliveryType = getProductDeliveryType(product?.primaryCategoryId);
  const lastVisitedAddress = CookieUtil.getCookie("lastVisitedAddress");
  const lastVisitedPincode = CookieUtil.getCookie("lastVisitedPincode");
  const lastVisitedIntLocation = CookieUtil.getCookie(LAST_VISITED_INTERNATIONAL_LOCATION);
  const cleverTapPDPCommonData = {
    page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
    product_name: product?.productName,
    pid: product?.productId,
    product_category: product?.primaryCategoryId,
    product_price: product?.productPrice?.price,
    is_variant_available: product?.isVariant === "Y",
    is_egg: clientVariantType?.toUpperCase() === CAKE_TYPE.WITH_EGG ?? false,
    delivery_type: productDeliveryType,
    is_international: isInternational,
    city: isInternational ? lastVisitedIntLocation : "",
    area: isInternational ? "" : lastVisitedAddress,
    pincode: isInternational ? "" : lastVisitedPincode,
    system_pincode: isInternational ? "" : lastVisitedPincode,
    country: catalogId,
    strike_price: product?.productPrice?.listPrice,
    discount_percentage: discountPercentage,
    product_rating: product?.productReviewsAndRatings?.ratingValue,
    product_review_count: product?.productReviewsAndRatings?.totalReview,
    is_product_available: product?.stockAvailable,
    sku_id: product?.skuCode,
  };
  const ishomePlpPdpLocationData = isHome || isPLP ? pageLocationSavedData : cleverTapPDPCommonData;

  useEffect(() => {
    const userSelectedAddress = savedAddress.find((item) => item?.postalAddress === postalAddress);
    setSelectedAddress(userSelectedAddress);
  }, [postalAddress]);

  /** */
  const onHandleAddressSelection = (item) => {
    setSelectedAddress(item);
    const options = {
      maxAge: 60 * 24 * 60 * 30,
      path: "/",
      encode: decodeURIComponent,
    };

    CookieUtil.setCookie(LAST_VISITED_PINCODE, item?.postalcode, options);
    CookieUtil.setCookie(LAST_VISITED_ADDRESS, item?.city, options);
    CookieUtil.setCookie(SELECTED_SAVED_ADDRESS, item?.postalAddress, options);
    CookieUtil.setCookie(SELECTED_SAVED_ADDRESS_USERNAME, item?.toName, options);
    dispatch(
      setSelectedDomesticLocation({
        pincode: item?.postalcode,
        address: item?.city,
      }),
    );
    dispatch(setIsShowDesktopLocationLockAction(false));
    dispatch(setIsShowMobileLocationLockAction(false));
    removeHashtagFromURL(FROM_LOCATION_LOCK);
    dispatch(setIsSavedAddressUserName(item?.toName));
    if (pathname?.includes("-lp")) {
      dispatch(
        requestToFetchProductListingAction({
          page: details?.currentPage,
          size: isMobile ? plpPageConstants.MOBILE_PRODUCT_PER_PAGE : plpPageConstants.DESKTOP_PRODUCT_PER_PAGE,
          geoId: COUNTRIES?.INDIA,
          categoryUrl: `fnp.com${pathname}`,
          pincode: item?.postalcode,
          filters: encodeURIComponent(appliedFilters),
          sortOption: encodeURIComponent(appliedSort),
        }),
      );
    }
    unlockBody();
    trackEvent(
      cleverTapCommonEvents.locationSaved,
      {
        ...ishomePlpPdpLocationData,
        area: item?.city,
        pincode: item?.postalcode,
        system_pincode: item?.postalcode,
      },
      ["clevertap"],
    );
  };
  return (
    <div
      className={`mb-16 flex max-h-[30vh] transform flex-col overflow-auto rounded-[16px] border border-gray-300 bg-white-900 scrollbar-thin scrollbar-webkit  md:p-16`}
    >
      <div className="flex w-full border-b-1 border-lightGrey p-16 md:mb-16 md:p-0">
        <div className="mr-8">
          <CustomImage
            src="/icons/user-square-desktop.svg"
            alt=""
            sizes="10vw"
            width={24}
            height={24}
            isPriority={false}
          />
        </div>
        <Typography variant="p" Tag="p" classes="font-600 text-16 text-fnp-500">
          Saved Addresses
        </Typography>
      </div>
      <div className="w-full">
        {savedAddress?.map((item, index) => {
          return (
            <RadioItemList
              id={`${item.toName}_${index}`}
              name={`${item.toName}_${index}`}
              radioListType="savedAddress"
              listContent={item}
              key={`${item.toName}_${index}`}
              isChecked={selectedAddress === item}
              // eslint-disable-next-line react/jsx-handler-names
              onChange={() => {
                onHandleAddressSelection(item);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
SavedAddress.propTypes = {
  savedAddress: PropTypes.array.isRequired,
  postalAddress: PropTypes.string,
};
