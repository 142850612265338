/**
 * Generates the URI for adding an item to the cart.
 *
 * @returns {string} The URI to add an item to the cart.
 */
export const ADD_TO_CART_URI = () => {
  return `/control/additem-rj`;
};

/**
 * Generates the URI for viewing the cart.
 *
 * @returns {string} The URI to view the cart, with external access enabled.
 */
export const VIEW_CART_URI = () => {
  return `/control/viewcart?isExternal=Y`;
};

/**
 * Generates the URI for deleting an item from the cart.
 *
 * @returns {string} The URI to delete an item from the cart.
 */
export const DELETE_CART_URI = () => {
  return `/control/deleteItem-rj`;
};

/**
 * Generates the URI for setting cart item values.
 *
 * @returns {string} The URI to set cart item values.
 */
export const SET_CART_ITEM_URI = () => {
  return `/control/setCartItemValues-rj`;
};

/**
 * Generates the URI for logging out.
 *
 * @returns {string} The URI to log out and redirect to the specified location.
 */
export const LOG_OUT = () => {
  return `/control/logout?to=/`;
};

/**
 * Generates the URI for logging out.
 *
 * @returns {string} The URI to log out and redirect to the specified location.
 */
export const UPDATE_ADD_ON_QUANTITY = () => {
  return `/control/updateAddonQuantityProduct-rj`;
};
