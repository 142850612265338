/* eslint-disable max-lines */
export const desktopIndiaConfig = {
  menuItems: [
    { href: "/", name: "Home India" },
    { href: "/about", name: "About India" },
  ],
  dnsPrefetchDomains: ["assets.adobedtm.com"],
  contactUs: { description: "+91 921 242 2000", link: "+919212422000" },
  currency: "INR",
  needTodayLink: "/same-day-delivery-gifts?promo=Header_Icon",
  helpCenterLink: "/info/help-center?promo=help_hicon&cid=india",
  countryList: [
    { iconUrl: "/icons/countries-flags/usa.svg", countryCurrency: "USD", label: "" },
    { iconUrl: "/icons/countries-flags/thailand.svg", countryCurrency: "THB", label: "" },
    { iconUrl: "/icons/countries-flags/singapore.svg", countryCurrency: "SGD", label: "" },
    { iconUrl: "/icons/countries-flags/qatar.svg", countryCurrency: "QAR", label: "" },
    { iconUrl: "/icons/countries-flags/new-zealand.svg", countryCurrency: "NZD", label: "" },
    { iconUrl: "/icons/countries-flags/malaysia.svg", countryCurrency: "MYR", label: "" },
    { iconUrl: "/icons/countries-flags/india.svg", countryCurrency: "INR", label: "" },
    { iconUrl: "/icons/countries-flags/uk.svg", countryCurrency: "GBP", label: "" },
    { iconUrl: "/icons/countries-flags/germany.svg", countryCurrency: "EUR", label: "" },
    { iconUrl: "/icons/countries-flags/canada.svg", countryCurrency: "CAD", label: "" },
    { iconUrl: "/icons/countries-flags/australia.svg", countryCurrency: "AUD", label: "" },
    { iconUrl: "/icons/countries-flags/uae.svg", countryCurrency: "AED", label: "" },
  ],
  moreInfoDropDown: [
    {
      iconUrl: "/icons/reminder.svg",
      text: "Reminder",
      label: "",
      landingUrl: "/account/reminder",
    },
    {
      iconUrl: "/icons/FNP-franchise.svg",
      text: "Start an FNP Franchise",
      label: "",
      landingUrl: "/info/franchise-programme?promo=franchise_enquiry",
    },
    {
      iconUrl: "/icons/become-a-vendor.svg",
      text: "Become a Vendor",
      label: "",
      landingUrl: "/info/become-a-partner?promo=partner_enquiry",
    },
    {
      iconUrl: "/icons/balloon-birthday-wedding-decor.svg",
      text: "Birthday / Wedding Decor",
      label: "",
      landingUrl: "/services/weddings-n-events?promo=wedding_enquiry",
    },
    { iconUrl: "/icons/media.svg", text: "Media", label: "" },
  ],
  accountDropDown: [
    { iconUrl: "/icons/login-register.svg", text: "Login/Register", label: "" },
    { iconUrl: "/icons/my-account-user.svg", text: "My Account", label: "", landingUrl: "/account/my-account" },
    { iconUrl: "/icons/brownie-Points.svg", text: "fnpCash", label: "", landingUrl: "/account/wallet-details" },
    { iconUrl: "/icons/my-order.svg", text: "My Orders", label: "", landingUrl: "/account/my-orders" },
    { iconUrl: "/icons/contact-us.svg", text: "Contact Us", label: "", landingUrl: "/info/contact-us" },
    { iconUrl: "/icons/faq-message-question.svg", text: "FAQs", label: "", landingUrl: "/info/faq" },
  ],

  loggedInAccountDropDown: [{ iconUrl: "/icons/logout.svg", text: "Logout", label: "" }],
  footerInfo: [
    {
      id: "worldwide-delivery",
      heading: "Worldwide Delivery",
      subheading: "We deliver gifts to over 70 countries",
    },
    {
      id: "safe-and-secure-payments",
      heading: "100% Safe & Secure Payments",
      subheading: "Pay using secure payment methods",
    },
    {
      id: "dedicated-help-center",
      heading: "Dedicated Help Center",
      subheading1: "Call us +91 9212422000 | 8:00AM-10:30PM",
      subheading2: "Chat With Us",
    },
  ],
  socialIcons: [
    {
      href: "https://www.facebook.com/fnpind",
      id: "facebook",
      ariaLabel: "Visit our facebook page",
    },
    { href: "https://twitter.com/fnp_ind", id: "twitter", ariaLabel: "Visit our twitter page" },
    {
      href: "https://www.instagram.com/fernsnpetalsindia/?hl=en",
      id: "instagram",
      ariaLabel: "Visit our instagram page",
    },
    { href: "https://in.pinterest.com/fnpind", id: "pinterest", ariaLabel: "Visit our pinterest page" },
  ],

  footerBodyLinks: [
    {
      title: "Policy Info",
      links: [
        { path: "/info/terms-and-conditions", linkName: "Terms & Conditions" },
        { path: "/info/privacy-policy", linkName: "Privacy Policy" },
        { path: "/info/terms-of-use", linkName: "Terms of Use" },
        { path: "/info/disclaimer", linkName: "Disclaimer" },
      ],
    },
    {
      title: "About Company",
      links: [
        { path: "/info/about-us", linkName: "About Us" },
        { path: "/info/fnp-team", linkName: "FNP Team" },
        { path: "/info/careers", linkName: "Careers" },
        { path: "/info/testimonials", linkName: "Testimonials" },
        { path: "/info/news", linkName: "News Room" },
      ],
    },
    {
      title: "FNP business",
      links: [
        { path: "/decoration-services", linkName: "Decoration Services" },
        { path: "/corporate", linkName: "Corporate Service" },
        { path: "/info/affiliate-program", linkName: "Affiliate Program" },
        { path: "/info/retail-stores", linkName: "Retails Stores" },
        { path: "/info/franchise-programme", linkName: "Franchise" },
      ],
    },
    {
      title: "Need Help ?",
      links: [
        { path: "/info/contact-us", linkName: "Contact Us" },
        { path: "/info/faq", linkName: "FAQs" },
      ],
    },
    {
      title: " Useful Links",
      links: [
        { path: "/quotes/wishes-n-messages", linkName: "Quotes N Wishes" },
        { path: "/article/flower-astrology", linkName: "Flower Astrology" },
        { path: "/info/articles-hub", linkName: "Article Hub" },
        { path: "/article/care-guide", linkName: "Care Guide" },
        { path: "/blog/", linkName: "Blog" },
      ],
    },
  ],
  paymentGatewayItems: [
    { id: "visa" },
    { id: "mastercard" },
    { id: "rupay" },
    { id: "diners-club" },
    { id: "american-express" },
  ],
  startYear: "1994",
  isSmartLockEnabled: true,
  isOmnitureEnabled: true,
  isCdpEnabled: true,
  popularMobileCodes: [
    "+91 IND",
    "+1 USA",
    "+1-284 VGB",
    "+61 AUS",
    "+65 SGP",
    "+974 QAT",
    "+64 NZL",
    "+60 MYS",
    "+1 CAN",
    "+971 ARE",
  ],
  moreInfoLinks: [
    {
      label: "Franchise Enquiries",
      href: "/info/franchise-programme?promo=franchise_enquiry",
    },
    {
      label: "Corporate Gifts",
      href: "https://www.fnp.com/corporate?promo=corporate_enquiry",
    },
  ],
  animationTime: 300,
  defaultCountry: {
    countryName: "India",
    countryCategoryID: "gifts",
    catelogID: "india",
    countryId: "IND",
    logo: "https://m-i1.fnp.com/assets/images/custom/country-flags/India.png",
    webLogo: "https://m-i1.fnp.com/assets/images/custom/country-flags/india.svg",
    countryCode: "91",
    countryCurrency: "INR",
  },
};

export const fnpBrandsData = {
  fnpBrands: [
    {
      imageUrl: "https://www.fnp.com/assets/images/custom/fnp-brands/Fnpvenues.png",
      brandUrl: "https://www.fnpvenues.com/",
      altTag: "Fnpvenues",
      width: 119,
      height: 44,
    },
    {
      imageUrl: "https://www.fnp.com/assets/images/custom/fnp-brands/FNP-weddings.png",
      brandUrl: "https://www.fnpweddings.com/",
      altTag: "FNP Weddings",
      width: 232,
      height: 44,
    },
    {
      imageUrl: "https://www.fnp.com/assets/images/custom/fnp-brands/FNP-water.png",
      brandUrl: "#",
      altTag: "FNP Water",
      width: 108,
      height: 44,
    },
    {
      imageUrl: "https://www.fnp.com/assets/images/custom/fnp-brands/WDH.png",
      brandUrl: "https://www.wdesignhub.com/",
      altTag: "WDH",
      width: 80,
      height: 44,
    },
    {
      imageUrl: "https://www.fnp.com/assets/images/custom/fnp-brands/FNP-media.png",
      brandUrl: "#",
      altTag: "FNP Media",
      width: 151,
      height: 44,
    },
    {
      imageUrl: "https://www.fnp.com/assets/images/custom/fnp-brands/Flagship.png",
      brandUrl: "https://www.flagshipbyfnp.com/",
      altTag: "Flagship",
      width: 69,
      height: 44,
    },
  ],
  csr: {
    content: [
      "Company Name: FNP E Retail Private Limited | CIN: U52100DL2021PTC376303 | Regd. Office Address: FNP Estate, Ashram Marg, Mandi Road, Gadaipur, New Delhi-110030",
      "Telephone No.: +91-11-26802680 | E-mail: help@fnp.com | Grievance Resolution Officer Name: Ms. Preeti Bindra | Contact No.: +91 9212422000 / 9755-248-248",
    ],
    linkText: "Corporate Social Responsibility (CSR) Policy",
    link: "/info/csr-policy",
  },
};

export const supportedCityAndCountry = {
  topCountries: [
    {
      catalogID: "usa",
      name: "USA",
      countryId: "USA",
      categoryId: "usa/gifts",
      url: "/icons/countries-flags/usa.svg",
    },
    {
      catalogID: "uk",
      name: "UK",
      countryId: "GBR",
      categoryId: "uk/gifts",
      url: "/icons/countries-flags/uk.svg",
    },
    {
      catalogID: "australia",
      name: "Australia",
      countryId: "AUS",
      categoryId: "australia/gifts",
      url: "/icons/countries-flags/australia.svg",
    },
    {
      catalogID: "canada",
      name: "Canada",
      countryId: "CAN",
      categoryId: "canada/gifts",
      url: "/icons/countries-flags/canada.svg",
    },
    {
      catalogID: "uae",
      name: "UAE",
      countryId: "UAE",
      categoryId: "uae/gifts",
      geoCode: ["AE"],
      geoDomainURL: "https://fnp.ae",
      shouldRedirect: true,
      redirectionText: "You will be redirected to our UAE site for a more localised experience",
      url: "/icons/countries-flags/uae.svg",
    },
  ],
  countryList: [
    {
      catalogID: "usa",
      name: "USA",
      countryId: "USA",
      categoryId: "usa/gifts",
      url: "/icons/countries-flags/usa.svg",
    },
    {
      catalogID: "uk",
      name: "UK",
      countryId: "GBR",
      categoryId: "uk/gifts",
      url: "/icons/countries-flags/uk.svg",
    },
    {
      catalogID: "australia",
      name: "Australia",
      countryId: "AUS",
      categoryId: "australia/gifts",
      url: "/icons/countries-flags/australia.svg",
    },
    {
      catalogID: "canada",
      name: "Canada",
      countryId: "CAN",
      categoryId: "canada/gifts",
      url: "/icons/countries-flags/canada.svg",
    },
    {
      catalogID: "germany",
      name: "Germany",
      countryId: "DEU",
      categoryId: "germany/gifts",
      url: "/icons/countries-flags/germany.svg",
    },
    {
      catalogID: "uae",
      name: "UAE",
      countryId: "ARE",
      categoryId: "uae/gifts",
      geoCode: ["AE"],
      geoDomainURL: "https://fnp.ae",
      shouldRedirect: true,
      redirectionText: "You will be redirected to fnp.ae for a more localised experience",
      url: "/icons/countries-flags/uae.svg",
    },
    {
      catalogID: "singapore",
      name: "Singapore",
      countryId: "SGP",
      categoryId: "singapore",
      geoCode: ["SG"],
      geoDomainURL: "https://fnp.sg",
      shouldRedirect: true,
      redirectionText: "You will be redirected to fnp.sg for a more localised experience",
      url: "/icons/countries-flags/singapore.svg",
    },
    {
      catalogID: "brazil",
      name: "Brazil",
      countryId: "brazil",
      categoryId: "brazil/gifts",
      url: "/icons/countries-flags/brazil.svg",
    },
    {
      catalogID: "philippines",
      name: "Philippines",
      countryId: "PHL",
      categoryId: "philippines/gifts",
      url: "/icons/countries-flags/philippines.svg",
    },
    {
      catalogID: "new-zealand",
      name: "New Zealand",
      countryId: "NZL",
      categoryId: "new-zealand/gifts",
      url: "/icons/countries-flags/new-zealand.svg",
    },
    {
      catalogID: "argentina",
      name: "Argentina",
      countryId: "ARG",
      categoryId: "argentina/gifts",
      url: "/icons/countries-flags/argentina.svg",
    },
    {
      catalogID: "austria",
      name: "Austria",
      countryId: "AUT",
      categoryId: "australia/gifts",
      url: "/icons/countries-flags/australia.svg",
    },
    {
      catalogID: "bahrain",
      name: "Bahrain",
      countryId: "BHR",
      categoryId: "bahrain/gifts",
      url: "/icons/countries-flags/bahrain.svg",
    },
    {
      catalogID: "belgium",
      name: "Belgium",
      countryId: "BEL",
      categoryId: "belgium/gifts",
      url: "/icons/countries-flags/belgium.svg",
    },
    {
      catalogID: "bulgaria",
      name: "Bulgaria",
      countryId: "BGR",
      categoryId: "bulgaria/gifts",
      url: "/icons/countries-flags/bulgaria.svg",
    },
    {
      catalogID: "china",
      name: "China",
      countryId: "CHN",
      categoryId: "china/gifts",
      url: "/icons/countries-flags/china.svg",
    },
    {
      catalogID: "croatia",
      name: "Croatia",
      countryId: "HRV",
      categoryId: "croatia/gifts",
      url: "/icons/countries-flags/croatia.svg",
    },
    {
      catalogID: "denmark",
      name: "Denmark",
      countryId: "DNK",
      categoryId: "denmark/gifts",
      url: "/icons/countries-flags/denmark.svg",
    },
    {
      catalogID: "egypt",
      name: "Egypt",
      countryId: "EGY",
      categoryId: "egypt/gifts",
      url: "/icons/countries-flags/egypt.svg",
    },
    {
      catalogID: "finland",
      name: "Finland",
      countryId: "FIN",
      categoryId: "finland/gifts",
      url: "/icons/countries-flags/finland.svg",
    },
    {
      catalogID: "france",
      name: "France",
      countryId: "FRA",
      categoryId: "france/gifts",
      url: "/icons/countries-flags/france.svg",
    },
    {
      catalogID: "greece",
      name: "Greece",
      countryId: "GRC",
      categoryId: "greece/gifts",
      url: "/icons/countries-flags/greece.svg",
    },
    {
      catalogID: "hong-kong",
      name: "Hong Kong",
      countryId: "HKG",
      categoryId: "hong-kong/gifts",
      url: "/icons/countries-flags/hong-kong.svg",
    },
    {
      catalogID: "hungary",
      name: "Hungary",
      countryId: "HUN",
      categoryId: "hungary/gifts",
      url: "/icons/countries-flags/hungary.svg",
    },
    {
      catalogID: "indonesia",
      name: "Indonesia",
      countryId: "IDN",
      categoryId: "indonesia/gifts",
      url: "/icons/countries-flags/indonesia.svg",
    },
    {
      catalogID: "ireland",
      name: "Ireland",
      countryId: "IRL",
      categoryId: "ireland/gifts",
      url: "/icons/countries-flags/ireland.svg",
    },
    {
      catalogID: "italy",
      name: "Italy",
      countryId: "ITA",
      categoryId: "italy/gifts",
      url: "/icons/countries-flags/italy.svg",
    },
    {
      catalogID: "japan",
      name: "Japan",
      countryId: "JPN",
      categoryId: "japan/gifts",
      url: "/icons/countries-flags/japan.svg",
    },
    {
      catalogID: "jordan",
      name: "Jordan",
      countryId: "JOR",
      categoryId: "jordan/gifts",
      url: "/icons/countries-flags/jordan.svg",
    },
    {
      catalogID: "kuwait",
      name: "Kuwait",
      countryId: "KWT",
      categoryId: "kuwait/gifts",
      url: "/icons/countries-flags/kuwait.svg",
    },
    {
      catalogID: "lebanon",
      name: "Lebanon",
      countryId: "LBN",
      categoryId: "lebanon/gifts",
      url: "/icons/countries-flags/lebanon.svg",
    },
    {
      catalogID: "malaysia",
      name: "Malaysia",
      countryId: "MYS",
      categoryId: "malaysia/gifts",
      url: "/icons/countries-flags/malaysia.svg",
    },
    {
      catalogID: "mauritius",
      name: "Mauritius",
      countryId: "MUS",
      categoryId: "mauritius/gifts",
      url: "/icons/countries-flags/mauritius.svg",
    },
    {
      catalogID: "morocco",
      name: "Morocco",
      countryId: "MAR",
      categoryId: "morocco/gifts",
      url: "/icons/countries-flags/morocco.svg",
    },
    {
      catalogID: "nepal",
      name: "Nepal",
      countryId: "NPL",
      categoryId: "nepal/gifts",
      url: "/icons/countries-flags/nepal.svg",
    },
    {
      catalogID: "netherlands",
      name: "Netherlands",
      countryId: "NLD",
      categoryId: "netherlands/gifts",
      url: "/icons/countries-flags/netherlands.svg",
    },
    {
      catalogID: "norway",
      name: "Norway",
      countryId: "NOR",
      categoryId: "norway/gifts",
      url: "/icons/countries-flags/norway.svg",
    },
    {
      catalogID: "oman",
      name: "Oman",
      countryId: "OMN",
      categoryId: "oman/gifts",
      url: "/icons/countries-flags/oman.svg",
    },
    {
      catalogID: "pakistan",
      name: "Pakistan",
      countryId: "PAK",
      categoryId: "pakistan/gifts",
      url: "/icons/countries-flags/pakistan.svg",
    },
    {
      catalogID: "poland",
      name: "Poland",
      countryId: "POL",
      categoryId: "poland/gifts",
      url: "/icons/countries-flags/poland.svg",
    },
    {
      catalogID: "portugal",
      name: "Portugal",
      countryId: "PRT",
      categoryId: "portugal/gifts",
      url: "/icons/countries-flags/portugal.svg",
    },
    {
      catalogID: "qatar",
      name: "Qatar",
      countryId: "QAT",
      categoryId: "qatar/gifts",
      url: "/icons/countries-flags/qatar.svg",
    },
    {
      catalogID: "romania",
      name: "Romania",
      countryId: "ROU",
      categoryId: "romania/gifts",
      url: "/icons/countries-flags/romania.svg",
    },
    {
      catalogID: "russia",
      name: "Russia",
      countryId: "RUS",
      categoryId: "russia/gifts",
      url: "/icons/countries-flags/russia.svg",
    },
    {
      catalogID: "saudi-arabia",
      name: "Saudi Arabia",
      countryId: "SAU",
      categoryId: "saudi-arabia/gifts",
      url: "/icons/countries-flags/saudi-arabia.svg",
    },
    {
      catalogID: "south-africa",
      name: "South Africa",
      countryId: "ZAF",
      categoryId: "south-africa/gifts",
      url: "/icons/countries-flags/south-africa.svg",
    },
    {
      catalogID: "spain",
      name: "Spain",
      countryId: "ESP",
      categoryId: "spain/gifts",
      url: "/icons/countries-flags/spain.svg",
    },
    {
      catalogID: "sri-lanka",
      name: "Sri Lanka",
      countryId: "LKA",
      categoryId: "sri-lanka/gifts",
      url: "/icons/countries-flags/sri-lanka.svg",
    },
    {
      catalogID: "sweden",
      name: "Sweden",
      countryId: "SWE",
      categoryId: "sweden/gifts",
      url: "/icons/countries-flags/sweden.svg",
    },
    {
      catalogID: "switzerland",
      name: "Switzerland",
      countryId: "CHE",
      categoryId: "switzerland/gifts",
      url: "/icons/countries-flags/switzerland.svg",
    },
    {
      catalogID: "taiwan",
      name: "Taiwan",
      countryId: "TWN",
      categoryId: "taiwan/gifts",
      url: "/icons/countries-flags/taiwan.svg",
    },
    {
      catalogID: "thailand",
      name: "Thailand",
      countryId: "THA",
      categoryId: "thailand/gifts",
      url: "/icons/countries-flags/thailand.svg",
    },
    {
      catalogID: "ukraine",
      name: "Ukraine",
      countryId: "UKR",
      categoryId: "ukraine/gifts",
      url: "/icons/countries-flags/ukraine.svg",
    },
  ],
};

export const savedAddress = [
  { name: "Majid", address: "Vaishali, Ghaziabad, 201101" },
  { name: "Sandeep", address: "Vaishali, Ghaziabad, 201101" },
  { name: "Adnan", address: "Vaishali, Ghaziabad, 201101" },
  { name: "Karan", address: "Vaishali, Ghaziabad, 201101" },
  { name: "Tushar", address: "Vaishali, Ghaziabad, 201101" },
];

export const quickLinkItems = [
  { label: "Bestsellers", href: "/cakes-bestsellers/birthday-lp?promo=plplink_cks_bdy_desk" },
  { label: "Same Day Delivery", href: "/cakes-same-day-delivery-lp?promo=plplink_cks_bdy_desk" },
  { label: "Flowers N Cakes", href: "/flowers-n-cakes/birthday-lp?promo=plplink_cks_bdy_desk" },
  { label: "Designer Cakes", href: "/designer-cakes/birthday-lp?promo=plplink_cks_bdy_desk" },
  { label: "Eggless Cakes", href: "/eggless-cakes/birthday-lp?promo=plplink_cks_bdy_desk" },
  { label: "Chocolate Cakes", href: "/chocolate-cakes/birthday-lp?promo=plplink_cks_bdy_desk" },
];

// export const viewCart = {
//   discount: "20% OFF",
//   items: [
//     {
//       deliveryLocation: "110001, New Delhi, Delhi",
//       productName: "Jade Plant In Gold Tone Metal Pot",
//       price: "Rs. 1000",
//       deliveryDate: "5th, May",
//       deliveryTimeSlot: "09:00 - 14:00 Hrs",
//       quantity: 1,
//     },
//   ],
// };

export const viewCart = {
  items: [
    {
      productId: "EXP76472",
      productTypeId: "AGGREGATED",
      orderItemSeqId: "00001",
      productCatalogId: "india",
      baseProductId: "EXP80376",
      isVariant: true,
      variantType: "Bouquet",
      productImage: "/images/pr/s/v20220706181748/enchanting-orchids-bouquet_1.jpg",
      productName: "Enchanting Orchids Flower Bouquet",
      isAddonsConfigured: true,
      productUrl: "/gift/enchanting-orchids-bouquet",
      shippingMethodName: "Express Delivery",
      dateForReport: "26 Jul 2024",
      deliveryDate: "Fri, Jul 26",
      isMultiOrderProduct: false,
      deliveryLocation: "110001, New Delhi, Delhi",
      timeSlotChangeMsg: null,
      showPincodeOrCity: true,
      isPersonalizationSaved: false,
      pincode: "110001",
      isIntlCatalog: false,
      primaryProductCategoryId: "EXPRESS",
      quantity: 1,
      supplierPartyId: "FRAN-000112",
      price: 799.0,
      addon: "N",
      isProductPersonalizable: false,
      shippingDetails: {
        shippingPrice: 19.0,
        deliveryTimeSlot: "17:00 - 23:00 Hrs",
        deliveryLocation: "110001, New Delhi, Delhi",
        isShippingPriceAvailable: true,
        deliveryTimeSlotId: "10025",
        shippingMethodName: "Express Delivery",
        shippingMethodId: "EARLIEST_DELIVERY",
        deliveryDate: {
          date: 26,
          month: "Jul",
          fullDeliveryDate: "26-07-2024",
          day: "Fri",
        },
        is_timedelivery_show: true,
      },
    },
  ],
  subTotal: "799",
  pricingSummary: {
    orderTotalMRP: 799.0,
    totalPromoDiscountAmount: 0,
    discountOnMRP: 0.0,
    orderSavedAmount: 0.0,
  },
  discount: "0",
  total: "799",
  totalShipping: "19.000000000000000",
  additionalPromoAdjustment: [
    {
      promoText: "Convenience Charge",
      additionalAmount: 39,
    },
  ],
  donationAmount: 0,
  carryBagEligibleFlag: false,
  carryBagAmount: 0,
  defaultCarryBagFlag: false,
};

export const shippingDetails = {
  "1_HOUR_DELIVERY": {
    longDescription: null,
    priceDetails: {
      price: 200.0,
    },
    shippingMethodName: "Instant Delivery",
    timeSlots: [
      {
        timeSlotId: "SUPER_DYNAMIC_SLOT",
        isDynamic: "Y",
        shippingPrice: 200.0,
        dynamicSlotText: "1 Hour Delivery",
        startTime: "09:00:00",
        leadTime: 1.0,
        endTime: "22:00:00",
      },
    ],
  },
  EARLIEST_DELIVERY: {
    longDescription: "Choose from any 5-hour slot during the day",
    priceDetails: {
      price: 19.0,
    },
    shippingMethodName: "Express Delivery",
    timeSlots: [
      {
        timeSlotId: "10002",
        isDynamic: "N",
        shippingPrice: 19.0,
        dynamicSlotText: null,
        startTime: "12:00:00",
        leadTime: null,
        endTime: "17:00:00",
      },
      {
        timeSlotId: "10061",
        isDynamic: "N",
        shippingPrice: 19.0,
        dynamicSlotText: null,
        startTime: "16:00:00",
        leadTime: null,
        endTime: "21:00:00",
      },
      {
        timeSlotId: "10025",
        isDynamic: "N",
        shippingPrice: 19.0,
        dynamicSlotText: null,
        startTime: "17:00:00",
        leadTime: null,
        endTime: "23:00:00",
      },
    ],
  },
  EXPRESS_DELIVERY: {
    longDescription: "Choose from any 3-hour slot during the day",
    priceDetails: {
      price: 49.0,
    },
    shippingMethodName: "Express Delivery ",
    timeSlots: [
      {
        timeSlotId: "10045",
        isDynamic: "N",
        shippingPrice: 49.0,
        dynamicSlotText: null,
        startTime: "15:00:00",
        leadTime: null,
        endTime: "18:00:00",
      },
      {
        timeSlotId: "10021",
        isDynamic: "N",
        shippingPrice: 49.0,
        dynamicSlotText: null,
        startTime: "18:00:00",
        leadTime: null,
        endTime: "21:00:00",
      },
      {
        timeSlotId: "10051",
        isDynamic: "N",
        shippingPrice: 49.0,
        dynamicSlotText: null,
        startTime: "19:00:00",
        leadTime: null,
        endTime: "23:00:00",
      },
    ],
  },
  FIXTIME_DELIVERY: {
    longDescription: "Choose from any 1-hour slot",
    priceDetails: {
      price: 99.0,
    },
    shippingMethodName: "Fixed Time Delivery ",
    timeSlots: [
      {
        timeSlotId: "10037",
        isDynamic: "N",
        shippingPrice: 99.0,
        dynamicSlotText: null,
        startTime: "14:00:00",
        leadTime: null,
        endTime: "15:00:00",
      },
      {
        timeSlotId: "10038",
        isDynamic: "N",
        shippingPrice: 99.0,
        dynamicSlotText: null,
        startTime: "15:00:00",
        leadTime: null,
        endTime: "16:00:00",
      },
      {
        timeSlotId: "10039",
        isDynamic: "N",
        shippingPrice: 99.0,
        dynamicSlotText: null,
        startTime: "16:00:00",
        leadTime: null,
        endTime: "17:00:00",
      },
      {
        timeSlotId: "10040",
        isDynamic: "N",
        shippingPrice: 99.0,
        dynamicSlotText: null,
        startTime: "17:00:00",
        leadTime: null,
        endTime: "18:00:00",
      },
      {
        timeSlotId: "10041",
        isDynamic: "N",
        shippingPrice: 99.0,
        dynamicSlotText: null,
        startTime: "18:00:00",
        leadTime: null,
        endTime: "19:00:00",
      },
      {
        timeSlotId: "10042",
        isDynamic: "N",
        shippingPrice: 99.0,
        dynamicSlotText: null,
        startTime: "19:00:00",
        leadTime: null,
        endTime: "20:00:00",
      },
      {
        timeSlotId: "10043",
        isDynamic: "N",
        shippingPrice: 99.0,
        dynamicSlotText: null,
        startTime: "20:00:00",
        leadTime: null,
        endTime: "21:00:00",
      },
      {
        timeSlotId: "10034",
        isDynamic: "N",
        shippingPrice: 99.0,
        dynamicSlotText: null,
        startTime: "21:00:00",
        leadTime: null,
        endTime: "22:00:00",
      },
    ],
  },
  MIDNIGHT_DELIVERY: {
    longDescription: "Gift will be delivered any time between 11:00 PM-11:59 PM",
    priceDetails: {
      price: 249.0,
    },
    shippingMethodName: "Pre-Midnight Delivery",
    timeSlots: [
      {
        timeSlotId: "10044",
        isDynamic: "N",
        shippingPrice: 249.0,
        dynamicSlotText: null,
        startTime: "23:00:00",
        leadTime: null,
        endTime: "23:59:59",
      },
    ],
  },
};

export const plpCapsules = [
  { textContent: "Roses", contentLink: "/roses/anniversary?promo=plplink_flws_anni_desk" },
  { textContent: "Lilies", contentLink: "/lilies/anniversary?promo=plplink_flws_anni_desk" },
  { textContent: "Premium Flowers", contentLink: "/premium-flowers?promo=plplink_flws_anni_desk" },
  {
    textContent: "Heart Shaped Arrangements",
    contentLink: "/heart-shaped-flower-arrangements?promo=plplink_flws_anni_desk",
  },
  { textContent: "Flowers n Cakes", contentLink: "/flowers-n-cakes/anniversary-lp?promo=plplink_flws_anni_desk" },
  {
    textContent: "Flowers n Chocolates",
    contentLink: "/flowers-n-chocolates/anniversary?promo=plplink_flws_anni_desk",
  },
];

export const plpBanners = [
  {
    imageUrl: "/assets/images/custom/new-desk-home/new_PLP/PLP-BANNER_WEB-12percent.jpg",
    landingUrl: "/roses/anniversary?promo=plplink_flws_anni_desk",
    altTag: "banner",
  },
];
