/**
 * Returns the API endpoint for user sign-up.
 *
 * @returns {string} The API endpoint for the sign-up process.
 */
export const USER_SIGN_UP_ENDPOINT = () => {
  return "/control/su-rj";
};

/**
 * Generates the API endpoint URL to fetch the countriesList from CMS.
 *
 * @returns {string} The API endpoint URL.
 */
export const FETCH_COUNTRY_LISTS = () => {
  return "/chocolate/v1/global/static/country/mobile/countrieslist";
};

/**
 * Generates the API endpoint URL to fetch the TNC JSON from CMS.
 *
 * @returns {string} The API endpoint URL.
 */
export const FETCH_TNC = () => {
  return "/chocolate/v1/global/static/web/terms-and-conditions";
};

/**
 * API endpoint URL to fetch the ghp response.
 */
export const INITIATE_GHP = () => {
  return "/productapi/api/rest/v1.3/ghp";
};

/**
 * API endpoint URL to fetch the INITIATE_CSRF_TOKENS response.
 */
export const INITIATE_CSRF_TOKENS = () => {
  return "/control/getCsrfTokens";
};
/**
 * API endpoint URL to fetch the FETCH_EXISTING_USER response.
 */
export const FETCH_EXISTING_USER = () => {
  return "/control/qcle-rj";
};
/**
 * API endpoint URL to fetch the SEND_OTP response.
 */
export const SEND_OTP = () => {
  return "/control/uotp-rj";
};
/**
 * API endpoint URL to fetch the VERIFY_OTP response.
 */
export const VERIFY_OTP = () => {
  return "/control/vc-rj";
};
/**
 * API endpoint URL to fetch the customer details response.
 */
export const GET_CUSTOMER_DETAILS = () => {
  return "/control/getCustomerDetails-rj";
};
