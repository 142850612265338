import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { plpPageConstants } from "../../../constants/pageConstants/plpConstants";
import { shallowEqual, useSelector } from "react-redux";
import Typography from "../../atoms/typography";
import ButtonComponent from "../../atoms/button";
import CustomImage from "../../atoms/customImage";
import { COUNTRIES } from "../../../constants/common/common";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";

/**
 * No Product Found
 *
 * @param {object} root .
 * @param {string} root.showText text to display when product not found
 * @param {string} root.subText sub text to display when product not found
 * @param {string} root.buttonText button text to display when product not found
 * @param {Function} root.onHandleSubmit function will be called on click of a button
 * @returns {React.element} parsing SVN Pages.
 */
const ProductNotFound = ({
  showText = plpPageConstants.NO_PRODUCT_MATCH_FOUND,
  subText = plpPageConstants.NO_PRODUCT_IN_THIS_CATEGORY_FOR_YOUR_LOCATION,
  buttonText = plpPageConstants.CHANGE_DELIVERY_PINCODE,
  onHandleSubmit,
}) => {
  const { trackEvent } = useAnalytics();

  const { pincode, isMobile, selectedIntLocation, catalogId } = useSelector(
    (state) => ({
      pincode: state?.locationLock?.selectedDomesticLocation?.pincode,
      isMobile: state?.appConfig?.isMobile,
      selectedIntLocation: state?.locationLock.selectedIntLocation,
      catalogId: state?.appConfig?.catalogId || COUNTRIES.INDIA,
    }),
    shallowEqual,
  );
  const isInternational = catalogId !== COUNTRIES.INDIA;
  const cityOrPincode = !isInternational ? pincode : selectedIntLocation?.location;

  useEffect(() => {
    const cleverTapData = {
      page_name: cleverTapCommonWords.PLP,
      pageType: cleverTapCommonWords.PLP,
      validation_text: showText,
      validation_for: cleverTapCommonWords.PLP,
    };

    trackEvent(cleverTapCommonEvents.validationViewed, cleverTapData, ["clevertap"]);
  }, []);

  return (
    <div className="flex h-full flex-col justify-center">
      <div className="flex flex-col">
        <CustomImage
          src="/icons/location-not-found.svg"
          width={194}
          height={167}
          alt="No Product Found"
          sizes="10vw"
          isPriority={true}
          className="mx-auto"
        />
        <div className="mt-48 flex flex-col gap-4">
          <Typography variant="p" Tag="p" classes="text-fnp-500 text-16 text-center font-500">
            {showText}
          </Typography>
          <Typography variant="p" Tag="p" classes="text-grey-500 text-center font-400 opacity-50">
            {subText}
          </Typography>
        </div>
      </div>
      <div className={`${isMobile ? "fixed" : "relative"} bottom-0 left-0 flex w-full flex-col`}>
        {isMobile && (
          <Typography variant="p" Tag="p" classes="text-fnp-500 text-12 font-400 text-center py-10 bg-blue-500">
            {plpPageConstants.SEE_ALL_AVAILABLE_PRODUCTS} <strong>{cityOrPincode}</strong>
          </Typography>
        )}
        <div className="px-16 py-12">
          <ButtonComponent
            size="medium"
            classes={`text-base px-16 ${!isMobile ? "w-max mx-auto px-32 mt-4" : ""}`}
            buttonVariant="filled"
            buttonTextSize="large"
            onClick={onHandleSubmit}
            label={buttonText}
          />
        </div>
      </div>
    </div>
  );
};

ProductNotFound.propTypes = {
  showText: PropTypes.string,
  subText: PropTypes.string,
  buttonText: PropTypes.string,
  onHandleSubmit: PropTypes.func.isRequired,
};

export default ProductNotFound;
