const dataLayerVarName = "adobeDataLayer";

/**
 * To create fnp country constants.
 * @param {*} brand
 * @param {*} country
 * @param {*} loginStatus
 * @param {*} catalogId
 * @param {*} countryId
 * @param {*} site
 * @returns
 */
const createFnpData = (brand, country, loginStatus, catalogId, countryId, site = "react") => ({
  brand,
  country,
  loginStatus,
  catalogId,
  countryId,
  site,
});

const FnpIND = createFnpData("fnp ind-m", "India", "guest", "india", "IN");
const FnpSG = createFnpData("fnp sg-m", "Singapore", "guest", "sgp", "SG");
const FnpUAE = createFnpData("fnp uae-m", "UAE", "guest", "uae", "UAE");
const FnpDesktopIND = createFnpData("fnp ind", "India", "guest", "india", "IN");
const FnpDesktopSG = createFnpData("fnp sg", "Singapore", "guest", "sgp", "SG");
const FnpDesktopUAE = createFnpData("fnp uae", "UAE", "guest", "uae", "UAE");

const channel = {
  PLP: "plp",
  HOME: "home",
  PDP: "pdp",
  SEARCH: "search result",
  CART: "cart",
  ERROR_404: "error404",
  INFO: "info",
  TESTIMONIALS: "testimonials",
  LOGIN: "login",
};
const eventCategories = {
  PRODUCT_VIEW: "product view",
  PRODUCT_LISTING: "product listing",
  SEARCH: "search",
  CART_VIEW: "cart view",
  CART_REMOVE: "cart remove",
  LOGIN_SIGNUP: "login signup",
};
const paths = {
  EVENT: "event",
  FNP_EVENT: "fnpevent",
  PAGE: "page",
  PRODUCT: "product",
  PRODUCT_LIST: "productString",
  CATEGORY: "category",
  CUSTOMER_DATA: "custdata",
  SEARCH: "search",
  CART: "cart",
  LINK: "link",
  GATE: "gate",
};

const pageCategories = {
  PRODUCT: "product",
  PRODUCT_LIST: "category",
  INFO: "info",
  HOME: "home",
  SEARCH: "search",
  CART: "cart",
  ERROR_404: "error404",
  TESTIMONIALS: "testimonials",
  LOGIN: "login",
  REGISTER: "register",
};
const loggedInStatus = {
  LOGGED_IN: "logged in",
  GUEST: "guest",
};

const gatePopupTracking = {
  AUTO_GATE_POPUP_VIEWED: "auto gate popup viewed",
  PINCODE_UPDATED: "pincode updated",
  PINCODE_RETAINED: "pincode retained",
  AUTO_CONTINUE_SHOPPING_CLICKED: "auto continue shopping clicked",
  MANUAL_CONTINUE_SHOPPING_CLICKED: "manual continue shopping clicked",
  AUTO_GREY_AREA_CLICKED: "auto grey area clicked",
  MANUAL_GREY_AREA_CLICKED: "manual grey area clicked",
  AUTO_GATE_CLOSE: "auto gate close",
  MANUAL_GATE_CLOSE: "manual gate close",
};

export {
  dataLayerVarName,
  FnpIND,
  FnpSG,
  FnpUAE,
  FnpDesktopIND,
  FnpDesktopSG,
  FnpDesktopUAE,
  channel,
  eventCategories,
  paths,
  pageCategories,
  loggedInStatus,
  gatePopupTracking,
};
