import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedDomesticLocation: {},
  selectedIntLocation: {},
  countryListData: [],
  cityListDataPDP: [],
  cityListData: [],
  visitedCountry: null,
  isShowMobileLocationLock: false,
  isShowDesktopLocationLock: false,
  isShowMobileLocationLockDrawer: false,
  isHideSavedAddresses: false,
  savedAddressUserName: null,
  onFocusChangeFromTextfield: false,
  selectedCountryFromList: "india",
  configData: {},
};

const LocationLockSlice = createSlice({
  name: "locationLockSlice",
  initialState,
  reducers: {
    getInternationalCityListPDP: () => {},

    setInternationalCityListDataPDP: (state, action) => {
      return {
        ...state,
        cityListDataPDP: action.payload,
      };
    },
    getInternationalCityList: (state) => {
      return {
        ...state,
      };
    },
    setInternationalCityListData: (state, action) => {
      return {
        ...state,
        cityListData: action.payload,
      };
    },

    setSelectedCountryAction: (state, action) => {
      return {
        ...state,
        visitedCountry: action.payload,
      };
    },

    setSelectedDomesticLocation: (state, action) => {
      return {
        ...state,
        selectedDomesticLocation: action.payload,
      };
    },

    setSelectedIntLocation: (state, action) => {
      return {
        ...state,
        selectedIntLocation: action.payload,
      };
    },
    setSelectedCountryFromList: (state, action) => {
      return {
        ...state,
        selectedCountryFromList: action.payload,
      };
    },
    setIsShowMobileLocationLockAction: (state, action) => {
      return {
        ...state,
        isShowMobileLocationLock: action.payload,
      };
    },
    setIsShowDesktopLocationLockAction: (state, action) => {
      return {
        ...state,
        isShowDesktopLocationLock: action.payload,
      };
    },
    setIsShowMobileLocationLockDrawerAction: (state, action) => {
      return {
        ...state,
        isShowMobileLocationLockDrawer: action.payload,
      };
    },
    setIsHideSavedAddresses: (state, action) => {
      return {
        ...state,
        isHideSavedAddresses: action.payload,
      };
    },
    setIsSavedAddressUserName: (state, action) => {
      return {
        ...state,
        savedAddressUserName: action.payload,
      };
    },

    getCountryListsData: () => {},

    setCountryListData: (state, action) => {
      return {
        ...state,
        countryListData: action.payload,
      };
    },
    onFocusRequestFromTextfield: (state, action) => {
      return {
        ...state,
        onFocusChangeFromTextfield: action.payload,
      };
    },
    getLocationLockConfigDataAction: (state) => {
      return {
        ...state,
      };
    },
    setLocationLockConfigDataAction: (state, action) => {
      return {
        ...state,
        configData: action.payload,
      };
    },
  },
});

export default LocationLockSlice.reducer;
export const {
  getInternationalCityListPDP,
  setInternationalCityListDataPDP,
  getInternationalCityList,
  setInternationalCityListData,
  setSelectedDomesticLocation,
  setSelectedCountryAction,
  setSelectedIntLocation,
  setIsShowMobileLocationLockAction,
  setIsShowDesktopLocationLockAction,
  setIsShowMobileLocationLockDrawerAction,
  setIsHideSavedAddresses,
  setIsSavedAddressUserName,
  getCountryListsData,
  setCountryListData,
  onFocusRequestFromTextfield,
  getLocationLockConfigDataAction,
  setLocationLockConfigDataAction,
  setSelectedCountryFromList,
} = LocationLockSlice.actions;
