"use client";
import React from "react";
import PropTypes from "prop-types";

/**
 * A component representing an individual tab.
 *
 * @component
 * @param {object} props - The props of the component.
 * @param {number} props.id - The unique identifier of the tab.
 * @param {number} props.tabIndex - The tab index for accessibility.
 * @param {boolean} props.isSelected - Indicates whether the tab is currently selected.
 * @param {function} props.onClick - The function to handle tab click events.
 * @param {string} props.classes - Additional CSS class names for the tab.
 * @param {string} props.bgStyleClass - Additional CSS class for the background div.
 * @returns {JSX.Element} - Returns the JSX element representing the Tab component.
 */
const Tab = ({ id, tabIndex, isSelected, onClick: onClick, children, classes = "", bgStyleClass }) => {
  return (
    <div
      role="tab"
      id={`tab_${id}`}
      className={`${classes ? classes : ""} cursor-pointer justify-center ${bgStyleClass}`}
      tabIndex={tabIndex}
      aria-selected={isSelected}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

Tab.propTypes = {
  id: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
  bgStyleClass: PropTypes.string,
};

export default React.memo(Tab);
