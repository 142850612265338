import { InterceptorClient } from "../../interceptor/interceptorClient";
import {
  GET_DELIVERY_KEY,
  GET_INTERNATIONAL_CITY_LIST_PDP,
  GET_INTERNATIONAL_CITY_LIST,
  GET_LOCATION_LOCK_CONFIG_DATA,
} from "./constant";
/**
 * This function gets the city list used in PDP only
 *
 * @param {string} countryId Id of country
 * @returns {object} object of city list
 */
export async function getCityListPDP(productId) {
  try {
    return await InterceptorClient.getFNTSInstance(process.env.NEXT_PUBLIC_BASE_URL).get(
      `${GET_INTERNATIONAL_CITY_LIST_PDP(productId)}`,
      false,
    );
  } catch (ex) {
    return "";
  }
}

/**
 * This function gets the city list used in homepage,PDP and PLP
 *
 * @param {string} countryId Id of country
 * @returns {object} object of city list
 */
export async function getCityList(productId) {
  try {
    return await InterceptorClient.getFNTSInstance(process.env.NEXT_PUBLIC_R2_CLIENT_API_GATEWAY).get(
      `${GET_INTERNATIONAL_CITY_LIST(productId)}`,
      false,
    );
  } catch (ex) {
    return "";
  }
}

/**
 * This function gets the delivery options as pet the pin code and date
 *
 * @param {string} pincode pin code entered
 * @param {string} deliveryDate selected delivery date
 * @returns {object}  object of delivery data
 */
export async function getDeliveryAreasAsKey(pincode, deliveryDate) {
  try {
    const params = { pincode };

    if (deliveryDate) params.deliveryDate = deliveryDate;
    return await InterceptorClient.getFNTSInstance(process.env.NEXT_PUBLIC_BASE_URL).get(
      GET_DELIVERY_KEY,
      false,
      params,
    );
  } catch (ex) {
    throw new Error(ex);
  }
}

/**
 * This function will return the config properties of location lock.
 */
export const getLocationLockConfigData = async () => {
  return await InterceptorClient.getGatewayInstance().get(`${GET_LOCATION_LOCK_CONFIG_DATA}`);
};
