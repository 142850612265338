import PropTypes from "prop-types";
import Typography from "../../../components/atoms/typography";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";
import CategoryImage from "../../../components/molecules/categoryImage";
import useAnalytics from "../../../analytics/useAnalytics";

/** */
const CategorySection = ({ isMobile, data, dimensions, headingClass }) => {
  const { trackEvent } = useAnalytics();
  const pageBannerData = {
    page_name: cleverTapCommonWords.HOME,
    page_type: cleverTapCommonWords.HOME,
  };
  /** */
  const onSetListItemWidth = (item) => {
    const listItemWidth = isMobile ? 100 / data?.mobileScroll : 100 / data?.desktopSlide;
    const responsive = item?.responsive;
    if (responsive) {
      const value = isMobile ? responsive.mobile : responsive.desktop;
      return value === "100%" ? value : `calc(${value} - ${isMobile ? "10px" : "28px"})`;
    }
    return `calc(${listItemWidth}% - ${isMobile ? "12px" : "28px"})`;
  };

  return (
    data?.list.length > 0 && (
      <div
        className={`relative w-full md:px-40 ${data?.backgroundColor ? "py-20 md:py-40" : ""}`}
        style={{ background: data?.backgroundColor }}
      >
        {data?.category_Name && (
          <Typography
            variant="h1"
            Tag="p"
            classes={`font-600 text-16 mb-12 md:mb-20 px-16 md:px-0 lg:text-24 xxl:text-32 ${headingClass} capitalize`}
          >
            {data?.category_Name}
          </Typography>
        )}
        <div className="relative w-full">
          <ul
            className={`scrollbar-hide flex px-16 md:px-0 ${data?.isMobileScroll ? "" : "flex-wrap"} w-full  items-center justify-between gap-12 overflow-x-auto overflow-y-hidden md:gap-32`}
          >
            {data.list.map((item, index) => {
              return (
                <li
                  key={index}
                  data-id={item.category_Name.split(" ").join("-").toLowerCase()}
                  style={{ width: onSetListItemWidth(item) }}
                  className={data?.isMobileScroll ? "flex-none" : ""}
                >
                  <a
                    href={item?.category_link}
                    // eslint-disable-next-line react/jsx-handler-names
                    onClick={() => {
                      trackEvent(
                        cleverTapCommonEvents.bannerClicked,
                        {
                          ...pageBannerData,
                          banner_id: index,
                          banner_name: item?.categoryName,
                        },
                        ["clevertap"],
                      );
                    }}
                  >
                    <CategoryImage
                      varient="imageWithText"
                      imageSrc={item.banner_image_url}
                      label={item.category_Name}
                      alt={item.altTag || item.category_Name}
                      width={item?.dimensions?.width || dimensions?.width}
                      height={item?.dimensions?.height || dimensions?.height}
                      sizes="12vw"
                      classes="rounded-2xl"
                    />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    )
  );
};
CategorySection.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  headingClass: PropTypes.string,
  dimensions: PropTypes.object.isRequired,
};

export default CategorySection;
