import PropType from "prop-types";
/**
 * A reusable React component for rendering a modal footer.
 * @param {params} props
 * @typedef {Object} params
 * @property {JSX.Element} children - The content to be displayed inside the modal footer. (Required)
 * @property {boolean} isShowHeaderBorder - Flag to show header border.
 * @property {boolean} footerClasses - classes for more formatting footer.
 * @returns {JSX.Element} The Modal footer.
 */
const ModalFooter = ({ children, isShowBorder, footerClasses }) => {
  return (
    <div
      role="modal-footer"
      className={`relative overflow-hidden text-ellipsis whitespace-nowrap p-16 pr-40 ${isShowBorder ? "border-t" : " "} ${footerClasses}`}
    >
      {children}
    </div>
  );
};
ModalFooter.propTypes = {
  children: PropType.node,
  isShowBorder: PropType.bool,
  footerClasses: PropType.string,
};
export default ModalFooter;
