/* eslint-disable react/jsx-handler-names */
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import SearchBar from "../header/searchBar";
import IconButtonComponent from "../../atoms/iconButton";
import Drawer from "../../molecules/drawer";
import { useCallback, useEffect, useState } from "react";
import { fetchSearchSuggestions } from "../../../api/common";
import { debounce, toggleElementVisibility } from "../../../utils/common";
import { getProductListingDetailsAPI } from "../../../api/plpApi";
import { plpPageConstants } from "../../../constants/pageConstants/plpConstants";
import {
  CURRENCY_ID,
  DEBOUNCE_TIME,
  MAX_COUNT_RECENT_SEARCH,
  RECENT_SEARCH_LIST,
} from "../../../constants/common/common";
import { getLocalStorageItem, setLocalStorageItem } from "../../../utils/storage/localStorageUtil";
import useAnalytics from "../../../analytics/useAnalytics";
import { cleverTapCommonEvents } from "../../../analytics/clevertapConstants";
import { GA4_EVENTS } from "../../../analytics/gaConstant";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setIsMobileSearchVisibleAction } from "../../../store/slices/commonSlice";

const Suggestions = dynamic(() => import("./searchComponents/suggestions"), { ssr: false });
const VoiceSearch = dynamic(() => import("./searchComponents/voiceSearch"), { ssr: false });
const RecentSearch = dynamic(() => import("./searchComponents/recentSearch"), { ssr: false });
const Loader = dynamic(() => import("../../atoms/loader"), { ssr: false });

/**
 * MobileSearch component handles search functionality for mobile devices,
 * including recent searches, search suggestions, and voice search.
 *
 * @param {Object} props - Component properties.
 * @param {Function} [props.setShowSearchPopUp=()=>{}] - Function to control the visibility of the search popup.
 *
 * @returns {JSX.Element} The rendered MobileSearch component.
 */
const MobileSearch = ({ setShowSearchPopUp = () => {}, catalogId, isSearchIconVisible = false }) => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const [isShowVoiceSearchDrawer, setIsShowVoiceSearchDrawer] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchSuggestion, setSearchSuggestion] = useState(null);
  const [recentSearch, setRecentSearch] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);

  const { isMobileSearchDrawerVisible } = useSelector(
    (state) => ({
      isMobileSearchDrawerVisible: state?.common?.isMobileSearchDrawerVisible,
    }),
    shallowEqual,
  );

  // Load recent searches from localStorage on mount
  useEffect(() => {
    const searchHistory = getLocalStorageItem(RECENT_SEARCH_LIST);
    const searchHistoryRecentSearchList = searchHistory && JSON.parse(searchHistory);
    searchHistory && setRecentSearch(searchHistoryRecentSearchList?.recentSearchList);
  }, []);

  // Debounced version of the search function to fetch suggestions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSuggestions = useCallback(
    debounce(async (value) => {
      if (value.trim().length === 0) return; // Skip API call if the input is empty
      const response = await fetchSearchSuggestions(catalogId, value, 1234);
      response?.suggestionResponse && setSearchSuggestion(response?.suggestionResponse);
    }, DEBOUNCE_TIME),
    [],
  );

  /**
   * Handles search input changes and triggers the suggestion fetch.
   *
   * @param {Event} event - The input change event.
   */
  const onSearch = async (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value.length > 0) {
      debouncedFetchSuggestions(value);
    } else {
      debouncedFetchSuggestions(value);
      setSearchSuggestion(null);
    }
  };

  /**
   * Clears the current search input and suggestions.
   */
  const onClearSearch = () => {
    setInputValue("");
    setSearchSuggestion(null);
  };

  /**
   * Adds a search keyword to the recent search list in localStorage.
   *
   * @param {string} keyword - The search keyword.
   * @param {string} url - The URL associated with the search keyword.
   */
  const addRecentSearchKeyword = (keyword, url) => {
    const searchHistory = getLocalStorageItem(RECENT_SEARCH_LIST);
    let recentSearchList = searchHistory ? JSON.parse(searchHistory) : { recentSearchList: [] };
    // Remove the existing object with the same keyword and URL, if it exists
    recentSearchList.recentSearchList = recentSearchList.recentSearchList.filter(
      (item) => item.keyword !== keyword || item.url !== url,
    );
    // Check if the list exceeds 8 items, remove the oldest one
    if (recentSearchList.recentSearchList.length >= MAX_COUNT_RECENT_SEARCH) {
      recentSearchList?.recentSearchList?.pop();
    }
    // Add the new search entry
    recentSearchList?.recentSearchList?.unshift({
      keyword: keyword,
      url: url,
      suggestionResponseType: "RS",
    });
    // Update localStorage
    setLocalStorageItem(RECENT_SEARCH_LIST, JSON.stringify(recentSearchList));
  };

  /**
   * Handles the form submission for searching and redirects to the product listing page.
   *
   * @param {Event} e - The form submit event.
   */
  const onSubmitForm = async (e, value) => {
    if (e) e.preventDefault();
    setShowLoader(true);
    const pageListingData = {
      page: plpPageConstants.INITIAL_PAGE,
      size: plpPageConstants.DESKTOP_PRODUCT_PER_PAGE,
      geoId: catalogId,
      lang: "en",
      currency: CURRENCY_ID,
      domainID: "fnp.com",
      isFacetEnabled: false,
      quickFilter: false,
      isFromApp: "yes",
      pageType: "search",
      qs: value,
    };
    const response = await getProductListingDetailsAPI(pageListingData);

    if (response?.redirectUrl) {
      const url = new URL(response.redirectUrl);
      const relativePath = `${url?.pathname}${url?.search}`;
      addRecentSearchKeyword(value, relativePath);
      window.location.href = relativePath;
      setShowLoader(false);
    } else {
      addRecentSearchKeyword(value, `/search?FNP_CURRENT_CATALOG_ID=${catalogId}&qs=${value}`);
      window.location.href = `/search?FNP_CURRENT_CATALOG_ID=${catalogId}&qs=${value}`;
      setShowLoader(false);
    }

    const clevertapSearchData = {
      search: value,
      is_popular_search: false,
      is_recent_search: false,
      is_manual_boost: false,
    };
    trackEvent(cleverTapCommonEvents.searched, clevertapSearchData, ["clevertap"]);
    trackEvent(GA4_EVENTS?.SEARCH, { search: value || null }, ["ga4"]);
  };

  /**
   * Closes the search popup and resets its state.
   */
  const onCloseSearch = () => {
    setShowSearchPopUp(false);
    dispatch(setIsMobileSearchVisibleAction(false));
  };

  /**
   * Toggles the visibility of the voice search drawer.
   */
  const onCloseVoiceSearch = () => {
    setInputValue("");
    toggleElementVisibility("sticky_bottom_menu", true);
    setIsShowVoiceSearchDrawer(!isShowVoiceSearchDrawer);
  };

  /**
   * Tracks a search event with Analytics.
   *
   * @param {string} value - The search item object.
   */
  const onSearchTrackEvent = (value, item) => {
    const clevertapSearchData = {
      search: item?.categoryName || item?.keyword,
      is_popular_search: item?.suggestionResponseType === "PR",
      is_recent_search: item?.suggestionResponseType === "RS",
      is_manual_boost: item?.suggestionResponseType === "MB",
    };
    trackEvent(cleverTapCommonEvents.searched, clevertapSearchData, ["clevertap"]);
    trackEvent(GA4_EVENTS?.SEARCH, { search: value || null }, ["ga4"]);
  };

  /**
   * handleMicClick handle mic click
   */
  const handleMicClick = (handleSearchPopup) => {
    setAutoFocus(false);
    setIsShowVoiceSearchDrawer(true);
    handleSearchPopup && dispatch(setIsMobileSearchVisibleAction(true));
    toggleElementVisibility("sticky_bottom_menu", false);
  };

  return (
    <>
      {!isSearchIconVisible && (
        <div className="mt-4 w-full">
          <SearchBar
            isShowStartAdornment={true}
            isShowEndAdornment={true}
            isReadonly={true}
            isMobile={true}
            onSearchBarClick={() => {
              dispatch(setIsMobileSearchVisibleAction(true));
              setAutoFocus(true);
            }}
            onMicClick={() => handleMicClick(true)}
          />
        </div>
      )}
      {isMobileSearchDrawerVisible && (
        <div className="fixed left-0 top-0 z-[99999] h-full w-full bg-fnp-20">
          {showLoader && <Loader />}
          <div className="flex h-44 w-full items-center justify-start bg-fnp-100 p-16">
            <span>
              <IconButtonComponent
                labelColor="w-full font-normal"
                classes="py-2"
                iconSrc="/icons/left-arrow.svg"
                imgHeight={24}
                imgWidth={24}
                onClick={onCloseSearch}
                isPriority={true}
              />
            </span>
          </div>
          <div className="p-16">
            <form onSubmit={(e) => onSubmitForm(e, inputValue)}>
              <SearchBar
                isShowStartAdornment={true}
                isShowEndAdornment={true}
                inputValue={inputValue}
                onClearSearch={onClearSearch}
                isAutoFocus={autoFocus}
                onChange={onSearch}
                isMobile={true}
                onMicClick={() => handleMicClick(false)}
              />
            </form>
          </div>
          {searchSuggestion && (
            <Suggestions
              searchSuggestion={searchSuggestion}
              inputValue={inputValue}
              onSearchTrackEvent={onSearchTrackEvent}
            />
          )}
          {(!searchSuggestion || searchSuggestion.length === 0) && recentSearch?.length > 0 && (
            <RecentSearch
              recentSearch={recentSearch}
              setRecentSearch={setRecentSearch}
              onSearchTrackEvent={onSearchTrackEvent}
            />
          )}
          <Drawer
            position="bottom"
            isOpen={isShowVoiceSearchDrawer}
            onDrawerClose={onCloseVoiceSearch}
            styles="max-h-[90vh]"
          >
            <VoiceSearch onSearchSubmit={onSubmitForm} setIsShowVoiceSearchDrawer={setIsShowVoiceSearchDrawer} />
          </Drawer>
        </div>
      )}
    </>
  );
};

MobileSearch.propTypes = {
  isShowSearchSec: PropTypes.bool,
  setShowSearchPopUp: PropTypes.func,
  catalogId: PropTypes.string,
  isSearchIconVisible: PropTypes.bool,
};

export default MobileSearch;
