/**
 *
 * @returns
 */
const useGA4 = () => {
  const dataLayerVarName = "dataLayer";
  /** */
  const init = () => {
    try {
      if (typeof window !== "undefined") {
        window[dataLayerVarName] = window[dataLayerVarName] || [];
      }
      console.log("GA Analytics Initialized");
    } catch (error) {
      console.log("GA not initialized properly");
    }
  };

  /**
   *
   * @param {*} eventName
   * @param {*} properties
   */
  const trackEvent = (eventName, properties = {}) => {
    try {
      window[dataLayerVarName].push({
        event: eventName,
        ...properties,
      });
    } catch (error) {
      console.log("GA not initialized properly");
    }
  };
  return { init, trackEvent };
};

export default useGA4;
