/* eslint-disable max-lines */
"use client";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import dynamic from "next/dynamic";
import CustomImage from "../../../atoms/customImage/index";
import {
  AUTO_TRIGGER_LOCK,
  DELIVERY_TO,
  FROM_LOCATION_LOCK,
  LOCATION_LOCK_HEADING,
  LOCATION_LOCK_ICON_TEXT,
  LOCATION_LOCK_SUB_HEADING,
  LOCATION_MANDATORY,
  LOCATION_MISSING,
  OUTSIDE_INDIA,
  WITHIN_INDIA,
} from "../../../../constants/common/locationFieldConstants";
import { getHash, pageName, stringWithCommaSplitter } from "../../../../utils/common";
import PopOver from "../../../atoms/popOver";
import Typography from "../../../atoms/typography";
import CookieUtil from "../../../../utils/storage/cookieUtil";
import {
  LAST_VISITED_ADDRESS,
  LAST_VISITED_PINCODE,
  SELECTED_SAVED_ADDRESS,
  SELECTED_SAVED_ADDRESS_USERNAME,
  SELECTED_INTERNATIONAL_CITY,
} from "../../../../constants/storage";
import {
  setIsHideSavedAddresses,
  setIsSavedAddressUserName,
  setIsShowDesktopLocationLockAction,
  setIsShowMobileLocationLockAction,
  setSelectedIntLocation,
  setSelectedCountryAction,
  setSelectedDomesticLocation,
  getCountryListsData,
  getLocationLockConfigDataAction,
  setIsShowMobileLocationLockDrawerAction,
  onFocusRequestFromTextfield,
} from "../../../../store/slices/locationLock";
import { useParams, usePathname } from "next/navigation";
import { requestToFetchProductListingAction } from "../../../../store/slices/plpSlice";
import { plpPageConstants } from "../../../../constants/pageConstants/plpConstants";
import { COUNTRIES } from "../../../../constants/common/common";
import { requestToSavedAddress } from "../../../../store/slices/savedAddresses";
import SavedAddress from "../../../molecules/locationLock/saveAddresses";
import isEmpty from "lodash.isempty";
import useAnalytics from "../../../../analytics/useAnalytics";
import { cleverTapCommonEvents } from "../../../../analytics/clevertapConstants";
import { lockBody, unlockBody } from "../../../../utils/document";
import MobileLocationLockDrawer from "../../../molecules/locationLock/mobileLocationLockDrawer";
import { GA4_EVENTS } from "../../../../analytics/gaConstant";
import Loader from "../../../atoms/loader";
import { setOnClickAdobeData } from "../../../../analytics/omniture/omniture-utility";
import { gatePopupTracking } from "../../../../analytics/omniture/omniture-consts";

const MobileLocationLock = dynamic(() => import("../../../molecules/locationLock/mobileLocationLock"), { ssr: false });
const LocationLock = dynamic(() => import("../../../molecules/locationLock"), {
  ssr: false,
  loading: () => (
    <div className="relative h-[188px] w-full">
      <Loader position="absolute" />
    </div>
  ),
});

/**
 * Location lock represents the location section in the header.
 * @returns {JSX.Element} The JSX representation of the Header component.
 */
const LocationLockHeader = ({ isMobile }) => {
  const triggerRef = useRef();
  const dispatch = useDispatch();
  const pathname = usePathname();
  const { trackEvent } = useAnalytics();
  const mandateDesktopRef = useRef();
  const [checkIfMandatory, setCheckIfMandatory] = useState(false);
  const params = useParams();

  const {
    isShowMobileLocationLock,
    isShowDesktopLocationLock,
    isShowMobileLocationLockDrawer,
    selectedDomesticLocation,
    details,
    appliedFilters,
    appliedSort,
    checkLoginInfo,
    isHideSavedAddresses,
    savedAddresses,
    saveAddressUserName,
    catalogId,
    selectedIntLocation,
    visitedCountry,
    countryListData,
    configData,
    product,
    selectedCountryFromList,
  } = useSelector(
    (state) => ({
      isShowMobileLocationLock: state?.locationLock?.isShowMobileLocationLock,
      isShowDesktopLocationLock: state?.locationLock?.isShowDesktopLocationLock,
      isShowMobileLocationLockDrawer: state?.locationLock?.isShowMobileLocationLockDrawer,
      selectedDomesticLocation: state?.locationLock.selectedDomesticLocation,
      details: state?.plp?.details || [],
      appliedFilters: state?.plp?.appliedFilters,
      appliedSort: state?.plp?.appliedSort,
      checkLoginInfo: state?.fus?.loginDetail,
      isHideSavedAddresses: state?.locationLock?.isHideSavedAddresses,
      savedAddresses: state?.savedAddress?.savedAddresses || [],
      saveAddressUserName: state?.locationLock.savedAddressUserName,
      catalogId: state.appConfig.catalogId,
      selectedIntLocation: state?.locationLock.selectedIntLocation,
      visitedCountry: state?.locationLock.visitedCountry,
      countryListData: state?.locationLock?.countryListData,
      configData: state?.locationLock?.configData,
      product: state?.pdp?.clientCurrentClickedItem,
      selectedCountryFromList: state?.locationLock?.selectedCountryFromList,
    }),
    shallowEqual,
  );
  const {
    isAutoGateEnabledOnDesktopHome,
    isAutoGateMandateOnDesktopHome,
    isAutoGateMandateOnDesktopPLP,
    isAutoGateEnabledOnDesktopPLP,
    isAutoGateEnabledOnMobileHome,
    isAutoGateEnabledOnMobilePLP,
    isAutoGateMandateOnMobileHome,
    isAutoGateMandateOnMobilePLP,
  } = configData;
  const postalAddress = CookieUtil.getCookie(SELECTED_SAVED_ADDRESS);
  const selectedUserName = CookieUtil.getCookie(SELECTED_SAVED_ADDRESS_USERNAME);
  const lockLocality = stringWithCommaSplitter(selectedDomesticLocation?.address, 1);
  const lastVisitedAddress = CookieUtil.getCookie(LAST_VISITED_ADDRESS);
  const lastVisitedPincode = CookieUtil.getCookie(LAST_VISITED_PINCODE);
  const selectedIntlCity = CookieUtil.getCookie(SELECTED_INTERNATIONAL_CITY);
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");
  const isAutoTriggerLock = CookieUtil.getCookie(AUTO_TRIGGER_LOCK);
  const isInternational = catalogId !== COUNTRIES.INDIA;
  const urlIdentifier = product?.urlIdentifier;
  const isMandateForDesktop =
    !isAutoTriggerLock &&
    catalogId === COUNTRIES.INDIA &&
    selectedCountryFromList === COUNTRIES.INDIA &&
    ((isAutoGateMandateOnDesktopHome && isHome) || (isAutoGateMandateOnDesktopPLP && isPLP));
  const isMandateForMobile =
    !isAutoTriggerLock &&
    catalogId === COUNTRIES.INDIA &&
    selectedCountryFromList === COUNTRIES.INDIA &&
    ((isAutoGateMandateOnMobileHome && isHome) || (isAutoGateMandateOnMobilePLP && isPLP));
  const isAutoForDesktop =
    !isAutoTriggerLock && ((isAutoGateEnabledOnDesktopHome && isHome) || (isAutoGateEnabledOnDesktopPLP && isPLP));
  const isAutoForMobile =
    !isAutoTriggerLock && ((isAutoGateEnabledOnMobileHome && isHome) || (isAutoGateEnabledOnMobilePLP && isPLP));

  mandateDesktopRef.current = isMandateForDesktop;

  const commonPageData = {
    page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
    is_location_available: !!(lastVisitedPincode || lastVisitedAddress),
  };
  const pageLocationPopupData = {
    ...commonPageData,
    mandatory: isMobile ? isMandateForMobile : isMandateForDesktop,
    auto: isMobile ? isAutoForMobile : isAutoForDesktop,
    pincode: !isInternational ? lastVisitedPincode || selectedDomesticLocation?.pincode : "",
    area: !isInternational ? lastVisitedAddress || selectedDomesticLocation?.address : "",
    system_pincode: !isInternational ? lastVisitedPincode || selectedDomesticLocation?.pincode : "",
    country: isInternational ? visitedCountry?.countryName : "",
    city: isInternational ? selectedIntLocation?.location : "",
  };

  useEffect(() => {
    if (getHash() === FROM_LOCATION_LOCK) {
      dispatch(setIsShowMobileLocationLockAction(true));
    }
  }, [params]);

  useEffect(() => {
    if (checkLoginInfo?.loggedin) {
      dispatch(requestToSavedAddress());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkLoginInfo?.loggedin]);

  useEffect(() => {
    const filterData = countryListData?.filter((item) => {
      return item?.catelogID === catalogId;
    })?.[0];

    dispatch(setSelectedCountryAction(filterData));
  }, [countryListData, catalogId]);

  useEffect(() => {
    if (isEmpty(countryListData)) {
      dispatch(getCountryListsData());
    }
  }, []);

  /**
   * This function will send the location lock data to adobeDataLayer.
   */
  const sendClickedAdobeData = (isPopViewed = false, isOutSideClick = false) => {
    const gateData = {
      link: {},
      category: {
        country: selectedCountryFromList === COUNTRIES.INDIA ? WITHIN_INDIA : OUTSIDE_INDIA,
        countryname: selectedCountryFromList || COUNTRIES.INDIA,
        city: selectedCountryFromList === COUNTRIES.INDIA ? "" : selectedIntLocation?.location || "",
        pincode: selectedDomesticLocation?.pincode || "",
      },
    };

    if (!isPopViewed) {
      if ((isAutoForDesktop && !isMobile) || (isAutoForMobile && isMobile)) {
        if (isOutSideClick) {
          gateData.link.linkName = gatePopupTracking.AUTO_GREY_AREA_CLICKED;
        } else {
          gateData.link.linkName = gatePopupTracking.AUTO_GATE_CLOSE;
        }
      } else {
        if (isOutSideClick) {
          gateData.link.linkName = gatePopupTracking.MANUAL_GREY_AREA_CLICKED;
        } else {
          gateData.link.linkName = gatePopupTracking.MANUAL_GATE_CLOSE;
        }
      }
    } else {
      gateData.link.linkName = DELIVERY_TO;
    }
    const clickData = {
      category: { ...gateData.category },
      link: { ...gateData.link },
    };
    const pageData = {
      channel: window?.fnpPageType,
      pageCategories: window?.fnpPageType,
    };
    setOnClickAdobeData(clickData, checkLoginInfo, pageData);
  };

  useEffect(() => {
    if (
      ((isEmpty(selectedDomesticLocation) && catalogId === COUNTRIES.INDIA) ||
        (isEmpty(selectedIntLocation?.pincode) && catalogId !== COUNTRIES.INDIA)) &&
      !isAutoTriggerLock
    ) {
      if (!Object.keys(configData)?.length) {
        dispatch(getLocationLockConfigDataAction());
      } else {
        if (
          (isAutoGateEnabledOnDesktopHome && isHome && !isMobile) ||
          (isAutoGateEnabledOnDesktopPLP && isPLP && !isMobile)
        ) {
          dispatch(setIsShowDesktopLocationLockAction(true));
        }
        if (
          (isAutoGateEnabledOnMobileHome && isHome && isMobile) ||
          (isAutoGateEnabledOnMobilePLP && isPLP && isMobile)
        ) {
          dispatch(setIsShowMobileLocationLockDrawerAction(true));
        }
        sendClickedAdobeData(true);
      }
    } else {
      setCheckIfMandatory(false);
    }
  }, [configData, selectedDomesticLocation, selectedIntLocation?.pincode]);

  useEffect(() => {
    const jsonSelectedIntlCity = JSON.parse(selectedIntlCity);

    if (!isEmpty(jsonSelectedIntlCity) && catalogId !== COUNTRIES.INDIA) {
      const selectedCountriesValues = Object.values(jsonSelectedIntlCity);

      const selectedCountry = selectedCountriesValues.filter((country) => {
        return country.catalogId === catalogId;
      })?.[0];

      dispatch(setSelectedIntLocation({ pincode: selectedCountry?.cityId, location: selectedCountry?.city }));
      dispatch(setSelectedCountryAction(selectedCountry));
    } else {
      if (lastVisitedAddress && lastVisitedPincode) {
        dispatch(
          setSelectedDomesticLocation({
            pincode: lastVisitedPincode,
            address: lastVisitedAddress,
          }),
        );
        trackEvent(GA4_EVENTS?.PINCODE_AVAILABLE, { pincode: lastVisitedPincode }, ["ga4"]);
      }
      if (selectedUserName) dispatch(setIsSavedAddressUserName(selectedUserName));
    }
  }, [selectedIntlCity, catalogId, lastVisitedAddress, lastVisitedPincode]);

  /**
   * Determines the delivery address text to display based on the selected location and catalog.
   *
   * @returns {string} - The formatted delivery location text based on the available data.
   */
  const getDeliverToText = () => {
    if (catalogId !== COUNTRIES.INDIA) {
      if (!isEmpty(visitedCountry?.countryName) && visitedCountry?.catelogID !== COUNTRIES.INDIA) {
        return visitedCountry?.countryName;
      }
      return LOCATION_MISSING;
    }

    if ((selectedDomesticLocation?.pincode || selectedDomesticLocation?.address) && catalogId === COUNTRIES.INDIA) {
      return `${selectedDomesticLocation?.address}, ${selectedDomesticLocation?.pincode}`;
    }

    return LOCATION_MISSING;
  };

  /**
   * Determines the locality text to display based on the selected location and catalog.
   *
   * @returns {string} - The locality text based on the selected location.
   */
  const getlockLocality = () => {
    if (catalogId !== COUNTRIES.INDIA) {
      if (!isEmpty(selectedIntLocation?.location)) {
        return selectedIntLocation?.location;
      }

      if (!isEmpty(visitedCountry?.countryName) && visitedCountry?.catelogID !== COUNTRIES.INDIA) {
        return visitedCountry?.countryName;
      }

      return LOCATION_LOCK_ICON_TEXT;
    }

    if (saveAddressUserName && catalogId === COUNTRIES.INDIA) {
      return saveAddressUserName;
    }

    if ((selectedDomesticLocation?.pincode || selectedDomesticLocation?.address) && catalogId === COUNTRIES.INDIA) {
      return lockLocality;
    }

    return LOCATION_LOCK_ICON_TEXT;
  };

  /**
   * function responsible to handle visibility
   * return bool
   */
  const onHandleVisibility = (isNotFromLocationLockPincodeSelection) => {
    if (isMobile) {
      dispatch(setIsShowMobileLocationLockAction(true));
      dispatch(setIsHideSavedAddresses(false));
      if (isInternational ? isEmpty(selectedIntLocation) : isEmpty(selectedDomesticLocation)) {
        dispatch(onFocusRequestFromTextfield(true));
      } else {
        dispatch(onFocusRequestFromTextfield(false));
      }
      sendClickedAdobeData(true);
      trackEvent(cleverTapCommonEvents.locationClicked, commonPageData, ["clevertap"]);
    } else {
      if (isShowDesktopLocationLock) {
        if (isNotFromLocationLockPincodeSelection && !selectedDomesticLocation?.pincode) {
          CookieUtil.deleteCookie(LAST_VISITED_ADDRESS);
          CookieUtil.deleteCookie(LAST_VISITED_PINCODE);
          // Updating listing on PLP page on pincode change
          if (pathname?.includes("-lp")) {
            dispatch(
              requestToFetchProductListingAction({
                page: details?.currentPage,
                size: isMobile ? plpPageConstants.MOBILE_PRODUCT_PER_PAGE : plpPageConstants.DESKTOP_PRODUCT_PER_PAGE,
                geoId: COUNTRIES?.INDIA,
                categoryUrl: `fnp.com${pathname}`,
                filters: encodeURIComponent(appliedFilters),
                sortOption: encodeURIComponent(appliedSort),
              }),
            );
          }
        }
        dispatch(setIsShowDesktopLocationLockAction(false));
      } else {
        trackEvent(cleverTapCommonEvents.locationClicked, commonPageData, ["clevertap"]);
        trackEvent(cleverTapCommonEvents.locationPopupViewed, pageLocationPopupData, ["clevertap"]);
        dispatch(setIsShowDesktopLocationLockAction(true));
        dispatch(setIsHideSavedAddresses(false));
        sendClickedAdobeData(true);
      }
    }
  };

  /**
   * Toogle Popover Handler.
   * @returns
   */
  const onTogglePopOverHandler = (isOutSideClick = false) => {
    sendClickedAdobeData(false, isOutSideClick);
    mandateDesktopRef?.current && isEmpty(selectedDomesticLocation)
      ? setCheckIfMandatory(true)
      : onHandleVisibility(true);
    trackEvent(
      cleverTapCommonEvents.locationPopupClosed,
      {
        ...pageLocationPopupData,
        pincode: null,
        area: null,
        system_pincode: null,
        country: null,
        city: null,
      },
      ["clevertap"],
    );
  };

  /**
   * To Continue Shopping by selecting location.
   */
  const onContinueShoppingClick = (isOutSideClick) => {
    if (!isMandateForMobile) {
      unlockBody();
      dispatch(setIsShowMobileLocationLockDrawerAction(false));
      sendClickedAdobeData(false, isOutSideClick);
    } else if (isEmpty(selectedDomesticLocation)) {
      lockBody();
      setCheckIfMandatory(isMandateForMobile);
    }
  };

  return (
    <div className="flex gap-5" tabIndex="0" ref={triggerRef}>
      <span className="relative flex min-w-[150px]  max-w-[200px] items-center justify-start gap-8">
        <div className="relative grid w-[150px] cursor-pointer" onClick={() => onHandleVisibility(true)}>
          <div className="flex w-full gap-8">
            {(!selectedDomesticLocation?.address && catalogId === COUNTRIES.INDIA) ||
            (!selectedIntLocation?.pincode && catalogId !== COUNTRIES.INDIA) ? (
              <CustomImage src="/icons/question-location.svg" alt="location-icon" width={24} height={24} />
            ) : isMobile ? (
              <CustomImage src="/icons/location-Icon.svg" alt="location-icon" width={24} height={24} />
            ) : null}
            <div>
              <span className="block max-w-[153px] overflow-hidden text-ellipsis whitespace-nowrap text-16 font-600 text-fnp-500 md:text-16 xxl:text-18">
                {getlockLocality()}
              </span>
              <div className="flex items-center gap-2">
                <span
                  className={`flex items-center justify-start gap-4 text-12 font-500 xxl:text-14 ${getDeliverToText() != LOCATION_MISSING ? "text-grey-400" : "text-red-500"} `}
                >
                  <span className="w-full max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
                    {getDeliverToText()}
                  </span>
                  <CustomImage
                    className="w-auto max-w-full"
                    src={
                      getDeliverToText() != LOCATION_MISSING
                        ? "/icons/filter-chevron.svg"
                        : "/icons/chevron-location.svg"
                    }
                    alt="down_red_arrow"
                    width={16}
                    height={16}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        {isMobile && isShowMobileLocationLockDrawer && (
          <MobileLocationLockDrawer
            isShowMobileLocationLockDrawer={isShowMobileLocationLockDrawer}
            onContinueShoppingClick={onContinueShoppingClick}
            isMandateForMobile={isMandateForMobile}
            isAutoForMobile={isAutoForMobile}
            checkIfMandatory={checkIfMandatory}
          />
        )}
        {isMobile ? (
          isShowMobileLocationLock && (
            <MobileLocationLock isMandatoryLocation={isMandateForMobile} isAutoLocation={isAutoForMobile} />
          )
        ) : (
          <PopOver
            isVisible={isShowDesktopLocationLock}
            isBackdrop={true}
            onTogglePopover={onTogglePopOverHandler}
            triggerRef={triggerRef}
            topOffset={65}
            classes="p-20"
            isShowCancelIcon={!(isMandateForDesktop && isEmpty(selectedDomesticLocation))}
            commonPageData={commonPageData}
          >
            <div
              className="relative flex h-full w-full flex-col flex-wrap bg-white-900 md:h-max md:w-[440px] md:rounded-2xl"
              id="locationLock"
              data-testid="locationLock"
            >
              <div className="absolute right-2 top-2 hidden md:block" />
              <div className="flex w-full">
                <div className="pr-8">
                  <CustomImage
                    src="/icons/location-Icon.svg"
                    alt=""
                    sizes="10vw"
                    width={24}
                    height={24}
                    isPriority={false}
                  />
                </div>
                <Typography variant="p" Tag="p" classes="font-600 text-16 text-fnp-500">
                  {LOCATION_LOCK_HEADING}
                </Typography>
              </div>
              <div className="w-full py-8">
                <Typography variant="p" Tag="p" classes="text-12 font-400 text-grey-500">
                  {LOCATION_LOCK_SUB_HEADING}
                </Typography>
              </div>
              {savedAddresses.length > 0 && !isHideSavedAddresses && catalogId === COUNTRIES.INDIA && (
                <SavedAddress postalAddress={postalAddress} savedAddress={savedAddresses} />
              )}
              <LocationLock
                onShowHideLocationLock={onHandleVisibility}
                isLocationError={checkIfMandatory}
                messageText={LOCATION_MANDATORY}
                setMandatoryError={(isDisable) => setCheckIfMandatory(isDisable)}
              />
            </div>
          </PopOver>
        )}
      </span>
    </div>
  );
};

LocationLockHeader.propTypes = {
  isMobile: PropTypes.bool,
};

export default LocationLockHeader;
