import { all, fork } from "redux-saga/effects";
import { watcherForCartSaga } from "./cartSaga";
import { watcherFetchCommonSaga } from "./commonSaga";
import watchLocationLockSaga from "./locationLock";
import {
  watcherFetchDeliveryDateSaga,
  watcherFetchProductListingSaga,
  watcherFiltersSaga,
  watcherGetPlpReviewsSaga,
} from "./plpSaga";
import { watcherFetchWebFeatureControls } from "./webFeatureControls";
import watchPdpSaga from "./pdpSaga";
import { watcherShippingDateAndSlotSelectionSaga } from "./commonSaga/ShippingDateAndSlotSelection";
import watcherLoginFlowSaga from "./loginSaga";
import { watcherAddOnSaga } from "./commonSaga/addOn";
import watchFnpCashSaga from "./fnpCashSaga";

/**
 * Root saga function that combines and forks multiple watcher sagas.
 *
 * @export
 * @generator
 * @function rootSaga
 * @yields {void}
 */
export default function* rootSaga() {
  yield all([
    fork(watcherForCartSaga),
    fork(watcherFetchDeliveryDateSaga),
    fork(watcherFetchProductListingSaga),
    fork(watcherGetPlpReviewsSaga),
    fork(watcherFetchWebFeatureControls),
    fork(watcherFetchCommonSaga),
    fork(watchLocationLockSaga),
    fork(watchPdpSaga),
    fork(watcherShippingDateAndSlotSelectionSaga),
    fork(watcherLoginFlowSaga),
    fork(watcherAddOnSaga),
    fork(watcherFiltersSaga),
    fork(watchFnpCashSaga),
  ]);
}
