import { createSlice } from "@reduxjs/toolkit";
import { current } from "@reduxjs/toolkit";

const initialState = {
  details: {},
  requestToDeliveryDate: false,
  responseFromDeliveryDate: false,
  failedToDeliveryDate: false,
  error: null,
};

export const DeliveryDateSlice = createSlice({
  name: "DeliveryDates",
  initialState,
  reducers: {
    requestToFetchDeliveryDateAction: (state) => {
      return {
        ...state,
        requestToDeliveryDate: true,
      };
    },
    responseFromFetchDeliveryDateAction: (state, action) => {
      return {
        ...state,
        responseFromDeliveryDate: true,
        details: {
          ...action.payload,
          deliveryDates: { ...(current(state?.details)?.deliveryDates || {}), ...action.payload.deliveryDates },
        },
      };
    },
    failedToFetchDeliveryDateAction: (state, action) => {
      return {
        ...state,
        failedToDeliveryDate: true,
        error: action.payload,
      };
    },
  },
});

export default DeliveryDateSlice.reducer;
export const {
  failedToFetchDeliveryDateAction,
  requestToFetchDeliveryDateAction,
  responseFromFetchDeliveryDateAction,
} = DeliveryDateSlice.actions;
