import Image from "next/image";
import PropTypes from "prop-types";
import Typography from "../typography";
/**
 *
 * @returns
 */
const ImageTile = ({
  imgSrc,
  imgAlt,
  imgHeight,
  imgWidth,
  title,
  imgClass = "",
  titleClass = "",
  titleContainerClass = "",
  children,
  imgContainerClass = "",
  isAdjustWidth = false,
  isPriority,
  ...restParams
}) => {
  const { style } = restParams;
  const loadingAttribute = isPriority ? "eager" : "lazy";
  return (
    <div className={`${isAdjustWidth ? "w-fit" : "w-full"} ${imgContainerClass}`} style={{ ...style }}>
      <Image
        loading={loadingAttribute}
        src={imgSrc}
        alt={imgAlt}
        height={imgHeight}
        width={imgWidth}
        sizes={"100vw"}
        quality={75}
        className={imgClass}
        {...restParams}
      />
      {title && (
        <div className={`text-center ${titleContainerClass}`}>
          <Typography Tag={"h6"} variant="h6" classes={titleClass}>
            {title}
          </Typography>
        </div>
      )}
      {children}
    </div>
  );
};
ImageTile.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  imgClass: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  titleContainerClass: PropTypes.string,
  titleClass: PropTypes.string,
  imgContainerClass: PropTypes.string,
  isAdjustWidth: PropTypes.bool,
  isPriority: PropTypes.bool,
};
export default ImageTile;
