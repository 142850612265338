/* eslint-disable max-lines */
/* eslint-disable react/jsx-handler-names */
import { usePathname } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import dynamic from "next/dynamic";
import {
  BOTTOM,
  CART,
  CONTINUE_GIFTING,
  CURRENCY_ID,
  PROCEED_TO_PAY,
  RIGHT,
  SAVING_AMOUNT,
  VIEW_PRICE_DETAILS,
} from "../../../constants/common/common";
import { requestSetCartItemValues, setIsCartVisible } from "../../../store/slices/cartSlice";
import {
  requestToFetchBlockedDatesAction,
  requestToFetchDeliveryDatesAction,
  requestToFetchInternationalShippingMethod,
  resetGetDeliveryDateAndGeoPriceAction,
  setDeliveryDetailsAction,
} from "../../../store/slices/common/shippingDateAndSlotSelection";
import { closeBottomDrawerFromParent, updateCurrency } from "../../../utils/common";
import { requestToOpenLoginDrawer } from "../../../store/slices/loginSignupSlice";
import ButtonComponent from "../../atoms/button";
import ImageTile from "../../atoms/imageTile";
import Drawer from "../../molecules/drawer";
import { unlockBody } from "../../../utils/document";
import { requestSurgePriceDetails, setBuyNowOrProceedToPayClick } from "../../../store/slices/pdpSlice";
import { ROUTE_CONSTANTS } from "../../../constants/route";
import { checkIsInternationalProduct } from "../../../utils/pdp/nonDeliverable";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";
import { CAKE_TYPE } from "../../../constants/pageConstants/pdpConstants";
import { generateDeliveryPopupData } from "../../../utils/clevertapCommon";
import useCartPreviewOmnitureSetup from "../../../customHooks/omniture/useCartPreviewOmnitureSetup";
import { PAGE_TYPE_VAR_NAME, PageTypes, setPageType } from "../../../utils/fnpPageType";

const CartBody = dynamic(() => import("../../molecules/cartBody"), { ssr: false });

const DomesticShippingDateAndSlotSelection = dynamic(
  () => import("../ShippingDateAndSlotSelection/DomesticShippingDateAndSlotSelection"),
  { ssr: false },
);
const InternationalShippingDateAndSlotSelection = dynamic(
  () => import("../ShippingDateAndSlotSelection/InternationalShippingDateAndSlotSelection"),
  { ssr: false },
);

/**
 * Cart component to display the cart items and handle cart-related actions.
 * It also manages the delivery details drawer for specific cart items.
 *
 * @component
 * @returns {JSX.Element} The Cart component.
 */
const Cart = ({ isMobile }) => {
  const dispatch = useDispatch();
  const priceDetailsRef = useRef(null);

  const pathname = usePathname();
  const { trackEvent } = useAnalytics();
  const [currentItem, setCurrentItem] = useState({});
  const [isInternational, setIsInternational] = useState(false);
  const [previousPageType, setPreviousPageType] = useState("");
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [showDeliveryDetailsDrawer, setShowDeliveryDetailsDrawer] = useState(false);
  const cart = useSelector((state) => state?.cart);
  const { usableBalance, fnpCashCheckboxState } = useSelector((state) => state?.fnpCash);
  const selectedCurrency = useSelector((state) => state?.currencyList?.userSelectedCurrency);
  const checkLoginStatus = useSelector((state) => state.fus.loginDetail?.loggedin);
  const loggedIn = useSelector((state) => state?.fus?.loginDetail?.loggedin);
  const clientVariantType = useSelector((state) => state?.pdp?.clientVariantType);
  const nextAvailableDifference = useSelector((state) => state?.shippingDateAndSlotSelection?.nextAvailableDifference);
  const completePathName = typeof window !== "undefined" && window.location.href ? window.location.href : "";

  const { isCartVisible } = cart;
  const drawerPosition = isMobile ? BOTTOM : RIGHT;

  const { totalShipping, additionalPromoAdjustment } = cart?.cartDetails?.paymentInfo || {};
  const finalAmount = fnpCashCheckboxState
    ? Number(cart?.cartDetails?.paymentInfo?.total) - Number(usableBalance)
    : Number(cart?.cartDetails?.paymentInfo?.total);

  const cartCommonData = { page_name: cleverTapCommonWords.CART, page_type: cleverTapCommonWords.CART };

  const cleverTapCartCommonData = {
    ...cartCommonData,
    complete_url: completePathName,
    clean_url: pathname,
    cart_value: finalAmount || 0,
    total_delivery_fee: Number(totalShipping) || 0,
    convenience_fee: additionalPromoAdjustment?.length ? additionalPromoAdjustment?.additionalAmount : 0,
    login_status: loggedIn ?? false,
    is_cart_empty: cart?.cartDetails?.items?.length === 0,
  };

  useCartPreviewOmnitureSetup();

  useEffect(() => {
    if (isCartVisible) {
      setPreviousPageType(window[PAGE_TYPE_VAR_NAME]);
      setPageType(PageTypes.CART_PREVIEW);
    }
  }, [isCartVisible]);

  /**
   * Toggles the visibility of the delivery details drawer and sets cart item values.
   *
   * @function
   * @param {Object} shippingMethodAndTimeSlot - The selected shipping method and time slot information.
   * @param {string} selectedDate - The selected delivery date.
   * @returns {void}
   */
  const onToggleDeliveryDetailsPopUp = (shippingMethodAndTimeSlot, selectedDate) => {
    const cartItemValues = {
      shippingMethodId: shippingMethodAndTimeSlot?.currentShippingMethod?.shippingMethodId,
      deliveryDate: selectedDate,
      timeSlotId: shippingMethodAndTimeSlot?.timeSlots?.timeSlots?.timeSlotId,
      itemIndex: currentItemIndex,
      shippingPrice: shippingMethodAndTimeSlot?.timeSlots?.timeSlots?.shippingPrice,
    };
    dispatch(requestSetCartItemValues(cartItemValues));
    setShowDeliveryDetailsDrawer((e) => !e);
  };

  /**
   * Handles the event when a cart item delivery button is clicked.
   * Opens the delivery date selection drawer for the specified item.
   *
   * @function
   * @param {Object} item - The cart item for which the delivery details need to be shown.
   * @param {number} index - The index of the cart item in the list.
   * @returns {void}
   */
  const onCartItemDeliveryClick = (item, index) => {
    const internationalProduct = checkIsInternationalProduct(item?.primaryProductCategoryId);
    setIsInternational(internationalProduct);
    if (internationalProduct) {
      dispatch(
        requestToFetchInternationalShippingMethod({
          productId: item?.productId,
          city: item?.pincode,
        }),
      );
      dispatch(requestSurgePriceDetails({ productId: item?.productId }));
    } else {
      const queryParams = {
        productId: item?.productId,
        pinCode: item?.pincode,
        currencyUomId: CURRENCY_ID,
        catalogId: item?.productCatalogId,
        countryGeoId: "IND",
      };
      dispatch(requestToFetchDeliveryDatesAction(queryParams));
      dispatch(
        requestToFetchBlockedDatesAction({
          productId: item?.productId,
          pinCode: item?.pincode,
        }),
      );
    }
    setCurrentItem(item);
    setCurrentItemIndex(index);
    setShowDeliveryDetailsDrawer((e) => !e);
    trackEvent(
      cleverTapCommonEvents.deliveryPopupViewed,
      {
        ...cartCommonData,
        ...generateDeliveryPopupData(item, clientVariantType, CAKE_TYPE, nextAvailableDifference),
      },
      ["clevertap"],
    );
  };

  /**
   * Handles the proceed to payment action.
   * Redirects to the checkout page if the user is logged in; otherwise, opens the login prompt.
   *
   * @function
   * @returns {void}
   */
  const onProceedToPay = () => {
    dispatch(setBuyNowOrProceedToPayClick(PROCEED_TO_PAY));
    if (checkLoginStatus) {
      window.location.href = `/${ROUTE_CONSTANTS.CHECKOUT}/${ROUTE_CONSTANTS.DELIVERY_ADDRESS}`;
    } else {
      const from = encodeURIComponent(`/${ROUTE_CONSTANTS.CHECKOUT}/${ROUTE_CONSTANTS.DELIVERY_ADDRESS}`);
      if (isMobile) {
        window.location.href = `/login?from=${from}`;
      } else {
        dispatch(requestToOpenLoginDrawer(true));
      }
    }
    dispatch(setIsCartVisible(false));
    trackEvent(
      cleverTapCommonEvents.proceedToCheckoutClicked,
      {
        ...cleverTapCartCommonData,
        fnp_cash_discount: fnpCashCheckboxState ? Number(usableBalance) : 0,
        is_cart_empty: undefined,
      },
      ["clevertap"],
    );
  };

  /**
   * Handles the event to close the cart drawer.
   * Clears delivery details and hides the cart.
   *
   * @function
   * @returns {void}
   */
  const onCartClose = () => {
    closeBottomDrawerFromParent();
    dispatch(setDeliveryDetailsAction({}));
    dispatch(resetGetDeliveryDateAndGeoPriceAction());
    dispatch(setIsCartVisible(false));
    setPageType(previousPageType);
    setShowDeliveryDetailsDrawer(false);
    unlockBody();
  };

  /**
   * Handles scrolling to the Price Details section of the component.
   *
   * @function
   */
  const onScrollToPriceDetails = () => {
    // Scroll to Price Details section
    priceDetailsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  /**
   * Handles the close cart action by hiding the cart, navigating to the home page, and unlocking the body scroll.
   */
  const handleContinueGifting = () => {
    unlockBody();
    window.location.href = "/";
    setPageType(previousPageType);
    dispatch(setIsCartVisible(false));
  };

  return (
    <>
      <Drawer
        position={drawerPosition}
        isOpen={isCartVisible}
        onDrawerClose={onCartClose}
        isDrawerCustomStyles={isMobile ? false : true}
        styles={isMobile ? "p-0 h-full" : "min-w-[360px] max-w-[400px] p-0 h-full"}
      >
        {showDeliveryDetailsDrawer ? (
          isInternational ? (
            <InternationalShippingDateAndSlotSelection
              classes="md:h-[100%] h-100%"
              currentItem={currentItem}
              onToggleDeliveryDetailsPopUp={onToggleDeliveryDetailsPopUp}
              isCart={true}
              cartCleverTapData={cleverTapCartCommonData}
            />
          ) : (
            <div className="h-[80%]">
              <DomesticShippingDateAndSlotSelection
                classes="md:h-[100%] h-100%"
                currentItem={currentItem}
                onToggleDeliveryDetailsPopUp={onToggleDeliveryDetailsPopUp}
                isCart={true}
                cartCleverTapData={cleverTapCartCommonData}
              />
            </div>
          )
        ) : (
          <>
            {isMobile && (
              <div className="flex items-center justify-start gap-12 border-b-1 border-lightGrey py-16 pl-5">
                <div onClick={onCartClose} className="cursor-pointer">
                  <ImageTile imgSrc={"/icons/left-arrow.svg"} imgHeight={20} imgWidth={20} imgAlt={"left-side-arrow"} />
                </div>
                <span className="-top-2 text-base font-semibold" data-testid="cart">
                  {CART}
                </span>
              </div>
            )}
            {!isMobile && (
              <div className="flex items-center justify-start gap-12 border-b-2 py-14 pl-5">
                <span className="text-base font-semibold" data-testid="cart">
                  {CART}
                </span>
              </div>
            )}
            <CartBody
              cartData={cart}
              onCartItemDeliveryClick={onCartItemDeliveryClick}
              selectedCurrency={selectedCurrency}
              priceDetailsRef={priceDetailsRef}
              isMobile={isMobile}
              isLoggedIn={checkLoginStatus}
              finalAmount={finalAmount}
              cleverTapCartCommonData={cleverTapCartCommonData}
              isLoading={cart?.isCartLoading}
            />
          </>
        )}

        {!showDeliveryDetailsDrawer && (
          <div className="fixed bottom-0 flex  w-full flex-col justify-center bg-white-900 shadow-top">
            {cart?.cartDetails?.paymentInfo?.orderSavedAmount > 0 && (
              <div className="mb-6 flex h-40 w-full items-center justify-center border-b-1 bg-fnp-1500  text-14 font-normal text-fnp-800">
                {SAVING_AMOUNT(cart?.cartDetails?.paymentInfo?.orderSavedAmount)}
              </div>
            )}
            {cart.cartDetails?.items?.length ? (
              <div className="mx-0 flex w-[100%] items-center justify-between border-t-1 border-gray-100 px-5 py-12">
                <div className="flex flex-col">
                  <span className="text-base font-bold text-black-100">
                    {updateCurrency(selectedCurrency, finalAmount)}
                  </span>
                  <span className="flex flex-wrap items-center gap-2">
                    <span
                      className="cursor-pointer text-xs font-medium text-green-100"
                      onClick={onScrollToPriceDetails}
                    >
                      {VIEW_PRICE_DETAILS}
                    </span>
                    <ImageTile
                      imgSrc={"/icons/green-chevron.svg"}
                      imgHeight={12}
                      imgWidth={12}
                      imgAlt={"green-right-arrow"}
                      isAdjustWidth={true}
                    />
                  </span>
                </div>
                <div className="w-[60%] xl:w-[60%]">
                  <ButtonComponent
                    classes="text-base ml-auto"
                    label={PROCEED_TO_PAY}
                    onClick={onProceedToPay}
                    isRemoveExtraPadding={true}
                    buttonTextSize="large"
                  />
                </div>
              </div>
            ) : !cart?.isCartLoading || !cart?.cartCount ? (
              <div className="w-full border-t-1 border-gray-100 px-5 py-12">
                <ButtonComponent
                  classes="text-base flex flex-row-reverse"
                  icon="/icons/cart-white.svg"
                  label={CONTINUE_GIFTING}
                  buttonTextSize="large"
                  onClick={handleContinueGifting}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </Drawer>
    </>
  );
};

export default Cart;

Cart.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};
