import Link from "next/link";
import CustomImage from "../../atoms/customImage";
import PropTypes from "prop-types";

/**
 * PLPBannerTile component renders a banner tile with an image.
 * If a redirection link is provided, the image is wrapped with a Link component.
 *
 * @component
 * @example
 * const tileData = {
 *   redirectionLink: "/some-page",
 *   trackingParam: "source=plp",
 *   productImageTile: "/path/to/image.jpg",
 *   altTag: "Product Image"
 * }
 * return <PLPBannerTile tileData={tileData} />
 *
 * @param {Object} props
 * @param {Object} props.tileData - The data for the tile.
 * @param {string} props.tileData.redirectionLink - The link to redirect when the tile is clicked.
 * @param {string} props.tileData.trackingParam - Additional tracking parameters to append to the link.
 * @param {string} props.tileData.productImageTile - The URL of the image to display.
 * @param {string} props.tileData.altTag - The alt text for the image.
 *
 * @returns {JSX.Element} The rendered banner tile component.
 */
const PLPBannerTile = ({ tileData, isPriority }) => {
  return tileData?.redirectionLink ? (
    <Link
      href={`${tileData?.redirectionLink}?${tileData?.trackingParam}`}
      className="absolute h-full w-full overflow-hidden"
    >
      <CustomImage
        src={tileData?.productImageTile}
        alt={tileData?.altTag}
        width={100}
        height={100}
        isPriority={isPriority}
        borderRadius={8}
        object-fit="cover"
        customStyle={{
          width: "100%",
          height: "100%",
        }}
        className="object-cover object-top"
      />
    </Link>
  ) : (
    <CustomImage
      src={tileData?.productImageTile}
      alt={tileData?.altTag}
      width={100}
      height={100}
      isPriority={isPriority}
      borderRadius={8}
      object-fit="cover"
      customStyle={{
        width: "100%",
        height: "100%",
      }}
      sizes="100vw"
    />
  );
};

PLPBannerTile.propTypes = {
  tileData: PropTypes.object.isRequired,
  isPriority: PropTypes.bool.isRequired,
};
export default PLPBannerTile;
