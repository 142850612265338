import { call, put, takeLatest } from "redux-saga/effects";
import { getProductList } from "../../../api/homeApi";
import {
  failedToFetchRecentlyViewedAction,
  requestToFetchRecentlyViewedAction,
  responseFromFetchRecentlyViewedAction,
} from "../../slices/commonSlice";
import { getSavedAddresses, initiateFusApiCall } from "../../../api/common";
import { initiateFus, setFusData } from "../../slices/common/fus";
import { responseGetCartItemsCount } from "../../slices/cartSlice";

import {
  failedToGetCurrency,
  requestToGetCurrency,
  requestToSaveCurrency,
  requestToSaveSelectedCurrency,
} from "../../slices/common/currencyLists";
import { getCurrencyList } from "../../../api/common";
import CookieUtil from "../../../utils/storage/cookieUtil";
import { CURRENCY_ID, LOCAL_CURRENCY } from "../../../constants/common/common";
import { requestToSavedAddress, savedAddressError, setSavedAddress } from "../../slices/savedAddresses";
/**
 *
 * @param {*} action
 */
function* fetchProductListSaga() {
  try {
    const response = yield getProductList();
    if (response?.data) {
      if (response?.data?.productResults?.length > 0) {
        yield put(responseFromFetchRecentlyViewedAction(response?.data?.productResults));
      } else {
        yield put(responseFromFetchRecentlyViewedAction([]));
      }
    } else {
      yield put(failedToFetchRecentlyViewedAction({ message: "Earliest delivery dates not found", code: 404 }));
    }
  } catch (error) {
    yield put(failedToFetchRecentlyViewedAction(error));
  }
}
/**
 * Generator function to call fus api call which gets the login details, cart quantity and pincode details of customer .
 *
 */
export function* initiateFusCall() {
  try {
    const response = yield call(initiateFusApiCall);
    yield put(setFusData(response));
    yield put(responseGetCartItemsCount(response?.cart?.cartTotalQuantity));
  } catch (error) {
    // yield put({ type: SET_ERROR, payload: error });
  }
}

/** */
function* fetchCurrencyListSaga() {
  try {
    const response = yield getCurrencyList();
    if (response?.currencies) {
      yield put(requestToSaveCurrency(response.currencies));
      const localCurrencyId = CookieUtil.getCookie(LOCAL_CURRENCY);
      if (localCurrencyId) {
        const userSelectedCurrency = response?.currencies?.find((item) => item.currency.id === localCurrencyId);
        yield put(requestToSaveSelectedCurrency(userSelectedCurrency));
      } else {
        CookieUtil.setCookie(LOCAL_CURRENCY, CURRENCY_ID, { path: "/", encode: decodeURIComponent });
        const userSelectedCurrency = response?.currencies?.find((item) => item.currency.id === CURRENCY_ID);
        yield put(requestToSaveSelectedCurrency(userSelectedCurrency));
      }
    }
  } catch (error) {
    yield put(failedToGetCurrency(error));
  }
}

/** */
function* fetchSavedAddresses() {
  try {
    const response = yield getSavedAddresses();
    yield put(setSavedAddress(response?.data?.addressList));
  } catch (error) {
    yield put(savedAddressError(error));
  }
}

/**
 * Description placeholder
 *
 * @export
 * @returns {{}}
 */
export function* watcherFetchCommonSaga() {
  yield takeLatest(requestToFetchRecentlyViewedAction.type, fetchProductListSaga);
  yield takeLatest(initiateFus.type, initiateFusCall);
  yield takeLatest(requestToGetCurrency.type, fetchCurrencyListSaga);
  yield takeLatest(requestToSavedAddress.type, fetchSavedAddresses);
}
