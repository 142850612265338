import PropTypes from "prop-types";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";
import CustomImage from "../../../components/atoms/customImage";
import Typography from "../../../components/atoms/typography";
import CategoryImage from "../../../components/molecules/categoryImage";

/** */
const OccasionSection = ({ isMobile, data, headingClass }) => {
  const { trackEvent } = useAnalytics();
  const bigSection = data?.list[0];
  const restList = data?.list.slice(1, data?.list.length);
  const half = Math.ceil(restList.length / 2);
  const firstHalf = restList.slice(0, half);
  const secondHalf = restList.slice(half);

  const listItemWidth = isMobile ? 100 / data?.mobileScroll : 100 / data?.desktopSlide;

  const pageBannerData = {
    page_name: cleverTapCommonWords.HOME,
    page_type: cleverTapCommonWords.HOME,
  };

  return (
    <div className={`relative w-full md:px-40`}>
      {data?.category_Name && (
        <Typography
          variant="h1"
          Tag="p"
          classes={`font-600 text-16 mb-16 md:mb-20 px-16 md:px-0 lg:text-24 xxl:text-32 ${headingClass} capitalize`}
        >
          {data?.category_Name}
        </Typography>
      )}
      <div className="relative flex w-full flex-wrap items-center justify-between gap-16 md:flex-nowrap">
        <div className="w-full px-16 md:w-[35%] md:px-0">
          <a href={bigSection?.category_link}>
            <CustomImage
              width={bigSection?.dimensions?.width}
              height={bigSection?.dimensions?.height}
              alt={bigSection?.altTag}
              src={bigSection?.banner_image_url}
            />
          </a>
        </div>
        <div className="w-full md:w-[60%]">
          <ul
            className={`scrollbar-hide flex w-full items-center justify-between  gap-16 overflow-x-auto overflow-y-hidden px-16 md:gap-32 md:px-0`}
          >
            {firstHalf.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`flex flex-col gap-16 md:gap-32 ${isMobile ? "flex-none" : ""}`}
                  style={{ width: `calc(${listItemWidth}% - ${isMobile ? "16px" : "28px"})` }}
                >
                  <a
                    href={item.category_link}
                    // eslint-disable-next-line react/jsx-handler-names
                    onClick={() => {
                      trackEvent(
                        cleverTapCommonEvents.bannerClicked,
                        {
                          ...pageBannerData,
                          banner_id: index,
                          banner_name: item?.category_Name,
                        },
                        ["clevertap"],
                      );
                    }}
                  >
                    <CategoryImage
                      varient="imageWithText"
                      imageSrc={item.banner_image_url}
                      label={item.category_Name}
                      alt={item.altTag}
                      width={data?.dimensions?.width}
                      height={data?.dimensions?.height}
                      sizes="12vw"
                      classes="rounded-2xl"
                    />
                  </a>

                  {secondHalf[index] && (
                    <a
                      href={secondHalf[index].category_link}
                      // eslint-disable-next-line react/jsx-handler-names
                      onClick={() => {
                        trackEvent(
                          cleverTapCommonEvents.bannerClicked,
                          {
                            ...pageBannerData,
                            banner_id: index,
                            banner_name: item?.category_Name,
                          },
                          ["clevertap"],
                        );
                      }}
                    >
                      <CategoryImage
                        varient="imageWithText"
                        imageSrc={secondHalf[index].banner_image_url}
                        label={secondHalf[index].category_Name}
                        alt={secondHalf[index].altTag}
                        width={data?.dimensions?.width}
                        height={data?.dimensions?.height}
                        sizes="12vw"
                        classes="rounded-2xl"
                      />
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
OccasionSection.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  headingClass: PropTypes.string,
};
export default OccasionSection;
