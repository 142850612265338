import PropTypes from "prop-types";
import CustomImage from "../../atoms/customImage";

/**
 * RatingStars component displays a rating in the form of stars.
 * @param {Object} props - Component props.
 * @param {number} props.ratings - The rating value.
 * @param {number} props.stars - The total number of stars to display.
 * @returns {JSX.Element} - Rendered component.
 */
const RatingStars = ({ ratings, stars }) => {
  return (
    <div className="flex gap-1">
      {Array.from({ length: stars }, (elem, index) => (
        <span key={index}>
          {ratings >= index + 1 ? (
            <CustomImage src="/icons/rating-star.svg" alt="rating-star" width={15} height={15} sizes="10vw" />
          ) : (
            <CustomImage src="/icons/empty-star.svg" alt="empty-star" width={15} height={15} sizes="10vw" />
          )}
        </span>
      ))}
    </div>
  );
};

RatingStars.propTypes = {
  ratings: PropTypes.number.isRequired,
  stars: PropTypes.number.isRequired,
};

export default RatingStars;
