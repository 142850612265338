import { useMemo } from "react";
import useCommonOmnitureData from "./useCommonOmnitureData";
import useOmnitureSetup from "./useOmnitureSetup";

/**
 * Cart Preview page omniture setup
 */
export default function useCartPreviewOmnitureSetup() {
  const { rootGeoId, catalogId, checkLoginInfo, cartItems } = useCommonOmnitureData();

  const omniData = useMemo(
    () => ({
      userDetails: {
        checkLoginInfo,
      },
      appConfigs: {
        rootGeoId,
        catalogId,
      },
      cartDetails: {
        cartItems,
      },
    }),
    [cartItems, checkLoginInfo, catalogId, rootGeoId],
  );

  useOmnitureSetup(omniData);
}
