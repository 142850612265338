import PropTypes from "prop-types";
import Slider from "react-slick";
import isArray from "lodash.isarray";
import Typography from "../../../components/atoms/typography";
import CategoryImage from "../../../components/molecules/categoryImage";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";
import { SlickNextArrow, SlickPrevArrow } from "../../../components/molecules/carouselHelpers";
/** */
const CarouselOrScrollSection = ({ isMobile, data, dimensions, headingClass, slidePerPage = 6 }) => {
  const { trackEvent } = useAnalytics();
  const carouselImages = isArray(data?.list) ? data?.list : [];
  const pageBannerData = {
    page_name: cleverTapCommonWords.HOME,
    page_type: cleverTapCommonWords.HOME,
  };
  const listItemWidth = isMobile ? 100 / data?.mobileScroll : 100 / data?.desktopSlide;

  const setting = {
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    slidesToShow: slidePerPage,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
  };

  return (
    carouselImages.length > 0 && (
      <div className="logoSlider relative w-full md:px-40">
        {data?.category_Name && (
          <Typography
            variant="h1"
            Tag="p"
            classes={`font-600 text-16 mb-12 md:mb-20 px-16 md:px-0 lg:text-24 xxl:text-32 ${headingClass} capitalize`}
          >
            {data?.category_Name}
          </Typography>
        )}
        {isMobile ? (
          <div className="relative w-full">
            <ul
              className={`scrollbar-hide flex px-16 md:px-0 ${data?.isMobileScroll ? "" : "flex-wrap"} w-full  items-center justify-between gap-12 overflow-x-auto overflow-y-hidden md:gap-32`}
            >
              {carouselImages.map((item, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      width: `calc(${listItemWidth}% - 16px)`,
                    }}
                    className={data?.isMobileScroll ? "flex-none" : ""}
                  >
                    <a
                      href={item?.category_link}
                      // eslint-disable-next-line react/jsx-handler-names
                      onClick={() => {
                        trackEvent(
                          cleverTapCommonEvents.bannerClicked,
                          {
                            ...pageBannerData,
                            banner_id: index,
                            banner_name: item?.categoryName,
                          },
                          ["clevertap"],
                        );
                      }}
                    >
                      <CategoryImage
                        varient="imageWithText"
                        imageSrc={item.banner_image_url}
                        label={item.category_Name}
                        alt={item.altTag}
                        width={item?.dimensions?.width || dimensions?.width}
                        height={item?.dimensions?.height || dimensions?.height}
                        sizes="12vw"
                        classes="rounded-2xl"
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <Slider {...setting} className="relative w-full">
            {carouselImages?.map((ele, index) => {
              return (
                <a
                  href={`${ele?.category_link}${ele?.productId ? (ele?.category_link?.includes("?") ? `&productId=${ele?.productId}&currencyUomId=INR&categoryId=gift&catalogId=india` : `?productId=${ele?.productId}&currencyUomId=INR&categoryId=gift&catalogId=india`) : ""}`}
                  key={index}
                  // eslint-disable-next-line react/jsx-handler-names
                  onClick={() => {
                    trackEvent(
                      cleverTapCommonEvents.bannerClicked,
                      {
                        ...pageBannerData,
                        banner_id: index,
                        banner_name: ele?.categoryName,
                      },
                      ["clevertap"],
                    );
                  }}
                >
                  <CategoryImage
                    varient="imageWithText"
                    imageSrc={ele.banner_image_url}
                    label={ele.category_Name}
                    alt={ele.altTag}
                    width={ele?.dimensions?.width || dimensions?.width}
                    height={ele?.dimensions?.height || dimensions?.height}
                    sizes="12vw"
                    classes="rounded-2xl"
                  />
                </a>
              );
            })}
          </Slider>
        )}
      </div>
    )
  );
};

CarouselOrScrollSection.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  headingClass: PropTypes.string,
  dimensions: PropTypes.object.isRequired,
  slidePerPage: PropTypes.number,
};
export default CarouselOrScrollSection;
