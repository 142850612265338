import { getProductDeliveryType, processDeliveryDate } from "../common";

/**
 * Utility function to generate optimized data for the product and delivery.
 *
 * @param {Object} item - The item object containing product and shipping information.
 * @param {string} clientVariantType - The type of the client variant (e.g., with or without egg).
 * @param {string} CAKE_TYPE - Constants related to the types of cakes, including WITH_EGG.
 * @param {Object} nextAvailableDifference - Object containing the next available date difference.
 * @returns {Object} The optimized data for the product and delivery.
 */
export const generateDeliveryPopupData = (item, clientVariantType, CAKE_TYPE, nextAvailableDifference) => {
  const isEgg = clientVariantType?.toUpperCase() === CAKE_TYPE.WITH_EGG ?? false;
  const isInternational = item?.productCatalogId !== "india";
  const shippingFee = item?.shippingDetails?.shippingPrice ?? 0;
  const deliverySlot = item?.shippingDetails?.deliveryTimeSlot;

  return {
    product_name: item?.productName,
    pid: item?.productId,
    product_category: item?.primaryProductCategoryId,
    product_price: item?.price,
    is_variant_available: item?.isVariant,
    is_egg: isEgg,
    delivery_type: getProductDeliveryType(item?.mainProduct?.primaryProductCategoryId),
    pincode: item?.pincode,
    area: item?.deliveryLocation,
    system_pincode: item?.pincode,
    country: item?.productCatalogId,
    city: item?.deliveryLocation,
    is_international: isInternational,
    date_of_delivery: processDeliveryDate(item?.deliveryDate),
    delivery_shipping: item?.shippingMethodName || "",
    delivery_shipping_fee: shippingFee,
    delivery_slot: deliverySlot,
    next_available_date_difference: nextAvailableDifference?.nextAvailableDateDiff,
  };
};
