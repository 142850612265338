import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import Chip from "../../atoms/chip";
import CustomImage from "../../atoms/customImage";
import Typography from "../../atoms/typography";
import HTMLReactParser from "html-react-parser";
import { isJsonString } from "../../../utils/common";

/**
 * QuickLinks component renders a list of quick links.
 * @param {Object[]} isMobile - Boolean to determine the mobile device.
 * @param {Object[]} dataList - Array of objects containing type, imageURL, categoryName, categoryId, redirectionUrl,  .
 * @param {Object[]} fallbackHTML - render HTML for each quick link.
 * @returns {JSX.Element} QuickLinks component.
 */
const QuickLinks = ({ isMobile, dataList, fallbackHTML }) => {
  const chipType = dataList?.capsuleType;
  const itemsList = dataList?.data?.length ? dataList?.data : fallbackHTML;

  /**
   * When capsule data is not available, these fallback chips will be visible.
   * @returns
   */
  const getFallbackChips = () =>
    !isJsonString(fallbackHTML)
      ? HTMLReactParser(fallbackHTML)
      : JSON.parse(itemsList)?.map((item, index) => (
          <Link href={item.contentLink} key={index} className="cursor-pointer first:ps-16 last:pr-16">
            <Chip id={`chip-${index}`} content={item?.textContent} variant="outlined" size="medium" classes="text-sm" />
          </Link>
        ));
  /**
   *
   */
  const getChips = () =>
    itemsList?.map((item, index) =>
      chipType === "text" ? (
        <Link href={item?.redirectionUrl} key={item?.categoryId} className="cursor-pointer first:ps-16 last:pr-16">
          <Chip id={`chip-${index}`} content={item?.categoryName} variant="outlined" size="medium" classes="text-sm" />
        </Link>
      ) : (
        <Link href={item?.redirectionUrl} key={item?.categoryId} className="cursor-pointer first:ps-16 last:pr-16">
          <CustomImage
            src={item?.imageUrl}
            alt={item?.categoryName}
            width={isMobile ? 65 : 133}
            height={isMobile ? 65 : 133}
            className={`mx-auto rounded-2xl ${isMobile ? "h-[65px] max-w-[65px]" : "h-[133px] max-w-[133px]"}`}
          />
          <Typography Tag="p" classes="text-12 font-500 leading-14 text-fnp-500 mt-4 overflow-hidden text-ellipsis">
            {item?.categoryName?.length > 15 && isMobile
              ? `${item?.categoryName?.slice(0, 15)}...`
              : item?.categoryName}
          </Typography>
        </Link>
      ),
    );

  return (
    <div
      className={`${!isJsonString(fallbackHTML) && !dataList?.data?.length ? "block" : "-mx-16 mb-16 flex"} gap-12 overflow-x-auto text-center scrollbar-none ${isMobile ? `mb-18 ${!isJsonString(fallbackHTML) ? "px-4" : ""}` : !isJsonString(fallbackHTML) ? "" : "mb-40 mt-48"}`}
    >
      {chipType ? getChips() : getFallbackChips()}
    </div>
  );
};

QuickLinks.propTypes = {
  isMobile: PropTypes.bool,
  dataList: PropTypes.object,
  fallbackHTML: PropTypes.any,
};

export default QuickLinks;
