import PropTypes from "prop-types";
import CustomImage from "../customImage";
import isEmpty from "lodash.isempty";

/**
 * ButtonComponent is a customizable button component that supports various sizes, variants, and icons.
 *
 * @param {object} props - The props object.
 * @param {string} props.size - The size of the button ("small", "normal", "medium").
 * @param {string} props.label - The text label displayed on the button.
 * @param {string} props.buttonVariant - The variant of the button ("filled", "outlined", "filter", "icon", "iconWithTextFilled", "iconWithTextOutlined", "underlineText", "countryDropdown").
 * @param {string} props.buttonTextSize - The text size of the button ("small", "medium", "large").
 * @param {string} props.icon - The name of the main icon to display on the button.
 * @param {string} props.subIcon - The name of the sub icon to display on the button (used in "countryDropdown" variant).
 * @param {string} props.backGroundColor - button background color.
 * @returns {JSX.Element} The ButtonComponent.
 */
const ButtonComponent = ({
  size = "small",
  label = "",
  buttonVariant = "filled",
  buttonTextSize = "medium",
  icon = "",
  subIcon = "",
  onClick = () => {},
  classes = "",
  backGroundColor = "",
  isRemoveExtraPadding = false,
  btnParentClasses,
  labelStyle,
  subIconClass = "ml-4 w-16",
  iconStyleWidth = 24,
  iconStyleHeight = 24,
  subIconStyleWidth = 24,
  subIconStyleHeight = 24,
  ...restParams
}) => {
  const buttonSizes = {
    small: `flex w-full h-auto ${isRemoveExtraPadding ? "" : "px-32"} py-12 items-center gap-2 shrink-0`,
    normal: "flex w-full h-auto px-32 py-12 items-center gap-2",
    medium: "flex w-full h-auto px-10 py-12 items-center gap-2.5",
    textBtn: "flex w-full h-auto items-center",
    customForPDP: "flex w-full px-8 py-12 items-center gap-1",
  };

  const buttonVariants = {
    filled: "bg-fnp-300 text-white-900 justify-center ",
    outlined: "border border-lightOliveGreen text-fnp-300 justify-center",
    filter: "text-black justify-center ",
    icon: "bg-white flex justify-center ",
    iconWithTextFilled: "text-xs bg-primary-green100 text-white justify-center ",
    iconWithTextOutlined: "text-xs bg-white border border-primary-green100 text-primary-green100 justify-center ",
    underlineText: "underline text-black-900 text-md font-bold",
    countryDropdown: "border",
    customOutlined: "border border-green-200 text-green-200 rounded-lg justify-center",
    disabled: "justify-center bg-fnp-300 text-white-900 opacity-50",
  };

  const buttonTextSizes = {
    small: "text-12 font-400",
    medium: "text-14 font-600",
    large: "text-16 font-600",
  };

  const sizeClass = buttonSizes[size] || "";
  const colorClass = buttonVariants[buttonVariant] || "";
  const textSize = buttonTextSizes[buttonTextSize] || "";

  return (
    <div className={`${btnParentClasses ? btnParentClasses : "flex w-full items-center justify-center"} `}>
      <button
        aria-label="button"
        type={"button"}
        onClick={onClick}
        className={`${classes} ${sizeClass} ${colorClass} rounded-lg  ${backGroundColor}`}
        {...restParams}
      >
        {(buttonVariant === "iconWithTextFilled" ||
          buttonVariant === "iconWithTextOutlined" ||
          buttonVariant === "countryDropdown") &&
          icon && (
            <div className="mr-4">
              <CustomImage
                src={icon}
                width={iconStyleWidth}
                height={iconStyleHeight}
                alt=""
                sizes="10vw"
                isPriority={false}
              />
            </div>
          )}
        <div className={buttonVariant === "countryDropdown" ? "flex w-full items-center justify-between " : ""}>
          <div className={`${labelStyle ? labelStyle : "text-center not-italic leading-6"} ${textSize}`}>{label}</div>
          {buttonVariant === "countryDropdown" && !isEmpty(subIcon) && (
            <div className={subIconClass}>
              <CustomImage
                src={subIcon}
                width={subIconStyleWidth}
                height={subIconStyleHeight}
                alt=""
                sizes="10vw"
                isPriority={false}
              />
            </div>
          )}
        </div>
        {!(
          buttonVariant === "iconWithTextFilled" ||
          buttonVariant === "iconWithTextOutlined" ||
          buttonVariant === "countryDropdown"
        ) &&
          icon && (
            <div className="p-1 pr-4">
              <CustomImage
                src={icon}
                width={iconStyleWidth}
                height={iconStyleHeight}
                alt=""
                sizes="10vw"
                isPriority={false}
              />
            </div>
          )}
      </button>
    </div>
  );
};

ButtonComponent.propTypes = {
  size: PropTypes.string,
  label: PropTypes.string,
  buttonVariant: PropTypes.string,
  buttonTextSize: PropTypes.string,
  icon: PropTypes.string,
  subIcon: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.string,
  backGroundColor: PropTypes.string,
  isRemoveExtraPadding: PropTypes.bool,
  btnParentClasses: PropTypes.string,
  labelStyle: PropTypes.string,
  subIconClass: PropTypes.string,
  subIconStyleWidth: PropTypes.number,
  subIconStyleHeight: PropTypes.number,
  iconStyleWidth: PropTypes.number,
  iconStyleHeight: PropTypes.number,
};

export default ButtonComponent;
