/* eslint-disable react/jsx-boolean-value */
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { calDiscountPercentage, getProductLargeImgList } from "../../../utils/common";
import CustomImage from "../../atoms/customImage";
import ProductCardImageSlider from "../../molecules/productCardImageslider";
import ProductInfo from "./productInfo";
import RateAndReview from "../../molecules/rateAndReview";
import { plpCleverTapEvents } from "../../../constants/pageConstants/plpConstants";
import { COUNTRIES, fnpPageType } from "../../../constants/common/common";
import { shallowEqual, useSelector } from "react-redux";
import { checkIsInternationalProduct } from "../../../utils/pdp/nonDeliverable";
import useAnalytics from "../../../analytics/useAnalytics";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";
import { usePathname } from "next/navigation";

/** */
const ProductCard = ({
  image,
  productUrl,
  title,
  price,
  productId,
  productAttributes,
  imgHeight,
  imgWidth,
  earliestDate,
  review,
  imageCount = 1,
  urlIdentifier = "",
  category,
  isPriority = false,
  isMobile,
  productWidget,
  skuCode,
  productCount,
  isHideSavedPercent = false,
  widgetTitle,
  isSmallReviewTag = false,
}) => {
  const pathname = usePathname();
  const [productImgList, setProductImgList] = useState([]);
  let catalogId = "";
  let categoryId = "";
  let updatedProductUrl = productUrl || "";

  // Extract catalogId and categoryId from urlIdentifier and productUrl if present
  if (urlIdentifier) {
    catalogId = urlIdentifier.split("/")?.[0];
  }
  if (productUrl) {
    categoryId = productUrl.split("/")?.[1];
  }

  // Update productUrl if both catalogId and categoryId are present
  if (categoryId && catalogId) {
    updatedProductUrl = `${productUrl}&productId=${productId}&currencyUomId=INR&categoryId=${categoryId}&catalogId=${catalogId}`;
  }

  const sliderRef = useRef(null);
  const { trackEvent } = useAnalytics();

  const { selectedCurrency, deliveryDates, pinCode, addRess, selectedIntLocation } = useSelector(
    (state) => ({
      selectedCurrency: state?.currencyList?.userSelectedCurrency?.currency?.id,
      deliveryDates: state?.deliveryDate?.details.deliveryDates,
      pinCode: state?.locationLock?.selectedDomesticLocation?.pincode,
      addRess: state?.locationLock?.selectedDomesticLocation?.address,
      selectedIntLocation: state?.locationLock.selectedIntLocation,
    }),
    shallowEqual,
  );
  let attributeStyles = {};
  productUrl =
    categoryId && catalogId
      ? `${productUrl}&productId=${productId}&currencyUomId=INR&categoryId=${categoryId}&catalogId=${catalogId}`
      : productUrl;

  if (productAttributes) {
    attributeStyles = {
      background: productAttributes?.gradientColors
        ? `linear-gradient(45deg, ${productAttributes?.gradientColors?.startColor} 0%, ${productAttributes?.gradientColors?.endColor} 100%)`
        : `#${productAttributes["background-color"]}`,
      color: `#${productAttributes["text-color"]}`,
    };
  }
  const isPLP = pathname?.includes("-lp");

  const discountPercentage = calDiscountPercentage(price?.mrp, price?.listPrice);
  const eventProperties = {
    page_name: categoryId,
    page_type: fnpPageType.CATEGORY,
    pid: productId,
    product_name: title,
    product_category: category,
    product_price: price?.listPrice,
    currency: selectedCurrency,
    strike_price: price?.mrp,
    discount_percentage: discountPercentage,
    product_rating: review?.ratingValue,
    product_review_count: review?.ratingCount,
    earliest_delivery_date: deliveryDates?.[productId],
    is_international: checkIsInternationalProduct(category),
    product_tag: productAttributes?.description,
  };

  const cleverTapData = {
    widget_name: widgetTitle,
    page_type: cleverTapCommonWords.PDP,
    page_name: productWidget?.urlIdentifier || "",
    is_carousel: productCount > 3,
    is_having_product: productCount > 1,
    is_having_image: true,
    is_having_cta: false,
    product_name: productWidget?.productName,
    pid: productWidget?.productId,
    product_category: productWidget?.primaryCategoryId,
    product_position: productWidget?.pos,
    product_row: 1,
    country: catalogId,
    is_international: catalogId !== COUNTRIES.INDIA,
    city: catalogId !== COUNTRIES.INDIA ? selectedIntLocation?.location : "",
    area: catalogId !== COUNTRIES.INDIA ? "" : addRess,
    pincode: catalogId !== COUNTRIES.INDIA ? "" : pinCode,
    system_pincode: catalogId !== COUNTRIES.INDIA ? "" : pinCode,
    sku_id: skuCode,
  };

  useEffect(() => {
    const images = getProductLargeImgList(image?.path, imageCount);
    setProductImgList(images);
  }, [imageCount, image]);

  /**
   * Handles mouse enter event to start the slider autoplay.
   * This function will only start the autoplay if the device is not mobile and the sliderRef is available.
   *
   * @function onHandleMouseEnter
   * @returns {void} No return value.
   */
  const onHandleMouseEnter = () => {
    if (!isMobile && sliderRef.current) {
      sliderRef.current.startAutoplay();
    }
  };
  /**
   * Handles mouse leave event to stop the slider autoplay.
   * This function will only stop the autoplay if the device is not mobile and the sliderRef is available.
   *
   * @function onHandleMouseLeave
   * @returns {void} No return value.
   */
  const onHandleMouseLeave = () => {
    if (!isMobile && sliderRef.current) {
      sliderRef.current.stopAutoplay();
    }
  };

  /**
   * On Product Click to track clevertap events.
   */
  const onProductClick = () => {
    if (fnpPageType.SEARCH || isPLP) {
      trackEvent(plpCleverTapEvents.PRODUCT_CLICKED, eventProperties, ["clevertap"]);
    } else {
      trackEvent(cleverTapCommonEvents.widgetClicked, cleverTapData, ["clevertap"]);
    }
  };

  return (
    <div
      onMouseEnter={onHandleMouseEnter} // Only triggers on desktop
      onMouseLeave={onHandleMouseLeave} // Only triggers on desktop
      onClick={onProductClick}
      data-productid={productId}
      itemProp="offers"
      itemScope
      itemType="http://schema.org/Offer"
    >
      <a href={updatedProductUrl} className="flex h-full w-full" itemProp="url" target={isMobile ? null : "_blank"}>
        <div className={`relative w-full overflow-hidden rounded-lg sm:border sm:border-gray-100 sm:bg-white-900`}>
          {productAttributes?.description && (
            <div className="absolute left-8 top-8 z-1">
              <div
                className={`${productAttributes?.isAnimation ? "animationEffect" : ""} relative block overflow-hidden rounded-4 p-4 text-12 leading-0`}
                style={{ ...attributeStyles }}
              >
                {productAttributes?.description}
              </div>
            </div>
          )}
          <div className="relative">
            {review && <RateAndReview review={review} isReviewTextHide={true} isSmallReviewTag={isSmallReviewTag} />}
            {isMobile ? (
              <CustomImage
                src={image?.path}
                width={imgWidth}
                height={imgHeight}
                alt={image?.altText ? image?.altText : image?.alt}
                borderRadius={8}
                isPriority={isPriority}
                itemProp="image"
              />
            ) : (
              <ProductCardImageSlider
                defaultImg={image?.path}
                productImgList={productImgList}
                imgHeight={imgHeight}
                imgWidth={imgWidth}
                ref={sliderRef}
                isPriority={isPriority}
                alt={image?.altText ? image?.altText : image?.alt}
              />
            )}
          </div>
          <ProductInfo
            title={title}
            price={price}
            productId={productId}
            earliestDate={earliestDate}
            isHideSavedPercent={isHideSavedPercent}
          />
        </div>
      </a>
    </div>
  );
};

ProductCard.propTypes = {
  image: PropTypes.shape({
    path: PropTypes.string.isRequired,
    altText: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.shape({
    listPrice: PropTypes.number,
    mrp: PropTypes.number,
    percentSaved: PropTypes.number,
  }).isRequired,
  productId: PropTypes.string.isRequired,
  productUrl: PropTypes.string.isRequired,
  productAttributes: PropTypes.object,
  imgHeight: PropTypes.number,
  imgWidth: PropTypes.number,
  earliestDate: PropTypes.string,
  review: PropTypes.object,
  imageCount: PropTypes.number,
  urlIdentifier: PropTypes.string.isRequired,
  category: PropTypes.string,
  isPriority: PropTypes.bool,
  isMobile: PropTypes.bool,
  productWidget: PropTypes.object,
  skuCode: PropTypes.string,
  productCount: PropTypes.number,
  isHideSavedPercent: PropTypes.bool,
  widgetTitle: PropTypes.string,
  isSmallReviewTag: PropTypes.bool,
};

export default ProductCard;
