import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCartVisible: false,
  isLoaderVisible: false,
  requestToAddToCart: false,
  responseToAddToCart: false,
  requestToViewCart: false,
  responseToViewCart: false,
  requestToSetCartItemValues: false,
  responseToSetCartItemValues: false,
  requestToUpdateCart: false,
  responseToUpdateCart: false,
  requestToDeleteCartItem: false,
  responseToDeleteCartItem: false,
  requestToGetCartItemsCount: false,
  responseToGetCartItemsCount: false,
  cartDetails: {},
  addToCartResponse: {},
  cartError: {},
  cartCount: 0,
  pdpCartNotificationPopUp: false,
  isSameItemInCart: false,
  selectedCartProduct: {},
  redirectToAddonFromCart: false,
  isCartLoading: true,
};

export const CartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    requestAddToCart: (state) => {
      state.requestToAddToCart = true;
    },
    responseAddToCart: (state, action) => {
      state.requestToAddToCart = false;
      state.responseToAddToCart = true;
      state.addToCartResponse = action.payload;
    },
    failedAddToCart: (state, action) => {
      state.requestToAddToCart = false;
      state.responseToAddToCart = false;
      state.cartError = action.payload;
    },
    requestViewCart: (state) => {
      state.requestToAddToCart = false;
      state.requestToViewCart = true;
      state.responseToViewCart = false;
      state.isCartLoading = true;
    },
    responseViewCart: (state, action) => {
      state.requestToViewCart = false;
      state.responseToViewCart = true;
      state.cartDetails = action.payload;
      state.isCartLoading = false;
    },
    failedViewCart: (state, action) => {
      state.requestToAddToCart = false;
      state.requestToViewCart = false;
      state.responseToViewCart = false;
      state.cartError = action.payload;
      state.isCartLoading = false;
    },
    requestSetCartItemValues: (state) => {
      state.requestToSetCartItemValues = true;
    },
    responseSetCartItemValues: (state) => {
      state.responseToSetCartItemValues = true;
    },
    failedSetCartItemValues: (state, action) => {
      state.cartError = action.payload;
    },
    failedUpdateCart: (state, action) => {
      state.requestToUpdateCart = false;
      state.responseToUpdateCart = false;
      state.cartError = action.payload;
    },
    responseUpdateCart: (state) => {
      state.requestToUpdateCart = false;
      state.responseToUpdateCart = true;
    },
    requestUpdateCart: (state) => {
      state.requestToUpdateCart = true;
      state.responseToUpdateCart = false;
    },
    requestDeleteCartItem: (state) => {
      state.requestToDeleteCartItem = true;
      state.responseToDeleteCartItem = false;
    },
    responseDeleteCartItem: (state) => {
      state.requestToDeleteCartItem = false;
      state.responseToDeleteCartItem = true;
    },
    failedDeleteCartItem: (state, action) => {
      state.cartError = action.payload;
      state.requestToDeleteCartItem = false;
      state.responseToDeleteCartItem = false;
    },

    responseGetCartItemsCount: (state, action) => {
      state.requestToGetCartItemsCount = false;
      state.responseToGetCartItemsCount = true;
      state.cartCount = action.payload;
    },

    setIsCartVisible: (state, action) => {
      state.isCartVisible = action.payload;
    },
    setIsLoaderVisible: (state, action) => {
      state.isLoaderVisible = action.payload;
    },

    requestToCartNotification: () => {},

    setPdpCartNotificationPopUp: (state, action) => {
      state.pdpCartNotificationPopUp = action.payload;
    },
    setIsSameItemInCart: (state, action) => {
      state.isSameItemInCart = action.payload;
    },
    setSelectedCartProduct: (state, action) => {
      state.selectedCartProduct = action.payload || {};
    },
    setRedirectToAddonFromCart: (state, action) => {
      state.redirectToAddonFromCart = action.payload;
    },
  },
});

export default CartSlice.reducer;
export const {
  setIsCartVisible,
  responseGetCartItemsCount,
  setIsLoaderVisible,
  failedAddToCart,
  requestAddToCart,
  responseAddToCart,
  failedViewCart,
  requestViewCart,
  responseViewCart,
  requestSetCartItemValues,
  responseSetCartItemValues,
  failedSetCartItemValues,
  failedUpdateCart,
  responseUpdateCart,
  requestUpdateCart,
  requestDeleteCartItem,
  responseDeleteCartItem,
  failedDeleteCartItem,
  requestToCartNotification,
  setPdpCartNotificationPopUp,
  setIsSameItemInCart,
  setSelectedCartProduct,
  setRedirectToAddonFromCart,
} = CartSlice.actions;
