"use client";
import { useState } from "react";
import TextField from "../../../atoms/textField";
import CustomImage from "../../../atoms/customImage";
import Regex from "../../../../utils/regex";
import { subscribeNewUser } from "../../../../api/common";
import {
  EMAIL_REQUIRED_LABEL,
  GET_UPDATES_TEXT,
  INVALID_EMAIL_ADDRESS,
  SUBSCRIBE_NOW,
} from "../../../../constants/common/footer";
import { EMAIL_INPUT } from "../../../../constants/pageConstants/loginConstants";

/**
 * Description placeholder
 * @date 08/03/2024 - 09:22:46
 *
 * @returns {*}
 */
const Subscription = () => {
  const [userEmail, setUserEmail] = useState("");
  const [valid, setValid] = useState("");
  const [subscribedApiResponse, setSubscribedApiResponse] = useState("");

  /**
   * This function renders the success or error message for email input
   */
  const renderMessage = () => {
    switch (valid) {
      case "invalid":
        return EMAIL_REQUIRED_LABEL;
      case "error":
        return INVALID_EMAIL_ADDRESS;
      case "success":
        return subscribedApiResponse;
      default:
        return "";
    }
  };

  /**
   * Description placeholder
   * @date 08/03/2024 - 09:26:03
   *
   * @param {*} e
   */
  const onChange = (e) => {
    setUserEmail(e.target.value);
  };

  /**
   * Description placeholder
   * @date 08/03/2024 - 09:17:58
   */
  const onSubscription = async (e) => {
    e.stopPropagation();
    if (userEmail.length > 0) {
      if (Regex.emailRegularExpression.test(userEmail)) {
        const subscribedUserResponse = await subscribeNewUser({ emailId: userEmail });
        setSubscribedApiResponse(subscribedUserResponse);
        setValid("success");
      } else {
        setValid("error");
      }
    } else {
      setValid("invalid");
    }
  };

  /**
   * This function handles the enter key down event
   * @param {Object} ev - Event
   */
  const onEnterPress = (ev) => {
    if (ev.key === "Enter") {
      onSubscription(ev);
    }
  };

  return (
    <>
      <div className="w-2/6 p-40" data-testid="subscription-component">
        <h6 className="mb-2 text-14 font-500 capitalize text-fnp-500">{SUBSCRIBE_NOW}</h6>
        <p className="text-12 leading-20 text-grey-500">{GET_UPDATES_TEXT}</p>
        <div className="mt-4">
          <TextField
            variant="primary"
            id="subscription-input"
            placeholder={EMAIL_INPUT.placeholder}
            type="email"
            inputValue={userEmail}
            isRequired={true}
            isError={valid === "invalid" || valid === "error"}
            isSuccess={valid === "success"}
            message={renderMessage()}
            onChange={onChange}
            onKeyPress={onEnterPress}
            startAdornment={
              <CustomImage src={`/icons/sms.svg`} width={24} height={24} alt="email icon" isPriority={false} />
            }
            endAdornment={
              <div onClick={onSubscription}>
                <CustomImage
                  src={`/icons/arrow.svg`}
                  width={20}
                  height={20}
                  alt="right arrow icon"
                  isPriority={false}
                />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};
export default Subscription;
