/* eslint-disable react/jsx-handler-names */
import { useState } from "react";
import PropTypes from "prop-types";
import Accrodion from "../../../molecules/accordion";
import Link from "next/link";
import SubCategoryMenu from "./menuItems";
import CustomImage from "../../../atoms/customImage";
import useAnalytics from "../../../../analytics/useAnalytics";
import { usePathname } from "next/navigation";
import { pageName } from "../../../../utils/common";
import { cleverTapCommonEvents } from "../../../../analytics/clevertapConstants";
import AccordionTitle from "../../../molecules/accordion/accordionTitle";
import AccordionContent from "../../../molecules/accordion/accordionContent";

/**
 * MenuItems component displays a nested list of items with accordion functionality.
 *
 * @param {Object[]} data - Array of category data to be displayed.
 * @param {string} data[].categoryName - The name of the category.
 * @param {Object[]} data[].childCategoryLists - Array of subcategories for each category.
 * @param {string} data[].childCategoryLists[].categoryName - The name of the subcategory.
 * @param {string} data[].childCategoryLists[].productCategoryId - The ID of the subcategory's product category.
 * @param {string} data[].childCategoryLists[].promo - The promotional information for the subcategory.
 * @param {number} itemIndex - The index of the category item.
 * @returns {JSX.Element} JSX for the MenuItems component.
 */
const MenuItems = ({ data, itemIndex, itemLevel = -1 }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const { trackEvent } = useAnalytics();
  const pathname = usePathname();
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");
  const is_having_image = data?.imageSource || "";
  const megaMenuData = {
    page_name: pageName(isHome, isPLP, "", ""),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
    is_having_image: !!is_having_image,
    action_type: "menu click",
  };

  /**
   * @param {*} categoryId
   * @param {*} categoryName
   * @param {*} promo
   * @returns
   */
  const renderItemLink = (categoryName, categoryId, promo) =>
    categoryName && (
      <Link
        key={categoryName}
        href={`${categoryId}?promo=${promo}`}
        className="px-12 py-8 text-12 text-grey-500"
        onClick={() => {
          trackEvent(
            cleverTapCommonEvents.megaMenuClicked,
            {
              ...megaMenuData,
              menu_name: categoryName,
              menu_category: categoryId,
            },
            ["clevertap"],
          );
        }}
      >
        {categoryName}
      </Link>
    );

  /**
   * @param {*} item
   * @param {*} index
   * @returns
   */
  const renderItems = (item, index) => (
    <Accrodion key={item.categoryName}>
      <AccordionTitle
        activeIndex={activeIndex}
        changeActiveIndex={setActiveIndex}
        index={index}
        inactiveClass="rounded-lg"
        activeClass={`rounded-t-lg ${itemLevel === 0 ? "mb-8" : ""}`}
        styles={`${itemLevel === 0 ? "flex item-center justify-between pe-8" : "text-14 flex text-fnp-500 justify-between bg-grey-100 ps-12 pe-8 py-8"}`}
      >
        <div className="flex items-center justify-between gap-8">
          {item.imageSource && (
            <CustomImage
              src={item.imageSource}
              width={60}
              height={60}
              sizes="10vw"
              alt={`${item.productCategoryId}_image`}
              borderRadius={8}
            />
          )}
          <span className={`w-full text-14 ${itemLevel === 0 || activeIndex === index ? "font-500" : "font-400"}`}>
            {item.categoryName}
          </span>
        </div>
      </AccordionTitle>

      <AccordionContent
        activeIndex={activeIndex}
        index={index}
        styles={`flex flex-col ${itemLevel === 0 ? "gap-8" : "bg-grey-100 rounded-b-lg"}`}
      >
        {item?.childCategoryLists?.map((subChildCategory, idx) => (
          <SubCategoryMenu key={`Category-${idx}`} data={subChildCategory} itemIndex={idx} />
        ))}
      </AccordionContent>
    </Accrodion>
  );

  /**
   * @param {*} item
   * @param {*} index
   * @returns
   */
  const renderMenuLevelItems = (item, index) => {
    if (itemLevel === 0) {
      return (
        <div
          key={item.productCategoryId}
          className="border-grey-300 flex items-center justify-center rounded-lg border-1 border-solid p-8"
        >
          {renderItems(item, index)}
        </div>
      );
    }
    return renderItems(item, index);
  };

  return (
    <>
      {Array.isArray(data)
        ? data?.map((item, index) => {
            renderItems(item, index);
          })
        : data?.childCategoryLists?.length > 0
          ? renderMenuLevelItems(data, itemIndex)
          : renderItemLink(data?.categoryName, data?.productCategoryId, data?.promo)}
    </>
  );
};

MenuItems.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        categoryName: PropTypes.string.isRequired,
        childCategoryLists: PropTypes.arrayOf(
          PropTypes.shape({
            categoryName: PropTypes.string.isRequired,
            childCategoryLists: PropTypes.array,
            parentProductCategoryId: PropTypes.string.isRequired,
            productCategoryId: PropTypes.string.isRequired,
            promo: PropTypes.string,
          }),
        ).isRequired,
        parentProductCategoryId: PropTypes.string.isRequired,
        productCategoryId: PropTypes.string.isRequired,
      }),
    ).isRequired,
    PropTypes.shape({
      categoryName: PropTypes.string.isRequired,
      childCategoryLists: PropTypes.arrayOf(
        PropTypes.shape({
          categoryName: PropTypes.string.isRequired,
          childCategoryLists: PropTypes.array,
          parentProductCategoryId: PropTypes.string.isRequired,
          productCategoryId: PropTypes.string.isRequired,
          promo: PropTypes.string,
        }),
      ).isRequired,
      parentProductCategoryId: PropTypes.string.isRequired,
      productCategoryId: PropTypes.string.isRequired,
    }).isRequired,
  ]),
  itemIndex: PropTypes.number,
  itemLevel: PropTypes.number,
};

export default MenuItems;
