import { put, takeLatest } from "redux-saga/effects";
import {
  failedToFetchProductListingAction,
  responseFromFetchProductListingAction,
  requestToFetchProductListingAction,
  responseFromLoadMoreProductListingAction,
  responseFromClearFiltersAction,
  requestToAddListingDataAction,
  responseFromAddListingDataAction,
} from "../../slices/plpSlice.js";
import {
  failedToGetReviewsAction,
  requestToGetReviewsAction,
  responseFromGetReviewsAction,
} from "../../slices/reviewSlice.js";
import {
  failedToFetchDeliveryDateAction,
  requestToFetchDeliveryDateAction,
  responseFromFetchDeliveryDateAction,
} from "../../slices/earliestDeliveryDatesSlice.js";
import { getProductListingDetailsAPI, getDeliveryDatesAPI } from "../../../api/plpApi/index.js";
import { setIsLoaderVisible } from "../../slices/cartSlice.js";

/**
 * Description placeholder
 *
 * @export
 * @returns {{}}
 */
function* fetchProductListingSaga(action) {
  try {
    const { filters, sortOption, isLoadMore } = action.payload;
    const response = yield getProductListingDetailsAPI(action.payload);
    if (response?.data?.length > 0) {
      if (isLoadMore) {
        yield put(responseFromLoadMoreProductListingAction(response));
      } else {
        if (filters === undefined) {
          yield put(responseFromClearFiltersAction({ filters: [] }));
        }
        yield put(
          responseFromFetchProductListingAction({
            response,
            filters: decodeURIComponent(filters),
            sortOption: decodeURIComponent(sortOption),
          }),
        );
      }
      const queryParams = response?.data?.map((product) => product?.productId).join(",");

      const earliestDeliveryDateResponse = yield getDeliveryDatesAPI(queryParams);
      if (earliestDeliveryDateResponse) {
        yield put(responseFromFetchDeliveryDateAction(earliestDeliveryDateResponse));
      } else yield put(failedToFetchDeliveryDateAction({ message: "Earliest delivery dates not found", code: 404 }));

      yield put(setIsLoaderVisible(false));
    } else {
      yield put(failedToFetchProductListingAction({ message: "Product listing not found", response, code: 404 }));
    }
  } catch (error) {
    yield put(failedToFetchProductListingAction(error));
  }
}

/**
 *
 */
function* getPlpReviewsSaga(action) {
  try {
    if (action) {
      yield put(responseFromGetReviewsAction(action?.payload));
    } else {
      yield put(failedToGetReviewsAction({ message: "Product reviews not found", code: 404 }));
    }
  } catch (error) {
    yield put(failedToGetReviewsAction(error));
  }
}

/**
 *
 * @param {*} action
 */
function* fetchDeliveryDateSaga(action) {
  try {
    if (action) {
      const { isChooseDeliveryType, productIds, pincode } = action.payload;
      if (isChooseDeliveryType) {
        const response = yield getDeliveryDatesAPI(productIds, pincode);
        yield put(responseFromFetchDeliveryDateAction(response));
      } else {
        yield put(responseFromFetchDeliveryDateAction(action.payload));
      }
    } else {
      yield put(failedToFetchDeliveryDateAction({ message: "Earliest delivery dates not found", code: 404 }));
    }
  } catch (error) {
    yield put(failedToFetchDeliveryDateAction(error));
  }
}

/**
 *
 * @param {*} action
 */
function* addListingFilterSortSaga(action) {
  try {
    if (action) {
      yield put(responseFromAddListingDataAction(action.payload));
    } else {
      yield put(failedToFetchDeliveryDateAction({ message: "Something went wrong to fetch listing data", code: 404 }));
    }
  } catch (error) {
    yield put(failedToFetchDeliveryDateAction(error));
  }
}

/**
 * Description placeholder
 *
 * @export
 * @returns {{}}
 */
export function* watcherFetchProductListingSaga() {
  yield takeLatest(requestToFetchProductListingAction.type, fetchProductListingSaga);
}

/**
 *
 */
export function* watcherFetchDeliveryDateSaga() {
  yield takeLatest(requestToFetchDeliveryDateAction.type, fetchDeliveryDateSaga);
}

/**
 *
 */
export function* watcherGetPlpReviewsSaga() {
  yield takeLatest(requestToGetReviewsAction.type, getPlpReviewsSaga);
}

/**
 *
 */
export function* watcherFiltersSaga() {
  yield takeLatest(requestToAddListingDataAction.type, addListingFilterSortSaga);
}
