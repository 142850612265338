/**
 *
 * @param {*} selectedCurrency
 * @returns
 */
export const iconsForSelectedCurrency = (selectedCurrency) => {
  switch (selectedCurrency) {
    case "INR":
      return "/icons/currency-icons/inr.svg";
    case "THB":
      return "/icons/currency-icons/thb.svg";
    case "SGD":
      return "/icons/currency-icons/sgd.svg";
    case "USD":
      return "/icons/currency-icons/usd.svg";
    case "QAR":
      return "/icons/currency-icons/qar.svg";
    case "NZD":
      return "/icons/currency-icons/nzd.svg";
    case "MYR":
      return "/icons/currency-icons/myr.svg";
    case "GBP":
      return "/icons/currency-icons/gbp.svg";
    case "EUR":
      return "/icons/currency-icons/eur.svg";
    case "CAD":
      return "/icons/currency-icons/cad.svg";
    case "AUD":
      return "/icons/currency-icons/aud.svg";
    case "AED":
      return "/icons/currency-icons/aed.svg";

    default:
      return "/icons/currency-icons/inr.svg";
  }
};
