/* eslint-disable react/jsx-boolean-value */
import PropTypes from "prop-types";
import Image from "next/image";
import Chip from "../../atoms/chip";
import { BEST_RATING, REVIEWS } from "../../../constants/common/common";

/**
 * PLPReviewRatings Component
 *
 * Displays the product's best rating and review count, with a button to scroll to the reviews section.
 *
 * @component
 *
 * @param {number} bestRating - Highest rating for the product/category.
 * @param {number} reviewCount - Total number of reviews.
 * @param {function} onScrollToReviews - Scrolls to the reviews section.
 *
 * @returns {JSX.Element}
 */
const PLPReviewRatings = ({ bestRating, reviewCount, onScrollToReviews }) => {
  return (
    <>
      <div itemProp="aggregateRating" itemScope itemType="//schema.org/AggregateRating">
        <meta itemProp="ratingValue" content={bestRating} />
        <meta itemProp="reviewCount" content={reviewCount} />
        <meta itemProp="bestRating" content={BEST_RATING} />
        <div className="flex gap-8">
          <div className="mx-2 w-fit">
            <Chip
              content={bestRating.toString()}
              startIcon="/icons/vector-rating.svg"
              variant="outlined"
              size="small"
              id="category_rating"
              classes="px-8 gap-4 py-4 text-fnp-500"
              color="default"
            />
          </div>
          <button
            className="flex items-center gap-8 px-2 py-2 text-16 font-400 text-blue-600"
            onClick={onScrollToReviews}
          >
            {reviewCount}
            <span className="mx-1">{REVIEWS}</span>
            <Image src="/icons/review-chevron.svg" alt={`filter-chevron_icon`} width={15} height={15} />
          </button>
        </div>
      </div>
    </>
  );
};
PLPReviewRatings.propTypes = {
  bestRating: PropTypes.number,
  reviewCount: PropTypes.number,
  onScrollToReviews: PropTypes.func,
};
export default PLPReviewRatings;
