import PropTypes from "prop-types";
import { CUSTOMER_REVIEWS_OPTIONS } from "../../../constants/common/splideOptions";
import TestimonialCard from "../testimonialCard";
import Slider from "react-slick";
import { SlickNextArrow, SlickPrevArrow } from "../../molecules/carouselHelpers";
import ButtonComponent from "../../atoms/button";

/**
 * CustomerReviewsCarousel component to display a carousel of customer reviews.
 *
 * @param {Object} props - The properties object.
 * @param {Array<Object>} props.reviews - The list of review objects.
 * @param {string} props.title - The title of the reviews section.
 * @param {number} props.totalReviews - Total Reviews related to category or product
 * @param {number} props.categoryId - Which category related reviews will display after redirect to testimonials pages
 * @param {number} props.productId - Which product related reviews will display after redirect to testimonials pages
 * @returns {JSX.Element} The rendered carousel component.
 */
export default function CustomerReviewsCarousel({
  reviews,
  title,
  pageType,
  categoryId = "",
  productId = "",
  isMobile,
  mobileCarouselParentStyle = "px-16",
  viewAllStyle = "px-16",
}) {
  const categoryWithoutLp = categoryId && categoryId.split("-lp");
  /** */
  const onRedirectToTestimonials = () => {
    window.location.href = `/info/testimonials?pageType=${pageType}&${categoryId ? `categoryId=${categoryWithoutLp[0]}` : `productId=${productId}`}&domainId=fnp.com`;
  };
  return (
    reviews.length > 0 && (
      <div className={`my-16 w-full md:my-0`} id="reviews-section">
        <div className={`mb-12 flex w-full justify-between md:mb-16 ${viewAllStyle}`}>
          <h3 className="flex items-center text-16 font-600 capitalize text-fnp-500 md:text-24">{title}</h3>
          <div>
            <ButtonComponent
              size="medium"
              classes="flex justify-center py-8 px-16 border-lightOliveGreen text-14 font-600 text-fnp-300"
              label="View All"
              onClick={onRedirectToTestimonials}
              buttonVariant="outlined"
            />
          </div>
        </div>
        {isMobile ? (
          <div
            className={`scrollbar-hide flex w-full gap-16 overflow-x-auto overflow-y-hidden ${mobileCarouselParentStyle}`}
          >
            {Array.isArray(reviews) &&
              reviews?.map((review, i) => (
                <div className="flex" key={i}>
                  <TestimonialCard review={review} />
                </div>
              ))}
          </div>
        ) : (
          <div className="w-full">
            <Slider
              {...CUSTOMER_REVIEWS_OPTIONS}
              nextArrow={<SlickNextArrow />}
              prevArrow={<SlickPrevArrow />}
              className="customerReviews leftAlignSlick relative w-full"
            >
              {Array.isArray(reviews) &&
                reviews?.map((review, i) => (
                  <div className="flex h-full w-full" key={i}>
                    <TestimonialCard review={review} />
                  </div>
                ))}
            </Slider>
          </div>
        )}
      </div>
    )
  );
}

/**
 * Custom PropType validator to ensure that either categoryId or productId is provided.
 *
 * @param {Object} props - The props object.
 * @returns {?Error} - Returns an error if both categoryId and productId are missing, otherwise null.
 */
const categoryOrProductIdPropType = (props) => {
  const { categoryId, productId } = props;
  if (!categoryId && !productId) {
    return new Error(`One of 'categoryId' or 'productId' is required.`);
  }
  return null;
};
CustomerReviewsCarousel.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  pageType: PropTypes.string,
  categoryId: categoryOrProductIdPropType,
  productId: categoryOrProductIdPropType,
  isMobile: PropTypes.bool.isRequired,
  mobileCarouselParentStyle: PropTypes.string,
  viewAllStyle: PropTypes.string,
};
