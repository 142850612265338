const PAGE_TYPE_VAR_NAME = "fnpPageType";

/**
 * Enum for defining various page types in the application.
 */
export const PageTypes = {
  HOME: "home",
  CATEGORY: "category",
  SEARCH: "search",
  INFO: "info",
  PRODUCT: "product",
  ERROR_404: "error404",
  TESTIMONIALS: "testimonials",
};

/**
 * Sets the page type in the global `window` object and optionally sends it to New Relic as a custom attribute.
 *
 * @param {string} pageType - The page type to set. Should be one of the values in `PageTypes`.
 */
export function setPageType(pageType) {
  if (typeof window !== "undefined") {
    window[PAGE_TYPE_VAR_NAME] = pageType;

    // Send the page type to New Relic, if available
    if (typeof newrelic !== "undefined" && window.newrelic.setCustomAttribute) {
      window.newrelic.setCustomAttribute("fnpPageType", pageType);
    }
  }
}

/**
 * Sets the page type for listing pages based on the provided category ID.
 *
 * @param {string} categoryId - The identifier of the category. Determines the page type.
 * - If "search", the page type is set to `SEARCH`.
 * - Otherwise, the page type is set to `CATEGORY`.
 */
export function setListingPageType(categoryId) {
  switch (categoryId) {
    case PageTypes.SEARCH:
      setPageType(PageTypes.SEARCH);
      break;
    default:
      setPageType(PageTypes.CATEGORY);
  }
}
