/* eslint-disable max-lines */
/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-boolean-value */
"use client";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/atoms/breadCrumb";
import CarouselContainer from "../../components/molecules/carouselContainer";
import InfiniteScroll from "../../components/molecules/infiniteScroll";
import PLPBannerTile from "../../components/molecules/plpBannerTile";
import QuickLinks from "../../components/molecules/quickLinks";
import CustomerReviewsCarousel from "../../components/organisms/customerReviewsCarousel";
import FilterSort from "../../components/organisms/filterSort";
import HeaderPlpDesktop from "../../components/organisms/headerPlpDesktop";
import ProductCard from "../../components/organisms/productCard";
import ProductFilter from "../../components/organisms/productFilter";
import { COUNTRIES, fnpPageType, LOCAL_CURRENCY, TESTIMONIAL_TITLE } from "../../constants/common/common";
import { plpBannerConstants, plpCleverTapEvents, plpPageConstants } from "../../constants/pageConstants/plpConstants";
import { requestToFetchDeliveryDateAction } from "../../store/slices/earliestDeliveryDatesSlice";
import { requestToAddListingDataAction, requestToFetchProductListingAction } from "../../store/slices/plpSlice";
import { isJsonString, scrollToSection, throttle } from "../../utils/common/index";
import { setCatalogId, setIsMobile } from "../../store/slices/common/appConfig";
import { setIsLoaderVisible } from "../../store/slices/cartSlice";
import isEmpty from "lodash.isempty";
import ProductNotFoundWrapper from "../../components/organisms/productNotFound";
import Regex from "../../utils/regex";
import OneTapLogin from "../../components/organisms/googleOneTapLogin";
import { hideElementsForPLP, showElementsForPLP } from "../../utils/document";
import Loader from "../../components/atoms/loader";
import SearchInstead from "../../components/molecules/searchPage/searchInstead";
import useAnalytics from "../../analytics/useAnalytics";
import PlpBanner from "./plpBanner/plpBanner";
import CookieUtil from "../../utils/storage/cookieUtil";
import { LAST_VISITED_PINCODE, SELECTED_INTERNATIONAL_CITY } from "../../constants/storage";
import { setGA4SelectedProduct, setGA4ViewItemList } from "../../analytics/gaEvents";
import { GA4_EVENTS } from "../../analytics/gaConstant";
import { setListingPageType } from "../../utils/fnpPageType";
import { usePathname } from "next/navigation";
import { setIsMobileSearchVisibleAction } from "../../store/slices/commonSlice";
import useListingOmnitureSetup from "../../customHooks/omniture/useListingOmnitureSetup";

const CommonConditionalComponents = dynamic(() => import("../../components/molecules/commonConditionalComponents"), {
  ssr: false,
});

/**
 * Product List Component
 * @returns {JSX.Element} Product List JSX
 */
export const ProductListingPage = ({
  fetchFromAPI,
  reviewResponse,
  deliveryDatesData,
  productsAttrTagResponse,
  breadcrumbs,
  categoryBanner,
  plpBanners,
  plpPromotionalBanners,
  capsulesData,
  isMobile,
  categoryId,
  shortDescription,
  catalogId,
  isShowBreadCrumbs = true,
  searchQuery,
  searchInstead,
}) => {
  const bannerTileData = isJsonString(plpPromotionalBanners) ? JSON.parse(plpPromotionalBanners) : [];

  const dispatch = useDispatch();
  const pathname = usePathname();
  const { init, trackEvent } = useAnalytics();
  const {
    data,
    facetDetailList,
    sortOptions,
    categoryRatingValue,
    categoryTotalReview,
    productCategoryName,
    total,
    totalPages: totalPage,
    noResultsFound,
  } = fetchFromAPI || {};

  const {
    details,
    appliedFilters,
    appliedSort,
    deliveryDates,
    pincode,
    isUserLoggedIn,
    isLoaded,

    isShowMobileLocationLockDrawer,
    isShowMobileLocationLock,
    isCartVisible,
    isCurrencyDrawerVisible,
    isMobileSearchDrawerVisible,
  } = useSelector(
    (state) => ({
      details: state?.plp?.details || [],
      appliedFilters: state?.plp?.appliedFilters,
      appliedSort: state?.plp?.appliedSort,
      deliveryDates: state?.deliveryDate?.details?.deliveryDates,
      pincode: state?.locationLock?.selectedDomesticLocation?.pincode,
      isUserLoggedIn: state?.loginSignUp?.loginSignUpSuccessfully,
      isLoaded: state?.plp?.isLoaded,
      loginDetail: state?.fus?.loginDetail,
      isShowMobileLocationLockDrawer: state?.locationLock?.isShowMobileLocationLockDrawer,
      isShowMobileLocationLock: state?.locationLock?.isShowMobileLocationLock,
      isCartVisible: state?.cart?.isCartVisible,
      isCurrencyDrawerVisible: state?.currencyList?.isCurrencyDrawerVisible,
      isMobileSearchDrawerVisible: state?.common?.isMobileSearchDrawerVisible,
    }),
    shallowEqual,
  );
  const currency = CookieUtil.getCookie(LOCAL_CURRENCY);
  const lastVisitedPincode = CookieUtil.getCookie(LAST_VISITED_PINCODE);
  const selectedIntlCity = CookieUtil.getCookie(SELECTED_INTERNATIONAL_CITY);

  const [plpBannerData, setPlpBannerData] = useState(null);

  const urlParams = new URLSearchParams(typeof window === "object" ? window?.location?.search : "");
  const getFiltersArr = urlParams.get("fq");
  const getSortValue = urlParams.get("sort");
  const fetchData = details?.data || data;
  let currentPage = details?.currentPage;
  let totalPages = details?.totalPages || totalPage;
  const facetsList = details?.facetDetailList || facetDetailList;
  const sortOptionsList = details?.sortOptions || sortOptions;
  const localProductTiles = fetchData ? [...fetchData] : [];

  const filters = getFiltersArr ? decodeURIComponent(getFiltersArr)?.split(",") : [];
  const sortOption = getSortValue ? decodeURIComponent(getSortValue) : "";
  const isCapsuleVisible = categoryBanner ? true : false;
  const selectedCountry = selectedIntlCity && JSON.parse(selectedIntlCity);

  bannerTileData?.forEach((banner) => {
    if (fetchData?.length > banner.position - 1) {
      localProductTiles?.splice(banner.position - 1, 0, banner);
    }
  });

  const listingRef = useRef(null);
  const sortLabel =
    sortOptionsList?.find((option) => option.value === decodeURIComponent(getSortValue))?.level ||
    plpPageConstants.RECOMMENDED;
  const previousPathName =
    typeof window !== "undefined" && document?.referrer ? new URL(document?.referrer)?.pathname : "";
  const commonPageData = {
    page_name: pathname,
    page_type: fnpPageType.CATEGORY,
  };

  const pageViewedData = {
    ...commonPageData,
    internal_source_type: previousPathName,
    internal_source: previousPathName,
    login_status: isUserLoggedIn,
    is_international: catalogId !== COUNTRIES.INDIA,
    plp_category: productCategoryName,
    plp_rating: categoryRatingValue || "",
    plp_review_count: categoryTotalReview,
    total_products: total,
    is_banner_available: !!categoryBanner, // Need to check with Karan for response.
    is_pill_available:
      Array.isArray(JSON.parse(capsulesData || "{}")?.data) && JSON.parse(capsulesData || "{}").data.length > 0,
    is_microsite: false,
    is_filter_available: !!facetsList?.length,
    default_sort: sortLabel,
  };

  useListingOmnitureSetup(breadcrumbs, searchInstead);

  useEffect(() => {
    dispatch(setIsMobileSearchVisibleAction(false));
    /**
     * set fnp page type for product listing page
     */
    setListingPageType(categoryId);

    dispatch(
      requestToAddListingDataAction({
        details: fetchFromAPI,
        filters: filters,
        sortOption: sortOption,
      }),
    );

    // Initialize analytic tools
    init();
    if (!isEmpty(data)) {
      const properties = setGA4ViewItemList(
        data,
        lastVisitedPincode,
        currency,
        isUserLoggedIn,
        categoryId,
        productCategoryName,
        isCapsuleVisible,
        sortOption,
        filters,
        catalogId,
        selectedCountry?.[catalogId.toUpperCase()]?.city,
      );
      trackEvent(GA4_EVENTS.VIEW_ITEM_LIST, { ...properties }, ["ga4"]);
      setTimeout(() => {
        trackEvent(plpCleverTapEvents.PAGE_VIEWED, { ...commonPageData, ...pageViewedData }, ["clevertap"]);
      }, 2000);
    }

    /**
     *
     * Handles the "CT_web_native_display" event and sets the appropriate state
     * based on the delivery topic.
     *
     * @param {object} event .
     */
    const handleEvent = (event) => {
      if (event.detail.kv.topic === plpBannerConstants.PLP_BANNER) {
        setPlpBannerData(event.detail.kv);
      }
    };
    document.addEventListener("CT_web_native_display", handleEvent);

    return () => {
      document.removeEventListener("CT_web_native_display", handleEvent);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      if (!localProductTiles?.length && isLoaded) {
        hideElementsForPLP();
      } else {
        showElementsForPLP();
      }
    }
  }, [localProductTiles?.length]);

  useEffect(() => {
    dispatch(requestToFetchDeliveryDateAction(deliveryDatesData));
    dispatch(setIsMobile(isMobile));
    dispatch(setCatalogId(catalogId));
  }, [isMobile, deliveryDatesData]);
  /**
   * Fetch more data in infinite scroll.
   * @param {*} page
   */
  const fetchMoreData = (page) => {
    page = page + 1;
    if (page < totalPages) {
      dispatch(setIsLoaderVisible(true));

      const commonPayload = {
        page,
        size: isMobile ? plpPageConstants.MOBILE_PRODUCT_PER_PAGE : plpPageConstants.DESKTOP_PRODUCT_PER_PAGE,
        geoId: catalogId || COUNTRIES?.INDIA,
        pincode: catalogId === COUNTRIES.INDIA ? pincode : "",
        sortOption: encodeURIComponent(appliedSort),
        isLoadMore: true,
      };

      const payload =
        categoryId === plpPageConstants?.SEARCH
          ? {
              ...commonPayload,
              pageType: plpPageConstants?.SEARCH,
            }
          : {
              ...commonPayload,
              categoryUrl: `fnp.com/${categoryId}`,
              filters: encodeURIComponent(appliedFilters),
            };
      dispatch(requestToFetchProductListingAction(payload));
      trackEvent(plpCleverTapEvents.PAGE_SCROLLED, { ...commonPageData, page_number: page }, ["clevertap"]);
    }
  };

  const throtlleNextLoad = throttle((page) => {
    if (isLoaded) fetchMoreData(page);
  }, 400);

  /**
   * This method provides a scroll behavior on click of the global Review Component
   * which in scrolls down to the What customers
   * are saying component
   */
  const onScrollToReviews = () => {
    scrollToSection("reviews-section", 148);
  };

  /** */
  const productClick = (index, product) => {
    const properties = setGA4SelectedProduct(
      index,
      product,
      pincode,
      currency,
      isUserLoggedIn,
      categoryId,
      productCategoryName,
      isCapsuleVisible,
      sortOption,
      filters,
      catalogId,
      selectedCountry?.[catalogId.toUpperCase()]?.city,
    );
    trackEvent(GA4_EVENTS.SELECT_ITEM, { ...properties }, ["ga4"]);
  };

  return (
    <div itemScope itemType="//schema.org/Service" id="mainContent">
      <OneTapLogin />
      <div className={`w-full px-16 sm:px-40 ${!localProductTiles.length && isMobile ? "h-[calc(100vh-70%)]" : ""}`}>
        <meta itemProp="serviceType" content="Gifting" />
        <div itemProp="offers" itemScope itemType="//schema.org/AggregateOffer" className="h-full">
          <div itemScope itemType="//schema.org/Product" className="h-full">
            <link
              itemProp="image"
              content={`${process.env.NEXT_PUBLIC_IMAGE_DOMAIN}images/cat/l/${categoryId
                .split("/")
                .map((o) => o.split("-").join(""))
                .join("-")}.jpg`}
            />
            <meta itemProp="description" content={shortDescription?.replace(Regex.htmlRegularExpression, "") || ""} />
            {!isMobile && isShowBreadCrumbs && <Breadcrumb items={breadcrumbs} catalogId={catalogId} />}
            <div className={`flex gap-16 py-16 md:pt-0 ${!localProductTiles.length ? "h-full" : ""}`}>
              {facetsList && !isMobile && (
                <div className="w-1/4 pb-16">
                  <ProductFilter facets={facetsList} categoryId={categoryId} />
                </div>
              )}
              <div className={`${!isMobile && facetsList ? "w-3/4" : "w-full"}`}>
                {plpBanners && isJsonString(plpBanners) && <CarouselContainer bannerList={JSON.parse(plpBanners)} />}
                {!isMobile && localProductTiles.length ? (
                  <HeaderPlpDesktop
                    facetFilter={facetsList}
                    sortData={sortOptions}
                    bestRating={categoryRatingValue}
                    productCategoryName={
                      noResultsFound && searchInstead?.length > 0
                        ? plpPageConstants?.SEARCH_INSTEAD
                        : productCategoryName || searchQuery
                    }
                    totalProducts={total}
                    productList={fetchData?.length || 0}
                    reviewCount={reviewResponse?.total}
                    onScrollToReviews={onScrollToReviews}
                    categoryId={categoryId}
                  />
                ) : (
                  <></>
                )}
                {searchInstead?.length > 0 && noResultsFound && (
                  <SearchInstead list={searchInstead} isMobile={isMobile} />
                )}
                {!isEmpty(plpBannerData) && localProductTiles.length ? (
                  <PlpBanner isMobile={isMobile} plpBannerData={plpBannerData} />
                ) : (
                  <></>
                )}
                {((capsulesData && isJsonString(capsulesData)) || categoryBanner) && localProductTiles.length ? (
                  <QuickLinks
                    isMobile={isMobile}
                    dataList={capsulesData ? JSON.parse(capsulesData) : null}
                    fallbackHTML={categoryBanner}
                  />
                ) : (
                  <></>
                )}
                {!details?.data?.length && !isLoaded && <Loader />}
                {localProductTiles.length ? (
                  <InfiniteScroll
                    currentPage={currentPage + 1}
                    totalPages={totalPages}
                    getMoreData={() => throtlleNextLoad(currentPage)}
                    containerRef={listingRef}
                  >
                    <div id="plpProduct" className="flex flex-wrap justify-start gap-16" ref={listingRef}>
                      {localProductTiles?.map((product, index) => (
                        <React.Fragment key={`Listing-Card-${index}`}>
                          {product.productId ? (
                            <div
                              className={
                                !isMobile
                                  ? facetsList
                                    ? "w-[calc(32.8%-8px)]"
                                    : "w-[calc(24.5%-8px)]"
                                  : "w-[calc(50%-8px)]"
                              }
                              key={`Card-${product?.productId}_${index}`}
                              onClick={() => productClick(index, product)}
                            >
                              <ProductCard
                                isMobile={isMobile}
                                image={product?.productImage}
                                title={product?.productName}
                                price={product?.price}
                                imgHeight={500}
                                imgWidth={500}
                                isPriority={index < 4 ? true : false}
                                review={product?.rating}
                                productId={product?.productId}
                                productUrl={product?.productUrl}
                                imageCount={product?.imageCount}
                                urlIdentifier={product?.urlIdentifier}
                                category={product?.fnpProductType}
                                productAttributes={productsAttrTagResponse?.[product?.["productAttributes"]?.[0]]}
                                earliestDate={deliveryDates?.[product?.productId]}
                                isSmallReviewTag={isMobile}
                              />
                            </div>
                          ) : (
                            <div
                              className={
                                !isMobile
                                  ? facetsList
                                    ? "relative w-[calc(32.8%-8px)]"
                                    : "relative w-[calc(24.5%-8px)]"
                                  : "relative w-[calc(50%-8px)]"
                              }
                              key={`Banner-${index}`}
                            >
                              <PLPBannerTile tileData={product} isPriority={index < 4 ? true : false} />
                            </div>
                          )}
                          {index ===
                            (isMobile
                              ? (localProductTiles.length < plpPageConstants.MOBILE_PRODUCT_PER_PAGE
                                  ? localProductTiles.length
                                  : plpPageConstants.MOBILE_PRODUCT_PER_PAGE) - 1
                              : (localProductTiles.length < plpPageConstants.DESKTOP_PRODUCT_PER_PAGE
                                  ? localProductTiles.length
                                  : plpPageConstants.DESKTOP_PRODUCT_PER_PAGE) - 1) &&
                            !isEmpty(reviewResponse?.data) && (
                              <CustomerReviewsCarousel
                                key={`Review-${product?.productId}_${index}`}
                                reviews={reviewResponse?.data}
                                title={TESTIMONIAL_TITLE}
                                totalReviews={reviewResponse?.total}
                                pageType={typeof window !== "undefined" ? window.fnpPageType : ""}
                                categoryId={categoryId}
                                isMobile={isMobile}
                                mobileCarouselParentStyle=""
                                viewAllStyle="pr-0 md:pr-16"
                              />
                            )}
                        </React.Fragment>
                      ))}
                    </div>
                  </InfiniteScroll>
                ) : isLoaded && !localProductTiles.length ? (
                  <ProductNotFoundWrapper />
                ) : (
                  <Loader isBackDrop={false} />
                )}
              </div>
            </div>
            {isMobile && isShowBreadCrumbs && localProductTiles.length ? (
              <Breadcrumb items={breadcrumbs} catalogId={catalogId} />
            ) : null}
          </div>
        </div>
      </div>
      {(facetsList || sortOptions) && isMobile && localProductTiles.length ? (
        <div
          className={`border-grey-25 fixed bottom-0 flex w-full border-y-1 bg-white-900 ${isShowMobileLocationLockDrawer || isShowMobileLocationLock || isCartVisible || isCurrencyDrawerVisible || isMobileSearchDrawerVisible ? "z-40" : "z-50"}`}
        >
          <FilterSort facetFilter={facetsList} sortData={sortOptions} categoryId={categoryId} />
        </div>
      ) : null}
      <CommonConditionalComponents isMobile={isMobile} />
    </div>
  );
};

ProductListingPage.propTypes = {
  fetchFromAPI: PropTypes.object.isRequired,
  reviewResponse: PropTypes.object.isRequired,
  deliveryDatesData: PropTypes.object,
  productsAttrTagResponse: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  categoryBanner: PropTypes.string,
  plpBanners: PropTypes.string,
  plpPromotionalBanners: PropTypes.string,
  capsulesData: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  categoryId: PropTypes.string.isRequired,
  shortDescription: PropTypes.string,
  catalogId: PropTypes.string.isRequired,
  isShowBreadCrumbs: PropTypes.bool,
  searchQuery: PropTypes.string,
  searchInstead: PropTypes.array,
};
