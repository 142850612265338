import PropTypes from "prop-types";
import Typography from "../../../atoms/typography";
import LocationLock from "..";
import CustomImage from "../../../atoms/customImage";
import { LOCATION_LOCK_HEADING, LOCATION_LOCK_SUB_HEADING } from "../../../../constants/common/locationFieldConstants";

/**
 * LocationLockFields component
 *
 * This component renders a full-screen modal for mobile devices to display and manage the location lock functionality.
 * It provides a header with a back button and includes location-related content.
 *
 * @component
 * @returns {JSX.Element} The rendered mobile location lock component.
 */
const LocationLockFields = ({
  fullProductDetails,
  isMandatoryLocation,
  isAutoForMobile,
  messageText,
  parentClass = "",
  setMandatoryError,
  isInternationalMobilePDP,
}) => {
  return (
    <div id="locationLockWrapper">
      <div
        className={`relative flex h-full w-full flex-col flex-wrap rounded-[16px] bg-white-900 px-16 py-20 md:h-max md:max-w-[440px] md:rounded-2xl ${parentClass}`}
        id="locationLock"
        data-testid="locationLock"
      >
        <div className="absolute right-2 top-2 hidden md:block" />
        <div className="flex w-full">
          <div className="pr-8">
            <CustomImage src="/icons/location-Icon.svg" alt="" sizes="10vw" width={24} height={24} isPriority={false} />
          </div>
          <Typography variant="p" Tag="p" classes="font-600 text-16 text-fnp-500">
            {LOCATION_LOCK_HEADING}
          </Typography>
        </div>
        <div className="w-full py-8">
          <Typography variant="p" Tag="p" classes="text-12 text-grey-500">
            {LOCATION_LOCK_SUB_HEADING}
          </Typography>
        </div>
        <LocationLock
          fullProductDetails={fullProductDetails}
          isLocationError={isMandatoryLocation}
          messageText={messageText}
          isDisable={isAutoForMobile}
          setMandatoryError={setMandatoryError}
          isInternationalMobilePDP={isInternationalMobilePDP}
        />
      </div>
    </div>
  );
};
LocationLockFields.propTypes = {
  fullProductDetails: PropTypes.object,
  isMandatoryLocation: PropTypes.bool,
  isAutoForMobile: PropTypes.bool,
  messageText: PropTypes.string,
  parentClass: PropTypes.string,
  setMandatoryError: PropTypes.func,
  isInternationalMobilePDP: PropTypes.bool,
};

export default LocationLockFields;
