import { takeEvery, put, call } from "redux-saga/effects";
import {
  requestToFetchFnpCashBalance,
  requestToFetchFnpLabel,
  requestToFetchUsableBalanceTnc,
  setFnpCashBalance,
  setFnpCashLabel,
  setFnpCashUsableBalanceTnc,
} from "../../slices/fnpCash";
import { getFnpCashBalance, getFnpCashLabelInfo, getUsableBalanceTnc } from "../../../api/fnpCash";

/**
 * Saga to set the fnp cash balance and usable balance.
 *
 * @generator
 * @function fetchFnpCashBalance
 * @yields {object} Redux action to set fnp cash balance or an error action.
 */
function* fetchFnpCashBalance() {
  try {
    const response = yield call(getFnpCashBalance);
    if (response?.isSuccess) {
      yield put(setFnpCashBalance(response));
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Saga to set the fnp cash label text.
 *
 * @generator
 * @function
 * @yields {object} Redux action to set fnp cash label or an error action.
 */
function* fetchFnpCashLabelInfo() {
  try {
    const response = yield call(getFnpCashLabelInfo);
    let parsedValue = {};
    try {
      // Only try parsing if response.value is a valid JSON string
      parsedValue = typeof response?.value === "string" ? JSON?.parse(response?.value) : {};
    } catch (parseError) {
      console.log("Failed to parse response:", parseError);
    }

    // Dispatch the parsed value, or an empty object if parsing failed
    yield put(setFnpCashLabel(parsedValue));
  } catch (error) {
    // Log the error and handle the failure gracefully
    console.log("API call failed:", error);
  }
}

/**
 * Saga to fetch fnp cash usable balance terms and conditions.
 *
 * @generator
 * @function fetchFnpCashUsableBalanceTnc
 * @yields {object} Redux action with TnC data or an error action.
 */
export function* fetchFnpCashUsableBalanceTnc() {
  try {
    const response = yield call(getUsableBalanceTnc);
    yield put(setFnpCashUsableBalanceTnc(response?.data));
  } catch (error) {
    console.log(error);
  }
}

/**
 * Root saga that watches for fnp cash-related actions and triggers corresponding sagas.
 *
 * @generator
 * @function FnpCashSaga
 */
function* watchFnpCashSaga() {
  yield takeEvery(requestToFetchFnpCashBalance.type, fetchFnpCashBalance);
  yield takeEvery(requestToFetchFnpLabel.type, fetchFnpCashLabelInfo);
  yield takeEvery(requestToFetchUsableBalanceTnc.type, fetchFnpCashUsableBalanceTnc);
}

export default watchFnpCashSaga;
