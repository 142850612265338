import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pincodeList: [],
};

export const IntegratedGoogleSuggestionSlice = createSlice({
  name: "IntegratedGoogleSuggestionSlice",
  initialState,
  reducers: {
    setPincodeList: (state, action) => {
      return {
        ...state,
        pincodeList: action.payload,
      };
    },
  },
});

export default IntegratedGoogleSuggestionSlice.reducer;
export const { setPincodeList } = IntegratedGoogleSuggestionSlice.actions;
