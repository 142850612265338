"use client";
import PropType from "prop-types";
import React from "react";
import ProductCard from "../productCard";
import Slider from "react-slick";
import { SlickNextArrow, SlickPrevArrow } from "../../molecules/carouselHelpers";
import { shallowEqual, useSelector } from "react-redux";
import { setGA4PdpPageGaEvent } from "../../../analytics/gaEvents";
import useAnalytics from "../../../analytics/useAnalytics";
import { GA4_EVENTS } from "../../../analytics/gaConstant";
import { PageTypes } from "../../../utils/fnpPageType";
/**
 *
 * @param {*} param0
 * @returns
 */
const Widget = ({
  widgetTitle,
  productsList,
  isShowEarliestDate = true,
  widgetRef,
  isMobile,
  type = "",
  position = "1",
  skuCode,
  isArrowVisibileDesktop = true,
  isHideSavedPercent = false,
  pageType,
}) => {
  const { trackEvent } = useAnalytics();

  const {
    selectedCurrencyFromState,
    clientCurrentClickedItem,
    fullProductDetails,
    clientVariantLabel,
    pincode,
    location,
    isUserLoggedIn,
  } = useSelector(
    (state) => ({
      selectedCurrencyFromState: state?.currencyList?.userSelectedCurrency,
      clientCurrentClickedItem: state?.pdp?.clientCurrentClickedItem,
      fullProductDetails: state?.pdp?.fullProductDetails,
      clientVariantLabel: state?.pdp?.clientVariantLabel,
      pincode: state?.locationLock?.selectedDomesticLocation?.pincode,
      location: state?.locationLock?.selectedIntLocation?.location,
      isUserLoggedIn: state?.fus?.loginDetail?.loggedin,
    }),
    shallowEqual,
  );

  /**
   * Handles the widget click event and sends a GA4 event with detailed product information.
   *
   * @function
   * @returns {void}
   */
  const onWidgetClicked = () => {
    const properties = setGA4PdpPageGaEvent(
      clientCurrentClickedItem,
      pincode,
      isUserLoggedIn,
      selectedCurrencyFromState?.currency?.id,
      fullProductDetails,
      clientVariantLabel,
      clientCurrentClickedItem?.catalogId,
      location,
      GA4_EVENTS?.WIDGET_VIEWED,
      type,
      position,
    );
    trackEvent(GA4_EVENTS?.WIDGET_VIEWED, { ...properties }, ["ga4"]);
  };

  var desktopSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: isArrowVisibileDesktop,
    autoplay: false,
    centerMode: false,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
  };

  const listItemWidth = 100 / 2.3;

  return (
    <section
      className="productCarousel w-full"
      ref={widgetRef}
      role="region"
      aria-label="Product carousel"
      id={widgetTitle?.replace(/\s+/g, "")}
    >
      {widgetTitle && productsList?.length > 0 && (
        <div className="pl-16 text-left">
          <p className="mb-8 overflow-hidden text-ellipsis pl-2 text-left text-16 font-600 capitalize md:mb-16 md:text-24">
            {widgetTitle}
          </p>
        </div>
      )}
      {isMobile ? (
        <ul className="scrollbar-hide flex w-full gap-16 overflow-x-auto overflow-y-hidden px-16">
          {productsList?.map((product, i) => (
            <li
              className="flex-none"
              key={i}
              style={{ width: `${listItemWidth}%` }}
              // eslint-disable-next-line react/jsx-handler-names
              onClick={pageType === PageTypes?.PRODUCT ? onWidgetClicked : undefined}
            >
              <ProductCard
                image={product?.productImage}
                productUrl={product?.url || product?.productUrl}
                title={product?.productName}
                price={product?.price}
                productId={product?.productId}
                imgHeight={428}
                imgWidth={428}
                urlIdentifier={product?.urlIdentifier}
                earliestDate={isShowEarliestDate ? product?.earliestDate : ""}
                review={product?.review}
                isMobile={isMobile}
                imageCount={Number(product?.imageCount)}
                productWidget={product}
                productCount={i}
                skuCode={skuCode}
                isHideSavedPercent={isHideSavedPercent}
                widgetTitle={widgetTitle}
              />
            </li>
          ))}
        </ul>
      ) : (
        <Slider {...desktopSettings} className="leftAlignSlick relative w-full">
          {productsList?.map((product, i) => (
            // eslint-disable-next-line react/jsx-handler-names
            <div className="px-12" key={i} onClick={pageType === PageTypes?.PRODUCT ? onWidgetClicked : undefined}>
              <ProductCard
                image={product?.productImage}
                productUrl={product?.url || product?.productUrl}
                title={product?.productName}
                price={product?.price}
                productId={product?.productId}
                imgHeight={428}
                imgWidth={428}
                urlIdentifier={product?.urlIdentifier}
                earliestDate={isShowEarliestDate ? product?.earliestDate : ""}
                review={product?.review}
                isMobile={isMobile}
                imageCount={Number(product?.imageCount)}
                productWidget={product}
                productCount={i}
                skuCode={skuCode}
                isHideSavedPercent={isHideSavedPercent}
              />
            </div>
          ))}
        </Slider>
      )}
    </section>
  );
};

Widget.propTypes = {
  widgetTitle: PropType.string,
  productsList: PropType.array,
  isShowEarliestDate: PropType.bool,
  widgetRef: PropType.any,
  isMobile: PropType.bool,
  isArrowVisibileDesktop: PropType.bool,
  type: PropType.string,
  position: PropType.number,
  skuCode: PropType.object,
  isHideSavedPercent: PropType.bool,
  pageType: PropType.string.isRequired,
};

export default React.memo(Widget);
