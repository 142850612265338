import PropTypes from "prop-types";
import Typography from "../../../components/atoms/typography";

/**
 * NumOneBrandSection displays a list of gift categories for sending abroad in a carousel.
 *
 * @component
 * @param {Object} props.data - The data for the number one brand section.
 * @param {boolean} props.isMobile - isMobile boolean data
 * @returns {JSX.Element}
 */
const NumOneBrandSection = ({ data, isMobile }) => (
  <div>
    <div className="mt-6 flex flex-col px-16 md:px-40">
      <Typography variant="h1" Tag="h1" classes="md:text-20 text-16 text-black-100 font-600">
        {data.heading}
      </Typography>
      {!isMobile && (
        <Typography variant="subheading1" Tag="p" classes="text-16 font-500 text-grey-500">
          {data.subHeading}
        </Typography>
      )}
    </div>
  </div>
);

NumOneBrandSection.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default NumOneBrandSection;
