import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadProgressStatus: {
    percentComplete: "",
  },
  uploadFinalStatus: false,
  multiImageConfig: [],
  multiImageText: [],
  imageUploadError: false,
  userPersonalization: [],
  successfullyAddedToast: null,
  customizedImageContent: null,
  presignedUrl: "",
  clearPersonalizedData: false,
};

const PersonalizationSlice = createSlice({
  name: "PersonalisationSlice",
  initialState,
  reducers: {
    uploadImageForPersonalization: () => {},
    uploadMultiImageForPersonalization: () => {},
    setImageUploadProgress: (state, action) => {
      return {
        ...state,
        uploadProgressStatus: action.payload,
      };
    },
    setUploadFinalStatus: (state) => {
      return {
        ...state,
        uploadFinalStatus: true,
      };
    },
    clearPersonlizedImageStore: (state) => {
      return {
        ...state,
        uploadProgressStatus: {
          percentComplete: "",
        },
        uploadFinalStatus: false,
        imageUploadError: false,
      };
    },
    setMultiImageConfig: (state, action) => {
      return {
        ...state,
        multiImageConfig: action.payload,
      };
    },
    setUserPersonalization: (state, action) => {
      return {
        ...state,
        userPersonalization: action.payload,
      };
    },
    setUploadErrorMessage: (state) => {
      return {
        ...state,
        imageUploadError: true,
      };
    },
    setSuccessfullyAddedToast: (state, action) => {
      return {
        ...state,
        successfullyAddedToast: action.payload,
      };
    },
    setMultiImageText: (state, action) => {
      return {
        ...state,
        multiImageText: action.payload,
      };
    },
    setPresignedUrl: (state, action) => {
      return {
        ...state,
        presignedUrl: action.payload,
      };
    },
    requestToCustomizableContent: (state) => {
      return {
        ...state,
      };
    },
    setCustomizedImageContent: (state, action) => {
      return {
        ...state,
        customizedImageContent: action.payload,
      };
    },
    resetPersonalizationValues: (state) => {
      state.uploadProgressStatus = {
        percentComplete: "",
      };
      state.uploadFinalStatus = false;
      state.multiImageConfig = [];
      state.multiImageText = [];
      state.imageUploadError = false;
      state.userPersonalization = [];
      state.successfullyAddedToast = null;
      state.customizedImageContent = null;
      state.presignedUrl = "";
      state.clearPersonalizedData = true;
    },
    toggleClearPersonlizedData: (state) => {
      return {
        ...state,
        clearPersonalizedData: false,
      };
    },
  },
});

export default PersonalizationSlice.reducer;
export const {
  uploadImageForPersonalization,
  setImageUploadProgress,
  setUploadFinalStatus,
  clearPersonlizedImageStore,
  setMultiImageConfig,
  uploadMultiImageForPersonalization,
  setUploadErrorMessage,
  setUserPersonalization,
  setMultiImageText,
  setSuccessfullyAddedToast,
  requestToCustomizableContent,
  setCustomizedImageContent,
  setPresignedUrl,
  resetPersonalizationValues,
  toggleClearPersonlizedData,
} = PersonalizationSlice.actions;
