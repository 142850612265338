import React from "react";
import PropTypes from "prop-types";
import EarliestDeliveryDate from "../earliestDeliveryDate";
import { useSelector } from "react-redux";
import { updateCurrency } from "../../../utils/common";
/**
 *
 * @param {*} param0
 * @returns
 */
const ProductInfo = ({ title, price, earliestDate, isHideSavedPercent = false }) => {
  const selectedCurrency = useSelector((state) => state?.currencyList?.userSelectedCurrency);

  return (
    <div className="pt-8 sm:p-12">
      <meta
        itemProp="availability"
        content={`https://schema.org/${earliestDate !== "_NA_" ? `InStock` : `OutofStock`}`}
      />
      {title && (
        <div className={`mb-10 truncate text-12 font-500 leading-0 text-fnp-500 md:text-14`} itemProp="name">
          {title}
        </div>
      )}
      {price && (
        <>
          <meta itemProp="priceCurrency" content={selectedCurrency?.currency?.id} />
          <div className="priceDetails mb-12 flex items-center gap-4 whitespace-nowrap" itemProp="price">
            <span className="mr-2 text-14 font-600 md:text-18" itemProp="price">
              {updateCurrency(selectedCurrency, price?.price ? price?.price : price?.listPrice)}
            </span>
            {price?.percentSaved > 0 && (
              <>
                <span className="ml-4 overflow-hidden text-ellipsis whitespace-nowrap text-12 font-400 text-gray-400 line-through md:text-14">
                  {updateCurrency(selectedCurrency, price?.mrp ? price?.mrp : price?.listPrice)}
                </span>
                {!isHideSavedPercent && (
                  <span className="ml-4 overflow-hidden text-ellipsis whitespace-nowrap text-12 font-500 text-offer-900 md:text-14">
                    {Math.round(price?.percentSaved)}% OFF
                  </span>
                )}
              </>
            )}
          </div>
        </>
      )}
      <EarliestDeliveryDate earliestDate={earliestDate} />
    </div>
  );
};

ProductInfo.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.shape({
    price: PropTypes.number,
    listPrice: PropTypes.number,
    mrp: PropTypes.number,
    percentSaved: PropTypes.number,
  }).isRequired,
  productId: PropTypes.string.isRequired,
  earliestDate: PropTypes.string,
  isHideSavedPercent: PropTypes.bool,
};

export default ProductInfo;
