// import clevertap from "clevertap-web-sdk";

let clevertap;

/**
 *
 * @returns
 */
const useCleverTap = () => {
  /**
   *
   */
  const init = () => {
    try {
      if (typeof window !== "undefined") {
        clevertap = require("clevertap-web-sdk"); // eslint-disable-line global-require
        clevertap.init(process.env.NEXT_PUBLIC_CLEVER_TAP_ACCOUNT_ID, process.env.NEXT_PUBLIC_CLEVER_TAP_REGION);
        clevertap.privacy.push({ useIP: true });
        clevertap.notifications.push({
          titleText: "Stay Updated!",
          bodyText: "Allow notifications to receive the latest offers and updates.",
          okButtonText: "Allow",
          rejectButtonText: "No, Thanks",
          askAgainTimeInSeconds: 86400,
          okButtonColor: "#737530",
          serviceWorkerPath: "/clevertap_sw.js",
        });
      }
      // clevertap.setDebugLevel(3); // Debug level can be adjusted based on needs
      console.log("CleverTap Initialized");
    } catch (error) {
      console.log("Clevertap not initialized properly");
    }
  };

  /**
   *
   * @param {*} eventName
   * @param {*} properties
   */
  const trackEvent = (eventName, properties = {}) => {
    try {
      clevertap.event.push(eventName, properties);
    } catch (error) {
      console.log("Clevertap not initialized properly");
    }
  };

  /**
   *
   * @param {*} properties
   */
  const onUserLogin = (properties) => {
    try {
      clevertap.onUserLogin.push(properties);
    } catch (error) {
      console.log("Clevertap not initialized properly");
    }
  };

  return { init, trackEvent, onUserLogin };
};

export default useCleverTap;
