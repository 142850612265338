import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/navigation";
import ProductNotFound from "./productNotFound";
import { plpPageConstants } from "../../../constants/pageConstants/plpConstants";
import {
  setIsShowDesktopLocationLockAction,
  setIsShowMobileLocationLockAction,
} from "../../../store/slices/locationLock";
import { removeFiltersParamsFromURL } from "../productFilter/helper";
import { requestToFetchProductListingAction } from "../../../store/slices/plpSlice";
import { COUNTRIES } from "../../../constants/common/common";

/**
 * This is a wrapper function for Product Not found
 */
const ProductNotFoundWrapper = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { isServiceable, pincode, selectedIntLocation, appliedFilters, isMobile, catalogId } = useSelector(
    (state) => ({
      isServiceable: state?.plp?.details?.serviceable,
      pincode: state?.locationLock?.selectedDomesticLocation?.pincode,
      selectedIntLocation: state?.locationLock.selectedIntLocation,
      appliedFilters: state?.plp?.appliedFilters,
      isMobile: state?.appConfig?.isMobile,
      catalogId: state?.appConfig?.catalogId || COUNTRIES.INDIA,
    }),
    shallowEqual,
  );
  const isInternational = catalogId !== COUNTRIES.INDIA;
  const cityOrPincode = !isInternational ? pincode : selectedIntLocation?.location;

  /**
   * This function handles the reset filter
   */
  const onResetFiltersHandler = () => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const newUrl = removeFiltersParamsFromURL(urlParams, "fq");
    window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);
    dispatch(
      requestToFetchProductListingAction({
        page: plpPageConstants.INITIAL_PAGE,
        size: isMobile ? plpPageConstants.MOBILE_PRODUCT_PER_PAGE : plpPageConstants.DESKTOP_PRODUCT_PER_PAGE,
        geoId: catalogId || COUNTRIES?.INDIA,
        categoryUrl: `fnp.com${window.location.pathname}`,
        pincode: !isInternational ? pincode : selectedIntLocation?.pincode,
        filters: [],
        sortOption: "",
      }),
    );
  };

  /**
   * This function handles the button click of change pin code
   */
  const onShowLocationLock = () => {
    if (isMobile) {
      dispatch(setIsShowMobileLocationLockAction(true));
    } else {
      dispatch(setIsShowDesktopLocationLockAction(true));
    }
  };

  /**
   * Function redirects to search page
   */
  const onRedirectToSearchPage = () => {
    const currentCatalogId = catalogId ?? COUNTRIES.INDIA;
    if (currentCatalogId !== COUNTRIES.INDIA) {
      router.push(`/search?FNP_CURRENT_CATALOG_ID=${currentCatalogId}&pincode=${cityOrPincode}`);
    } else if (document.referrer.includes("/gift/")) {
      router.push(`/all-gifts-lp?promo=vag_pdp_sameplp_allgifts_${cityOrPincode}`);
    } else {
      router.push(`/all-gifts-lp?promo=vag_plp_allgifts_${cityOrPincode}`);
    }
  };

  /**
   * Function redirects to gifts page
   */
  const onRedirectToGiftsPage = () => {
    router.push(catalogId === COUNTRIES.INDIA ? `/all-gifts-lp?promo=vag_plp_allgifts` : `/${catalogId}/gifts`);
  };

  /**
   * this function renders the PNA for pincode selection
   *
   * @returns {React.ReactElement} return PNA jsx
   */
  const renderPinCodePNA = () => {
    if (isServiceable)
      return (
        <ProductNotFound
          showText={plpPageConstants.NO_PRODUCT_FOUND}
          subText={plpPageConstants.NO_PRODUCT_IN_THIS_CATEGORY_FOR_YOUR_LOCATION}
          buttonText={`${plpPageConstants.VIEW_AVAILABLE_GIFT_FOR} ${cityOrPincode}`}
          onHandleSubmit={onRedirectToSearchPage}
        />
      );
    return (
      <ProductNotFound
        showText={plpPageConstants.NOT_SERVICEABLE}
        subText={plpPageConstants.WE_ARE_SORRY}
        buttonText={plpPageConstants.CHANGE_DELIVERY_PINCODE}
        onHandleSubmit={onShowLocationLock}
      />
    );
  };

  /**
   * this function renders no product found
   *
   * @returns {React.ReactElement} return no product found component
   */
  const renderPNA = () => {
    if (!appliedFilters.length && !cityOrPincode) {
      return (
        <ProductNotFound
          showText={plpPageConstants.NO_PRODUCT_FOUND}
          subText={plpPageConstants.NOT_SERVICABLE_CATEGORY}
          buttonText={plpPageConstants.VIEW_AVAILABLE_GIFT}
          onHandleSubmit={onRedirectToGiftsPage}
        />
      );
    }
    if (!appliedFilters.length && cityOrPincode) {
      return renderPinCodePNA();
    }
    if (appliedFilters.length && !cityOrPincode) {
      return (
        <ProductNotFound
          showText={plpPageConstants.NO_PRODUCT_FOUND}
          subText={plpPageConstants.WE_COULD_NOT_FIND_ANY_PRODUCTS}
          buttonText={plpPageConstants.RESET_FILTERS}
          onHandleSubmit={onResetFiltersHandler}
        />
      );
    }
    if (appliedFilters.length && cityOrPincode) {
      if (!isServiceable) {
        return (
          <ProductNotFound
            showText={plpPageConstants.NOT_SERVICEABLE}
            subText={plpPageConstants.WE_ARE_SORRY}
            buttonText={plpPageConstants.CHANGE_DELIVERY_PINCODE}
            onHandleSubmit={onShowLocationLock}
          />
        );
      }
      return (
        <ProductNotFound
          showText={plpPageConstants.NO_PRODUCT_FOUND}
          subText={plpPageConstants.WE_COULD_NOT_FIND_ANY_PRODUCTS}
          buttonText={plpPageConstants.RESET_FILTERS}
          onHandleSubmit={onResetFiltersHandler}
        />
      );
    }
    return <></>;
  };

  return renderPNA();
};

export default ProductNotFoundWrapper;
