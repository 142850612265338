/* eslint-disable react/jsx-handler-names */
import PropTypes from "prop-types";
import Slider from "react-slick";
import CustomImage from "../../../components/atoms/customImage";
import { HOME_BANNERS_OPTIONS } from "../../../constants/common/splideOptions";
import useAnalytics from "../../../analytics/useAnalytics";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";
import { useRef } from "react";
import { appendDots, customPaging } from "../../../components/molecules/carouselHelpers";

/**
 * HeroBannerSlider displays a carousel of hero banners, each containing an image and optionally triggering analytics events.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Array} props.bannersList - An array of banners to be displayed
 * @param {Object} [props.dimensions={ width: 586, height: 440 }] - The dimensions of the banner images
 * @returns {JSX.Element}
 */
const HeroBannerSlider = ({ bannersList, dimensions = { width: 586, height: 440 }, isMobile }) => {
  const { trackEvent } = useAnalytics();

  const activeSlide = useRef(0);

  var settings = {
    ...HOME_BANNERS_OPTIONS,
    beforeChange: (current, next) => (activeSlide.current = next),
    appendDots: (dots) => appendDots(dots, activeSlide.current),
    customPaging: (i) => customPaging(activeSlide.current, bannersList)(i),
  };

  const pageBannerData = {
    page_name: cleverTapCommonWords.HOME,
    page_type: cleverTapCommonWords.HOME,
  };

  return (
    <Slider {...settings} className="relative w-full pb-36 md:pb-0">
      {bannersList.map((banner, i) => (
        <a
          href={banner.category_link}
          key={i}
          className={`px-16`}
          onClick={() => {
            trackEvent(
              cleverTapCommonEvents.bannerClicked,
              {
                ...pageBannerData,
                banner_id: i,
                banner_name: banner?.altTag,
              },
              ["clevertap"],
            );
          }}
        >
          <CustomImage
            src={banner.banner_image_url}
            alt={banner.altTag || "Gifts on FNP"}
            width={dimensions?.width}
            height={dimensions?.height}
            customStyle={{ width: "100%", height: "100%" }}
            borderRadius={isMobile ? 20 : 8}
            isPriority={i === 0 ? true : false}
          />
        </a>
      ))}
    </Slider>
  );
};
HeroBannerSlider.propTypes = {
  bannersList: PropTypes.array.isRequired,
  dimensions: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};
export default HeroBannerSlider;
