import { setPincodeList } from "../../store/slices/common/integratedGoogleSuggestion";
import { fetchContentFromCMSOnClient } from "../common";
import { AVAILABLE_PINCODES, fetchCityPincodeBasedOnLocation, fetchPincodeJson, PINCODE_MAPPING } from "./constants";
import { InterceptorClient } from "../../interceptor/interceptorClient";

const devBaseUrl = process.env.NEXT_PUBLIC_BASE_URL;

/**
 * This function fetches pincode list by using the first two digit of pincode
 *
 * @param {*} pincode  it refers to first two digit of pincode.
 * @param {Function} dispatch dispatcher
 * @param {Array} availablePincodes get all available pincode
 * @returns {Array} rel alternate data on success and empty on exception.
 */
export async function getPincodes(pincode, dispatch, availablePincodes) {
  if (availablePincodes?.length && availablePincodes.indexOf(pincode) > -1) {
    try {
      const response = await InterceptorClient.getFNTSInstance(devBaseUrl).get(fetchPincodeJson(pincode), false);
      const sanitizedPinCodes = response.map((row) => {
        return {
          pincode: Object.keys(row)[0],
          location: row[Object.keys(row)[0]],
        };
      });

      const uniquePinCodes = [];
      sanitizedPinCodes.forEach((value) => {
        if (!uniquePinCodes.find((o) => o.pincode === value.pincode)) {
          uniquePinCodes.push(value);
        }
      });

      dispatch(setPincodeList(uniquePinCodes));
      return response;
    } catch (ex) {
      //   error.config.url = `assets/js/pincodes/${pincode}.json`;
      //   error.response.status = `An exception occurred while fetching pincode JSON => ${ex}`;
      //   errorLogger(error, SHOW_REQUESTS_LOGS);
      return [];
    }
  } else {
    return [];
  }
}

/**
 * generate search  term suggestions
 *
 * @returns {Array} return the available pincodes.
 */
export async function getAvailablePincodes() {
  try {
    const response = await fetchContentFromCMSOnClient(AVAILABLE_PINCODES);
    return response;
  } catch (ex) {
    throw new Error({ ex, isSuccess: true });
  }
}

/**
 * Get near by pin code
 *
 * @param {Array} pinCodeList pincode list
 * @param {string} localPinCode pincode
 * @returns {Array} get near pin code list
 */
export const getPincodeSetter = (pinCodeList, localPinCode) => {
  return pinCodeList.filter((item) => Number(localPinCode) === Number(item.pincode.slice(0, localPinCode.length)));
};

/**
 * This function gets pin code for a provided location
 *
 * @param {number} latitude this is the latitude of the location selected through google map
 * @param {number} longitude this is the longitude of the location selected through google map
 * @returns {void} returns nothing
 */
export async function getCityPincodeBasedOnLocation(latitude, longitude) {
  try {
    return await InterceptorClient.getFNTSInstance(devBaseUrl).get(
      fetchCityPincodeBasedOnLocation(latitude, longitude),
      false,
    );
  } catch (e) {
    // error.config.url = "d/control/getPinCodeByPostalAddress";
    // error.response.status = `An exception occurred while calling fetching city name => ${e}`;
    // errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/**
 * This function maps the pin code as per the value
 *
 * @returns {*} .
 */
export async function getPinCode() {
  try {
    return await InterceptorClient.getFNTSInstance(devBaseUrl).get(PINCODE_MAPPING, false);
  } catch (ex) {
    // error.config.url = "control/jc?id=PINCODE_MAPPING";
    // error.response.status = `An exception occurred while fetching pincode mapping list => ${ex.code}`;
    // errorLogger(error, SHOW_REQUESTS_LOGS);
    throw new Error(ex);
  }
}
