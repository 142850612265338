import React from "react";
import PropTypes from "prop-types";
import RadioButton from "../../atoms/radioButton/index";
import RadioListContent from "../../atoms/radioListContent/index";

/**
 * Description placeholder
 * @date 13/03/2024 - 11:47:18
 *
 * @param {{ id?: string; label?: string; labelPlacement?: string; labelColor?: string; isDisabled?: boolean; radioListType?: string; listContent?: {}; isShowCountryCode?: boolean; }} param
 * @param {string} [param.id="radio"]
 * @param {string} [param.label=""]
 * @param {string} [param.labelPlacement="right"]
 * @param {string} [param.labelColor=""]
 * @param {boolean} [param.isDisabled=false]
 * @param {string} [param.radioListType=""]
 * @param {{}} [param.listContent={}]
 * @param {boolean} [param.isShowCountryCode=false]
 * @param {boolean} [param.isChecked=false]
 * @returns
 */
const RadioItemList = ({
  id = "radio",
  label = "",
  labelPlacement = "right",
  labelColor = "",
  isDisabled = false,
  radioListType = "",
  listContent = {},
  isShowCountryCode = false,
  isChecked = false,
  ...restParams
}) => {
  return (
    <label
      htmlFor={id}
      className={`flex w-full flex-col items-center justify-between p-2.5 ${!label && "border-b-1"} cursor-pointer`}
      data-testid="radio-item"
    >
      <div className={`flex w-full items-center justify-between`}>
        {radioListType !== "" && (
          <RadioListContent
            radioListType={radioListType}
            listContent={listContent}
            id={id}
            isShowCountryCode={isShowCountryCode}
            isChecked={isChecked}
          />
        )}
        <RadioButton
          id={id}
          name={listContent?.name}
          label={label}
          labelPlacement={labelPlacement}
          labelColor={labelColor}
          isDisabled={isDisabled}
          isChecked={isChecked}
          {...restParams}
        />
      </div>
    </label>
  );
};

RadioItemList.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  labelColor: PropTypes.string,
  isDisabled: PropTypes.bool,
  radioListType: PropTypes.string,
  listContent: PropTypes.object,
  isShowCountryCode: PropTypes.bool,
  isChecked: PropTypes.bool.isRequired,
};

export default RadioItemList;
