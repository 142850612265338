import useCleverTap from "./useCleverTap";
import useGA4 from "./useGA4";

/**
 *
 * @returns
 */
const useAnalytics = () => {
  const { init: initCT, trackEvent: trackEventCT, onUserLogin } = useCleverTap();
  const { init: initGA4, trackEvent: trackEventGA4 } = useGA4();

  /**
   *
   * @param {*} eventName
   * @param {*} properties
   * @param {*} tools
   */
  const init = (tools = ["clevertap", "ga4"]) => {
    if (tools.includes("clevertap")) {
      initCT();
    }
    if (tools.includes("ga4")) {
      initGA4();
    }
  };

  /**
   *
   * @param {*} eventName
   * @param {*} properties
   * @param {*} tools
   */
  const trackEvent = (eventName, properties = {}, tools = ["clevertap", "ga4"]) => {
    const updatedProperties = {
      ...properties,
      webVersion: "v1",
    };

    if (tools.includes("clevertap")) {
      trackEventCT(eventName, updatedProperties);
    }
    if (tools.includes("ga4")) {
      trackEventGA4(eventName, properties);
    }
  };

  return { init, trackEvent, onUserLogin };
};

export default useAnalytics;
