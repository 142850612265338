import homeData from "./homePageMock.json";
import plpData from "./productListingPageMock.json";
import metaData from "./metaData";
import webFeatureControl from "./web_feature_control.json";
import checkoutTNC from "./terms-and-conditions.json";
import countriesList from "./countriesList.json";
import productAttrTag from "./productAttrTag.json";
import reviews from "./reviews.json";
import savedAddresses from "./savedAddresses.json";
export const mapping = {
  "/chocolate/v1/home": homeData,
  "/columbus/v1/productList": plpData,
  "/chocolate/v1/category/": metaData,
  "/chocolate/v1/global/static/web/web_feature_control": webFeatureControl,
  "/chocolate/v1/global/static/web/terms-and-conditions": checkoutTNC,
  "/chocolate/v1/global/static/country/mobile/countrieslist": countriesList,
  "/chocolate/v1/global/static/product_attr_tag": productAttrTag,
  "/moody/v1/category/reviews": reviews,
  "/control/getSavedRecipientAddresses?viewSize=40&viewIndex=0": savedAddresses,
};
