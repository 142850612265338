import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/rootSaga";
import CartSlice from "./slices/cartSlice";
import IntegratedGoogleSuggestionSlice from "./slices/common/integratedGoogleSuggestion";
import CommonSlice from "./slices/commonSlice";
import EarliestDeliveryDatesSlice from "./slices/earliestDeliveryDatesSlice";
import LocationLockSlice from "./slices/locationLock";
import LoginSignupSlice from "./slices/loginSignupSlice";
import PDPSlice from "./slices/pdpSlice";
import PLPSlice from "./slices/plpSlice";
import ReviewSlice from "./slices/reviewSlice";
import webFeatureControls from "./slices/webFeatureControls";
import ShippingDateAndSlotSelectionSlice from "./slices/common/shippingDateAndSlotSelection";
import AppConfigSlice from "./slices/common/appConfig";
import FusSlice from "./slices/common/fus";
import AddOnSlice from "./slices/common/addOn";
import CurrencyListSlice from "./slices/common/currencyLists";
import FnpCashSlice from "./slices/fnpCash";
import PersonalizationSlice from "./slices/personalizationSlice";
import SavedAddressesSlice from "./slices/savedAddresses";

const sagaMiddleware = createSagaMiddleware();
const storeMiddleware = [sagaMiddleware];

const appReducer = combineReducers({
  cart: CartSlice,
  plp: PLPSlice,
  review: ReviewSlice,
  deliveryDate: EarliestDeliveryDatesSlice,
  loginSignUp: LoginSignupSlice,
  webFeatureControls: webFeatureControls,
  common: CommonSlice,
  pdp: PDPSlice,
  pincodeLocationSuggestion: IntegratedGoogleSuggestionSlice,
  locationLock: LocationLockSlice,
  personalization: PersonalizationSlice,
  shippingDateAndSlotSelection: ShippingDateAndSlotSelectionSlice,
  appConfig: AppConfigSlice,
  fus: FusSlice,
  addOn: AddOnSlice,
  currencyList: CurrencyListSlice,
  fnpCash: FnpCashSlice,
  savedAddress: SavedAddressesSlice,
});

/**
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
const rootReducer = (state, action) => {
  return appReducer(state, action);
};
// const persistedReducer = persistReducer(persistConfig, rootReducer);

const _store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(storeMiddleware);
  },
});
sagaMiddleware.run(rootSaga);
// export const _persistorStore = persistStore(_store);
export default _store;
