import isEmpty from "lodash.isempty";
import {
  setDeliveryDateErrorFromAddToCart,
  setPersonalizationErrorFromAddToCart,
  setPincodeErrorFromAddToCart,
} from "../../../store/slices/pdpSlice";

/**
 *
 * @param {*} string
 * @returns String with first alphabet Capital
 */
export const capitalizeFirstLetter = (string) => {
  return string?.toLowerCase().replace(/^./, (match) => match.toUpperCase());
};

/**
 * This method is to filter out flavour from cake product
 *
 */
export const generateFlavorTypeList = (variantProducts) => {
  const uniqueFlavors = [];

  variantProducts?.forEach((variant) => {
    const key = Object.keys(variant)?.[0];

    if (key) {
      const [, , flavor] = key.split("|");
      if (flavor) {
        const trimmedFlavor = flavor.trim();
        if (!uniqueFlavors.includes(trimmedFlavor)) {
          uniqueFlavors.push(trimmedFlavor);
        }
      }
    }
  });

  return uniqueFlavors;
};

/**
 *
 * @param {*} pincode
 * @param {*} deliveryDetailsData
 * @param {*} userPersonalization
 * @param {*} dispatch
 * @returns
 */
export const validateAllRequiredFieldsToAddToCart = (
  pincode,
  deliveryDetailsData,
  personalizationValidation,
  dispatch,
) => {
  let hasError = false;

  /**
   * @param {string} elementId
   */
  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  if (!pincode) {
    scrollToElement("location_lock");
    dispatch(setPincodeErrorFromAddToCart(true));
    hasError = true;
  } else if (deliveryDetailsData) {
    scrollToElement("delivery_date_slot");
    dispatch(setDeliveryDateErrorFromAddToCart(true));
    hasError = true;
  } else if (!personalizationValidation) {
    scrollToElement("personalization_section");
    dispatch(setPersonalizationErrorFromAddToCart(true));
    hasError = true;
  } else if (!hasError) {
    dispatch(setPincodeErrorFromAddToCart(false));
    dispatch(setDeliveryDateErrorFromAddToCart(false));
    dispatch(setPersonalizationErrorFromAddToCart(false));
  }

  return hasError;
};

/**
 *
 * @param {Object} item - The product object containing the product URL and product ID.
 * @param {Object} selectedCurrency - The selected currency object, or `null`/`undefined` for the default currency.
 * @param {string} productCatalogId - The ID of the product catalog.
 * @returns {string} - The redirected URL with query parameters appended.
 *
 * Constructs a URL by appending query parameters based on the product details,
 * selected currency, and product catalog ID.
 */
export const handleRedirectedURL = (item, selectedCurrency, productCatalogId) => {
  const extractCategoryId = item?.productUrl.split("/");
  const payloadObj = {
    productId: item?.productId,
    currencyUomId: isEmpty(selectedCurrency) ? "INR" : selectedCurrency?.currency?.id,
    categoryId: extractCategoryId?.[1],
    catalogId: productCatalogId,
  };

  const encoded = new URLSearchParams(payloadObj).toString();

  return `${item?.productUrl}?${encoded}`;
};

/**
 * This method is used to check the product is digital or not
 *
 * @returns {boolean}  will return true or false
 */
export const checkIsDigitalProduct = (currentItem) => {
  if (currentItem?.shippingDisplayOptions && !currentItem?.shippingDisplayOptions.showPincodeOrCity) {
    return Object.keys(currentItem?.shippingDisplayOptions?.defaultGeoDetails).length > 0;
  }
  return false;
};

/**
 * This method is used to check that product is personalized type or not
 *
 * @param {object} product
 * @returns {boolean} is express product or not .
 */
export const isPersonalizedProduct = (product) => product?.personalizedInfo?.personalizedProduct;

/**
 * Function to render a bottom border line, with visibility based on screen size.
 * The border is hidden on mobile by default and shown on desktop, or vice versa based on the desktopVisibility argument.
 *
 * @param {boolean} [desktopVisibility=false] - Determines the visibility of the border on desktop screens.
 *                                              If true, the border is hidden on desktop and shown on mobile.
 *                                              If false, the border is shown on desktop and hidden on mobile.
 * @returns {JSX.Element} A div element with a bottom border and background color.
 */
export const borderLine = (desktopVisibility = false) => (
  <div className={`border-b-3 border-lightGrey bg-grey-100 ${desktopVisibility ? "hidden md:block" : "md:hidden"}`} />
);
