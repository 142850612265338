import PropTypes from "prop-types";
import CustomImage from "../../../components/atoms/customImage";
import Typography from "../../../components/atoms/typography";
import { useState } from "react";
import Chip from "../../../components/atoms/chip";
import ButtonComponent from "../../../components/atoms/button";
import Loader from "../../../components/atoms/loader";
import { getQuickSearch } from "../../../api/homeApi";
import useAnalytics from "../../../analytics/useAnalytics";
import { cleverTapCommonEvents } from "../../../analytics/clevertapConstants";

/**
 * GiftFinderSection component displays a tab-based interface for finding gifts and includes a section
 * for sending gifts abroad.
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data for the gift finder section.
 * @returns {JSX.Element} - The rendered GiftFinderSection component.
 */
function GiftFinderSection({ data, isMobile }) {
  const [filterData, setFilterData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [isTabClicked, setIsTabClicked] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [selectedOccasion, setSelectedOccasion] = useState({});
  const [selectedGiftType, setSelectedGiftType] = useState({});
  const [filteredGiftTypes, setFilteredGiftTypes] = useState([]);
  const [filteredOccasionTypes, setFilteredOccasionTypes] = useState([]);

  const { trackEvent } = useAnalytics();

  /**
   * Converts a string to camelCase and appends an "s" at the end.
   *
   * @param {string} text - The text to convert.
   * @returns {string} - The generated camelCase element ID with an "s" appended.
   */
  const onGenerateElemId = (text) => {
    return (
      text
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => (index === 0 ? match.toLowerCase() : match.toUpperCase()))
        .replace(/\s+/g, "") + "s"
    );
  };

  /**
   * Handles the display of the selected tab by updating the activeTab state.
   *
   * @param {number} index - The index of the selected tab.
   */
  const onDisplay = async (index) => {
    Object.keys(filterData).length === 0 && setShowLoader(true);
    const quickSearchData = Object.keys(filterData).length === 0 && (await getQuickSearch());
    quickSearchData && setFilterData(quickSearchData);
    if (Object.keys(filterData).length !== 0 || quickSearchData) {
      setIsTabClicked(true);
      setActiveTab(index);
      setShowLoader(false);
    }
  };

  /**
   * Handles the click event on a tab item, updating the relevant state and triggering the render of items.
   *
   * @param {number} index - The index of the clicked tab item.
   * @param {string} type - The type of the tab (e.g., "occasion", "giftType").
   * @param {string} label - The label of the clicked tab item.
   * @param {string} value - The value of the clicked tab item.
   */
  const onTabClick = (index, type, label, value) => {
    const selectedObj = { index, type, value, label };
    const dataKey = value.replace(/-/g, "").trim();
    if (type === "occasions") {
      setSelectedOccasion(selectedObj);
      trackEvent(
        cleverTapCommonEvents.giftFinderClicked,
        {
          occasion_gift_finder: selectedObj.value,
          gift_type: null,
        },
        ["clevertap"],
      );
      const newGiftTypes = filterData[dataKey];
      if (newGiftTypes) {
        setFilteredGiftTypes(newGiftTypes);
        onDisplay(data.list.findIndex((item) => item.category_Name.toLowerCase() === "gift type"));
      }
    } else if (type === "giftTypes") {
      setSelectedGiftType(selectedObj);
      trackEvent(
        cleverTapCommonEvents.giftFinderClicked,
        {
          occasion_gift_finder: null,
          gift_type: selectedObj.value,
        },
        ["clevertap"],
      );
      const newOccasionTypes = filterData[dataKey];
      newOccasionTypes && setFilteredOccasionTypes(newOccasionTypes);
    }
  };

  /**
   * Renders the items for the selected tab.
   * @param {string} tabName - The name of the tab to render items for.
   * @returns {JSX.Element[]} - An array of JSX elements representing the rendered items.
   */
  const renderItems = (tabName) => {
    /** */
    const isActiveChip = (item) => {
      if (tabName === "occasions") {
        return selectedOccasion.value === item.value;
      } else if (tabName === "giftTypes") {
        return selectedGiftType.value === item.value;
      }
      return false;
    };

    /** */
    const getClassNames = (item) => {
      return `cursor-pointer text-sm ${isActiveChip(item) ? "text-fnp-400 border-darkOliveGreen bg-fnp-50" : "text-grey-400 border-grey-300"}`;
    };

    if (tabName === "occasions") {
      if (filteredOccasionTypes.length === 0) {
        return filterData?.occassions?.map((item, index) => (
          <Chip
            id={item?.value}
            key={item?.label}
            size={isMobile ? "small" : "medium"}
            content={item?.label}
            color="default"
            variant="default"
            classes={getClassNames(item)}
            onClick={() => onTabClick(index, tabName, item?.label, item?.value)}
          />
        ));
      }
      return filteredOccasionTypes.map((item, index) => (
        <Chip
          id={item?.value}
          key={item?.label}
          size={isMobile ? "small" : "medium"}
          content={item?.label}
          color="default"
          variant="default"
          classes={getClassNames(item)}
          onClick={() => onTabClick(index, tabName, item?.label, item?.value)}
        />
      ));
    }
    if (tabName === "giftTypes") {
      if (filteredGiftTypes.length === 0) {
        return filterData?.giftTypes?.map((item, index) => (
          <Chip
            id={item?.value}
            key={item?.label}
            size={isMobile ? "small" : "medium"}
            content={item?.label}
            color="default"
            variant="default"
            classes={getClassNames(item)}
            onClick={() => onTabClick(index, tabName, item?.label, item?.value)}
          />
        ));
      }
      return filteredGiftTypes.map((item, index) => (
        <Chip
          id={item?.value}
          key={item?.label}
          size={isMobile ? "small" : "medium"}
          content={item?.label}
          color="default"
          variant="default"
          classes={getClassNames(item)}
          onClick={() => onTabClick(index, tabName, item?.label, item?.value)}
        />
      ));
    }
  };

  /** */
  const onRedirectToPLP = () => {
    const occasionKey = selectedOccasion.value ? selectedOccasion.value.replace(/-/g, "").trim() : "";
    const giftTypeKey = selectedGiftType.value ? selectedGiftType.value.replace(/-/g, "").trim() : "";
    const redirectionKey = (occasionKey + giftTypeKey).trim();
    const redirectionUrl = filterData?.combinations[redirectionKey];
    window.location.href = `/${redirectionUrl.includes("-lp") ? redirectionUrl : `${redirectionUrl}-lp`}`;
    trackEvent(
      cleverTapCommonEvents.giftFinderClicked,
      {
        occasion_gift_finder: occasionKey,
        gift_type: giftTypeKey,
      },
      ["clevertap"],
    );
  };
  /** */
  const onClearAll = () => {
    setIsTabClicked(false);
    setActiveTab(null);
    setSelectedOccasion({});
    setSelectedGiftType({});
    setFilteredGiftTypes([]);
    setFilteredOccasionTypes([]);
  };

  return (
    <div className={`${isMobile ? "" : "relative flex w-full"} md:px-40`}>
      <div
        className="flex w-full items-end justify-between bg-fnp-50 px-16 py-20 md:rounded-24 md:px-40 md:py-40"
        style={{
          backgroundImage: "url(/icons/magnifyGlass.svg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: isMobile ? "16px 0" : "40px 20px",
          backgroundSize: isMobile ? "80px" : "100px",
        }}
      >
        <div className="flex w-full flex-col md:w-1/2">
          <Typography
            variant="h1"
            Tag="p"
            classes="leading-none text-32 text-fnp-900 font-600 pl-20 mb-16 lg:text-48 xxl:text-56"
          >
            {data.title}
          </Typography>
          <div
            className={`relative flex w-full flex-col rounded-2xl bg-white-900 p-16 shadow-md ${showLoader ? "overflow-hidden" : ""}`}
          >
            {showLoader && <Loader position="absolute" zIndex="z-1" />}
            <Typography variant="h1" Tag="p" classes="text-16 md:text-20 text-fnp-500 font-400 mb-16 xxl:text-24">
              {data.heading}⚡
            </Typography>
            <div className="w-full">
              <ul className="flex w-full">
                {data.list.map((item, index) => {
                  const isActive = activeTab === index;
                  const tabId = onGenerateElemId(item.category_Name);
                  return (
                    <li
                      className={`flex w-1/2 cursor-pointer flex-col items-center justify-center text-center ${isTabClicked && "border-b-2"} ${isActive && "border-b-2 border-darkOliveGreen"}`}
                      key={index}
                      id={tabId}
                      onClick={() => onDisplay(index)}
                    >
                      <CustomImage
                        src={item.banner_image_url}
                        width={isMobile ? 80 : 173}
                        height={isMobile ? 80 : 173}
                        sizes="10vw"
                        alt={item.category_Name}
                        borderRadius={8}
                      />
                      <Typography
                        variant="h1"
                        Tag="p"
                        classes={`relative text-14 font-400 my-8 md:text-16 md:my-16 ${selectedOccasion.label && item.category_Name === "Occasion" ? "has-after text-fnp-400" : `${index === activeTab ? "text-fnp-400" : "text-grey-400"}`} `}
                      >
                        {selectedOccasion.label && item.category_Name === "Occasion"
                          ? selectedOccasion.label
                          : item.category_Name}
                      </Typography>
                    </li>
                  );
                })}
              </ul>
              {data.list.map((item, index) => {
                const isActive = activeTab === index;
                const tabId = onGenerateElemId(item.category_Name);
                return (
                  <div
                    key={index}
                    data-id={tabId}
                    className={`${isActive ? "block" : "hidden"} slide-down flex flex-wrap gap-10 py-16`}
                  >
                    {renderItems(tabId)}
                  </div>
                );
              })}
              {(selectedGiftType.value || selectedOccasion.value) && (
                <div className="flex justify-between gap-16">
                  <ButtonComponent
                    buttonTextSize="large"
                    buttonVariant="textBtn"
                    label="Clear"
                    classes="border-1 border-fnp justify-center text-fnp-300 py-8"
                    onClick={onClearAll}
                  />
                  <ButtonComponent buttonTextSize="large" label="View Gifts" onClick={onRedirectToPLP} classes="py-8" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="-mb-40 mr-[8%] hidden w-1/3 self-stretch md:block"
          style={{
            backgroundImage: `url(/icons/finder.png)`,
            backgroundSize: "100%",
            backgroundPosition: "top center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>
    </div>
  );
}

GiftFinderSection.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        // eslint-disable-next-line camelcase
        category_Name: PropTypes.string.isRequired,
        // eslint-disable-next-line camelcase
        banner_image_url: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};
export default GiftFinderSection;
