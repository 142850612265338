export const ALL_GIFTS = "All Gifts";
export const CART_IS_EMPTY = "Your cart is empty";
export const LETS_FILL_THE_CART = "Let's fill it up, shall we?";
export const DELIVERY_DATE_AND_TIME_SLOT = "Delivery Date & Time Slot";
export const REMOVE_GIFT = "Remove Gift?";
export const CONTINUE_GIFTING = "Continue Gifting";
export const CART = "Cart";
export const YES_REMOVE = "Yes, Remove";
export const VIEW_PRICE_DETAILS = "View price details";
export const BOTTOM = "bottom";
export const RIGHT = "right";
export const SELECT_DELIVERY_DATE = "Select Delivery Date";
export const SELECT_SHIPPING_METHOD_AND_TIME_SLOT = "Select Delivery Method & Time Slot";
export const SELECT_SHIPPING_METHOD = "Select Delivery Method";
export const SELECT_TIME_SLOT = "Select Time Slot";
export const CALENDAR = "calendar";
export const DELIVERY_METHOD = "deliveryMethod";
export const DELIVERY_METHOD_WITH_TIMESLOTS = "timeSlots";
export const TIME_SLOT = "timeSlot";
export const PROCEED_TO_PAY = "Proceed to Pay";
export const MOVE_TO_WISHLIST = "Move To Wishlist";
export const CONTINUE_SHOPPING = "Continue Shopping";
export const CHANGE = "Change";
export const ENTER_LOCATION_AVAILABILITY = "Enter Location to check availability";
export const DELIVER_TO = "Deliver to";
export const DAYS_OF_WEEK = ["S", "M", "T", "W", "Th", "F", "S"];
export const WEEKDAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const WANT_TO_REMOVE_GIFT = "Are you sure you want to remove this gift?";
export const INCLUDES_ADD_ONS = "(Includes _id_ Add Ons)";
export const COUNTRIES = { INDIA: "india", SINGAPORE: "singapore" };
export const COUNTRIES_URL = { SINGAPORE: { url: "https://www.fnp.sg/", text: "fnp.sg" } };
export const CURRENCY_ID = "INR";
export const TESTIMONIAL_TITLE = "Customer Reviews";
export const RECENTLY_VIEWED_BY_YOU_TITLE = "Recently Viewed By You";
export const YOU_MAY_ALSO_LIKE_TITLE = "You May Also Like";
export const WHAT_OTHERS_LIKE_TITLE = "What Others Are Viewing";
export const CUSTOMER_REVIEWS = "Customer Reviews";
export const LAST_SEARCH = "Last Searched";
export const SUGGESTIONS = "Suggestions";
export const WHAT_ARE_YOU_LOOKING_FOR = "What are you looking for?";
export const LISTENING = "Listening...";
export const WE_GOT_IT = "We got it";
export const SHOWING_RESULT_FOR = "Showing result for...";
export const TRY_AGAIN = "Try again";
export const SORRY_DID_NOT_GET_YOU = "Sorry! Didn't get you";
export const TAP_THE_MICROPHONE = "Tap the microphone to try again";
export const UH_OH = "Uh-oh!";
export const NO_RESULTS_FOUND_FOR = "No results found for";
export const PLEASE_TRY_TO_SEARCH_SOMETHING_ELSE = "Please try to search something else";
export const SEARCH_BAR_PLACEHOLDER = "Sending good luck plants or more";
export const INDIAN_RUPEE = "₹";
export const PRICE = "Price";
export const DETAILS = "Details";
export const MAXIMUM_RETAIL_PRICE = "Maximum Retail Price";
export const INCLUSIVE_OF_ALL_TAXES = "(Inclusive of all taxes)";
export const MAKE_IT_EXTRA_SPECIAL = "Make it extra special";
export const MAKE_IT_EXTRA_SPECIAL_ICON = "/icons/color-gift-box.svg";
export const DEBOUNCE_TIME = 500;
export const RECENT_SEARCH_LIST = "recentSearchList";
export const MAX_COUNT_RECENT_SEARCH = 8;
export const SEARCH_ANIMATION_SPEED = 100; // milliseconds
export const RECOMMENDED = "RECOMMENDED";
export const ADDED_TO_CART = "added to cart";
export const ITEM = "Item";
export const BASE_ITEM = `Base ${ITEM}`;
export const ADD_SYMBOL = "+";
export const EQUAL_SYMBOL = "=";
export const TOTAL = "Total";
export const FREE = "Free";
export const SHIPPING = "Shipping";
export const CHANGE_CURRENCY = "Change Currency";
export const LOCAL_CURRENCY = "localCurrency";
export const FORMAT = { JSON: "json" };
export const ADD_ON = "Add-on";
export const S = "s";
export const FOOTER_DESCRIPTION_LENGTH = 800;

export const READ_MORE = "Read More...";
export const READ_LESS = "Read Less";
export const BEST_RATING = 5;
export const REVIEWS = "Reviews";
export const TESTIMONIALS = "Testimonials";
export const CUSTOMER_REVIEWS_N_TESTIMONIALS = "Customer Reviews & Testimonials";
export const WHAT_OUR_USERS_SAY_ABOUT_US = "What our users say about us";
export const SELECT_MONTH = "Select Month";
export const SELECT_YEAR = "Select Year";

/**
 *
 */
export const SAVING_AMOUNT = (amount) => `🎊You are saving ₹${amount || 0} on this order`;
export const DELIVERY_CHARGES = "Delivery Charges";
export const PRICE_DETAILS = "Price Details";
export const FINAL_AMOUNT = "Final Amount";
export const FREE_DELIVERY = "Free Delivery";
export const TOTAL_PRODUCT_PRICE = "Total Product Price";
export const DISCOUNT_ON_MRP = "Discount on MRP";
export const DONATION_TO_CRY = "Donation to CRY";

// eslint-disable-next-line no-undef
export const catalogueCountries = new Set([
  "uae",
  "usa",
  "uk",
  "australia",
  "canada",
  "singapore",
  "philippines",
  "germany",
  "new-zealand",
  "argentina",
  "austria",
  "bahrain",
  "bangladesh",
  "belgium",
  "brazil",
  "bulgaria",
  "chile",
  "china",
  "croatia",
  "denmark",
  "egypt",
  "finland",
  "france",
  "greece",
  "haiti",
  "hong-kong",
  "hungary",
  "indonesia",
  "ireland",
  "israel",
  "italy",
  "japan",
  "jordan",
  "kenya",
  "korea",
  "kuwait",
  "lebanon",
  "macau",
  "malaysia",
  "mauritius",
  "mexico",
  "morocco",
  "nepal",
  "netherlands",
  "norway",
  "oman",
  "pakistan",
  "poland",
  "portugal",
  "qatar",
  "romania",
  "russia",
  "saudi-arabia",
  "south-africa",
  "spain",
  "sri-lanka",
  "sweden",
  "switzerland",
  "taiwan",
  "thailand",
  "turkey",
  "ukraine",
  "vietnam",
  "zimbabwe",
]);
export const PDP = "pdp";
export const GO_TO_CART = "goToCart";
export const BUY_NOW = "buyNow";
export const PRODUCT_PRICE_BREAKUP = "Product Price Breakup";

export const SOMETHING_WENT_WRONG = "Oops! Something went wrong";
export const ERROR_FIXING = "Brace yourself till we get the error fixed.";
export const TRY_AGAIN_LATER = "You may also refresh the page or try again later";

export const PAGE_NOT_FOUND = "Oops! Page not found";
export const CANT_FIND_PAGE = "We can't find the page you are looking for.";
export const TRY_POPULAR_PAGES = "Why not try these popular pages.";

export const fnpPageType = {
  HOME: "home",
  CATEGORY: "category",
  SEARCH: "search",
  CART_PREVIEW: "cartPreview",
  GIFT_FINDER: "giftfinder",
  PLP_MICRO_SITE: "plp_micro_site",
  PRODUCT: "product",
  ERROR_404: "error404",
  CHECKOUT: "checkout",
  INFO: "info",
};
export const fnpPageName = {
  HOME: "HOME",
  PLP: "PLP",
  PDP: "PDP",
  PRODUCT: "PRODUCT",
  CART: "CART",
  MICROSITE: "MICROSITE",
};
export const INTERNATIONAL = "INTERNATIONAL";
export const THRESHOLD_APP_BANNER = 200;
export const THRESHOLD_HEADER_FOR_SEARCH = 50;
export const INCORRECT_ENTRY = "Incorrect entry. Please resend OTP";
export const PLEASE_ENTER_OTP = "Please Enter the OTP";
export const NO_PRODUCT_FOUND = "No Product Found";
export const NEXT = "Next";
export const PREVIOUS = "Previous";
export const IS_HIDE_APP_BANNER = "isHideAppBanner";
