import { InterceptorClient } from "../../interceptor/interceptorClient";
import { ADD_TO_CART_URI, DELETE_CART_URI, SET_CART_ITEM_URI, UPDATE_ADD_ON_QUANTITY, VIEW_CART_URI } from "./constant";
const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
/**
 * API for the data for home page
 * @returns
 */
export const addToCartService = async (params) => {
  return await InterceptorClient.getFNTSInstance(baseUrl).post(params, `${ADD_TO_CART_URI()}`, false); //second parameter for mock
};

/**
 *
 * @returns
 */
export const viewCartService = async () => {
  return await InterceptorClient.getFNTSInstance(baseUrl).get(`${VIEW_CART_URI()}`, false); //second parameter for mock
};
/**
 *
 * @returns
 */
export const setCartItemValuesService = async (params) => {
  return await InterceptorClient.getFNTSInstance(baseUrl).post(params, `${SET_CART_ITEM_URI()}`, false); //second parameter for mock
};

/**
 *
 * @returns
 */
export const deleteCartItemService = async (params) => {
  return await InterceptorClient.getFNTSInstance(baseUrl).get(`${DELETE_CART_URI()}?${params}`, false); //second parameter for mock
};

/**
 *
 * @returns
 */
export const updateAddOnQuantity = async (params) => {
  return await InterceptorClient.getFNTSInstance(baseUrl).get(`${UPDATE_ADD_ON_QUANTITY()}?${params}`, false); //second parameter for mock
};
