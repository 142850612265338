import React, { useState } from "react";
import PropTypes from "prop-types";
import Drawer from "../../../molecules/drawer";
import Accrodion from "../../../molecules/accordion";
import Checkbox from "../../../atoms/checkbox";
import ButtonComponent from "../../../atoms/button";
import AccordionTitle from "../../../molecules/accordion/accordionTitle";
import AccordionContent from "../../../molecules/accordion/accordionContent";

/**
 * A component representing a filter panel for desktop view.
 *
 * @param {object} props - The props for the component.
 * @param {boolean} props.isOpen - Whether the filter panel is open or not.
 * @param {function} props.onClose - Function to handle closing the filter panel.
 * @param {Array} props.data - Array of filter data objects.
 * @returns {JSX.Element} The FilterDesktop component.
 */
export default function FilterDesktop({ isOpen, onClose: onClose, data }) {
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <Drawer position="right" isOpen={isOpen} onClose={onClose} styles="p-0">
      <div className="border-grey-300 border-b px-5 py-4 text-2xl font-semibold">Filters</div>
      <Accrodion styles="p-3">
        {data?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <AccordionTitle
                key={`${item.facetFieldDisplayName}_${index}`}
                activeIndex={activeIndex}
                changeActiveIndex={setActiveIndex}
                index={index}
                styles="flex justify-between p-2"
              >
                {item.facetFieldDisplayName}
              </AccordionTitle>

              <AccordionContent
                key={`${item.facetFieldName}_${index}`}
                index={index}
                activeIndex={activeIndex}
                styles="p-3 text-sm gap-3 flex flex-col"
              >
                {item.facetOptions.map((items) => {
                  return (
                    <Checkbox
                      label={`${items.displayName} (${items.count})`}
                      labelColor="text-grey-400"
                      activeColor="text-fnp-400"
                      key={items.displayName}
                    />
                  );
                })}
              </AccordionContent>
            </React.Fragment>
          );
        })}
      </Accrodion>
      <div className="absolute bottom-6 w-full p-5">
        <ButtonComponent label="Apply" size="normal" />
      </div>
    </Drawer>
  );
}

FilterDesktop.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      facetFieldDisplayName: PropTypes.string.isRequired,
      facetFieldName: PropTypes.string.isRequired,
      facetOptions: PropTypes.arrayOf(
        PropTypes.shape({
          displayName: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired,
          selected: PropTypes.bool.isRequired,
          priceFrom: PropTypes.string,
          priceTo: PropTypes.string,
          subFacetOptions: PropTypes.arrayOf(
            PropTypes.shape({
              displayName: PropTypes.string.isRequired,
              value: PropTypes.string.isRequired,
              count: PropTypes.number.isRequired,
            }),
          ),
        }),
      ).isRequired,
    }),
  ).isRequired,
};
