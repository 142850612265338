import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestToGetCurrency: false,
  currencyListErrorMessage: "",
  userSelectedCurrency: {},
  currencies: [],
  isCurrencyDrawerVisible: false,
};
export const CurrencyListSlice = createSlice({
  name: "currencyList",
  initialState,
  reducers: {
    /**
     * Reducer to handle opening and closing of the drawer.
     * @param {Object} state - Current state.
     */
    requestToGetCurrency: (state) => {
      state.requestToGetCurrency = true;
    },
    /**
     * Reducer to handle opening and closing of the drawer.
     * @param {Object} state - Current state.
     */
    failedToGetCurrency: (state, action) => {
      state.currencyListErrorMessage = action.payload;
    },
    /**
     * Reducer to save user details to state.
     * @param {Object} state - Current state.
     * @param {Object} action - Action object containing user details payload.
     */
    requestToSaveCurrency: (state, action) => {
      state.currencies = action.payload;
    },
    /**
     * Reducer to save user details to state.
     * @param {Object} state - Current state.
     * @param {Object} action - Action object containing user details payload.
     */
    requestToSaveSelectedCurrency: (state, action) => {
      state.userSelectedCurrency = action.payload;
    },
    setCurrencyDrawerVisible: (state, action) => {
      state.isCurrencyDrawerVisible = action.payload;
    },
  },
});

export default CurrencyListSlice.reducer;
export const {
  requestToGetCurrency,
  failedToGetCurrency,
  requestToSaveCurrency,
  requestToSaveSelectedCurrency,
  setCurrencyDrawerVisible,
} = CurrencyListSlice.actions;
