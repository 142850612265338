import { put, takeLatest, select, call } from "redux-saga/effects";
import {
  fetchExistingUserApiCall,
  getCsrfTokensApiCall,
  getCustomerDetails,
  sendOTP,
  userSignUp,
} from "../../../api/loginApi";
import {
  changePhoneNumber,
  customerDetailsError,
  otpError,
  requestToFetchSignUp,
  requestToFetchUsl,
  requestToGetCsrfTokens,
  requestToGetCustomerDetails,
  requestToHandleSuccessfullToast,
  requestToOpenLoginDrawer,
  requestToOTP,
  requestToRegister,
  requestToSaveUserDetails,
  requestToSendOtp,
  requestToValidateExistingUser,
  responseFromGetCustomerDetails,
  setCsrfTokens,
  setValidateExistingUser,
  signUpError,
  uslError,
} from "../../slices/loginSignupSlice";
import { initiateFusCall } from "../commonSaga";
import { ACCOUNT_CREATED_SUCCESSFULLY } from "../../../constants/pageConstants/loginConstants";

/**
 *
 * @param {*} action
 */
function* fetchUser(userData) {
  try {
    const otpTokenA = yield select((state) => state.loginSignUp.tokens?._fc_a);
    const otpTokenB = yield select((state) => state.loginSignUp.tokens?._fc_b);
    if (userData?.exists) {
      yield put(
        requestToSaveUserDetails({
          email: userData?.email,
          isExisting: true,
          loginToken: userData?.logintoken,
        }),
      );

      const otpPayload = {
        un: userData?.un,
        logintoken: userData?.logintoken,
        _fc_a: otpTokenA,
        _fc_b: otpTokenB,
        deviceType: userData?.deviceType,
      };
      const otpResponse = yield sendOTP(otpPayload);
      if (otpResponse?.response === "success") {
        yield put(requestToOTP(otpResponse));
      } else {
        yield put(otpError(otpResponse));
      }
    } else if (!userData?.exists) {
      yield put(
        requestToSaveUserDetails({
          email: userData?.email,
          isExisting: false,
          loginToken: userData?.logintoken,
        }),
      );
      yield put(requestToRegister(true));
    }
  } catch (error) {
    yield put(uslError(error));
  }
}
/**
 *
 * @param {*} action
 */
function* sendOtpToUser(action) {
  try {
    const response = yield sendOTP(action.payload);
    if (response?.response === "success") {
      yield put(requestToOTP(response));
      yield put(requestToRegister(false));
      yield put(changePhoneNumber(false));
    }
  } catch (error) {
    yield put(otpError(error));
  }
}

/**
 *
 * @param {*} action
 */
function* fetchSignUpData(action) {
  const { loginToken, email, userName, countryCode, phoneNumber } = action.payload;
  try {
    const response = yield userSignUp(loginToken, userName, countryCode, phoneNumber, email);

    if (response.isSuccess) {
      yield call(initiateFusCall);
      yield put(requestToHandleSuccessfullToast(ACCOUNT_CREATED_SUCCESSFULLY));
      yield put(requestToOpenLoginDrawer(false));
    }
  } catch (error) {
    yield put(signUpError(error));
  }
}
/**
 *
 * @param {*} action
 */
function* fetchCsrfTokens() {
  try {
    const response = yield getCsrfTokensApiCall();
    if (response.isSuccess) {
      yield put(setCsrfTokens(response?.data));
    }
  } catch (error) {
    yield put(signUpError(error));
  }
}
/**
 *
 * @param {*} action
 */
function* validateExistingUser(action) {
  try {
    const response = yield fetchExistingUserApiCall({ un: action.payload.un });
    if (response) {
      yield fetchUser({ ...response, ...action.payload });
      yield put(setValidateExistingUser(response));
    }
  } catch (error) {
    yield put(signUpError(error));
  }
}

/**
 * To fetch the login user details.
 * @returns
 */
function* getCustomerLoginDetails() {
  try {
    const response = yield getCustomerDetails();
    yield put(responseFromGetCustomerDetails(response));
  } catch (error) {
    yield put(customerDetailsError(error));
  }
}

/**
 * Description placeholder
 *
 * @export
 * @returns {{}}
 */
function* watcherLoginFlowSaga() {
  yield takeLatest(requestToFetchUsl.type, fetchUser);
  yield takeLatest(requestToSendOtp.type, sendOtpToUser);
  yield takeLatest(requestToFetchSignUp.type, fetchSignUpData);
  yield takeLatest(requestToGetCsrfTokens.type, fetchCsrfTokens);
  yield takeLatest(requestToValidateExistingUser.type, validateExistingUser);
  yield takeLatest(requestToGetCustomerDetails.type, getCustomerLoginDetails);
}

export default watcherLoginFlowSaga;
