"use client";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, TabList, TabPanel } from "../../../molecules/tabion";
import MenuItems from "./menuItems";
import { GLOBAL, SEND_GIFT_ABROAD } from "../../../../constants/common/headerToolbarConstants";
import useAnalytics from "../../../../analytics/useAnalytics";

/**
 * MobileMegaMenu component for displaying a mobile-friendly mega menu.
 *
 * @param {object} props - Component props.
 * @param {Array} props.data - Array of menu item data objects.
 * @returns {JSX.Element} JSX for the MobileMegaMenu component.
 */
const MobileMegaMenu = ({ data = [] }) => {
  const { init } = useAnalytics();
  const [selectedTab, setSelectedTab] = useState(0);
  const modifiedCategories = data?.reduce((accumulator, currentObject) => {
    if (currentObject?.categoryName !== SEND_GIFT_ABROAD && currentObject?.categoryName !== GLOBAL) {
      return accumulator.concat(currentObject);
    }
    return accumulator;
  }, []);
  useEffect(() => {
    init();
  }, []);
  const onTabChange = useCallback((evt) => {
    setSelectedTab(evt);
  }, []);

  return (
    <div className="w-full p-16">
      <Tabs>
        <TabList
          className={`-mt-3 mb-20 w-[150px] max-w-full items-center overflow-hidden text-ellipsis whitespace-nowrap !border-t-0 text-center text-14 font-400 leading-24 text-grey-400`}
          isActiveStyle="border-b-2 border-darkOliveGreen text-fnp-400 font-600 pb-4 mt-0"
          tabListStyle="justify-center gap-16"
          onTabSelected={onTabChange}
          selected={selectedTab}
        >
          {modifiedCategories.map((title, index) => {
            return (
              <Tab key={title.productCategoryId} id={index}>
                {title.categoryName}
              </Tab>
            );
          })}
        </TabList>
        {modifiedCategories.map((items, index) => (
          <TabPanel key={items.productCategoryId} id={index} className="overflow-auto">
            <div className="flex flex-col gap-12">
              {items?.childCategoryLists?.map((subItem, i) => (
                <MenuItems key={`Level-0-${i}`} data={subItem} itemIndex={i} itemLevel={0} />
              ))}
            </div>
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

MobileMegaMenu.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string,
      productCategoryId: PropTypes.string,
      promo: PropTypes.string,
      childCategoryLists: PropTypes.array,
    }),
  ).isRequired,
};

export default React.memo(MobileMegaMenu);
