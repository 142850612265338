"use client";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import dynamic from "next/dynamic";
import {
  requestToGetCustomerDetails,
  requestToOpenLoginDrawer,
  resetLoginValue,
} from "../../../store/slices/loginSignupSlice";
import { requestToGetWebFeatureControls } from "../../../store/slices/webFeatureControls";
import { DesktopMegaMenu } from "../megaMenu";
import FNPIcon from "./fnpIcon/index";
import HeaderToolBar from "./headerToolBar";
import LocationLockHeader from "./locationLockHeader";
// import MobileSearch from "../mobileSearch";
import { requestToGetCurrency } from "../../../store/slices/common/currencyLists";
import DesktopSearch from "../desktopSearch";
import { initiateFus } from "../../../store/slices/common/fus";
import { requestToFetchFnpCashBalance, requestToFetchFnpLabel } from "../../../store/slices/fnpCash";
import isEmpty from "lodash.isempty";
import { THRESHOLD_HEADER_FOR_SEARCH } from "../../../constants/common/common";
import { setGA4LoginAndSignUp } from "../../../analytics/gaEvents";
import useAnalytics from "../../../analytics/useAnalytics";
import { GA4_EVENTS, SOCIAL_LOGIN_KEY } from "../../../analytics/gaConstant";
import { cleverTapCommonEvents } from "../../../analytics/clevertapConstants";
import { getSessionStorage, removeSessionStorage } from "../../../utils/storage/sessionStorageUtil";
import { onClevertapPublish } from "../../../api/common";

const Login = dynamic(() => import("../login"), { ssr: false });
const SignUp = dynamic(() => import("../signUp"), { ssr: false });
const PhoneNumber = dynamic(() => import("../phoneNumber"), { ssr: false });
const VerifyDetails = dynamic(() => import("../verifyDetails"), { ssr: false });
const CheckoutTNC = dynamic(() => import("../../molecules/checkoutTNC"), { ssr: false });
const LoginSignUpImage = dynamic(() => import("../../molecules/loginSingUpImage"), { ssr: false });
const Drawer = dynamic(() => import("../../molecules/drawer"), { ssr: false });

/**
 * Header component represents the header section of the page.
 * It includes the FNPIcon, LocationLock, SearchBar, and HeaderButtons components.
 *
 * @param {Object} props - The props for the Header component.
 * @param {boolean} props.isFromPDP - Indicates if the header is being rendered on the product details page.
 * @param {boolean} props.isMobile - Indicates if the current device is mobile.
 * @param {string} props.catalogId - The catalog identifier (e.g., "india").
 * @param {Array} props.preloadLinks - Array of link objects for preloading resources.
 * @returns {JSX.Element} The JSX representation of the Header component.
 */
export const Header = ({
  isFromPDP = false,
  isMobile,
  catalogId = "india",
  preloadLinks,
  isLoginPage = false,
  serverSelectedCurrency,
  searchQuery,
  isShowSearchIcon = true,
  menuData,
}) => {
  const dispatch = useDispatch();
  const headerRef = useRef(null);
  const { trackEvent, onUserLogin } = useAnalytics();
  const [isSearchIconVisible, setIsSearchIconVisible] = useState(false);
  const { loginSignUp, loginDetail, bannerOnLoginScreen, checkLoginStatus } = useSelector(
    (state) => ({
      loginSignUp: state?.loginSignUp,
      loginDetail: state?.fus?.loginDetail,
      bannerOnLoginScreen: state?.webFeatureControls?.data?.bannerOnLoginScreen,
      checkLoginStatus: state.fus.loginDetail?.loggedin,
    }),
    shallowEqual,
  );

  /**
   * On scroll handler to get the offset of documentElement.
   */
  const onHandleScroll = () => {
    const offsetTop = document.documentElement.getBoundingClientRect().top;

    if (Math.abs(offsetTop) < THRESHOLD_HEADER_FOR_SEARCH) {
      setIsSearchIconVisible(false);
    } else {
      setIsSearchIconVisible(true);
    }
  };

  useEffect(() => {
    if (loginDetail?.loggedin) {
      dispatch(requestToGetCustomerDetails());
    }
  }, [loginDetail?.loggedin]);

  useEffect(() => {
    const isUserLoggedIn = loginDetail?.loggedin && !isEmpty(loginSignUp?.userDetails);
    if (isUserLoggedIn) {
      const userDetails = { ...loginSignUp?.userDetails, userType: loginDetail?.userType };
      const isOAuth = loginDetail?.isOAuthUser;
      const storageItem = !!getSessionStorage(SOCIAL_LOGIN_KEY);
      const eventName = loginDetail?.userType === "Existing" ? GA4_EVENTS.LOGIN : GA4_EVENTS.SIGNUP;
      if (storageItem) {
        const publishCleverTapData = { profile: { identity: userDetails?.emailID } };
        onClevertapPublish(publishCleverTapData);
        const properties = setGA4LoginAndSignUp(userDetails, isOAuth);
        trackEvent(eventName, { ...properties }, ["ga4"]);
        onUserLogin({
          Site: {
            Identity: userDetails?.emailID,
            Email: userDetails?.emailID,
          },
        });
        trackEvent(cleverTapCommonEvents.loginSuccess, { loginSuccess: "Login Success" }, ["clevertap"]);
        removeSessionStorage(SOCIAL_LOGIN_KEY);
      }
    }
  }, [loginSignUp?.userDetails]);

  useEffect(() => {
    dispatch(requestToGetWebFeatureControls());
    dispatch(requestToGetCurrency());
    dispatch(initiateFus());
    dispatch(requestToFetchFnpLabel());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMobile) {
      document.addEventListener("scroll", onHandleScroll);
    }

    return () => {
      if (isMobile) {
        document.removeEventListener("scroll", onHandleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checkLoginStatus) dispatch(requestToFetchFnpCashBalance());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkLoginStatus]);

  // Prefetch, preload, or preconnect links based on their `rel` attribute
  if (preloadLinks && preloadLinks.length > 0) {
    preloadLinks.forEach((linkItem) => {
      if (linkItem.rel === "dns-prefetch") {
        ReactDOM.prefetchDNS(linkItem.href);
      } else if (linkItem.rel === "preload") {
        ReactDOM.preload(linkItem.href);
      } else if (linkItem.rel === "preconnect") {
        ReactDOM.preconnect(linkItem.href);
      }
    });
  }

  /**
   * Handles the action to close the drawer.
   * Dispatches an action to set the drawer state to closed.
   *
   */
  const onDrawerClose = () => {
    dispatch(requestToOpenLoginDrawer(false));
    dispatch(resetLoginValue());
  };

  if (isLoginPage) {
    return (
      <header className="mx-auto my-0 flex px-40 py-12">
        <div className="flex justify-start">
          <FNPIcon imgWidth={81} imgHeight={40} />
        </div>
      </header>
    );
  }

  return (
    <>
      <div
        className={`${isMobile ? "relative" : "fixed"} bg-white top-0 z-[100] mb-8 grid w-full grid-cols-1 border-b border-[#F5F5F5] bg-white-900 p-16 pb-8 shadow-[0px_1px_4px_0px_rgba(0,0,0,0.04)] md:p-0`}
        ref={headerRef}
      >
        <header className="flex items-center justify-between md:px-40 md:py-12">
          <div className="flex w-3/5 justify-start">
            <div className="flex items-center justify-center md:gap-20">
              <span className="hidden md:flex">
                <FNPIcon />
              </span>
              <span className="border-white-smoke hidden h-48 border-r border-solid lg:flex" />
              {!isFromPDP && <LocationLockHeader isMobile={isMobile} />}
            </div>

            <span className="relative hidden w-full max-w-[652px] justify-center md:flex md:flex-col md:px-40">
              <DesktopSearch catalogId={catalogId} searchQuery={searchQuery} />
            </span>
          </div>
          <HeaderToolBar
            isMobile={isMobile}
            serverSelectedCurrency={serverSelectedCurrency}
            isSearchIconVisible={isSearchIconVisible && isShowSearchIcon}
          />
        </header>

        {!isMobile && (
          <>
            <DesktopMegaMenu data={menuData} classes="md:flex" />
            <Drawer
              position="right"
              isOpen={loginSignUp?.openLoginDrawer}
              onDrawerClose={onDrawerClose}
              styles="min-w-[360px] max-w-[400px]"
              childContainerClass="flex flex-col justify-between overflow-auto scrollbar-thin"
            >
              {!loginSignUp?.showRegister && !loginSignUp?.otpSuccess && !loginSignUp?.changePhoneNumber && (
                <Login isHeader={true} />
              )}
              {loginSignUp?.showRegister && !loginSignUp?.changePhoneNumber && <SignUp isHeader={true} />}
              {loginSignUp?.showTNC && <CheckoutTNC />}
              {loginSignUp?.otpSuccess && !loginSignUp?.changePhoneNumber && <VerifyDetails isHeader={true} />}
              {loginSignUp?.changePhoneNumber && <PhoneNumber isHeader={true} />}
              {bannerOnLoginScreen && <LoginSignUpImage url={bannerOnLoginScreen} isHeader={true} />}
            </Drawer>
          </>
        )}
      </div>
    </>
  );
};

// Define PropTypes for the Header component
Header.propTypes = {
  isFromPDP: PropTypes.bool,
  isMobile: PropTypes.bool,
  catalogId: PropTypes.string,
  preloadLinks: PropTypes.arrayOf(
    PropTypes.shape({
      rel: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ),
  isLoginPage: PropTypes.bool,
  serverSelectedCurrency: PropTypes.object,
  searchQuery: PropTypes.string,
  isShowSearchIcon: PropTypes.bool,
  menuData: PropTypes.array,
};
