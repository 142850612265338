import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fnpCashTitle: "fnpCash",
  usedCashText: "You used",
  availableBalance: 0,
  usableBalance: 0,
  usableBalanceTnc: "",
  fnpChipText: "New",
  fnpCashCheckboxState: null,
  defaultFnpCashSelected: "N",
};

const FnpCashSlice = createSlice({
  name: "fnpCash",
  initialState,
  reducers: {
    requestToFetchFnpCashBalance: () => {},
    requestToFetchFnpLabel: () => {},
    requestToFetchUsableBalanceTnc: () => {},

    setFnpCashLabel: (state, action) => {
      state.fnpCashTitle = action.payload?.wallet?.label?.fnpcash;
      state.usedCashText = action.payload?.wallet?.label?.youused;
      state.fnpChipText = action.payload?.wallet?.label?.new;
      state.defaultFnpCashSelected = action.payload?.wallet?.fnpWalletSelected;
    },

    setFnpCashBalance: (state, action) => {
      state.availableBalance = action.payload?.response?.balance;
      state.usableBalance = action.payload?.response?.usableBalance;
    },

    setFnpCashUsableBalanceTnc: (state, action) => {
      state.usableBalanceTnc = action.payload;
    },
    isFnpCashCheckboxChecked: (state, action) => {
      state.fnpCashCheckboxState = action.payload;
    },
  },
});

export const {
  setFnpCashLabel,
  setFnpCashBalance,
  setFnpCashUsableBalanceTnc,
  requestToFetchFnpCashBalance,
  requestToFetchFnpLabel,
  requestToFetchUsableBalanceTnc,
  isFnpCashCheckboxChecked,
} = FnpCashSlice.actions;

export default FnpCashSlice.reducer;
