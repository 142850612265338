import Image from "next/image";
import PropTypes from "prop-types";

/**
 * Chip component represents a small interactive element.
 * @param {Object} props - The props of the component.
 * @param {string} props.id - The unique identifier for the chip.
 * @param {string} props.content - The text content of the chip.
 * @param {string} [props.startIcon] - The URL of the icon to be displayed at the start of the chip.
 * @param {string} [props.endIcon] - The URL of the icon to be displayed at the end of the chip.
 * @param {Function} [props.onClick] - The function to be called when the chip is clicked.
 * @param {"small" | "medium"} [props.size] - The size of the chip.
 * @param {"filled" | "outlined"} [props.variant] - The variant of the chip.
 * @param {"primary" | "dark" | "error" | "info" | "success" | "warning"} [props.color] - The color of the chip.
 * @param {string} [props.subHeader] - The subheader text of the chip.
 * @param {number} [props.iconSize] - The size of the starting and ending icon of the chip.
 * @returns {JSX.Element} Chip component.
 */
const Chip = ({
  id = "chip-component",
  content,
  startIcon,
  endIcon,
  onClick,
  color = "primary",
  size = "small",
  variant = "filled",
  subHeader,
  onClose,
  classes = "",
  iconSize,
}) => {
  const chipIconSize = iconSize || (size === "small" ? 12 : 15);

  /**
   * Gets the CSS class name based on the provided size.
   *
   * @param {string} size - The size for which to retrieve the CSS class.
   *                  Must be either "small", "medium", or any other value.
   * @returns {string} The CSS class name corresponding to the size.
   */
  const getSizeClass = () => {
    let sizeClass = "";
    switch (size) {
      case "small":
        sizeClass = "text-xs p-2.5";
        break;
      case "medium":
        sizeClass = "p-2.5";
        break;
      default:
        sizeClass = "text-xs p-2";
        break;
    }

    return sizeClass;
  };

  /**
   * Gets the CSS class name based on the provided color.
   *
   * @param {string} color - The color for which to retrieve the CSS class.
   *                  Must be one of "primary", "dark", "error", "info",
   *                  "success", or "warning". Defaults to "text-gray-500"
   *                  if an invalid color is provided.
   * @returns {string} The CSS class name corresponding to the color.
   */
  const getColorClass = () => {
    const colorClasses = {
      primary: `text-gray-500`,
      dark: "text-black font-medium",
      error: "bg-red-500 text-white-900",
      info: "bg-yellow-500 text-white-900",
      success: "bg-green-500 text-white-900",
      warning: "bg-orange-500 text-white-900",
      default: " ",
    };
    return colorClasses[color];
  };

  const defaultClass = `flex justify-center items-center whitespace-nowrap border-1 rounded-full ${getColorClass()} ${getSizeClass()} ${subHeader && "flex-col"}`;

  /**
   * Gets the CSS class name based on the provided button variant.
   *
   * @param {string} variant - The variant of the button for which to retrieve
   *                             the CSS class. Must be one of "filled" or
   *                             "outlined". Defaults to "outlined" if an
   *                             invalid variant is provided.
   * @returns {string} The CSS class name corresponding to the button variant.
   */
  const getVariantClass = () => {
    let variantClass = "";
    switch (variant) {
      case "filled":
        variantClass = "border-black bg-gray-200";
        break;
      case "outlined":
        variantClass = "border-gray-200";
        break;
      default:
        variantClass = "";
        break;
    }

    return variantClass;
  };

  return (
    <div id={id} onClick={onClick} className={`${defaultClass}  ${getVariantClass()} ${classes}`} data-testid="chip">
      {startIcon && <Image src={startIcon} alt={`${content}_start_icon`} width={chipIconSize} height={chipIconSize} />}
      {/* {content && <span className={subHeader && "font-semibold"}>{content}</span>} */}
      {content}
      {subHeader && <span className="font-normal">{subHeader}</span>}
      {endIcon && (
        <Image src={endIcon} alt={`${content}_end_icon`} width={chipIconSize} height={chipIconSize} onClick={onClose} />
      )}
    </div>
  );
};

Chip.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium"]),
  variant: PropTypes.oneOf(["filled", "outlined", "default"]),
  color: PropTypes.oneOf(["primary", "dark", "error", "info", "success", "warning", "default"]),
  subHeader: PropTypes.string,
  onClose: PropTypes.func,
  classes: PropTypes.string,
  iconSize: PropTypes.number,
};

export default Chip;
