"use client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FOOTER_DESCRIPTION_LENGTH, READ_LESS, READ_MORE } from "../../../constants/common/common";

/** */
export const LongDescription = ({ longDescription }) => {
  const [showMore, setShowMore] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const minimalContent = longDescription.length < FOOTER_DESCRIPTION_LENGTH;
  const [buttonLabel, setButtonLabel] = useState(READ_MORE);

  // Ensure this runs only on the client
  useEffect(() => {
    setIsMounted(true);
  }, []);

  /**
   * This method is handling readmore/readless footer description
   */
  const onHandleViewMore = () => {
    setShowMore((prevShowMore) => {
      const newShowMore = !prevShowMore;
      setButtonLabel(newShowMore ? READ_LESS : READ_MORE);
      return newShowMore;
    });
  };

  /** */
  const renderDescription = () => {
    if (minimalContent) {
      return <span dangerouslySetInnerHTML={{ __html: longDescription }} />;
    }

    // Split the long description into two parts
    const visibleContent = longDescription.slice(0, FOOTER_DESCRIPTION_LENGTH);
    const hiddenContent = longDescription.slice(FOOTER_DESCRIPTION_LENGTH);

    return (
      <>
        <span dangerouslySetInnerHTML={{ __html: visibleContent }} />
        {showMore && <span dangerouslySetInnerHTML={{ __html: hiddenContent }} />}
      </>
    );
  };

  if (!isMounted) {
    // During server-side rendering or initial client-side hydration, render only the basic structure
    return (
      <article className="p-16 md:p-40 md:pb-0" id="longDescription">
        <span dangerouslySetInnerHTML={{ __html: longDescription.slice(0, FOOTER_DESCRIPTION_LENGTH) }} />
      </article>
    );
  }

  return (
    <>
      <article className="relative p-16 md:p-40 md:pb-0" id="longDescription">
        {renderDescription()}
      </article>
      {!minimalContent && (
        <button
          type="button"
          onClick={onHandleViewMore}
          className="mr-40 inline-flex justify-end text-14 font-500 text-blue-100"
        >
          {buttonLabel}
        </button>
      )}
    </>
  );
};

LongDescription.propTypes = {
  longDescription: PropTypes.string.isRequired,
};
