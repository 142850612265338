import PropTypes from "prop-types";
import Link from "next/link";
import Chip from "../../../atoms/chip";
import { plpPageConstants } from "../../../../constants/pageConstants/plpConstants";

/**
 * SearchInstead component displays a list of alternative search suggestions.
 * Each item is wrapped in a link, which redirects users to the relevant category or source.
 * It uses the `Chip` component to render each suggestion as an interactive chip.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.list - An array of suggestion items.
 * @param {Boolean} props.isMobile - An boolean value which indicate that request from desktop or Mobile 
 * @returns {JSX.Element} - The rendered list of search suggestions.

 */
const SearchInstead = ({ list, isMobile }) => {
  return (
    <>
      {isMobile && <div className="mb-16 text-16">{plpPageConstants?.SEARCH_INSTEAD}</div>}
      <div className="mb-16 flex flex-wrap gap-8">
        {list?.map((item, index) => (
          <Link href={item.source} key={index} className="cursor-pointer">
            <Chip id={`chip-${index}`} content={item?.category} variant="outlined" size="medium" classes="text-sm" />
          </Link>
        ))}
      </div>
    </>
  );
};

SearchInstead.propTypes = {
  list: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
export default SearchInstead;
