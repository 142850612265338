import { mapping } from "./mockApiMapping";

/**
 * Function to map with uri endpoint and mock data.
 * @param {string} uri - The URI of the endpoint.
 * @returns {Promise} - A Promise representing the result of the request.
 */
const getMockResponseData = (uri) => {
  const key = Object.keys(mapping).filter((key) => {
    return uri.includes(key);
  });
  return key[0];
};
/**
 * Make a GET for mock.
 * @param {string} uri - The URI of the endpoint.
 * @returns {Promise} - A Promise representing the result of the request.
 */
const getMock = (uri) => {
  return mapping[getMockResponseData(uri)];
};
/**
 * Make a POST for mock.
 * @param {string} uri - The URI of the endpoint.
 * @returns {Promise} - A Promise representing the result of the request.
 */
const postMock = (uri) => {
  return mapping[getMockResponseData(uri)];
};

export { getMock, postMock };
