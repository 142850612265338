import { InterceptorClient } from "../../interceptor/interceptorClient";
import {
  CMS_API_VERSION,
  GET_DYNAMIC_SEARCH_TEXT_PLACEHOLDER,
  GET_SEARCH_SUGGESTIONS,
  GET_CURRENCY_LIST,
  FUS_API,
  SUBSCRIBE_NEW_USER,
  GET_MEGA_MENU,
  GET_CALL_CHAT_TIME,
  GET_SAVED_ADDRESSES,
  GET_POPULAR_LINK404_DESKTOP,
  GET_POPULAR_LINK404_MOBILE,
  GET_MEGA_MENU_DESKTOP,
  GET_APP_CLEVERTAP_PUBLISH_URL,
} from "./constant";

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

const basicHeaders = {
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Headers": "Content-Type",
  "Content-Type": "text/html;charset=UTF-8",
  "X-made-with": "Made with <3 in India",
};
/**
 * Method to fetch content from CMS on client side
 *
 * @param {string} contentURL page content slug
 * @returns {string} returns html file data
 */
export async function fetchContentFromCMSOnClient(contentURL, headersList, setResHeaders = false) {
  const headers = setResHeaders
    ? {
        ...basicHeaders,
        "edge-control": headersList?.["edge-control"],
        "edge-cache-tag": headersList?.["edge-cache-tag"],
        expires: headersList?.expires || headersList?.Expires,
      }
    : { ...basicHeaders };
  try {
    const endPoint = CMS_API_VERSION + contentURL;
    return await InterceptorClient.getGatewayInstance().get(endPoint, false, null, {
      ...headers,
    });
  } catch (err) {
    throw new Error(err.message);
  }
}
/** */
export async function fetchSearchSuggestions(geoId, keyword, uuid, userId) {
  try {
    return await InterceptorClient.getGatewayInstance().get(
      `${GET_SEARCH_SUGGESTIONS(geoId, keyword, uuid, userId)}`,
      false,
    );
  } catch (error) {
    throw new Error(error.message);
  }
}

/** */
export async function getCurrencyList() {
  try {
    return await InterceptorClient.getFNTSInstance(baseUrl).get(`${GET_CURRENCY_LIST()}`, false);
  } catch (error) {
    throw new Error(error.message);
  }
}
/**
 * get dynamic search placeholder
 *
 * @returns {Array} return the search text
 */
export async function getDynamicSearchTextPlaceholder() {
  try {
    const res = await InterceptorClient.getGatewayInstance().get(`${GET_DYNAMIC_SEARCH_TEXT_PLACEHOLDER()}`, false);
    return res;
  } catch (ex) {
    throw new Error({ ex, isSuccess: true });
  }
}

/**  Initiates a call to the FUS API using the InterceptorClient.
 *
 * This function retrieves data from the FUS API endpoint defined by `FUS_API`.
 * It utilizes the `InterceptorClient` to get the gateway instance and perform a GET request.
 *
 * @async
 * @function initiateFusCall
 * @returns {Promise<Object>} A promise that resolves to the response from the FUS API.
 * @throws {Error} Throws an error if the API call fails or if an error occurs during the request.
 *
 */
export async function initiateFusApiCall() {
  try {
    return await InterceptorClient.getGatewayInstance(baseUrl).get(`${FUS_API()}`, false);
  } catch (error) {
    throw new Error(error.message);
  }
}

/**
 * Fetches mega menu details from the API.
 * @returns {Promise<unknown>} A promise that resolves to the mega menu details.
 */
export const getMegaMenuContentAPI = async (isMobile, catalogID = "") => {
  return await InterceptorClient.getGatewayInstance().get(
    `${isMobile ? GET_MEGA_MENU() : GET_MEGA_MENU_DESKTOP(catalogID)}`,
    false,
    null,
    {
      "x-device-type": isMobile ? "app" : "desktop",
      "x-domain": "www.fnp.com",
    },
  );
};

/**  Initiates a post call to the User Subscribing API using the InterceptorClient.
 *
 * This function retrieves data from the User Subscribing API endpoint defined by `SUBSCRIBE_NEW_USER`.
 * It utilizes the `InterceptorClient` to get the fnts instance and perform a POST request.
 *
 * @async
 * @function subscribeNewUser
 * @returns {Promise<Object>} A promise that resolves to the response from the User Subscribing API.
 * @throws {Error} Throws an error if the API call fails or if an error occurs during the request.
 */
export async function subscribeNewUser(params) {
  const urlParams = new URLSearchParams(params);
  try {
    return await InterceptorClient.getFNTSInstance(baseUrl).post(urlParams, `${SUBSCRIBE_NEW_USER()}`, false, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
  } catch (error) {
    throw new Error(error.message);
  }
}

/**
 * This method fetches Timing for CALL and CHAT.
 *
 * @returns {object} response
 */
export async function getCallChatTime() {
  try {
    const response = await InterceptorClient.getGatewayInstance().get(`${GET_CALL_CHAT_TIME()}`, false);
    return response;
  } catch (ex) {
    return ex;
  }
}
/**
 * This method fetches Timing for CALL and CHAT.
 *
 * @returns {object} response
 */
export async function getSavedAddresses() {
  try {
    const response = await InterceptorClient.getFNTSInstance(baseUrl).get(`${GET_SAVED_ADDRESSES()}`, false);
    return response;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method fetches popular link desktop data for OOPS 404 page.
 *
 * @returns {object} response
 */
export async function getPopularLink404Desktop() {
  try {
    const response = await InterceptorClient.getGatewayInstance().get(`${GET_POPULAR_LINK404_DESKTOP()}`, false);
    return response;
  } catch (ex) {
    return ex;
  }
}

/**
 * This method fetches popular link mobile data for OOPS 404 page.
 *
 * @returns {object} response
 */
export async function getPopularLink404Mobile() {
  try {
    const response = await InterceptorClient.getGatewayInstance().get(`${GET_POPULAR_LINK404_MOBILE()}`, false);
    return response;
  } catch (ex) {
    return ex;
  }
}

/**
 * Function to publish data to CleverTap
 * @param {Object} params - Parameters to be sent in the API request
 * @returns {Promise<Object|null>} - The API response data or null in case of error
 */
export const onClevertapPublish = async (params) => {
  try {
    const response = await InterceptorClient.getFNTSInstance(baseUrl).post(
      params,
      GET_APP_CLEVERTAP_PUBLISH_URL(),
      false,
    );
    return response;
  } catch (err) {
    console.error("Error occurred while publishing to CleverTap:", err.message || "Something went wrong!");
  }
};
