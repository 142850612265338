import PropType from "prop-types";
import ImageTile from "../../../atoms/imageTile";
/**
 * A reusable React component for rendering a modal header.
 * @param {params} props
 * @typedef {Object} params
 * @property {JSX.Element} children - The content to be displayed inside the modal header. (Required)
 * @property {function} onToggleModal - Function to be called when the user toggles the modal visibility.
 * @property {boolean} isShowHeaderBorder - Flag to show header border.
 * @property {boolean} headerClasses - classes for more formatting header.
 * @returns {JSX.Element} The Modal header.
 */
const ModalHeader = ({ children, onToggleModal, isShowBorder, headerClasses }) => {
  return (
    <div
      className={`relative overflow-hidden text-ellipsis whitespace-nowrap p-16 pr-40 ${isShowBorder ? "border-b" : " "} ${headerClasses}`}
      role="modal-header"
    >
      {onToggleModal && (
        <div className="absolute right-16">
          <ImageTile
            imgSrc={"/icons/close-circle.svg"}
            imgAlt={"close-icon"}
            imgHeight={24}
            imgWidth={24}
            imgClass={"shrink-0 cursor-pointer"}
            onClick={onToggleModal}
          />
        </div>
      )}
      {children}
    </div>
  );
};
ModalHeader.propTypes = {
  children: PropType.node.isRequired,
  onToggleModal: PropType.func,
  isShowBorder: PropType.bool,
  headerClasses: PropType.string,
};
export default ModalHeader;
