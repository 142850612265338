"use client";
import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
import { desktopIndiaConfig } from "../../../constants/mockData/mockData";
import CustomImage from "../../atoms/customImage";
import TextField from "../../atoms/textField";
import Typography from "../../atoms/typography";
import RadioItemList from "../radioItemList";
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import { useDispatch, useSelector } from "react-redux";
import { getCountryListsData } from "../../../store/slices/locationLock";

/**
 * This component handles the country list dropdown.
 * @param {string} title - The title to be displayed.
 * @param {boolean} [isSearchEnable=false] - Flag to enable or disable search functionality.
 * @param {boolean} [isShowCountryCode=false] - Flag to show or hide country codes.
 * @param {object} selectedCountry - The currently selected country.
 * @param {boolean} [isAnimation=true] - Flag to enable or disable animations.
 * @param {func} [onCountryClick] - callback func trigger when click on country.
 * @returns {JSX.Element} The CountryListDropDown component.
 *
 */
export default function CountryListDropDown({
  title = "",
  isSearchEnable = true,
  isShowCountryCode = false,
  selectedCountry,
  isAnimation = true,
  onCountryClick,
}) {
  const dispatch = useDispatch();
  const countryListData = useSelector((state) => state.locationLock.countryListData);

  const [searchCountry, setSearchCountry] = useState("");
  const countryList = useRef("");

  const [filteredCountryList, setFilteredCountryList] = useState([]);
  const animationTime = desktopIndiaConfig.animationTime;

  useEffect(() => {
    if (!isEmpty(countryListData)) {
      countryList.current = countryListData;
      setFilteredCountryList(countryListData);
    }
  }, [countryListData]);

  useEffect(() => {
    if (isEmpty(countryListData)) {
      dispatch(getCountryListsData());
    }
  }, []);

  /**
   * Handles changes in the search input field.
   * @param {Event} e - The input change event.
   */
  const onInputChange = async (e) => {
    const inputValue = e.target.value;
    setSearchCountry(inputValue);
    if (isEmpty(inputValue)) {
      setFilteredCountryList(countryList.current);
    } else {
      let filteredListData = countryList.current.filter((item) => {
        return item.countryName.toLowerCase().includes(inputValue.toLowerCase());
      });
      setFilteredCountryList(filteredListData);
    }
  };

  /**
   * Handles the selection of the same country.
   * @param {object} item - The selected country item.
   */
  const onSameCountrySelection = async (item) => {
    if (get(item, "countryName") === get(selectedCountry, "countryName")) {
      onCountryClick(item);
    }
  };

  /**
   * Handles the selection of a country.
   * @param {object} item - The selected country item.
   */
  const onCountrySelection = async (item) => {
    onCountryClick(item);
  };

  return (
    <div className="mt-4">
      {title && title.length > 0 && (
        <Typography variant="p" Tag="p" classes="inter font-600 text-16 text-fnp-500 leading-4 p-16">
          {title}
        </Typography>
      )}
      {isSearchEnable && (
        <TextField
          placeholder="Search country"
          type="text"
          id="searchCountry"
          isRequired={false}
          variant="primary"
          startAdornment={<CustomImage src="/icons/search-bar.svg" alt="" sizes="10vw" width={24} height={24} />}
          inputValue={searchCountry}
          onChange={onInputChange}
        />
      )}
      {filteredCountryList?.length > 0 && (
        <div
          data-testid="countryList"
          className={`mt-16 flex transform flex-col overflow-auto rounded-8 border border-thinLightGrey pl-2 pr-2 transition-all scrollbar-thin scrollbar-webkit duration-[${animationTime}] ease-out ${isAnimation ? "max-h-[65vh] opacity-100 md:max-h-[30vh]" : "max-h-[0] opacity-0"}`}
        >
          {filteredCountryList.map((item) => {
            let newItem = { name: item.countryName, url: item.webLogo, countryCode: "+" + item.countryCode };
            return (
              <RadioItemList
                id={item.catelogID}
                name={item.catalogID}
                radioListType="countrySelection"
                listContent={newItem}
                isShowCountryCode={isShowCountryCode}
                key={item.countryId}
                isChecked={get(item, "countryName") === get(selectedCountry, "countryName")}
                onClick={() => onSameCountrySelection(item)}
                onChange={() => onCountrySelection(item)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

CountryListDropDown.propTypes = {
  title: PropTypes.string,
  isSearchEnable: PropTypes.bool,
  isShowCountryCode: PropTypes.bool,
  selectedCountry: PropTypes.object.isRequired,
  isAnimation: PropTypes.bool,
  onCountryClick: PropTypes.func.isRequired,
};
