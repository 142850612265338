/* eslint-disable max-lines */
import endsWith from "lodash/endsWith";
import { CURRENCY_ID, INDIAN_RUPEE, MONTH_NAMES } from "../../constants/common/common";
import { cleverTapCommonWords } from "../../analytics/clevertapConstants";
import { productTypes } from "../../constants/pageConstants/pdpConstants";

/**
 * Description placeholder
 * @date 04/04/2024 - 15:18:39
 *
 * @export
 * @returns {*}
 */
export default function isMobile() {
  let isMobileDevice = false;
  if (typeof window !== "undefined") {
    isMobileDevice = window?.matchMedia("(max-width: 1024px)")?.matches;
  }
  return isMobileDevice;
}

/**
 * Get the initials from a given name.
 * @param {string} name - The name to get initials from.
 * @returns {string} - Initials of the name.
 */
export const getFirstLetters = (name) => {
  const words = name?.split(" ");
  let initials = "";
  words?.forEach((word) => {
    initials += word.charAt(0);
  });
  return initials;
};

/**
 * Get the time elapsed since the given timestamp.
 * @param {number} timestamp - The timestamp in milliseconds.
 * @returns {string} - Time elapsed string.
 */
export const getTimeAgo = (timestamp) => {
  const reviewedTimeStamp = new Date(timestamp);
  const currentTimestamp = Date.now();
  const timeDifference = currentTimestamp - reviewedTimeStamp;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""} ago`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (weeks > 0) {
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
};

/**
 * Throttle function to reduce the multiple api calls.
 * @param {*} func
 * @param {*} delay
 * @returns
 */
export function throttle(func, delay) {
  let timeout = null;
  return function (...args) {
    if (!timeout) {
      timeout = setTimeout(() => {
        func(...args);
        timeout = null;
      }, delay);
    }
  };
}

/**
 * Splits a string by commas and returns the part at the specified index.
 *
 * @param {string} value - The string to split.
 * @param {number} index - The index of the part to return.
 * @returns {string} - The part of the string at the specified index, or an empty string if the index is out of bounds.
 */
export const stringWithCommaSplitter = (value, index) => {
  if (value !== undefined) {
    const parts = value?.split(", ");
    if (index >= 0 && index < parts?.length) {
      return parts[index];
    } else {
      return value;
    }
  }
};

/** To check if passed string is a json string
 *
 * @param {string} str json string
 *
 * @returns {boolean} true or false depending upon if string can be parsed
 */
export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * This method provides a polyfill for the smooth scroll, This gets called only when the native smooth scroll is not
 * present in the Browser
 *
 * @param {*} to element offset from top.
 * @param {*} duration duration for scroll.
 */
export const smoothScrollPolyfill = (to, duration) => {
  const element = document.scrollingElement || document.documentElement;
  const start = element.scrollTop;
  const change = to - start;
  const startDate = +new Date();
  /**
   * This method calculates the time for ease in and out
   *
   * @param {number} t current time.
   * @param {number} b start value.
   * @param {number} c change in value.
   * @param {number} d duration.
   * @returns {number} ease in out quadrant.
   */
  const easeInOutQuad = (t, b, c, d) => {
    let time = t;
    time /= d / 2;
    if (time < 1) return (c / 2) * time * time + b;
    time -= 1;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  /**
   * This performs the animation to the scroll.
   */
  const animateScroll = () => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;
    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = to;
    }
  };
  animateScroll();
};

/**
 * This method provides a scroll behavior on click to the section present in the Dome
 *
 * @param {string} scrollId - div Id
 * @param {number} offsetValue - offset value
 */
export const scrollToSection = (scrollId, offsetValue) => {
  const supportsNativeSmoothScroll = "scrollBehavior" in document.documentElement.style;
  if (document.getElementById(scrollId)) {
    const eleOffsetTop = document.getElementById(scrollId).offsetTop - offsetValue;
    if (supportsNativeSmoothScroll) {
      window.scroll({ top: eleOffsetTop, behavior: "instant" });
    }
  }
};

/**
 * This method formats a given number into a shortened form using "K"
 * for thousands, appending "Reviews" to the end of the result.
 *
 * @param {number} number - The number to format
 * @returns {string} - The formatted string in "K" format if the number is
 * greater than or equal to 1000, otherwise returns the number followed by "Reviews"
 */
export const formatToK = (number, isReviewTextHide) => {
  if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K";
  }
  return `${number?.toString()} ${isReviewTextHide ? "" : "Reviews"}`;
};

/**
 * This method converts a given 24-hour format time string into a 12-hour format
 * with "AM" or "PM" appended to the result.
 *
 * @param {string} time24 - The time string in "HH:MM" 24-hour format
 * @returns {string} - The formatted time string in 12-hour format with "AM" or "PM"
 */
export const convertTo12Hour = (time24) => {
  const [hours, minutes] = time24.split(":");
  const AmPm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  return `${hours12}:${minutes.toString().padStart(2, "0")} ${AmPm}`;
};

/**
 * This function formats a date object into a human-readable string.
 *
 * @function
 * @param {Date} date - The date object to format.
 * @returns {string} A string representation of the date in the format "DDth MMMM" (e.g. "12th January").
 */
export const formatSelectedDate = (date) => {
  const day = date?.getDate();
  const month = date?.toLocaleString("default", { month: "long" });
  const suffix =
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
          ? "rd"
          : "th";
  return `${day}${suffix} ${month}`;
};

/**
 * Debounce function to delay the execution of a given function until after a specified wait time.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The number of milliseconds to delay.
 * @returns {Function} - A debounced version of the given function.
 */
export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

/**
 * This function returns an array of large images for the slick image slider for Desktop Product  card
 *
 * @param {string} url contains product image url
 * @param {number} imageCount number of images of particular product
 * @returns {Array} returns an array of multiple images
 */
export const getProductLargeImgList = (url, imageCount) => {
  if (!url) return ["/assets/images/image_placeholder-bb.png"];
  if (!imageCount) return [`${url.replace("/m/", "/l/")}`];

  const productImages = [];
  for (let index = 1; index <= imageCount; index += 1) {
    let imageUrl = url.replace("/m/", "/l/").split(".jpg")[0];
    const hasExtra = endsWith(imageUrl, "_1");
    if (hasExtra) {
      imageUrl = imageUrl.slice(0, -2);
    }
    productImages.push(`${imageUrl}_${index}.jpg`);
  }

  return productImages;
};

/**
 * Function to filter out null, undefined, and empty string values from an object
 *
 * @param {object} obj contains object to be filtered
 * @returns {object} filtered object
 */
export const removeEmptyNullAndUndefinedFromObject = (obj) => {
  return Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined && value !== ""),
  );
};

/**
 * Convert the filter objects in to array.
 * @param {*} filterObj
 * @returns
 */
export const convertFilterObjToArray = (filterObj) => {
  const manipulatedArr = filterObj
    ? Object?.values(filterObj)?.reduce((acc, item) => {
        if (item) {
          return [...acc, item];
        }
        return acc;
      }, [])
    : [];

  return manipulatedArr;
};

/** */
export const updateCurrency = (selectedCurrency = {}, price) => {
  let updatedPrice = price;
  let currencySymbol = INDIAN_RUPEE;
  if (
    price &&
    selectedCurrency?.currency?.id &&
    selectedCurrency?.currency?.factor &&
    selectedCurrency?.currency?.id !== CURRENCY_ID
  ) {
    updatedPrice = (selectedCurrency?.currency?.factor * parseFloat(price)).toFixed(2);
    currencySymbol = selectedCurrency?.currency?.id;
  }
  return `${currencySymbol} ${updatedPrice}`;
};

/**
 *
 */
export const intersectionObserver = (ref, apiCb) => {
  /**
   * Intersection Observer callback
   * @param {IntersectionObserverEntry[]} entries
   */
  const intersection = (entries) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      apiCb();
      // eslint-disable-next-line no-use-before-define
      observe.unobserve(ref.current); // Stop observing once data is fetched
    }
  };
  const observe = new IntersectionObserver(intersection, {
    threshold: 0.1, // Trigger when 10% of the section is visible
  });
  if (ref.current) {
    observe.observe(ref.current);
  }
};
/**
 * Parses a metadata string to extract title, description, keywords, canonical URL, Twitter meta tags, OG meta tags, and relAlt tags.
 *
 * @param {string} metaString- The raw metadata string.
 * @returns {Object} An object containing title, description, keywords, canonical URL, Twitter meta tags, OG meta tags, and relAlt tags.
 */
export const parseMetaString = (metaString) => {
  if (!metaString) {
    return {
      title: "",
      description: "",
      keywords: "",
      canonical: "",
      og: {
        title: "",
        description: "",
        image: "",
        url: "",
        type: "",
      },
      headAnalyticsData: {
        links: [],
        scriptSources: [],
        inLineScripts: [],
      },
    };
  }
  const titleMatch = metaString?.match(/<title>(.*?)<\/title>/);
  const descriptionMatch = metaString?.match(/<meta name="description" content="(.*?)"/);
  const keywordsMatch = metaString?.match(/<meta name="keywords" content="(.*?)"/);
  const canonicalMatch = metaString?.match(/<link rel="canonical" href="(.*?)"/);
  const ogTitleMatch = metaString?.match(/<meta property="og:title" content="(.*?)"/);
  const ogDescriptionMatch = metaString?.match(/<meta property="og:description" content="(.*?)"/);
  const ogImageMatch = metaString?.match(/<meta property="og:image" content="(.*?)"/);
  const ogUrlMatch = metaString?.match(/<meta property="og:url" content="(.*?)"/);
  const ogTypeMatch = metaString?.match(/<meta property="og:type" content="(.*?)"/);

  const scriptTags = metaString?.match(/<script[^>]*>.*?<\/script>/gs);
  const srcScripts = [];
  const inLineScripts = [];

  scriptTags?.forEach((scriptTag) => {
    // Look for src attributes strictly within the opening <script> tag
    const srcMatch = scriptTag.match(/<script[^>]*src=["']?([^"'>]+)["']?[^>]*>/);
    const hasAsync = /async/.test(scriptTag); // Check if async is present
    const hasDefer = /defer/.test(scriptTag); // Check if defer is present
    const typeMatch = scriptTag.match(/type=["'](.*?)["']/); // Look for type attribute
    const scriptType = typeMatch ? typeMatch[1] : "text/javascript";

    if (srcMatch) {
      // If a src attribute is found, it's an external script
      srcScripts.push({
        src: srcMatch[1],
        async: hasAsync,
        defer: hasDefer,
        type: scriptType,
      });
    } else {
      // If no src attribute, it's an inline script
      const inlineScript = scriptTag.replace(/<script[^>]*>|<\/script>/g, "").trim();

      // Ensure inline script is not empty or only whitespace
      if (inlineScript) {
        inLineScripts.push({
          content: inlineScript,
          type: scriptType,
        });
      }
    }
  });

  // Match all link tags with dns-prefetch, prefetch, preload, and preconnect
  const dnsPrefetchLinks = metaString?.match(/<link rel="(dns-prefetch|prefetch|preload|preconnect)" href="(.*?)"/g);

  // Map the matched links into the desired object format
  const links = dnsPrefetchLinks
    ? dnsPrefetchLinks.map((link) => {
        // Extract the rel and href attributes from the matched link string
        const relMatch = link.match(/rel="(.*?)"/);
        const hrefMatch = link.match(/href="(.*?)"/);

        return {
          rel: relMatch ? relMatch[1] : "",
          href: hrefMatch ? hrefMatch[1] : "",
        };
      })
    : [];

  return {
    title: titleMatch ? titleMatch[1] : "",
    description: descriptionMatch ? descriptionMatch[1] : "",
    keywords: keywordsMatch ? keywordsMatch[1] : "",
    canonical: canonicalMatch ? canonicalMatch[1] : "",
    og: {
      title: ogTitleMatch ? ogTitleMatch[1] : "",
      description: ogDescriptionMatch ? ogDescriptionMatch[1] : "",
      image: ogImageMatch ? ogImageMatch[1] : "",
      url: ogUrlMatch ? ogUrlMatch[1] : "",
      type: ogTypeMatch ? ogTypeMatch[1] : "",
    },
    headAnalyticsData: {
      links,
      scriptSources: srcScripts,
      inLineScripts,
    },
  };
};
/**
 * This method is used to check if cutoff timestamp exists
 *
 * @param {object} cutOffTimeStamp contains time.
 * @returns {boolean} returns true or false.
 */
export const isSameDayDeliveryAvailable = (cutOffTimeStamp) => {
  if (!cutOffTimeStamp) {
    return true;
  }
  const currentTime = new Date();
  const cuttoffSplit = cutOffTimeStamp && cutOffTimeStamp?.split(":");
  const cutoffTime = new Date();
  cutoffTime.setHours(cuttoffSplit[0]);
  cutoffTime.setMinutes(cuttoffSplit[1]);
  const isCutoffTimePassed = cutoffTime >= currentTime;
  return isCutoffTimePassed;
};
/**
 * This method is used to format the date
 *
 * @param {object} date date object.
 * @param {string} separator format seperator.
 * @returns {string} modified object
 */
export function dateToDMY(date, separator = "-") {
  const d = date?.getDate();
  const m = date?.getMonth() + 1;
  const y = date?.getFullYear();
  return `${d <= 9 ? `0${d}` : d}${separator}${m <= 9 ? `0${m}` : m}${separator}${y}`;
}
/**
 * this function check is date passed
 *
 * @param {string} date date
 * @returns {boolean} true or false
 */
export function checkCurrentAndFutureDate(date) {
  const currentDate = new Date();
  const [day, month, year] = date.split("-");
  const formattedBlockedDate = new Date(`${month}/${day}/${year}`);

  currentDate.setHours(0, 0, 0, 0);
  formattedBlockedDate.setHours(0, 0, 0, 0);
  if (formattedBlockedDate.getTime() >= currentDate.getTime()) {
    return true;
  }
  return false;
}
/**
 * this function check the dates list contain current or future date
 *
 * @param {object} dates dates
 * @returns {boolean} true or false
 */
export function getDates(dates) {
  let passedDate = false;
  // eslint-disable-next-line no-unused-expressions
  dates &&
    dates.length > 0 &&
    dates.forEach((date) => {
      if (!passedDate) {
        passedDate = checkCurrentAndFutureDate(date);
      }
    });
  return passedDate;
}

/**
 * Utility method to get ordinals
 *
 * @param {object} date date value
 * @returns {string} ordinal.
 */
export function getSuffix(date) {
  const s = ["th", "st", "nd", "rd"];
  const v = date % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
}

/**
 * This method takes the "-" separated date eg: 07-11-2024 and finds out its delivery day
 * that can be either Today or Tomorrow.
 *
 * @param { string } deliveryDate .
 * @param {boolean} isWithDateOrdinal flag to enable date
 * @returns { string } String in delivery date Today and Tomorrow.
 */
export function getEarliestDeliveryDateString(deliveryDate, isWithDateOrdinal) {
  const inputDate = deliveryDate?.split("-");
  const day = Number(inputDate && inputDate[0]);
  const month = Number(inputDate && inputDate[1]);
  const year = Number(inputDate && inputDate[2]);
  const today = new Date();
  const tomorrow = new Date(today?.getTime() + 24 * 60 * 60 * 1000);
  if (day === today?.getDate() && month === today?.getMonth() + 1 && year === today?.getFullYear()) return "Today";
  if (day === tomorrow?.getDate() && month === tomorrow?.getMonth() + 1 && year === tomorrow?.getFullYear())
    return "Tomorrow";
  if (isWithDateOrdinal) {
    return `${day}${getSuffix(day)} ${MONTH_NAMES[month - 1]}`;
  }
  return deliveryDate;
}

/**
 * Retrieves the hash part of the current URL.
 *
 * @returns {string} The hash portion of the current URL (e.g., "#section1").
 *                   Returns an empty string if there is no hash or if executed in a non-browser environment.
 */
export const getHash = () => (typeof window !== "undefined" ? window.location.hash : "");

/**
 * Removes a specific hashtag from a URL.
 *
 * @param {string} url - The full URL containing the hashtags.
 * @param {string} hashtag - The specific hashtag to remove (e.g., "removeMe").
 * @returns {string} - The updated URL with the specified hashtag removed.
 * @throws {TypeError} - If the input URL is invalid.
 */
export function removeHashtagFromURL(hashtag) {
  const url = typeof window !== "undefined" ? window.location.href : "";
  const finalHash = hashtag.startsWith("#") ? hashtag.slice(1) : hashtag;
  try {
    const urlObj = new URL(url);
    const currentHash = urlObj.hash;

    if (currentHash.includes(finalHash)) {
      const updatedHash = currentHash
        .split("#") // Split the hash into parts
        .filter((tag) => tag !== finalHash) // Remove the specified hashtag
        .join("#"); // Join the remaining parts

      urlObj.hash = updatedHash ? `#${updatedHash}` : ""; // Update the hash
    }

    // Use history API to update the URL without reloading
    if (typeof window !== "undefined") {
      window.history.replaceState(null, "", urlObj.toString());
    }
  } catch (error) {
    console.error("Invalid URL:", error.message);
    return url;
  }
}

/**
 * Triggers a click event on the button with the ID "bottom_drawer_btn".
 * This function is typically used to programmatically close a bottom drawer component.
 *
 * @function closeBottomDrawerFromParent
 */
export function closeBottomDrawerFromParent() {
  const closeBottomDrawer = document.getElementById("bottom_drawer_btn");
  if (closeBottomDrawer) {
    closeBottomDrawer.click();
  }
}

/**  This method is used to calculate percentage off product
 *
 * @param {string} dateTimeStr date time string.
 * @returns {object} date object
 */
export const convertTimestampStringToDate = (dateTimeStr) => {
  let date = 0;
  if (dateTimeStr) {
    let dateTimeParts = dateTimeStr.split(" ");
    const timePart = dateTimeParts[1].split(":");
    dateTimeParts = dateTimeParts[0].split("-");
    date = new Date(dateTimeParts[2], parseInt(dateTimeParts[1], 10) - 1, dateTimeParts[0], timePart[0], timePart[1]);
  }
  return date;
};

/**
 * This method is used display time lapse clock
 *
 * @param {number} timer timestamp.
 * @returns {string} modified object
 */
export const clock = (timer) => {
  const delta = Math.floor(timer / 1000);
  const hours = Math.floor(delta / 3600);
  const minutes = Math.floor((delta - 3600 * hours) / 60);
  const seconds = delta - 3600 * hours - 60 * minutes;
  const displayTime = `${(hours < 10 ? "0" : "") + hours}:${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
  return displayTime;
};

/**
 * Determines the page name based on certain conditions.
 *
 * @param {boolean} isHome - A flag indicating if the page is the home page.
 * @param {boolean} isPLP - A flag indicating if the page is a Product Listing Page (PLP).
 * @param {string} categoryId - The category ID to return if the page is a PLP.
 * @param {string} urlIdentifier - A URL identifier to return if the page is neither home nor PLP.
 *
 * @returns {string} The page name based on the input flags:
 * - Returns `cleverTapCommonWords.HOME` if `isHome` is true.
 * - Returns the `categoryId` if `isPLP` is true.
 * - Returns `urlIdentifier` if neither `isHome` nor `isPLP` are true.
 */
export const pageName = (isHome, isPLP, categoryId, urlIdentifier) => {
  if (isHome) {
    return cleverTapCommonWords.HOME;
  } else if (isPLP) {
    return categoryId;
  }
  return urlIdentifier;
};

/**
 * This function calculates the time left until a given end date.
 *
 * @param {string} endDate - The end date.
 *  @returns {object} An object with the time left in hours, minutes, and seconds.
 */
export const calculateTimeLeft = (endDate) => {
  const now = new Date();
  const end = new Date(endDate);
  const difference = end - now;

  let timeLeft = { hours: 0, minutes: 0, seconds: 0 };

  if (difference > 0) {
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    timeLeft = {
      hours,
      minutes,
      seconds,
    };
  }

  return timeLeft;
};

/**
 * Toggles the visibility of an element by its ID.
 *
 * @param {string} id - The ID of the element to show or hide.
 * @param {boolean} isVisible - Whether to show (true) or hide (false) the element.
 */
export const toggleElementVisibility = (id, isVisible) => {
  const element = document.getElementById(id);
  if (!element) return;
  element.style.display = isVisible ? "block" : "none";
};

/**
 * Checks if the given date (`selectedDate`) is today or tomorrow.
 *
 * @param {Date} selectedDate - The date that needs to be checked.
 * @param {Date} [today=new Date()] - (Optional) The reference date for "today". Defaults to the current date if not provided.
 *
 * @returns {Object} - Returns an object with two boolean properties:
 *    - `isToday`: `true` if `selectedDate` is today's date, otherwise `false`.
 *    - `isTomorrow`: `true` if `selectedDate` is tomorrow's date, otherwise `false`.
 */
export function isDateTodayOrTomorrow(selectedDate, today = new Date()) {
  const tomorrow = new Date(today);
  tomorrow.setDate(today?.getDate() + 1);

  const isDateToday = dateToDMY(today) === dateToDMY(selectedDate);
  const isDateTomorrow = dateToDMY(tomorrow) === dateToDMY(selectedDate);

  return { isDateToday, isDateTomorrow };
}
/**
 * This method initiates the lazy loading of all images on a page.
 * When the element is in the view port the data-src attributes or data-imageurl attributes
 * values are assigned to src attributes to load the images.
 */
export const lazyLoadImgs = () => {
  const elements = document.querySelectorAll("[data-imageurl],[data-src]");
  if (elements) {
    if ("IntersectionObserver" in window) {
      const imageObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;
            if (lazyImage?.dataset?.imageurl) {
              if (lazyImage?.src !== lazyImage.dataset.imageurl) {
                lazyImage.src = lazyImage.dataset.imageurl;
              }
            } else if (lazyImage?.dataset?.src) {
              if (lazyImage.src !== lazyImage.dataset.src) {
                lazyImage.src = lazyImage.dataset.src;
              }
            }
          }
        });
      });
      elements.forEach((v) => imageObserver.observe(v));
    } else {
      elements.forEach((elem) => {
        const element = elem;
        if (element?.dataset?.src) element.src = elem.dataset.src;
        else if (element?.dataset?.imageurl) element.src = elem.dataset.imageurl;
      });
    }
  }
};
/**
 * Determines the delivery date.
 *
 * @param {string} deliveryDates The dateString of the product.
 * @returns {Date} The delivery Date.
 */
export const processDeliveryDate = (deliveryDates) => {
  /**
   * if deliveryDates is not present then sending a empty string.
   */
  let dateObj = "";
  if (deliveryDates && typeof deliveryDates === "string") {
    let dateParts;
    if (deliveryDates.includes("-")) {
      dateParts = deliveryDates.split("-");
    } else if (deliveryDates.includes("/")) {
      dateParts = deliveryDates.split("/");
    } else {
      return dateObj;
    }
    if (dateParts.length === 3) {
      dateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
      return dateObj;
    }
    return dateObj;
  }
  return dateObj;
};
/**
 * This method is used to calculate percentage off product
 *
 * @param {number} listPrice list price.
 * @param {number} price price.
 * @returns {number} percentage
 */
export function calDiscountPercentage(listPrice, price) {
  const priceDifference = parseFloat(listPrice) - parseFloat(price);
  const percentage = (parseFloat(priceDifference) / parseFloat(listPrice)) * 100;
  return Math.floor(percentage);
}
/**
 * Determines the delivery type based on the primary category ID.
 *
 * @param {string} primaryCategoryId The primary category ID of the product.
 * @returns {string} The delivery type ('express' or 'courier').
 */
export const getProductDeliveryType = (primaryCategoryId) => {
  if (primaryCategoryId === productTypes.EXPRESS || primaryCategoryId === productTypes.PERSONALIZED) {
    return productTypes.EXPRESS;
  }
  return productTypes.COURIER;
};

/**
 * Smoothly scrolls the page to the element with the specified ID.
 *
 * @param {string} elementId - The ID of the target element to scroll to.
 * @throws {Error} Logs an error to the console if the element with the given ID is not found.
 *
 */
/**
 * Smoothly scrolls the page to the element with the specified ID, with an offset.
 *
 * @param {string} elementId - The ID of the target element to scroll to.
 * @param {number} [offset=0] - The offset to stop before the target element.
 */
export const onSmoothScrollToElement = (elementId, offset = 50) => {
  const targetElement = document.getElementById(elementId);
  if (targetElement) {
    // Calculate the position to scroll to with offset
    const offsetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;

    // Smooth scroll to the calculated position
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  } else {
    console.error(`Element with id "${elementId}" not found.`);
  }
};
/**
 * @param {number} currYear current year
 * @param {number} lastNYears array length required
 * @returns {Array} category name
 */
export function getLastNYears(currYear, lastNYears) {
  const years = [];
  for (let i = 0; i <= lastNYears; i += 1) {
    const year = currYear - i;
    years.push({ year });
  }
  return years;
}
/** */
export function getMonthNumber(monthName) {
  const date = new Date(`${monthName} 1, 2000`); // Create a date object with the given month name
  return date.getMonth() + 1; // getMonth() returns 0-based month, so add 1
}
