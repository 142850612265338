import { catalogueCountries, COUNTRIES } from "../../constants/common/common";

/**
 * Parses a URL query string into a JSON object.
 *
 * @param {string} query - The query string to parse.
 *
 * @returns {Object}
 */
const getJsonFromQueryString = (query) => {
  // TBD: Needs to be discussed in case of Search Page
  if (!query) {
    return {};
  }
  const result = {};
  query.split("&").forEach((part) => {
    if (!part) return;
    const splittedPart = part.split("+").join(" ");
    const eq = splittedPart.indexOf("=");
    let key = eq > -1 ? splittedPart.substr(0, eq) : splittedPart;
    const val = eq > -1 ? decodeURIComponent(splittedPart.substr(eq + 1)) : "";
    const from = key.indexOf("[");
    if (from === -1) result[decodeURIComponent(key)] = val;
    else {
      const to = key.indexOf("]", from);
      const index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) result[key] = [];
      if (!index) result[key].push(val);
      else result[key][index] = val;
    }
  });
  return result;
};

/**
 * Determines the catalogue and category based on URL slugs and search parameters.
 *
 * @param {string[]} slugs - An array of slugs extracted from the URL.
 * @param {Object} searchParams - Search parameters used to customize the catalogue or category selection.
 *
 * @returns {Object}
 */
const getCatalogueAndCategory = (slugs, searchParams) => {
  let catalogue = COUNTRIES.INDIA;
  let category = "";

  if (slugs?.length > 0) {
    const selectedCountry = slugs[0];
    const isInternational = catalogueCountries.has(selectedCountry);
    if (isInternational) {
      catalogue = selectedCountry;
      category = slugs[1] | "";
    } else if (selectedCountry === "search") {
      const slugObject = slugs.reduce((acc, slug, index) => {
        acc[`slug${index + 1}`] = slug;
        return acc;
      }, {});

      const customQuery = {
        ...searchParams,
        ...slugObject,
      };

      let path = "/" + slugs.join("/");
      const obj = getJsonFromQueryString(path);
      catalogue =
        (obj?.FNP_CURRENT_CATALOG_ID ?? customQuery?.FNP_CURRENT_CATALOG_ID)
          ? customQuery?.FNP_CURRENT_CATALOG_ID
          : COUNTRIES.INDIA;
      category = "search";
    } else {
      category = slugs[0];
    }
  }

  return { catalogue, category };
};

export default getCatalogueAndCategory;
