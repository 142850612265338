import PropTypes from "prop-types";
import ListDropDown from "../listDropDown";
import CustomImage from "../../atoms/customImage";
import { charactersOnlyRegex, digitsOnlyRegex } from "../../../constants/regex";
import { COUNTRIES } from "../../../constants/common/common";

/**
 * Renders a dropdown list section based on the type of delivery and search criteria.
 * Displays suggestions for pincode, location, or international selection based on user input.

 * @param {Object} props - The properties object.
 * @returns {JSX.Element|null} A dropdown list component based on search criteria, or `null` if no matches are found.
 */
const ListDropDownSection = ({
  suggestions,
  searchValue,
  deliveryType,
  onHandlePincodeSelection,
  selectedRow,
  onHandleSelectedPlace,
  selectedCountry,
  handleInternationSelection,
}) => {
  return (
    <div>
      {suggestions?.length > 0 &&
        searchValue.length >= 2 &&
        searchValue.match(/[0-9]{2}/) &&
        deliveryType === "pincode" && (
          <ListDropDown
            id="pincode-list-dropdown"
            suggestions={suggestions}
            keyname="location-list"
            onClick={onHandlePincodeSelection}
            onKeyDown={onHandlePincodeSelection}
            classes="overflow-auto scrollbar-thin scrollbar-webkit max-h-[38vh]"
            startAdornment={(props) => (
              <CustomImage src="/icons/location-Icon.svg" sizes="100vw" alt="location_pin" {...props} />
            )}
            isKeyDownSelectedRow={selectedRow}
          />
        )}
      {suggestions?.length > 0 &&
        searchValue.length >= 3 &&
        (searchValue.match(charactersOnlyRegex) || (searchValue.match(digitsOnlyRegex) && searchValue.length >= 6)) &&
        deliveryType === "location" && (
          <ListDropDown
            id="location-list-dropdown"
            suggestions={suggestions}
            keyname="location-list"
            onClick={onHandleSelectedPlace}
            onKeyDown={onHandleSelectedPlace}
            classes="scrollbar-thin scrollbar-webkit max-h-[38vh]"
            startAdornment={(props) => (
              <CustomImage src="/icons/location-Icon.svg" sizes="100vw" alt="location_pin" {...props} />
            )}
            listValue="description"
            isKeyDownSelectedRow={selectedRow}
          />
        )}

      {selectedCountry?.catelogID !== COUNTRIES.INDIA && (
        <ListDropDown
          id="location-list-dropdown"
          suggestions={suggestions}
          keyname="location-list"
          // eslint-disable-next-line react/jsx-handler-names
          onClick={handleInternationSelection}
          classes="scrollbar-thin scrollbar-webkit max-h-[38vh]"
          startAdornment={(props) => (
            <CustomImage src="/icons/location-Icon.svg" sizes="100vw" alt="location_pin" {...props} />
          )}
          listValue="cityName"
          isKeyDownSelectedRow={selectedRow}
        />
      )}
    </div>
  );
};

export default ListDropDownSection;

ListDropDownSection.propTypes = {
  suggestions: PropTypes.array.isRequired,
  searchValue: PropTypes.string.isRequired,
  deliveryType: PropTypes.string,
  onHandlePincodeSelection: PropTypes.func,
  onHandleSelectedPlace: PropTypes.func,
  selectedRow: PropTypes.number,
  visitedCountry: PropTypes.object,
  handleInternationSelection: PropTypes.func,
  selectedCountry: PropTypes.object.isRequired,
};
