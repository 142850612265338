/* eslint-disable react/jsx-boolean-value */
import React from "react";
import PropTypes from "prop-types";
import Typography from "../typography";

/**
 * Breadcrumb component to display a breadcrumb trail.
 * @param {Object[]} items - An array of objects representing breadcrumb items.
 * @param {string} items[].href - The URL for the breadcrumb item.
 * @param {string} items[].label - The label/text for the breadcrumb item.
 * @param {boolean} [items[].svg] - Optional SVG icon for the breadcrumb item.
 * @returns {JSX.Element} - Breadcrumb component JSX.
 */
const Breadcrumb = ({ items, catalogId, isShowSchema = true, isFromMicrosite = false }) => {
  return (
    <div itemScope itemType="https://schema.org/BreadcrumbList" className="navigation px-16">
      <nav
        className={`py-16 ${isFromMicrosite ? "px-16 sm:px-40" : "sm:px-0"}`}
        aria-label="breadcrumb"
        id="breadcrumbs"
        {...(isShowSchema && {
          itemProp: "itemListElement",
          itemScope: true,
          itemType: "http://schema.org/ListItem",
        })}
      >
        <ul className="flex w-full flex-wrap items-center truncate md:flex-nowrap">
          {items?.map(({ url, label }, index) => {
            const href = catalogId === "india" ? url : index === 0 ? `/${catalogId}/gifts-lp` : `/${catalogId}${url}`;
            return items?.length - 1 > index ? (
              <li key={index} className="inline-flex items-center capitalize">
                <a
                  href={href}
                  itemProp="name"
                  className={`flex items-center text-12 text-grey-400 hover:text-blue-600 focus:text-blue-600 focus:outline-none`}
                >
                  {label}
                  {index < items?.length - 1 && (
                    <span className="flex size-5 flex-shrink-0 items-center justify-center text-16 text-grey-400">
                      /
                    </span>
                  )}
                </a>
                {isShowSchema && <meta itemProp="position" content={index + 1} />}
              </li>
            ) : (
              <li key={index} className="inline-flex items-center">
                <Typography Tag={"p"} variant="h6" classes="flex items-center text-12 text-grey-500" itemProp="name">
                  {label}
                </Typography>
                {isShowSchema && <meta itemProp="position" content={index + 1} />}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string.isRequired,
      svg: PropTypes.bool,
    }),
  ).isRequired,
  catalogId: PropTypes.string.isRequired,
  isShowSchema: PropTypes.bool,
  isFromMicrosite: PropTypes.bool,
};

export default Breadcrumb;
