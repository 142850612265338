import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "../../../utils/pdp/common";

/**
 * ListDropDown component renders a list drop-down with autocomplete functionality.
 * @param {object} props - Component props.
 * @param {Array} props.suggestions - The array of suggestions to be rendered.
 * @param {React.ComponentType} props.startAdornment - The start adornment component to be rendered for each suggestion item.
 * @param {Function} props.onClick - The function to handle click events on suggestion items.
 * @param {string} [props.classes=""] - Additional CSS classes to apply to the component.
 * @returns {JSX.Element} - The rendered ListDropDown component.
 */
const ListDropDown = ({
  onClick,
  classes = "",
  id = "",
  suggestions,
  listValue,
  startAdornment: StartAdornment,
  isKeyDownSelectedRow,
  ...restparams
}) => {
  return (
    <>
      {suggestions && (
        <ul id={id} className={`z-10 max-h-[30vh] shadow-sm ${classes}`} data-testid="list-dropdown">
          {suggestions.map((item, index) => (
            <li
              key={index}
              className={`flex cursor-pointer items-center justify-start border-b-1 border-gray-100 px-8 py-16 hover:bg-slate-100 ${isKeyDownSelectedRow === index ? "bg-slate-100" : ""}  `}
              onClick={() => onClick(item)}
              id={`list-item-${index}`}
              {...restparams}
            >
              {StartAdornment && <StartAdornment width={24} height={24} sizes="100vw" isPriority={false} />}
              {typeof item === "object" ? (
                <span className="w-[calc(100%-24px)] px-8">
                  {listValue && item[listValue] !== undefined ? (
                    <span className="block w-full overflow-hidden text-ellipsis whitespace-nowrap text-14">
                      {item[listValue]}
                    </span>
                  ) : (
                    Object.values(item).map((value, i) => (
                      <span key={i} className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-14">
                        {i === 0 ? value + ", " : value}
                      </span>
                    ))
                  )}
                </span>
              ) : (
                <span className="block w-full overflow-hidden text-ellipsis whitespace-nowrap text-14">
                  {capitalizeFirstLetter(item)}
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

ListDropDown.propTypes = {
  id: PropTypes.string,
  suggestions: PropTypes.array.isRequired,
  startAdornment: PropTypes.elementType,
  classes: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  listValue: PropTypes.string,
  isKeyDownSelectedRow: PropTypes.number,
};

export default ListDropDown;
