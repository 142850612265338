"use client";
import PropTypes from "prop-types";
import React, { useState } from "react";

/**
 * A component representing tabs.
 *
 * @component
 * @param {object} props - The props of the component.
 * @param {ReactNode} props.children - The children elements representing the tabs.
 * @returns {JSX.Element} - Returns the JSX element representing the Tabs component.
 */
const Tabs = ({ children = Node, classes = "" }) => {
  /**
   * Represents the currently selected index.
   * @type {number}
   */
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <React.Fragment>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child) && (index === 0 || index === selectedIndex + 1)) {
          return React.cloneElement(child, {
            id: index,
            selected: selectedIndex,
            class: `${classes}`,
            onChange: setSelectedIndex,
          });
        }
        return null;
      })}
    </React.Fragment>
  );
};

Tabs.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};

export default Tabs;
