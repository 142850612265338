// utils/localStorage.js

/**
 * Safely sets an item in localStorage.
 * Automatically converts non-string values to JSON.
 *
 * @param {string} key - The key under which to store the value.
 * @param {*} value - The value to store (will be stringified if not a string).
 * @returns {void}
 */
export const setLocalStorageItem = (key, value) => {
  try {
    return window.localStorage.setItem(key, value);
  } catch (error) {
    console.error(`Error setting localStorage item: ${key}`, error);
  }
};

/**
 * Safely gets an item from localStorage.
 * Automatically parses JSON if the stored value is a stringified object.
 *
 * @param {string} key - The key of the item to retrieve.
 * @param {*} [defaultValue=null] - The default value to return if the key doesn't exist or parsing fails.
 * @returns {*} The retrieved value, or the default value if the item is not found or parsing fails.
 */
export const getLocalStorageItem = (key) => {
  try {
    const value = window.localStorage.getItem(key);
    if (value === null || value === undefined || value === "undefined") {
      return "";
    } else if (typeof value === "string") {
      return value;
    } else return [];
  } catch (error) {
    console.error(`Error getting localStorage item: ${key}`, error);
  }
};

/**
 * Removes an item from localStorage.
 *
 * @param {string} key - The key of the item to remove.
 * @returns {void}
 */
export const removeLocalStorageItem = (key) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing localStorage item: ${key}`, error);
  }
};
