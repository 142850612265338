import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  retainedPincode: {},
  loginDetail: {},
  fvId: "",
  loading: true,
};

//FUS gets the data for login,cart quatity and pincode retention detail of user
export const FusSlice = createSlice({
  name: "fusSlice",
  initialState,
  reducers: {
    initiateFus: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    setFusData: (state, action) => {
      return {
        ...state,
        loginDetail: action.payload?.checklogin,
        fvId: action.payload?.fvId,
        retainedPincode: action.payload?.pincodeGivenByUser,
        loading: action.payload?.checklogin?.loggedin ? true : false,
      };
    },
  },
});

export default FusSlice.reducer;
export const { initiateFus, setFusData } = FusSlice.actions;
