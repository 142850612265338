import PropTypes from "prop-types";
import CustomImage from "../customImage";

/**
 * IconButtonComponent is a button component that displays an icon along with optional text.
 *
 * @param {object} props - The props object.
 * @param {string} [props.label=""] - The optional text label displayed alongside the icon.
 * @param {string} props.buttonVariant - The variant of the button ("icon", "iconWithText", "iconWithTextFilled", "iconWithoutTextFilled", "iconWithTextOutlined").
 * @param {string} props.iconSrc - Url string of image.
 * @param {func} props.onClick - On click handler function.
 * @param {string} props.classes - custom classes.
 * @param {string} props.imgWidth - width of image.
 * @param {string} props.imgHeight - height of image.
 * @param {string} props.labelColor - custom label text color.
 * @returns {JSX.Element} The IconButtonComponent.
 */
const IconButtonComponent = ({
  label = "",
  buttonVariant = "",
  iconSrc = "",
  onClick,
  classes = "",
  className = "",
  imgWidth = 24,
  imgHeight = 24,
  labelColor = "",
  imageContainerMargin = "mx-6 flex items-center",
  altText = "",
  isPriority = false,
}) => {
  const iconButtonVariants = {
    icon: "bg-white-900 font-medium justify-center w-auto",
    iconWithText: "text-xs bg-white-900 text-primary-green100 font-medium justify-center w-auto",
    iconWithTextFilled: "text-xs bg-green-100 text-primary-green100 font-medium justify-center w-auto",
    iconWithoutTextFilled: "bg-green-100 justify-center w-auto ",
    iconWithTextOutlined:
      "text-xs bg-white border-1 border-primary-green100 text-primary-green100 font-medium justify-center w-auto",
  };

  const colorClass = iconButtonVariants[buttonVariant] || "";

  return (
    <div className={`flex w-full items-center justify-center ${className}`}>
      <button
        type={"button"}
        className={`${colorClass} ${classes}`}
        onClick={onClick}
        aria-label={label || "button"}
        title={label || "button"}
      >
        <div className={imageContainerMargin} style={{ height: `${imgHeight}px`, width: `${imgWidth}px` }}>
          <CustomImage
            src={iconSrc}
            width={imgWidth}
            height={imgHeight}
            alt={altText || label || iconSrc}
            sizes="10vw"
            isPriority={isPriority}
          />
        </div>
        {buttonVariant !== "icon" && (
          <div
            className={`overflow-hidden text-ellipsis whitespace-nowrap text-center text-12 not-italic leading-4 md:block xxl:text-14 ${labelColor}`}
          >
            {label}
          </div>
        )}
      </button>
    </div>
  );
};

IconButtonComponent.propTypes = {
  label: PropTypes.string,
  buttonVariant: PropTypes.string,
  iconSrc: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.string,
  className: PropTypes.string,
  imgHeight: PropTypes.number,
  imgWidth: PropTypes.number,
  labelColor: PropTypes.string,
  imageContainerMargin: PropTypes.string,
  altText: PropTypes.string,
  isPriority: PropTypes.bool,
};

export default IconButtonComponent;
