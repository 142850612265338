import PropTypes from "prop-types";
import Typography from "../../../components/atoms/typography";
import { useEffect, useState } from "react";

/**
 * SaleAssist component displays a category name (if available)
 * and renders a div with a specified id.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.data - Data object containing information to render the component.
 * @param {string} [props.data.category_Name] - The name of the category to be displayed.
 * @param {string} [props.data.divId] - The id of the div element to be rendered.
 *
 * @returns {JSX.Element} The rendered SaleAssist component.
 */
const SaleAssist = ({ data, headingClass = "" }) => {
  const [isShowVideoSection, setIsShowVideoSection] = useState(false);
  /**
   * Mounts the sale assist video widget and initializes the source video.
   * This function retrieves the widget options from the provided data
   * and calls the appropriate methods from the `saleassistVideoTiles` API.
   *
   * @function showVideoWidget
   * @returns {void}
   */
  const showVideoWidget = () => {
    window.saleassistVideoTiles.mountWidget(data?.widgetOption);
    window.saleassistVideoTiles.mountSourceVideo({
      id: data?.widgetOption?.id,
    });
  };
  useEffect(() => {
    if (typeof window !== "undefined" && window.saleassistVideoTiles) {
      showVideoWidget();
      setIsShowVideoSection(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={`relative px-0 md:px-40 ${!isShowVideoSection ? "hidden" : ""}`}>
      {data?.category_Name && (
        <div className="relative w-full px-16 sm:mb-20 sm:px-40">
          <Typography variant="h1" Tag="p" classes={`font-600 text-16 md:mb-20 lg:text-24 xxl:text-32 ${headingClass}`}>
            {data.category_Name}
          </Typography>
        </div>
      )}
      <div id={data?.divId} />
    </div>
  );
};
SaleAssist.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line camelcase
    category_Name: PropTypes.string,
    divId: PropTypes.string,
    widgetOption: PropTypes.shape({
      id: PropTypes.string.isRequired,
      width: PropTypes.string,
      height: PropTypes.string,
      borderRadius: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
  }).isRequired,
  headingClass: PropTypes.string,
};
export default SaleAssist;
