"use client";
import { usePathname } from "next/navigation";
import PropTypes from "prop-types";

import { shallowEqual, useSelector } from "react-redux";
import { COUNTRIES } from "../../../../constants/common/common";
import useAnalytics from "../../../../analytics/useAnalytics";
import { cleverTapCommonEvents } from "../../../../analytics/clevertapConstants";
import { pageName } from "../../../../utils/common";
import CustomImage from "../../../atoms/customImage";

/**
 * FNPIcon component represents the FNP (Ferns N Petals) icon.
 * Clicking on this icon redirects the user to the FNP homepage.
 * @returns {JSX.Element} The JSX representation of the FNPIcon component.
 */
const FNPIcon = ({ imgWidth = 75, imgHeight = 37 }) => {
  const pathname = usePathname();
  const { trackEvent } = useAnalytics();
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");
  const { product, catalogId } = useSelector(
    (state) => ({
      product: state?.pdp?.clientCurrentClickedItem,
      catalogId: state.appConfig.catalogId,
    }),
    shallowEqual,
  );
  const urlIdentifier = product?.urlIdentifier;
  const homeURL = catalogId === COUNTRIES.INDIA ? "/" : `/${catalogId}/gifts-lp`;

  /**
   * Handles the click event on the FNP icon.
   * Redirects the user to the FNP homepage.
   */
  const onClick = () => {
    const pageHeaderMenuData = {
      page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
      pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
      menu_name: "FNP",
    };
    trackEvent(cleverTapCommonEvents.headerMenuClicked, pageHeaderMenuData, ["clevertap"]);
  };

  return (
    <a className={`cursor-pointer w-[${imgWidth}px]`} onClick={onClick} id="header_icon" href={homeURL}>
      <CustomImage
        src="/icons/fnp-gift.svg"
        width={imgWidth}
        height={imgHeight}
        alt="Ferns N Petals"
        isPriority={false}
      />
    </a>
  );
};

FNPIcon.propTypes = {
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
};

export default FNPIcon;
