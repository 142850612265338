import React, { useState } from "react";
import { SortMobile } from "../sorts";
import { FilterMobile } from "../filters";
import PropTypes from "prop-types";
import CustomImage from "../../atoms/customImage";
import { plpPageConstants } from "../../../constants/pageConstants/plpConstants";
import { lockBody, unlockBody } from "../../../utils/document";

/**
 *
 * @param {*} param0
 * @returns
 */
const FilterSort = ({ sortData, facetFilter, categoryId }) => {
  const [isFilter, setIsFilter] = useState(false);
  const [isSort, setIsSort] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [sortApplied, setSortApplied] = useState(sortData?.[0]?.level || plpPageConstants.RECOMMENDED);

  /**
   * Opens the filter panel.
   */
  const onFilterOpen = () => {
    if (!isFilter) {
      setIsFilter(true);
      lockBody();
    }
  };

  /**
   * Closes the filter panel.
   */
  const onFilterClose = () => {
    if (isFilter) {
      setIsFilter(false);
    }
    unlockBody();
  };

  /**
   * Opens the sort options panel.
   */
  const onSortOpen = () => {
    if (!isSort) {
      setIsSort(true);
      lockBody();
    }
  };

  /**
   * Closes the sort options panel.
   */
  const onSortClose = () => {
    if (isSort) {
      setIsSort(false);
    }
    unlockBody();
  };

  return (
    <>
      <div className="flex w-full items-center justify-between">
        {sortData?.length > 0 && (
          <div
            className={`flex w-full flex-1 items-center gap-12 px-16 py-10 ${facetFilter?.length ? "justify-end pr-32" : "justify-center"}`}
            onClick={onSortOpen}
          >
            <CustomImage src="/icons/sort.svg" alt="sort icon" width={18} height={18} isPriority={false} sizes="10vw" />
            <div className="flex flex-col gap-4 leading-0">
              <span className="text-14 font-600 text-fnp-300">{plpPageConstants.SORT_BY}</span>
              <span className="text-10 font-500 text-grey-500">{sortApplied}</span>
            </div>
          </div>
        )}
        {facetFilter?.length > 0 && (
          <>
            <div className="h-[70%] w-1 bg-grey-25" />
            <div
              className={`flex w-full flex-1 items-center gap-12 px-16 py-10 ${sortData?.length ? "justify-start pl-32" : "justify-center"}`}
              onClick={onFilterOpen}
            >
              <CustomImage
                src="/icons/filter.svg"
                alt="filter icon"
                width={18}
                height={18}
                isPriority={false}
                sizes="10vw"
              />
              <div className="flex flex-col gap-4 leading-0">
                <span className="text-14 font-600 text-fnp-300">{plpPageConstants.FILTER}</span>
                <span className="text-10 font-500 text-grey-500">
                  {filterCount ? `${filterCount} ${plpPageConstants.APPLIED}` : plpPageConstants.NOT_FILTER}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
      <SortMobile
        isOpen={isSort}
        onClose={onSortClose}
        data={sortData}
        categoryId={categoryId}
        selectSortName={sortApplied}
        setSelectSortName={(sortName) => setSortApplied(sortName)}
      />
      {facetFilter && (
        <FilterMobile
          isOpen={isFilter}
          onClose={onFilterClose}
          data={facetFilter}
          categoryId={categoryId}
          setFilterCount={(count) => setFilterCount(count)}
        />
      )}
    </>
  );
};
FilterSort.propTypes = {
  sortData: PropTypes.array.isRequired,
  facetFilter: PropTypes.array,
  categoryId: PropTypes.string.isRequired,
};

export default FilterSort;
