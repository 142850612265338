export const GA4_EVENTS = {
  PINCODE_ENTERED: "pincode_entered",
  PINCODE_AVAILABLE: "pincode_available",
  VIEW_ITEM_LIST: "view_item_list",
  FILTER_CLICKED: "filter_clicked",
  SELECT_ITEM: "select_item",
  SEARCH: "search",
  DELIVERY_LOCATION_ADDED: "delivery_location_added",
  AFTER_CITY_SELECTION: "after_city_selection",
  VIEW_ITEM: "view_item",
  WIDGET_VIEWED: "widget_viewed",
  ADD_TO_CART: "add_to_cart",
  BUY_NOW: "buy_now",
  NSA: "NSA",
  OUT_OF_STOCK: "out_of_stock",
  DELIVERY_DATE_SELECTION: "delivery_date_selection",
  SHIPPING_METHOD_SELECTED: "shipping_method_selected",
  TIME_SLOT_SELECTED: "time_slot_selected",
  VARIANT_CHANGED: "variant_changed",
  REMOVE_FROM_CART: "remove_from_cart",
  CONTINUE_WITHOUT_ADDON: "continue_without_addon",
  ADDONS_ADDED: "addons_added",
  ADDONS_REMOVE: "addons_remove",
  ADDON_SELECTED: "add_on_selected",
  LOGIN: "login",
  SIGNUP: "sign_up",
};

export const PASSWORD = "password";
export const SOCIAL_LOGIN = "social_login";
export const SOCIAL_LOGIN_KEY = "ctapSocialLogin";
