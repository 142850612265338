import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reviews: [],
  requestToGetReviews: false,
  responseFromGetReviews: false,
  failedToGetReviews: false,
  error: null,
};

export const ReviewSlice = createSlice({
  name: "PlpReviews",
  initialState,
  reducers: {
    requestToGetReviewsAction: (state) => {
      return {
        ...state,
        requestToGetReviews: true,
      };
    },
    responseFromGetReviewsAction: (state, action) => {
      return {
        ...state,
        requestToGetReviews: false,
        responseFromGetReviews: true,
        reviews: action.payload,
      };
    },
    failedToGetReviewsAction: (state, action) => {
      return {
        ...state,
        requestToGetReviews: false,
        failedToGetReviews: true,
        error: action.payload,
      };
    },
  },
});

export default ReviewSlice.reducer;
export const { failedToGetReviewsAction, requestToGetReviewsAction, responseFromGetReviewsAction } =
  ReviewSlice.actions;
