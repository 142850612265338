/**
 *
 * @returns
 */
const useAdobeAnalytics = () => {
  /**
   *
   */
  const init = () => {
    try {
      if (typeof window !== "undefined") {
        window.adobeDataLayer = window.adobeDataLayer || [];
      }
      console.log("Adobe Analytics Initialized");
    } catch (error) {
      console.log("Adobe not initialized properly");
    }
  };

  /**
   *
   * @param {*} eventName
   * @param {*} properties
   */
  const trackEvent = (eventName, properties = {}) => {
    try {
      window.adobeDataLayer.push({
        event: eventName,
        ...properties,
      });
    } catch (error) {
      console.log("Adobe not initialized properly");
    }
  };

  return { init, trackEvent };
};

export default useAdobeAnalytics;
