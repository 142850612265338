/* eslint-disable react/boolean-prop-naming */
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

/**
 * This component helps to render children (such as components or tags) at any level within a specific root element.
 * @param {*} props
 * @returns
 */
const Portal = ({ children, rootId, renderOnDidMount = false }) => {
  const isClient = typeof window !== "undefined";
  const mount = useRef(renderOnDidMount || !isClient ? null : document.getElementById(rootId));
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    if (renderOnDidMount && isClient) {
      mount.current = document.getElementById(rootId);
      if (!didMount) setDidMount(true);
    }
  }, []);

  if (mount.current) return createPortal(children, mount.current);
  return null;
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  rootId: PropTypes.string.isRequired,
  renderOnDidMount: PropTypes.bool,
};

export default Portal;
