/* eslint-disable react/jsx-boolean-value */
import PropTypes from "prop-types";
import { getFirstLetters, getTimeAgo } from "../../../utils/common";
import RatingStars from "../../molecules/ratingStars";
import CustomImage from "../../atoms/customImage";

/**
 * Component for displaying a testimonial card.
 * @param {object} props - The props object.
 * @param {object} props.review - The review object containing reviewer details.
 * @returns {JSX.Element} - Testimonial card component.
 */
const TestimonialCard = ({ review, isShowImage = false }) => {
  return (
    <div
      className="flex h-full w-full flex-wrap justify-between rounded-xl border-1 border-thinLightGrey p-16 md:flex-nowrap"
      itemProp="review"
      itemScope
      itemType="//schema.org/Review"
    >
      <div className={isShowImage && review?.productImageLink ? "w-full md:w-[calc(100%-126px)]" : "w-full"}>
        <div className="mb-16 flex w-full items-center">
          <div className="flex h-44 w-44 items-center justify-center rounded-full bg-grey-700 uppercase text-fnp-100">
            {getFirstLetters(review?.reviewerName)}
          </div>
          <div className="ml-10 w-[calc(100%-3.75rem)]">
            <RatingStars ratings={review.overallRating / 2} stars={5} />
            <div className="mt-4 flex w-full items-center gap-4 truncate text-12 text-grey-75">
              <span className="max-w-[50%] overflow-hidden text-ellipsis whitespace-nowrap md:max-w-[60%]">
                <div itemScope itemProp="author" itemType="http://schema.org/Person">
                  <meta itemProp="name" content={review?.reviewerName} />
                </div>
                {review?.reviewerName}
              </span>
              <span className="h-[4px] w-[4px] flex-none rounded-full bg-fnp-500" />
              <span className="text-12 text-gray-500" itemProp="datePublished">
                {getTimeAgo(review?.respondedDateMillis)}
              </span>
            </div>
          </div>
        </div>
        <div className="flex text-14 font-400 leading-5 text-fnp-500" itemProp="description">
          <p>{review.reviewText}</p>
        </div>
        <div className="mt-16 flex flex-wrap gap-8 font-500">
          {review?.deliveryOccasion && (
            <div className="w-auto max-w-[12.5rem] rounded-md bg-grey-100 p-4 px-6 text-12  font-500 capitalize leading-5 text-grey-75">
              Occasion:<span className="ml-4">{review.deliveryOccasion}</span>
            </div>
          )}
          {review?.deliveryCity && (
            <div className="w-auto max-w-[12.5rem] rounded-md bg-grey-100 p-4 px-6 text-12 font-500 capitalize leading-5 text-grey-75">
              City:<span className="ml-4">{review.deliveryCity}</span>
            </div>
          )}
        </div>
      </div>
      {isShowImage && review?.productImageLink && (
        <div className="mt-8 w-full md:mt-0 md:w-auto">
          <CustomImage
            src={process.env.NEXT_PUBLIC_IMAGE_DOMAIN + review?.productImageLink}
            alt={review?.productAlternateText}
            width={126}
            height={126}
            isPriority={false}
            borderRadius={8}
          />
        </div>
      )}
    </div>
  );
};

TestimonialCard.propTypes = {
  review: PropTypes.shape({
    reviewerName: PropTypes.string.isRequired,
    overallRating: PropTypes.number.isRequired,
    respondedDateMillis: PropTypes.number.isRequired,
    reviewText: PropTypes.string,
    deliveryOccasion: PropTypes.string,
    deliveryCity: PropTypes.string.isRequired,
    productImageLink: PropTypes.string,
    productAlternateText: PropTypes.string,
  }).isRequired,
  isShowImage: PropTypes.bool,
};

export default TestimonialCard;
