import { createSlice } from "@reduxjs/toolkit";
import { current } from "@reduxjs/toolkit";

const initialState = {
  details: {},
  appliedFilters: [],
  appliedSort: "",
  requestToProductListingDetails: false,
  responseFromProductListingDetails: false,
  failedToProductListingDetails: false,
  isLoaded: false,
  error: null,
};

export const PLPSlice = createSlice({
  name: "ProductListing",
  initialState,
  reducers: {
    requestToFetchProductListingAction: (state) => {
      return {
        ...state,
        requestToProductListingDetails: true,
        isLoaded: false,
      };
    },
    responseFromFetchProductListingAction: (state, action) => {
      return {
        ...state,
        responseFromProductListingDetails: true,
        details: {
          ...action.payload.response,
        },
        appliedFilters: action.payload.filters?.split(","),
        appliedSort: action.payload.sortOption,
        isLoaded: true,
      };
    },
    responseFromLoadMoreProductListingAction: (state, action) => {
      return {
        ...state,
        responseFromProductListingDetails: true,
        details: {
          ...action.payload,
          data: [...(current(state?.details)?.data || []), ...action.payload.data],
        },
        isLoaded: true,
      };
    },
    responseFromClearFiltersAction: (state, action) => {
      return {
        ...state,
        appliedSort: { ...state.appliedSort },
        appliedFilters: action.payload.filters,
        isLoaded: true,
      };
    },
    requestToAddListingDataAction: (state) => {
      return {
        ...state,
        isLoaded: false,
      };
    },
    responseFromAddListingDataAction: (state, action) => {
      return {
        ...state,
        details: action.payload.details,
        appliedSort: action.payload.sortOption || "",
        appliedFilters: action.payload.filters || [],
        isLoaded: true,
      };
    },
    failedToFetchProductListingAction: (state, action) => {
      return {
        ...state,
        details: {
          ...action.payload.response,
        },
        failedToProductListingDetails: true,
        error: action.payload,
        isLoaded: true,
      };
    },
  },
});

export default PLPSlice.reducer;
export const {
  failedToFetchProductListingAction,
  requestToFetchProductListingAction,
  responseFromFetchProductListingAction,
  responseFromLoadMoreProductListingAction,
  responseFromClearFiltersAction,
  requestToAddListingDataAction,
  responseFromAddListingDataAction,
} = PLPSlice.actions;
