import PropTypes from "prop-types";
import React from "react";
import ButtonComponent from "../../../atoms/button";
import Drawer from "../../../molecules/drawer";
import { Tabs } from "../../../molecules/tabion";
import Tab from "../../../molecules/tabion/tab";
import TabList from "../../../molecules/tabion/tabList";
import TabPanel from "../../../molecules/tabion/tabPanel";
import { plpCleverTapEvents, plpPageConstants } from "../../../../constants/pageConstants/plpConstants";
import CheckboxItemList from "../../../molecules/checkboxItemList";
import { useCallback, useEffect, useState } from "react";
import { requestToFetchProductListingAction } from "../../../../store/slices/plpSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { unlockBody } from "../../../../utils/document";
import { COUNTRIES, fnpPageType } from "../../../../constants/common/common";
import { getReplaceFiltersURL, removeFiltersParamsFromURL } from "../../productFilter/helper";
import { convertFilterObjToArray } from "../../../../utils/common";
import useAnalytics from "../../../../analytics/useAnalytics";
import { GA4_EVENTS } from "../../../../analytics/gaConstant";
import isEmpty from "lodash.isempty";
import { usePathname } from "next/navigation";

/**
 * FilterMobile component for filtering items on mobile devices.
 * @param {object} props - Component props.
 * @param {boolean} props.isOpen - Flag indicating if the filter is open.
 * @param {function} props.onClose - Function to handle closing the filter.
 * @param {Array} props.data - Array of filter data objects.
 * @param {number} props.totalProductCount - Total count of available products.
 * @returns {JSX.Element} FilterMobile component JSX.
 */
export default function FilterMobile({ isOpen, onClose, data, categoryId, setFilterCount }) {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const pathname = usePathname();
  const urlParams = new URLSearchParams(typeof window === "object" ? window?.location?.search : "");
  const { appliedSort, appliedFilters, totalProducts, pincode } = useSelector(
    (state) => ({
      appliedSort: state?.plp?.appliedSort,
      appliedFilters: state?.plp?.appliedFilters,
      totalProducts: state?.plp?.details?.total,
      pincode: state?.locationLock?.selectedDomesticLocation?.pincode,
    }),
    shallowEqual,
  );

  const catalogId = useSelector((state) => state.appConfig.catalogId);

  useEffect(() => {
    if (appliedFilters?.length > 0 && appliedFilters?.[0] !== "" && initialLoad) {
      setFilterCount?.(appliedFilters.length);
      setSelectedOptions(
        appliedFilters?.reduce((acc, item) => {
          const categoryValues = item?.split(":");
          return { ...acc, [categoryValues[1]]: `${categoryValues[0]}:${categoryValues[1]}` };
        }, {}),
      );
    }
  }, [appliedFilters]);

  /**
   * Dispatch the selected filters when option change listner triggered.
   */
  const onSelectedOptions = useCallback(
    (option, sortOption, selectedPincode) => {
      const appliedFilterArray = convertFilterObjToArray(option);
      setFilterCount(appliedFilterArray?.length);
      const newUrl = getReplaceFiltersURL(urlParams, appliedFilterArray);
      window.scrollTo(0, 0);
      dispatch(
        requestToFetchProductListingAction({
          page: plpPageConstants.INITIAL_PAGE,
          size: plpPageConstants.MOBILE_PRODUCT_PER_PAGE,
          geoId: catalogId || COUNTRIES?.INDIA,
          categoryUrl: `fnp.com/${categoryId}`,
          pincode: selectedPincode,
          filters: encodeURIComponent(appliedFilterArray),
          sortOption: encodeURIComponent(sortOption),
        }),
      );
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);
    },
    [selectedOptions],
  );

  useEffect(() => {
    if (!initialLoad) {
      onSelectedOptions(selectedOptions, appliedSort, pincode);
    }
  }, [selectedOptions]);

  /**
   *
   * @returns
   */
  const getLabel = (option) => {
    const priceTo = option?.priceTo;

    if (option?.priceFrom && priceTo)
      return `${(parseFloat(option.priceFrom) || 0).toFixed()} ${
        priceTo?.trim() === plpPageConstants?.Asterisk
          ? plpPageConstants.AndAbove
          : `${plpPageConstants.TO} ${(parseFloat(priceTo) || 0).toFixed()}`
      } (${option.count})`;

    return `${option?.displayName} (${option.count})` ?? "";
  };

  /**
   * @param {*} event
   * @param {*} facetName
   */
  const onOptionChange = (value, facetName) => {
    setInitialLoad(false);
    if (selectedOptions?.[value]) {
      const option = { ...selectedOptions };
      delete option[value];
      setSelectedOptions({ ...option });
    } else {
      setSelectedOptions((prev) => ({
        ...prev,
        [value]: `${facetName}:${value}`,
      }));
      trackEvent(
        plpCleverTapEvents.FILTER_SELECTED,
        {
          page_name: pathname,
          page_type: fnpPageType.CATEGORY,
          filter: `${facetName} : ${value}`,
        },
        ["clevertap"],
      );
      trackEvent(
        GA4_EVENTS.FILTER_CLICKED,
        { active_filters: [...appliedFilters, `${facetName} : ${value}`], selected_filter: value },
        ["ga4"],
      );
    }
  };

  /**
   * This will close the filter drawer.
   */
  const onApplyFilters = () => {
    setFilterCount?.(appliedFilters.length);
    unlockBody();
    onClose();
  };

  /**
   * This will clear all the applied filters.
   */
  const onClearFilters = () => {
    if (isEmpty(selectedOptions)) {
      return;
    }
    setInitialLoad(false);
    setSelectedOptions({});
    setFilterCount?.(0);
    setTimeout(() => {
      const newUrl = removeFiltersParamsFromURL(urlParams, "fq");
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);
      unlockBody();
      onClose();
    }, 100);
  };

  /**
   * Filtering the tabs which has more than zero filters available.
   */
  const getFilterTabs = () => {
    return data?.map((item, index) => {
      let totalCount = item?.facetOptions?.reduce((acc, option) => {
        return acc + option?.count;
      }, 0);
      return totalCount > 0 ? (
        <Tab key={`Tab-${item.facetFieldName}`} id={index}>
          <span className="flex w-full items-center justify-start p-18 text-14 font-400">
            {item.facetFieldDisplayName}
          </span>
        </Tab>
      ) : (
        <React.Fragment key={`Tab-${item.facetFieldName}`} />
      );
    });
  };

  return (
    <Drawer position="bottom" isOpen={isOpen} onDrawerClose={onClose} styles="p-0">
      <div className="h-[70vh]">
        <div className="flex gap-8 border-b border-gray-200  px-16 pb-12">
          <span className="text-16 font-600 text-fnp-500">{plpPageConstants.FILTER}</span>
          <span className="text-base text-grey-400">{`(${totalProducts} Gifts available)`}</span>
        </div>
        <div className="mb-10 flex h-full flex-row">
          <Tabs>
            <TabList
              className="flex w-full gap-0 py-2"
              parentClassName="overflow-y-scroll max-h-[80%]"
              isActiveStyle="bg-fnp-100 relative after:absolute after:top-1/2 after:-translate-y-1/2 after:w-4 after:h-4/6 after:bg-fnp-300 after:-left-1 after:rounded-r-3xl"
              tabListStyle="flex-col justify-around"
            >
              {getFilterTabs()}
            </TabList>
            {data?.map((item) => {
              return (
                <TabPanel
                  key={`Panel-${item?.facetFieldName}`}
                  className="border-fnp-100 flex max-h-[80%] w-full flex-col gap-5 overflow-auto border-l-2 px-5 py-16"
                >
                  {item["facetOptions"].map(
                    (option, index) =>
                      option.count > 0 && (
                        <div key={option.value} className="text-12">
                          <CheckboxItemList
                            id={`${option?.value?.replace(" ", "")}_${index}`}
                            label={getLabel(option)}
                            value={option.value}
                            onChange={() => onOptionChange(option?.value, item?.facetFieldName)}
                            data-parentfilter={item?.facetFieldName}
                            isChecked={!!selectedOptions?.[option.value]}
                            labelColor="text-grey-400"
                            activeColorClass="text-[12px]"
                            labelPlacement="largeGap"
                          />
                        </div>
                      ),
                  )}
                </TabPanel>
              );
            })}
          </Tabs>
        </div>
        <div className="absolute -bottom-16 flex w-full gap-16 bg-white-900 px-16 py-12">
          <div className="w-1/2">
            <ButtonComponent
              buttonVariant="outlined"
              label="Clear All"
              classes={isEmpty(selectedOptions) ? "opacity-50" : ""}
              size="normal"
              onClick={onClearFilters}
            />
          </div>
          <div className="w-1/2">
            <ButtonComponent
              buttonVariant="filled"
              label={plpPageConstants.SHOW_RESULTS}
              size="normal"
              onClick={onApplyFilters}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
}

FilterMobile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      facetFieldDisplayName: PropTypes.string.isRequired,
      facetFieldName: PropTypes.string.isRequired,
      facetOptions: PropTypes.arrayOf(
        PropTypes.shape({
          displayName: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired,
          selected: PropTypes.bool.isRequired,
          priceFrom: PropTypes.string,
          priceTo: PropTypes.string,
          subFacetOptions: PropTypes.arrayOf(
            PropTypes.shape({
              displayName: PropTypes.string.isRequired,
              value: PropTypes.string.isRequired,
              count: PropTypes.number.isRequired,
            }),
          ),
        }),
      ).isRequired,
    }),
  ).isRequired,
  categoryId: PropTypes.string.isRequired,
  setFilterCount: PropTypes.func.isRequired,
};
