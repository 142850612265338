/**
 * Extracts and formats the main details of a product from the given item object.
 * @param {Object} item - The item object containing product details.
 * @returns {Object} An object containing formatted product details including:
 */
const getMainProductDetails = (item) => {
  return {
    pincode: item.pincode,
    quantity: item.quantity,
    productId: item.productId,
    personalizedImage: item.personalizedImage,
    primaryProductCategoryId: item.primaryProductCategoryId,
    personalizedText: item.personalizedText || "",
    isProductPersonalizable: item.isProductPersonalizable,
    productName: item.productName,
    variantType: item.variantType,
    productImage: item.productImage,
    isVariant: item.isVariant,
    price: item.price,
    absoluteImageUrl: {
      alt: item.productName,
    },
  };
};

/**
 * Updates the cart data based on the response received from the server.
 * Processes items in the cart, differentiating between base items and addons,
 * and calculates totals for the cart.
 *
 * @param {Object} resp - The response object containing cart data.
 * @param {Array} resp.items - An array of items in the cart.
 * @param {number} resp.totalShipping - The total shipping cost.
 * @param {number} resp.subTotal - The subtotal of the cart before shipping and other charges.
 * @param {number} resp.total - The total cost of the cart.
 * @param {number} resp.donationAmount - The total donation amount included in the cart.
 *
 * @returns {Object} An updated cart data object containing:
 */
const updateViewCartJSON = (resp) => {
  const giftItems = [];
  const cartData = {};
  let baseItemTotal = 0;
  let addonsTotal = 0;
  let noOfAddons = 0;

  const totalAdditionalAmount = resp?.additionalPromoAdjustment?.reduce(
    (total, charge) => total + charge.additionalAmount,
    0,
  );

  if (resp.items && resp.items.length > 0) {
    resp.items.forEach((product, index) => {
      const item = { ...product };
      if (item.addon === "N") {
        item.addons = [];
        item.giftIndex = giftItems.length + 1;
        item.mainProduct = getMainProductDetails(item);
        item.cartItemIndex = index;
        item.isMultiOrderProduct = !!item.deliveryDatesList;
        giftItems.push(item);
        baseItemTotal += item.price;
        if (item.isProductPersonalizable || !!item.messageOnCake) {
          item.personalize = {
            isPersnlImg: item.isPersonalizedImageAvailable,
            isPersnlTxt: item.isPersonalizedTextAvailable,
            personalizedImage: item.personalizedImage,
            personalizedText: item.personalizedText,
            messageOnCake: item.messageOnCake,
          };
        }
      } else if (item.addon === "Y") {
        const giftLength = giftItems.length;
        giftItems[giftLength - 1].addons.push(item);
        addonsTotal += item.price;
        noOfAddons += item.quantity;
      }
    });
  }
  cartData.paymentInfo = {
    baseItemTotal,
    addonsTotal,
    noOfAddons,
    noOfBaseItems: giftItems.length,
    totalShipping: resp.totalShipping,
    subTotal: resp.subTotal,
    total: Number(resp.total) + Number(totalAdditionalAmount),
    donationAmount: resp.donationAmount,
    discountOnMRP: resp?.pricingSummary?.discountOnMRP,
    orderSavedAmount: resp?.pricingSummary?.orderSavedAmount,
    orderTotalMRP: resp?.pricingSummary?.orderTotalMRP,
    totalPromoDiscountAmount: resp?.pricingSummary?.totalPromoDiscountAmount,
    discount: resp?.discount,
    additionalPromoAdjustment: resp?.additionalPromoAdjustment,
  };
  cartData.items = giftItems;
  return { ...cartData };
};

export default updateViewCartJSON;
